import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, IconButton, Slide, DialogActions, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../API/useApiRequest";
import "./videofeed.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReportDialog = ({ openReportDialog, handleCloseReportDialog, selectedVideoId }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();

    const [reportReason, setReportReason] = useState("");

    // Services
    const { apiRequests } = useAPIRequest();

    const handleReportReasonChange = (event) => {
        setReportReason(event.target.value);
    };

    const handleReportSubmit = async () => {

        if (!reportReason.trim()) {
            addToast("Report reason cannot be empty", { appearance: "error" });
            return;
        }

        try {
            const { response } = await apiRequests({
                endPoint: `shorts/${selectedVideoId}/flag`,
                method: "POST",
                body: { reason: reportReason },
                addToast,
            });

            if (response.status === 200) {
                setReportReason("");
                addToast("Thank you for your feedback. Your report has been submitted successfully", { appearance: "success" });
                handleCloseReportDialog();
            } else {
                addToast("Failed to submit report. Please try again later.", { appearance: "error" });
            }
        } catch (error) {
            addToast("Error submitting report. Please try again later.", { appearance: "error" });
        }
    };

    return (
        <>
            <Dialog
                open={openReportDialog}
                onClose={handleCloseReportDialog}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                    <IconButton
                        edge="start"
                        onClick={handleCloseReportDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                    Report Short
                </DialogTitle>
                <DialogContent style={{ padding: "0px 20px" }}>

                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Reason for reporting"
                        value={reportReason}
                        onChange={handleReportReasonChange}
                        multiline
                        minRows={3}
                    />

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className='report-button'
                        onClick={handleReportSubmit}
                    >Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReportDialog
