import React, { useContext, useState, useEffect } from 'react'
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import './referpatient.css';
import { TextField, InputAdornment, Typography, Dialog, DialogTitle, DialogContent, Chip, IconButton, DialogActions, Button, Grid, Avatar, Stack, Slide } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useToasts } from "react-toast-notifications";
import useGetData from '../../API/useGetData';
import UrlRoute from "../../API/UrlRoute";
import InfiniteScroll from "react-infinite-scroll-component";
import { fileCompressor } from "../../components/FileCompressor";
import Constants from "../../MeddyConnectConstant";
import ViewUserProfile from '../../Profile/ViewProfile/ViewUserProfile';
import { YContext } from "../../Context/YContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let nextId = 0;

const ReferPatient = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();
    const history = useHistory();

    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filterType, setFilterType] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [referringDoctorList, setReferringDoctorList] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [patientName, setPatientName] = useState("");
    const [patientContactNo, setPatientContactNo] = useState("");
    const [patientMedicalHistory, setPatientMedicalHistory] = useState("");
    const [selectedPdfFiles, setSelectedPdfFiles] = useState([]);
    const [showSelectedImages, setShowSelectedImages] = useState([]);
    const [compressedImages, setCompressedImages] = useState([]);
    const [referredDoctorId, setReferredDoctorId] = useState("");
    const [referredDoctorName, setReferredDoctorName] = useState("");
    const [referredDoctorSpeciality, setReferredDoctorSpeciality] = useState("");
    const [referredDoctorProfileImage, setReferredDoctorProfileImage] = useState("");
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [specialityOptions, setSpecialityOptions] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);

    // Services
    const { apiRequests } = useAPIRequest();
    const { getCountry, getStates, getCity } = useGetData();
    const { currentUser, getUserInfo } = useContext(YContext);

    // Dialogs
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [openReferPatientDialog, setOpenReferPatientDialog] = useState(false);
    const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = useState(false);
    const [openViewProfileDialog, setOpenViewProfileDialog] = useState(false);

    const handleViewProfileDialogClickOpen = (id) => {
        setOpenViewProfileDialog(true);
        setSelectedUserId(id);
    };

    const handleViewProfileDialogClickClose = () => {
        setOpenViewProfileDialog(false);
        setSelectedUserId(null);
    };

    const handleAlertVerificationDialogClickOpen = () => {
        setOpenAlertVerificationDialog(true);
    };

    const handleAlertVerificationDialogClickClose = () => {
        setOpenAlertVerificationDialog(false);
    };

    const handleVerifyClick = () => {
        history.push(Constants.VERIFICATION);
    };

    const handleCancel = () => {
        history.push(Constants.HOME_SCREEN);
    };

    const handleFilterDialogClickOpen = () => {
        setOpenFilterDialog(true);
    };

    const handleFilterDialogClickClose = () => {
        setOpenFilterDialog(false);
    };

    const handleReferPatientDialogClickOpen = (id) => {
        setOpenReferPatientDialog(true);
        setReferredDoctorId(id);
        const doctorObject = getDoctorById(id, searchTerm.length > 0 ? searchResults : referringDoctorList);

        if (doctorObject) {
            const name = doctorObject.name || doctorObject.referredDoctorName;
            const speciality = doctorObject.speciality || doctorObject.referredDoctorSpeciality;
            const profileImage = doctorObject.referredDoctorProfile?.href || doctorObject.profileLink?.href;

            setReferredDoctorName(name);
            setReferredDoctorSpeciality(speciality);
            setReferredDoctorProfileImage(profileImage);
        }
    };

    const getDoctorById = (id, dataArray) => {
        // Check each item in dataArray
        for (const item of dataArray) {
            // Check if the item has a 'referredDoctorId' property
            if (item.referredDoctorId === id) {
                // If it has 'referredDoctorId', return the item
                return item;
            } else if (item.results) {
                // If it doesn't have 'userId', check 'results' property
                const doctorInResults = item.results.find(result => result.userId === id);
                if (doctorInResults) {
                    // If found in 'results', return the result
                    return doctorInResults;
                }
            }

        }

        return null;
    };


    const handleReferPatientDialogClickClose = () => {
        setOpenReferPatientDialog(false);
    };

    useEffect(() => {
        getUserInfo().then(() => {
            checkUserVerification();
        });
    }, [currentUser.identityVerified])

    const checkUserVerification = () => {
        setLoading(true);

        if (currentUser.identityVerified === true) {
            getReferenceData();
            getReferringDoctorList(pageNo);
            getDoctorSpecialities();
        } else if (currentUser.identityVerified === false) {
            handleAlertVerificationDialogClickOpen();
        } else {
            console.log("MeddyConnect")
        }

        setLoading(false);
    };

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    // Function to get doctor Specialities

    const getDoctorSpecialities = async () => {

        const { response, data, error } = await apiRequests({
            endPoint: `doctor/specialities`,
            method: "GET",
            addToast,
        });

        if (data) {
            setSpecialityOptions(data);
        } else {
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    // handle search related functions

    const handleSearchResultChange = (event) => {

        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);

        if (newSearchTerm.length >= 3) {

            getSearchResults(newSearchTerm, country, state, city, selectedSpeciality);

        } else if (newSearchTerm.length === 0) {

            setSearchTerm("");
            setCountry("");
            setState("");
            setCity("");
            setSelectedSpeciality("");
            setSelectedFilter([]);
            setSearchResults([]);
            setReferringDoctorList([]);
            setPageNo(0);
            getReferringDoctorList(0);

        }
    };

    // Function to get search results

    const getSearchResults = async (searchTerm, country, state, city, selectedSpeciality) => {

        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `search/users?q=${searchTerm}&country=${country}&state=${state}&city=${city}&speciality=${selectedSpeciality}`,
            method: "GET",
            addToast,
        });

        if (data) {
            const doctorEntities = data.filter(newEntity => newEntity.userType === "DOCTOR");
            setSearchResults(doctorEntities);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    // Function to get referring doctor list

    const getReferringDoctorList = async (pageNo) => {

        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `patient-referrals/by-referring-doc?page=${pageNo}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setReferringDoctorList((prevData) => {
                const uniqueEntities = data.entities.filter(newEntity =>
                    !prevData.some(existingEntity => existingEntity.id === newEntity.id)
                );
                return [...uniqueEntities, ...prevData];
            });

            setTotalPages(data.totalPages);
            setPageNo(data.page);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    // handle filter related functions

    const handleCountryClick = (countryName) => {
        setCountry(countryName);
        setStateChoices([]);
        setCityChoices([]);
        getStatesData(countryName);
        if (selectedFilter.length < 5 && country === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: countryName,
                type: "Country"
            }
            ]);
        }
    };

    const handleStateClick = (stateName) => {
        setState(stateName);
        setCityChoices([]);
        getCityData(country, stateName);
        if (selectedFilter.length < 5 && state === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: stateName,
                type: "State"
            }
            ]);
        }
    };

    const handleCityClick = (cityName) => {
        setCity(cityName)
        if (selectedFilter.length < 5 && city === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: cityName,
                type: "City"
            }
            ]);
        }
    };

    const handleSpecialityClick = (specialityName) => {
        setSelectedSpeciality(specialityName);
        if (selectedFilter.length < 5 && selectedSpeciality === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: specialityName,
                type: "Speciality"
            }
            ]);
        }
    };

    const handleFilterDelete = (type) => {
        if (type === "Country") {
            const removeItems = selectedFilter.filter(item =>
                item.type !== "Speciality"
            );
            const updatedCountry =
                selectedFilter.filter(item =>
                    !removeItems.includes(item)
                );
            setSelectedFilter(updatedCountry);
            setCountry("");
            setState("");
            setCity("");
            setSearchResults([]);
            getSearchResults(searchTerm, "", "", "", selectedSpeciality);
        } else if (type === "State") {
            const removeItems = selectedFilter.filter(item =>
                item.type !== "Country" && item.type !== "Speciality"
            );
            const updatedState =
                selectedFilter.filter(item =>
                    !removeItems.includes(item)
                );
            setSelectedFilter(updatedState);
            setState("");
            setCity("");
            setSearchResults([]);
            getSearchResults(searchTerm, country, "", "", selectedSpeciality);
        } else if (type === "City") {
            const updatedCity =
                selectedFilter.filter(item =>
                    item.type !== type
                );
            setSelectedFilter(updatedCity);
            setCity("");
            setSearchResults([]);
            getSearchResults(searchTerm, country, state, "", selectedSpeciality);
        } else if (type === "Speciality") {
            const updatedSpeciality =
                selectedFilter.filter(item =>
                    item.type !== type
                );
            setSelectedFilter(updatedSpeciality);
            setSelectedSpeciality("");
            setSearchResults([]);
            getSearchResults(searchTerm, country, state, city, "");
        }
    };

    const handleApplyFilterClick = (countryName, stateName, cityName, specialityName) => {

        setOpenFilterDialog(false);

        setSearchResults([]);
        getSearchResults(searchTerm, countryName, stateName, cityName, specialityName);

    };

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const images = [];
        const pdfs = [];

        if (selectedFilesArray.length > 5 || selectedFilesArray.some(file => file.type === 'application/pdf' && file.size > 4 * 1024 * 1024)) {
            if (selectedFilesArray.length > 5) {
                addToast("You can not upload more than five files", { appearance: "error" });
            } else {
                addToast("PDF file size should be 4 MB or less", { appearance: "error" });
            }
            return;
        }

        selectedFilesArray.forEach(file => {
            if (file.type.startsWith('image/')) {
                images.push(file);
            } else if (file.type === 'application/pdf') {
                pdfs.push(file);
            }
        });

        handleImageProcessing(images);
        setSelectedPdfFiles(prevPdfFiles => [...prevPdfFiles, ...pdfs]);

    }

    const handleImageProcessing = async (images) => {
        setFileLoading(true);
        const compressedImagesArray = [];

        const showImages = images.map((file) => URL.createObjectURL(file));
        setShowSelectedImages((prevImages) => prevImages.concat(showImages));

        for (const image of images) {

            try {

                const compressedImage = await fileCompressor(image, addToast);
                const imageData = {
                    type: image.type,
                    compressedImage: compressedImage
                };
                compressedImagesArray.push(imageData);

            } catch (error) {
                setFileLoading(false);
                console.error('Error compressing image:', error);
            }
        }

        setCompressedImages(prevCompressedImages => [...prevCompressedImages, ...compressedImagesArray]);
        setFileLoading(false);
    };

    const handleImageDelete = (id) => {

        const newImages = showSelectedImages.filter((photo, index) => index !== id)
        setShowSelectedImages(newImages)

        const fileCompressedArray = compressedImages.filter((photo, index) => index !== id)
        setCompressedImages(fileCompressedArray)

    };

    const handlePdfFileDelete = (id) => {

        const pdfFiles = selectedPdfFiles.filter((photo, index) => index !== id)
        setSelectedPdfFiles(pdfFiles)

    };

    function base64ToBlob(base64String) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray]);
    }

    const validateReferPatientForm = () => {

        let valid = true;

        if (patientName.trim() === '') {
            addToast('Patient Name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (patientContactNo.trim() === '') {
            addToast('Patient Contact No cannot be empty', { appearance: 'error' });
            valid = false;
        } else if (!/^[0-9]*$/.test(patientContactNo)) {
            addToast('Patient Contact Number must contain only numbers', { appearance: 'error' });
            valid = false;
        } else if (patientContactNo.length !== 10) {
            addToast('Patient Contact must be 10 digits long', { appearance: 'error' });
            valid = false;
        }

        if (patientMedicalHistory.trim() === '') {
            addToast('Patient Medical History cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (showSelectedImages.length > 5) {
            addToast("You can not upload more than five images", { appearance: "error" });
            valid = false;
        }

        if (selectedPdfFiles.length > 5) {
            addToast("You can not upload more than five attachments", { appearance: "error" });
            valid = false;
        }

        return valid;
    };

    const handleReferPatientSubmit = () => {

        if (validateReferPatientForm()) {
            submitReferPatient();
        }

    };

    const submitReferPatient = async () => {
        setIsApiCallInProgress(true);
        setFileLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: UrlRoute.PATIENT_REFERRALS_URL,
            method: "POST",
            body: {
                referredDoctorId: referredDoctorId,
                patientName: patientName,
                patientCountryCode: "+91",
                patientContactNo: patientContactNo,
                patientMedicalHistory: patientMedicalHistory,
            },
            addToast,
        });

        if (data) {
            let patientReferralsId = data.id;

            let isImageUploadedSuccessfully = false;
            let isAttachmentUploadedSuccessfully = false;

            addToast("Refer Patient successfully !!!", { appearance: "success" });

            if (showSelectedImages.length !== 0) {

                const photos = new FormData()

                // Iterate over the compressedImages array
                compressedImages.forEach((imageData, index) => {
                    try {
                        const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob

                        // Append the blob with the correct MIME type and filename
                        photos.append("attachments", blob, `image_${index}.${imageData.type}`, `image/${imageData.type}`);
                    } catch (error) {
                        console.error("Error converting image to blob:", error);
                    }
                });

                const { response, data, error } = await apiRequests({
                    endPoint: `${UrlRoute.UPLOAD_REFERRAL_DOCUMENT_URL}/${patientReferralsId}`,
                    method: "POST",
                    body: photos,
                    addToast,
                });
                if (data) {
                    isImageUploadedSuccessfully = true;
                    addToast("Images uploaded successfully !!!", { appearance: "success" });
                } else {
                    setFileLoading(false);
                    setIsApiCallInProgress(false);
                    addToast("Refer Patient Successfully, but there's an error uploading images, Please upload again.!", { appearance: "error" });
                }
            }

            if (selectedPdfFiles.length !== 0) {

                const formData = new FormData()

                for (let i = 0; i < selectedPdfFiles.length; i++) {
                    formData.append("attachments", selectedPdfFiles[i])
                }

                const { response, data, error } = await apiRequests({
                    endPoint: `${UrlRoute.UPLOAD_REFERRAL_DOCUMENT_URL}/${patientReferralsId}`,
                    method: "POST",
                    body: formData,
                    addToast,
                });
                if (data) {
                    isAttachmentUploadedSuccessfully = true;
                    addToast("PDF files uploaded successfully !!!", { appearance: "success" });
                } else {
                    setFileLoading(false);
                    setIsApiCallInProgress(false);
                    addToast("Refer Patient Successfully, but there's an error uploading attachments, Please upload again.!", { appearance: "error" });
                }
            }

            if (isImageUploadedSuccessfully === true || isAttachmentUploadedSuccessfully === true) {
                handleReferPatientDialogClickClose();
                setPatientName("");
                setPatientContactNo("");
                setPatientMedicalHistory("");
                setReferredDoctorId("");
                setSearchTerm("");
                setShowSelectedImages([]);
                setSelectedPdfFiles([]);
                setCompressedImages([]);
                setIsApiCallInProgress(false);
                setFileLoading(false);
                getReferringDoctorList(0);
            } else {
                handleReferPatientDialogClickClose();
                setPatientName("");
                setPatientContactNo("");
                setPatientMedicalHistory("");
                setReferredDoctorId("");
                setSearchTerm("");
                setIsApiCallInProgress(false);
                setFileLoading(false);
                getReferringDoctorList(0);
            }

        } else if (response.status === 409) {
            handleReferPatientDialogClickClose();
            setPatientName("");
            setPatientContactNo("");
            setPatientMedicalHistory("");
            setReferredDoctorId("");
            setSearchTerm("");
            setShowSelectedImages([]);
            setSelectedPdfFiles([]);
            setCompressedImages([]);
            setIsApiCallInProgress(false);
            setFileLoading(false);
            addToast("Currently you cannot use the referral feature as you are not a verified user. Kindly complete your verification to enjoy all MeddyConnect features !", { appearance: "success" });
            handleAlertVerificationDialogClickOpen();
        }
        else {
            handleReferPatientDialogClickClose();
            setPatientName("");
            setPatientContactNo("");
            setPatientMedicalHistory("");
            setReferredDoctorId("");
            setSearchTerm("");
            setShowSelectedImages([]);
            setSelectedPdfFiles([]);
            setCompressedImages([]);
            setIsApiCallInProgress(false);
            setFileLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    }

    return (
        <>
            <div style={{ height: "auto" }}>

                <div className="referpatient-filter-container" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000" }}>
                    <div className="referpatient-search-container" >
                        <div className="referpatient-search-filter-div" >

                            <div className="referpatient-searchbar-textfield">
                                <TextField fullWidth
                                    placeholder="Search Doctor..."
                                    name="searchbar"
                                    id="searchbar"
                                    autoComplete="off"
                                    value={searchTerm}
                                    onChange={handleSearchResultChange}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end" >
                                                < SearchIcon color="primary" />
                                            </InputAdornment>,
                                    }}
                                    className='referpatient-search-textfield'
                                />
                            </div>

                            <div className='icon-div'>
                                <IconButton onClick={handleFilterDialogClickOpen} disabled={!searchTerm} color="primary">
                                    <TuneOutlinedIcon className='referpatient-filter-icon' />
                                </IconButton>
                            </div>

                        </div>
                    </div>
                </div>

                <div style={{ display: "flex" }}>

                    <div className="referpatient-drawer">
                        <Navigation />
                    </div>

                    <div className="referpatient-container">

                        {selectedFilter.length > 0 &&
                            <Grid container spacing={0.8} style={{ margin: "0px 10px 12px 10px" }}>
                                {selectedFilter.map((item, index) => {
                                    return (
                                        <Grid item key={index}>
                                            <Typography variant="subtitle2" color="text.primary" style={{ display: "none" }}>
                                                {item.type}
                                            </Typography>
                                            <Chip label={item.title}
                                                variant="outlined"
                                                color="primary"
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                onDelete={() => handleFilterDelete(item.type)}
                                            />
                                        </Grid>
                                    );
                                })
                                }
                            </Grid>
                        }

                        {searchTerm.length > 2 ? (
                            // Render search results
                            <div className="refer-patient-list">
                                {searchResults.length > 0 ?
                                    searchResults?.map((item) => {
                                        return (
                                            item.results.map((value) => {
                                                return (
                                                    <div className="refer-patient" key={value.userId} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                                        <Avatar src={value.profileLink?.href} className='refer-doctor-picture' />
                                                        <div className="refer-patient-info">
                                                            <Typography align="left" color="text.primary" className='refer-patient-title'>
                                                                {value.name}
                                                            </Typography>
                                                            <Typography align="left" color="text.primary" className='refer-patient-subtitle'>
                                                                {value.speciality}
                                                            </Typography>
                                                            <Typography align="left" color="text.primary" className='refer-patient-description'>
                                                                {value.city && value.state ? `${value.city}, ${value.state}` : value.city || value.state}
                                                            </Typography>

                                                            <div className='refer-patient-btn'>
                                                                <Button variant="text" color="primary" className='view-btn' onClick={() => handleViewProfileDialogClickOpen(value.userId)}>View</Button>
                                                                {currentUser.id !== value.userId && (
                                                                    <Button variant="contained" color="primary" className='refer-btn' onClick={() => handleReferPatientDialogClickOpen(value.userId)}>Refer</Button>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })

                                        );
                                    })
                                    :
                                    <Grid container style={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                                No results found
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </div>

                        ) : (
                            // Render referring doctor list with infinite scroll
                            <div style={{ marginTop: "12px" }}>
                                <InfiniteScroll
                                    style={{ overflow: "hidden" }}
                                    dataLength={referringDoctorList.length}
                                    next={() => {
                                        if (pageNo < totalPages) {
                                            getReferringDoctorList(pageNo + 1);
                                        }
                                    }}
                                    hasMore={!(pageNo + 1 === totalPages)}
                                    loader={<YLoader loading={loading} />}
                                    endMessage={<span></span>}
                                >

                                    {referringDoctorList.length > 0 ?
                                        referringDoctorList.map((value) => {
                                            return (
                                                <div className="refer-patient" key={value.id} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                                    <Avatar src={value.referredDoctorProfile?.href} className='refer-doctor-picture' />
                                                    <div className="refer-patient-info">
                                                        <Typography align="left" color="text.primary" className='refer-patient-title'>
                                                            {value.referredDoctorName}
                                                        </Typography>
                                                        <Typography align="left" color="text.primary" className='refer-patient-subtitle'>
                                                            {value.referredDoctorSpeciality}
                                                        </Typography>
                                                        <Typography align="left" color="text.primary" className='refer-patient-description'>
                                                            {value.city && value.state ? `${value.city}, ${value.state}` : value.city || value.state}
                                                        </Typography>

                                                        <div className='refer-patient-btn'>
                                                            <Button variant="text" color="primary" className='view-btn' onClick={() => handleViewProfileDialogClickOpen(value.referredDoctorId)}>View</Button>
                                                            {currentUser.id !== value.referredDoctorId && (
                                                                <Button variant="contained" color="primary" className='refer-btn' onClick={() => handleReferPatientDialogClickOpen(value.referredDoctorId)}>Refer</Button>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <Grid container style={{ marginTop: "10px" }}>
                                            <Grid item xs={12}>
                                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                                    No results found
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }

                                </InfiniteScroll>

                            </div>
                        )}

                        <ViewUserProfile
                            openViewProfileDialog={openViewProfileDialog}
                            handleViewProfileDialogClickClose={handleViewProfileDialogClickClose}
                            selectedUserId={selectedUserId}
                        />


                        {/* ############################ Refer patient dialog ######################################### */}
                        <Dialog open={openReferPatientDialog}
                            // onClose={handleReferPatientDialogClickClose} 
                            fullScreen={fullScreen}
                        >
                            <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                                <IconButton
                                    edge="start"
                                    onClick={handleReferPatientDialogClickClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                    }}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                                Patient Details
                            </DialogTitle>
                            <DialogContent>

                                <Grid container direction="row" style={{ marginTop: "12px" }}>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={2} alignItems="center" >

                                            <Avatar alt="referred doctor image" src={referredDoctorProfileImage} style={{ height: "48px", width: "48px" }} />
                                            <Stack spacing={0.3} >

                                                <Typography align="left" color="text.primary">
                                                    <span className='refer-title-text'> Referring to :</span> {referredDoctorName}
                                                </Typography>

                                                <Typography align="left" color="text.primary">
                                                    {referredDoctorSpeciality}
                                                </Typography>

                                            </Stack>

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth
                                            required
                                            label="Patient Name"
                                            name="patientname"
                                            value={patientName}
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                const formattedValue = inputValue
                                                    .split(" ")
                                                    .map((word) => {
                                                        if (word === word.toUpperCase()) {
                                                            return word;
                                                        } else {
                                                            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                        }
                                                    })
                                                    .join(" ");

                                                setPatientName(formattedValue);
                                            }}
                                            autoComplete="off"
                                            id="patientname"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth
                                            required
                                            label="Patient Contact No"
                                            name="patientcontactno"
                                            inputProps={{ maxLength: 10, type: "tel" }}
                                            value={patientContactNo}
                                            onChange={(event) => setPatientContactNo(event.target.value)}
                                            autoComplete="off"
                                            id="patientcontactno"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        <Typography align="left" color="text.primary" className='refer-title-text'>
                                            Patient Medical History
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField fullWidth
                                            required
                                            label="Patient Medical History"
                                            name="patientmedicalhistory"
                                            value={patientMedicalHistory}
                                            onChange={(event) => setPatientMedicalHistory(event.target.value.toLowerCase()
                                                .split(" ")
                                                .map(word => {
                                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                                })
                                                .join(" "))}
                                            autoComplete="off"
                                            multiline
                                            minRows={3}
                                            id="patientmedicalhistory"
                                            variant="outlined"
                                        />
                                    </Grid>

                                    {/* ############################  upload document section ######################################### */}

                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        <Typography align="left" color="text.primary" className='refer-title-text'>
                                            Upload Reports
                                        </Typography>
                                    </Grid>

                                    {(showSelectedImages.length < 5 || selectedPdfFiles.length < 5) && (
                                        <Grid container direction="column" justifyContent="center" alignItems="center" className="patient-document-upload-container">
                                            <Grid item>
                                                <IconButton component="label" style={{ padding: "0px" }} >
                                                    <input hidden accept="image/*,.pdf" multiple type="file" onChange={handleFileChange} />
                                                    <CloudUploadIcon color="primary" className="patient-document-upload-Icon" />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" color="text.primary" align="center" gutterBottom>
                                                    Upload Documents
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {showSelectedImages.length > 0 &&
                                        <div className="patient-reports-gallery">
                                            {showSelectedImages.map((photo, index) => {
                                                return (
                                                    <div key={photo} style={{ position: "relative" }}>
                                                        <img src={photo} alt="upload" height="100%" width="100%" />
                                                        <CloseIcon style={{ position: "absolute", top: "6px", right: "6px", fontSize: fullScreen ? "18px" : "22px", color: "red", cursor: "pointer" }} onClick={() => handleImageDelete(index)} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }

                                    {selectedPdfFiles.length > 0 &&
                                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                                            {selectedPdfFiles.map((item, index) => {
                                                return (
                                                    <div className="patient-reports-pdf-container" key={index}>
                                                        <PictureAsPdfIcon className='patient-reports-pdf-icon' />
                                                        <Typography variant="subtitle1" className='patient-reports-pdf-filename'>
                                                            {item.name}
                                                        </Typography>
                                                        <ClearIcon className='patient-reports-pdf-icon' onClick={() => handlePdfFileDelete(index)} />
                                                    </div>
                                                )
                                            })}
                                        </Grid>
                                    }

                                </Grid>

                            </DialogContent>
                            {!isApiCallInProgress && (
                                <DialogActions>
                                    <Button variant="contained" fullWidth className='refer-dialog-button' onClick={handleReferPatientSubmit} >Refer Patient</Button>
                                </DialogActions>
                            )}
                        </Dialog>

                        {/* ############################ filter dialog ######################################### */}
                        <Dialog open={openFilterDialog} onClose={handleFilterDialogClickClose} fullScreen={fullScreen} fullWidth>
                            <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                                <IconButton
                                    edge="start"
                                    onClick={handleFilterDialogClickClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                    }}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                                Filters
                            </DialogTitle>
                            <DialogContent>
                                <>

                                    <Grid container style={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="text.primary">
                                                Select Filter
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Chip label="Country"
                                                variant="outlined"
                                                value={filterType}
                                                color="primary"
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                onClick={(e) => setFilterType("Country")}
                                            />
                                        </Grid>
                                        {country === "India" &&
                                            <Grid item>
                                                <Chip label="State"
                                                    variant="outlined"
                                                    value={filterType}
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onClick={(e) => setFilterType("State")}
                                                />
                                            </Grid>
                                        }
                                        {state.length > 0 &&
                                            <Grid item>
                                                <Chip label="City"
                                                    variant="outlined"
                                                    value={filterType}
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onClick={(e) => setFilterType("City")}
                                                />
                                            </Grid>
                                        }
                                        <Grid item>
                                            <Chip label="Speciality"
                                                variant="outlined"
                                                value={filterType}
                                                color="primary"
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                onClick={(e) => setFilterType("Speciality")}
                                            />
                                        </Grid>
                                    </Grid>

                                    {selectedFilter.length > 0 &&
                                        <>

                                            <Grid container style={{ marginTop: "10px" }}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="text.primary">
                                                        Applied Filters
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                {selectedFilter.map((item, index) => {
                                                    return (
                                                        <Grid item key={index}>
                                                            <Typography variant="subtitle2" color="text.primary" style={{ display: "none" }}>
                                                                {item.type}
                                                            </Typography>
                                                            <Chip label={item.title}
                                                                variant="outlined"
                                                                color="primary"
                                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                onDelete={() => handleFilterDelete(item.type)}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                                }
                                            </Grid>
                                        </>

                                    }

                                    <hr style={{ margin: "10px 0px" }} />

                                    {(() => {
                                        switch (filterType) {
                                            case "Country":
                                                return (
                                                    <>
                                                        {country === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(countryChoices) && countryChoices.map((option, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={option}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={country}
                                                                                    onClick={() => handleCountryClick(option)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "State":
                                                return (
                                                    <>
                                                        {state === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(stateChoices) && stateChoices.map((name, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={name}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={state}
                                                                                    onClick={() => handleStateClick(name)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "City":
                                                return (
                                                    <>
                                                        {state.length > 0 && city === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(cityChoices) && cityChoices.map((element, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={element}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={city}
                                                                                    onClick={() => handleCityClick(element)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "Speciality":
                                                return (
                                                    <>
                                                        {selectedSpeciality === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(specialityOptions) && specialityOptions.map((item, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={item}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={selectedSpeciality}
                                                                                    onClick={() => handleSpecialityClick(item)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            default:
                                                return <></>;
                                        }
                                    })()}

                                </>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" fullWidth className='refer-dialog-button' onClick={() => { handleApplyFilterClick(country, state, city, selectedSpeciality) }}> Apply </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openAlertVerificationDialog}
                            // onClose={handleAlertVerificationDialogClickClose}
                            TransitionComponent={Transition}
                        >
                            <DialogTitle >MeddyConnect</DialogTitle>
                            <DialogContent>

                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    Currently you cannot use the referral feature as you are not a verified user.
                                </Typography>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCancel} color="primary" >Cancel</Button>
                                <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>

            {
                fileLoading && (
                    <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
                        <CircularProgress color="primary" />
                    </Backdrop>
                )
            }

        </>
    )
}

export default ReferPatient
