import React, { useState, useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function YLoader({
  loading,
  muiBackdropRoot = {
    backgroundColor: "rgb(255 255 255 / 50%)",
    zIndex: 0,
  },
}) {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <Backdrop
      open={isLoading}
      style={muiBackdropRoot}
      onClick={() => setLoading(false)}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
