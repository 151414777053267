import React, { useState, useEffect, useContext } from 'react'
import {
    Grid, Typography,
    Paper, Button
} from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./pricecard.css";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import ClearIcon from '@mui/icons-material/Clear';
import { razorPayment } from "../../utils/RazorPaymnet";
import { YContext } from "../../Context/YContext";
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";

const Pricecard = () => {
    const [loading, setLoading] = useState(false);
    const [packageDetails, setPackageDetails] = useState([]);
    const { addToast } = useToasts();
    const history = useHistory();
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();

    const handleDelete = (id) => {

        setPackageDetails(packageDetails.map((item) => {
            if (item.id === id) {
                return { ...item, offerCode: item.offerCode = "" }
            } else {
                return item
            }
        }))

    };


    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = async () => {
        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `references/packages`,
            method: "GET",
            addToast,
        });

        if (data) {
            setPackageDetails(data.packages);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }


    };

    const paymentCheckout = async (code, offerCode) => {
        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `orders`,
            method: "POST",
            body: { packageCode: code, offerCode: offerCode },
            addToast,
        });
        if (data) {

            razorPayment(data.id,
                data.amount,
                `${currentUser.name}`,
                currentUser.email,
                currentUser.contactNo,
                updatePaymentStatus
            );
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }


    }

    const updatePaymentStatus = async (
        status,
        orderId,
        paymentId,
        paymentSignature
    ) => {
        const body =
            status == "failed"
                ? { id: orderId, paymentId }
                : { id: orderId, paymentId, paymentSignature };

        const { response, data, error } = await apiRequests({
            endPoint: `orders/${orderId}`,
            method: "PUT",
            body,
            addToast,
        });

        if (data && data.status == "captured") {

            addToast(
                "We have successfully received your payment. Welcome to MeddyConnect.!",
                { appearance: "success" }
            );

            try {
                await getUserInfo(); // Wait for user info update

                history.push(Constants.HOME_SCREEN); // Redirect after user info update          
            } catch (error) {
                console.error('Error updating user info:', error);
            }

        } else if (data && data.status == "failed") {
            addToast(
                "Your payment is failed, in case if you amount is deducted then it will be auto-refunded in 5-7 days. Please contact support@meddyconnect.com for more detail!!!",
                { appearance: "error" }
            );
        }
        setLoading(false);
    };

    let currentPackage = packageDetails.filter(member => member.packageCode === currentUser.packageType)

    let currentPackagePrice = currentPackage.map((item) => {
        return item.originalPrice
    })

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="price-card-drawer">
                        <Navigation />
                    </div>

                    <div className="price-card-container" >

                        {packageDetails.length > 0 ? (
                            packageDetails.map((value) => {
                                return (
                                    <Paper elevation={6} className="card-container" key={value.id}>
                                        <Grid container >

                                            <Grid item xs={12}>
                                                <Typography variant="h6" align='left' color="text.primary">
                                                    {value.packageTitle}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="span" color="text.primary">
                                                    &#8377;
                                                </Typography>

                                                {value.offerCode ? <>
                                                    <Typography variant="subtitle1" component="span" color="text.primary" style={{ marginLeft: "8px", textDecorationLine: "line-through" }}>
                                                        {value.originalPrice}
                                                    </Typography>
                                                    <Typography variant="subtitle1" component="span" color="text.primary" style={{ marginLeft: "8px" }}>
                                                        {value.discountedPrice}
                                                    </Typography> </>
                                                    :
                                                    <Typography variant="subtitle1" component="span" color="text.primary" style={{ marginLeft: "8px" }}>
                                                        {value.originalPrice}
                                                    </Typography>
                                                }

                                                <Typography variant="subtitle1" component="span" color="text.primary" style={{ marginLeft: "8px" }}>
                                                    for {value.durationMonths} Month
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Typography variant="subtitle1" color="text.primary" component="span" >
                                                    OFFER:
                                                </Typography>

                                                {value.offerCode ?
                                                    <Button variant="outlined" color="primary" endIcon={<ClearIcon />} className="offer-btn" size="small" onClick={() => handleDelete(value.id)}>
                                                        {value.offerCode}
                                                    </Button> : ""}

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" color="text.primary" component="span">
                                                    Items:
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.primary" component="span" style={{ marginLeft: "10px" }}>
                                                    {value.noOfProducts}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={10}>
                                                <Typography variant="subtitle1" color="text.primary">
                                                    {value.description}
                                                </Typography>
                                            </Grid>

                                            {currentPackagePrice < value.originalPrice && (
                                                <Grid item xs={3} sm={2}>
                                                    <Button variant="contained" className="card-btn" size="large" color="primary"
                                                        onClick={() => paymentCheckout(value.packageCode, value.offerCode)}>
                                                        {currentUser.packageType !== "MEDDY_CONNECT_STARTER" ? "Upgrade" : "Buy"}
                                                    </Button>
                                                </Grid>
                                            )}

                                        </Grid>
                                    </Paper>
                                );
                            })
                        ) : (
                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        No results found
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                        }

                    </div>
                </div>


                <YLoader loading={loading} />
            </div>
        </>

    )
}

export default Pricecard

