import React from "react";
import { Paper, Typography, IconButton } from "@mui/material";
import "../../Profile/Profile.css";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { useTheme } from '@mui/material/styles';

const ViewBlog = ({ userBlogData }) => {
    const theme = useTheme();

    return (
        <>
            {/* ############################ Blog section ######################################### */}
            <div>

                <div>
                    <Typography align="left" className="view-user-title" color="text.primary">
                        Blog
                    </Typography>
                </div>

                {userBlogData && userBlogData.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>
                            {value.link.href ?
                                <div className="section_container">
                                    <IconButton>
                                        <AutoStoriesIcon color="primary" fontSize="large" />
                                    </IconButton>
                                    <div className='section_details'>
                                        <a href="#" target="_blank" rel="noreferrer"
                                            style={{ textDecoration: "none", color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                                            {value.link.href}
                                        </a>
                                    </div>

                                </div>
                                : " "}
                        </Paper>
                    );
                })}

            </div>
        </>
    )
}

export default ViewBlog
