import React from 'react'
import './referraldashboardpage.css';
import { Grid, Button, Typography } from '@mui/material';
import LineChartReferredByMe from './LineChartReferredByMe';
import LineChartReferredToMe from './LineChartReferredToMe';

const Dashboard = () => {
    return (
        <>
            <Grid container className='Referred-container' >
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom align='left'>
                        Referred to Me
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LineChartReferredByMe />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth className='Referred-button'>View my referral</Button>
                </Grid>
            </Grid>

            <Grid container className='Referred-container'>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom align='left'>
                        Referred by Me
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LineChartReferredToMe />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth className='Referred-button'>View referral</Button>
                </Grid>
            </Grid>


        </>
    )
}

export default Dashboard