import React, { useState, useEffect, useContext } from 'react'
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./pricecard.css";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import { YContext } from "../../Context/YContext";
import { useHistory } from "react-router-dom";
import { Typography, Grid, Button } from '@mui/material';
import moment from "moment";

const Packagedetails = () => {
    const { currentUser, getUser } = useContext(YContext);
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [currentPackage, setCurrentPackage] = useState("");
    const history = useHistory();

    // Services
    const { apiRequests } = useAPIRequest();

    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = async () => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `orders/active-package`,
            method: "GET",
            addToast,
        });

        if (data) {
            setCurrentPackage(data);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const { description, endDate, packageTitle } = currentPackage

    const handleBuy = () => {
        history.push("/packages");
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="price-card-drawer">
                        <Navigation />
                    </div>

                    <div className="price-card-container" >

                        <div>
                            <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
                                <Grid item xs={12}>
                                    <Typography align='center' variant="h5" gutterBottom color="text.primary">
                                        Hi, {currentUser.name}
                                    </Typography>
                                </Grid>
                                {currentUser.packageType !== "MEDDY_CONNECT_STARTER" ?

                                    <Grid item xs={12}>
                                        <Typography align='center' variant="subtitle2" color="text.primary" gutterBottom>
                                            You are currently subscribe to {packageTitle} plan.
                                        </Typography>
                                        <Typography align='center' variant="subtitle2" color="text.primary" gutterBottom>
                                            {description}
                                        </Typography>
                                        <Typography align='center' variant="subtitle2" color="text.primary" gutterBottom>
                                            Expiry Date:  {moment(endDate).format("D-MMM-YYYY")}
                                        </Typography>
                                    </Grid>
                                    :
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12} style={{ color: "rgb(144, 144, 144)" }}>
                                            <Typography align='center' variant="subtitle2" gutterBottom>
                                                Currently you don't have any subscription.
                                            </Typography>
                                            <Typography align='center' variant="subtitle2" gutterBottom>
                                                Subscribe to MeddyConnect package and start selling
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={4}>
                                    <Button fullWidth variant="contained" color="primary" className="package-btn" onClick={handleBuy}>
                                        {currentUser.packageType !== "MEDDY_CONNECT_STARTER" ? "Upgrade" : "Subscribe"}
                                    </Button>
                                </Grid>
                            </Grid>

                        </div>

                    </div>
                </div>


                <YLoader loading={loading} />
            </div>
        </>

    )
}

export default Packagedetails

