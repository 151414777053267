import React, { useState, useEffect, useContext } from "react";
import { Paper, Grid, Button, Typography, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Slide } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../Profile/Profile.css";
import useAPIRequest from "../../API/useApiRequest";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import GroupsIcon from '@mui/icons-material/Groups';
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Membership = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    // Variables
    const [userId, setUserId] = useState("");
    const [userType, setUserType] = useState("");
    const [membershipsDetails, setMembershipsDetails] = useState([]);
    const [membership, setMembership] = useState('');
    const [membershipId, setMembershipId] = useState("");
    const [editMembership, setEditMembership] = useState('');

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getUserType } = useUserType();

    // Dialogs
    const [openMembershipsDialog, setOpenMembershipsDialog] = React.useState(false);
    const [openEditMembershipDialog, setOpenEditMembershipDialog] = useState(false);

    const handleMembershipsDialogClickClose = () => {
        setOpenMembershipsDialog(false);
    };
    const handleMembershipsDialogClickOpen = () => {
        setOpenMembershipsDialog(true);
    };

    const handleEditMembershipDialogClickClose = () => {
        setOpenEditMembershipDialog(false);
    };
    const handleEditMembershipDialogClickOpen = (id) => {
        setOpenEditMembershipDialog(true);
        getEditMembershipDetailsWithId(id);
    };

    // Functions
    const getUserMembershipDetails = () => {

        setLoading(true);
        setUserId(currentUser.id);
        setUserType(currentUser.userType);
        setMembershipsDetails(currentUser.membershipDetails);
        setLoading(false);
    }

    useEffect(() => {
        getUserMembershipDetails();
    }, [currentUser])

    // Functions for create membership

    const handleAddUserMemberships = (membership, userType, userId) => {

        const newMembership = {
            membership: membership,
        };

        createUserMembership(userType, userId, newMembership);

    };

    const createUserMembership = async (userType, userId, newMembership) => {

        setOpenMembershipsDialog(false);
        const user = getUserType(userType);
        const membership = [newMembership]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                membershipDetails: membership,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
            setMembership('');
        }

    }

    // Functions for edit membership

    const getEditMembershipDetailsWithId = (id) => {

        let membership = membershipsDetails.find(item => item.id === id)

        setMembershipId(membership.id);
        setEditMembership(membership.membership);

    };

    const handleEditUserMembership = (editMembership, membershipId, userType, userId) => {

        const editMembershipDetails = {
            membership: editMembership,
            id: membershipId,
        };

        editUserMembership(userType, userId, editMembershipDetails);
    };

    const editUserMembership = async (userType, userId, editMembershipDetails) => {

        setOpenEditMembershipDialog(false);
        const user = getUserType(userType);
        const membership = [editMembershipDetails]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                membershipDetails: membership,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
        }

    }

    // Functions for delete membership

    const deleteUserMemberships = async (userType, id) => {

        const user = getUserType(userType);

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/membership/${id}`,
            method: "DELETE",
            addToast,
        });

        if (response.status === 200) {
            getUserInfo();
        };

    }

    const validateMembershipForm = () => {

        let valid = true;

        if (membership.trim() === '') {
            addToast('Membership name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleAddMembershipsSubmit = () => {

        if (validateMembershipForm()) {
            handleAddUserMemberships(membership, userType, userId);
        }
    };

    const validateEditMembershipForm = () => {

        let valid = true;

        if (editMembership.trim() === '') {
            addToast('Membership name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleEditMembershipsSubmit = () => {

        if (validateEditMembershipForm()) {
            handleEditUserMembership(editMembership, membershipId, userType, userId)
        }
    };

    return (
        <>
            {/* ############################ Membership section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleMembershipsDialogClickOpen} className="profile_btn">
                        Memberships
                    </Button>
                </div>

                {membershipsDetails && membershipsDetails.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>

                            <div className="section_container">
                                <IconButton>
                                    <GroupsIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {value.membership}
                                    </Typography>
                                </div>
                                <div className="section_iconbtn_container">
                                    <IconButton onClick={() => handleEditMembershipDialogClickOpen(value.id)} className="icon_btn">
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => deleteUserMemberships(userType, value.id)} className="icon_btn">
                                        <DeleteIcon color="primary" />
                                    </IconButton>
                                </div>
                            </div>

                        </Paper>
                    );
                })}
                {/* ############################ Create Membership dialog ######################################### */}
                <Dialog open={openMembershipsDialog} onClose={handleMembershipsDialogClickClose} fullScreen={fullScreen} maxWidth="sm" fullWidth TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleMembershipsDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Membership
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Membership"
                                    name="membership"
                                    value={membership}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setMembership(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="membership"
                                    variant="outlined"
                                    required
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={handleAddMembershipsSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                {/* ############################ Edit Membership dialog ######################################### */}
                <Dialog open={openEditMembershipDialog} onClose={handleEditMembershipDialogClickClose} fullScreen={fullScreen} maxWidth="sm" fullWidth TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleEditMembershipDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Edit Membership
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Membership"
                                    name="editMembership"
                                    value={editMembership}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setEditMembership(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="editMembership"
                                    variant="outlined"
                                    required
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={handleEditMembershipsSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>


                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Membership