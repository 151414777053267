import React, { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, Typography, Card,CardMedia,CardContent,CardActions,Button } from '@mui/material';
import { FaCalendarAlt } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import "./managemagazines.css";


const ManageMagazines = () => {
    const [loading, setLoading] = useState(true);

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="manage_magazines-drawer">
                        <Navigation />
                    </div>

                    <div className="manage_magazines-container">

                        <Grid container direction="row" spacing={2} className='all_magazines-card-container'>
                            {magazinesData.map((element) => {
                                return (
                                    <Grid item xs={12} sm={6} key={element.id}>
                                        <Card elevation={3} className='all_magazines-card' >
                                            <CardMedia
                                                component="img"
                                                height="180"
                                                image={element.eventimage}
                                                alt="Magazine images"
                                            />
                                            <CardContent style={{ padding: "16px 16px 0px 16px" }}>

                                                <Grid container direction="row" rowSpacing={0.5}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" className="manage_magazines-title">
                                                            {element.eventname}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">
                                                            <FaCalendarAlt className='all_magazines-icon' />
                                                            {element.month},  {element.year}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                            </CardContent>
                                            <CardActions>

                                                <Grid container direction="row" justifyContent="flex-end">
                                                    <Grid item>
                                                    <Button variant="contained" className="manage_magazines-button">Delete</Button>
                                                    </Grid>
                                                </Grid>

                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })
                            }
                        </Grid>

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default ManageMagazines

const magazinesData =
    [
        {
            id: 0,
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "Biotech Week",
            month: "Oct",
            year: "2020"
        },
        {
            id: 1,
            eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
            eventname: "Hippocrates Med Review",
            month: "Dec",
            year: "2021"
        },
        {
            id: 2,
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "Scientific American",
            month: "Apr",
            year: "2019"
        },
        {
            id: 3,
            eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
            eventname: "The Lancet",
            month: "Jun",
            year: "2015"
        },

    ];

