import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import swDev from "./swDev";
import { YProvider } from "./script/Context/YContext";
import MyThemeProvider from "./script/Context/MyThemeProvider";
import { ToastProvider } from "react-toast-notifications";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <MyThemeProvider>
    <ToastProvider
      autoDismiss
      autoDismissTimeout={3000}
      placement="bottom-right"
    >
      <Router>
        <YProvider>
          <App />
        </YProvider>
      </Router>
    </ToastProvider>
  </MyThemeProvider>
);

swDev();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

