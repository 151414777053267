import { useState, useEffect, createContext, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getTheme } from "../utils/getTheme";
import CssBaseline from '@mui/material/CssBaseline';

export const ThemeContext = createContext({});

const MyThemeProvider = ({ children }) => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState(prefersDarkMode ? "dark" : "light");

    useEffect(() => {
        setMode(prefersDarkMode ? "dark" : "light");
    }, [prefersDarkMode]);

    const theme = useMemo(() => createTheme(getTheme(mode)), [mode]);

    return (
        <ThemeContext.Provider value={{ mode }}>
            <ThemeProvider theme={theme}>
                {/* <CssBaseline /> */}
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

export default MyThemeProvider;
