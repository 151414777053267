import { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Typography, IconButton, Grid } from '@mui/material';
import "./buycategory.css";
import { useToasts } from "react-toast-notifications";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import usePublicAPIRequest from '../../API/useApiPublicRequest';
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";
import { useTheme } from '@mui/material/styles';
import UrlRoute from "../../API/UrlRoute";


const BuyCategory = (props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();

    const data = props.location.state;

    // useState to store Item Categories
    const [itemCategories, setItemCategories] = useState([]);

    // Services
    const { apiPublicRequests } = usePublicAPIRequest();

    useEffect(() => {
        getItemCategories();
    }, []);

    const getItemCategories = async () => {

        setLoading(true);
        const { response, data, error } = await apiPublicRequests({
            endPoint: UrlRoute.PRODUCT_CATEGORIES_URL,
            method: "GET",
            addToast,
        });

        if (data) {
            setItemCategories(data);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const handleCategoryClick = (categoryType) => {

        let selectedCategory = itemCategories.filter(item => item.categoryLink.rel === categoryType)

        handleCategoryList(selectedCategory);
    };

    const handleCategoryList = (selectedCategory) => {

        const categories = [...selectedCategory, data];

        history.push({
            pathname: Constants.PRODUCT_SUBCATEGORY,
            state: {
                data: categories
            }
        })
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="buynew-category-Drawer">
                        <Navigation />
                    </div>

                    <div className="buynew-category-container">

                        <div className="buynew-category-list">
                            {itemCategories.length > 0 ? (
                                itemCategories.map((value, index) => {
                                    return (
                                        <div className="category-list" key={index} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                            <img src={value.categoryLink?.href} alt='instrument image' className='category-list-img' style={{ backgroundColor: theme.palette.mode === "light" ? "#f0f0f0" : "#fff" }} />
                                            <div className="category-list-info">
                                                <Typography className='category-list-Name categories_title' align="left" color="text.primary">
                                                    {value.categoryLink?.rel}
                                                </Typography>
                                            </div>

                                            <IconButton className="categories-btn-container" style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} onClick={() => handleCategoryClick(value.categoryLink?.rel)}>
                                                <ArrowForwardIosIcon className="categories-icon" />
                                            </IconButton>

                                        </div>
                                    );
                                })
                            ) : (
                                <Grid container style={{ marginTop: "10px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                            No results found
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                            }
                        </div>

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default BuyCategory
