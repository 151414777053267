import React, { useState, useEffect } from "react";
import useTokenService from "../utils/useTokenService";
import Urls from "../API/UrlRoute";
import Constants from "../MeddyConnectConstant";

const YContext = React.createContext();

const YProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ show: false, msg: "" });
  const [currentUser, setCurrentUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);

  // Upload state
  const [isProcessOngoing, setIsProcessOngoing] = useState(false);

  const { getOrGenerateToken } = useTokenService();
  const axios = require("axios").create({
    baseURL: process.env.REACT_APP_URL,
  });

  const getUserInfo = async () => {

    const countryCode = localStorage.getItem(Constants.COUNTRY_CODE);

    if (countryCode !== null) {

      const token = await getOrGenerateToken();
      getUser(token);

    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUser = async (token) => {
    const options = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let response = await axios.get(Urls.USER_INFO_URL, options);
      if (response.data) {
        setCurrentUser(response.data);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  const startProcess = () => setIsProcessOngoing(true);
  const finishProcess = () => setIsProcessOngoing(false);

  return (
    <YContext.Provider
      value={{
        error,
        currentUser,
        setCurrentUser,
        isLoading,
        setError,
        setIsLoading,
        isAuthenticated,
        setIsAuthenticated,
        getUserInfo,
        showSubscription,
        setShowSubscription,
        // Upload management
        isProcessOngoing,
        startProcess,
        finishProcess,
      }}
    >
      {children}
    </YContext.Provider>
  );
};
export { YProvider, YContext };
