import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Chip, Avatar, Button, CardActions } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './attendtraining.css';

const RecommendationCards = () => {
    return (
        <div className='recommdation-card'>
            {data.map((element) => {
                return (
                    <Grid container direction="column" className='attendtraining-card-container' key={element.id}>
                        <Grid item xs={12}>
                            <Card elevation={3} className='attendtraining-card'>
                                <CardContent>
                                    <div className='training-card'>

                                        <div className="training-div">
                                            <div className="training-title training-title-text">
                                                <Typography variant="h6" align='left' className='training_text'>
                                                    {element.trainingtitle}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="subtitle1">
                                                    <VideocamIcon className='attendtraining-icon' />
                                                    {element.trainingtype}
                                                </Typography>
                                            </div>
                                        </div>

                                        <Grid container direction="row" spacing={1}>
                                            <Grid item>
                                                <Avatar
                                                    alt="trainername"
                                                    src="https://images.unsplash.com/photo-1584043720379-b56cd9199c94?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                                    sx={{ width: 35, height: 35 }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" component="span">
                                                    Mehul Kuwadia
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        < Grid container style={{ marginTop: "5px", minHeight: "80px" }}>
                                            {element.trainingtags.map((tag) => {
                                                return (
                                                    <Grid item style={{ marginRight: "8px" }} key={tag.tagsid}>
                                                        <Chip label={tag.tagname} variant="outlined" className='training-tags' />
                                                    </Grid>
                                                );
                                            })
                                            }
                                        </Grid>

                                        <Grid container direction="row">
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" >
                                                    &#8377; {element.trainingcost}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    <AccessTimeIcon className='training-icon' />
                                                    {element.trainingDate}, {element.trainingtime}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" className='attendtraining-description' >
                                                    {element.trainingdetails}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </div>

                                </CardContent>
                                <CardActions>

                                    <Grid container direction="row" justifyContent="flex-end">
                                        <Grid item>
                                            <Button variant="contained" fullWidth className='register-btn'>Register</Button>
                                        </Grid>
                                    </Grid>

                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                );
            })
            }

        </div>
    )
}

export default RecommendationCards

const data =
    [
        {
            id: 0,
            trainingtitle: "Root canal treatment Root canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth.Root canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth.",
            trainingtype: "Online",
            trainingdetails: "Root canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth. During the root canal process, the inflamed pulp is removed. The surfaces inside the tooth are then cleaned and disinfected, and a filling is placed to seal the space,Root canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth. During the root canal process, the inflamed pulp is removed. The surfaces inside the tooth are then cleaned and disinfected, and a filling is placed to seal the space",
            trainingcost: "500",
            trainingDate: "May 24, 2023",
            trainingtime: "9.00 AM To 5.00 PM",
            trainingtags: [
                {
                    tagsid: 0,
                    tagname: "Orthopedic"
                },
                {
                    tagsid: 1,
                    tagname: "Radiology"
                }
            ]
        },
        {
            id: 1,
            trainingtitle: "General Human Anatomy including Osteology and Histology",
            trainingtype: "Offline",
            trainingdetails: "BDS course is similar to MBBS in terms of duration, eligibility and Admission process. The BDS course is opted by students who become professional dentists.Root canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth. During the root canal process, the inflamed pulp is removed. The surfaces inside the tooth are then cleaned and disinfected, and a filling is placed to seal the spaceRoot canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth. During the root canal process, the inflamed pulp is removed. The surfaces inside the tooth are then cleaned and disinfected, and a filling is placed to seal the space",
            trainingcost: "1000",
            trainingDate: "Jan 24, 2023",
            trainingtime: "10.00 AM To 7.00 PM",
            trainingtags: [
                {
                    tagsid: 0,
                    tagname: "Orthopedic"
                },
                {
                    tagsid: 1,
                    tagname: "Radiology"
                },
                {
                    tagsid: 2,
                    tagname: "MBBS"
                }
            ]
        },
        {
            id: 2,
            trainingtitle: "Oral Medicine and Roentgenology.",
            trainingtype: "Offline",
            trainingdetails: "Root canal treatment is a dental procedure that relieves pain caused by an infected or abscessed tooth. During the root canal process, the inflamed pulp is removed. The surfaces inside the tooth are then cleaned and disinfected, and a filling is placed to seal the space",
            trainingcost: "2500",
            trainingDate: "May 24, 2023",
            trainingtime: "9.00 AM To 5.00 PM",
            trainingtags: [
                {
                    tagsid: 0,
                    tagname: "Orthopedic"
                },
            ]
        },
        {
            id: 3,
            trainingtitle: "Oral Surgery, Local Anesthesia and General Anesthesia",
            trainingtype: "Online",
            trainingdetails: "BDS course is similar to MBBS in terms of duration, eligibility and Admission process. The BDS course is opted by students who become professional dentists.",
            trainingcost: "3000",
            trainingDate: "Jan 24, 2023",
            trainingtime: "10.00 AM To 7.00 PM",
            trainingtags: [
                {
                    tagsid: 0,
                    tagname: "Orthopedic"
                },
                {
                    tagsid: 1,
                    tagname: "Radiology"
                },
                {
                    tagsid: 2,
                    tagname: "Nursing"
                },
                {
                    tagsid: 3,
                    tagname: "Anatomy"
                }
            ]
        },


    ];