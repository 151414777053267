import { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardActions, Avatar, Typography, Grid, Button, Chip, IconButton, InputAdornment, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useGetData from '../../API/useGetData';
import useAPIRequest from "../../API/useApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import { useToasts } from "react-toast-notifications";
import './allobservershipcards.css';
import moment from "moment";
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";

let nextId = 0;

const AllObservershipCards = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();
    const history = useHistory();

    const [allObservershipApplications, setAllObservershipApplications] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filterType, setFilterType] = useState("");

    // Services
    const { apiRequests } = useAPIRequest();
    const { getCountry, getStates, getCity } = useGetData();

    // Dialogs
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    const handleApplyObservershipClick = (id) => {
        history.push(`${Constants.OBSERVERSHIP_DETAILS}/${id}`);
    };

    const handleFilterDialogClickOpen = () => {
        setOpenFilterDialog(true);
    };

    const handleFilterDialogClickClose = () => {
        setOpenFilterDialog(false);
    };

    useEffect(() => {
        getReferenceData();
        getObservershipApplications(searchTerm, country, state, city, pageNo);
    }, []);

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    // Function to get all obsevership applications

    const getObservershipApplications = async (searchTerm, country, state, city, pageNo) => {

        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `search/internships?q=${searchTerm}&country=${country}&state=${state}&city=${city}&page=${pageNo}`,
            method: "GET",
            addToast,
        });

        if (data) {

            if (searchTerm || country || state || city) {
                setAllObservershipApplications(data.entities);
            } else {
                setAllObservershipApplications((prevData) => {
                    const uniqueEntities = data.entities.filter(newEntity =>
                        !prevData.some(existingEntity => existingEntity.id === newEntity.id)
                    );
                    return [...prevData, ...uniqueEntities];
                });
            }

            setTotalPages(data.totalPages);
            setPageNo(data.page);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    // handle search related functions

    const handleSearchResultChange = (searchWord) => {
        setSearchTerm(searchWord);
        if (searchWord.length === 0 || searchWord.length >= 3) {
            setAllObservershipApplications([]);
            setPageNo(0);
            getObservershipApplications(searchWord, country, state, city, 0);
        }
    };

    // handle filter related functions

    const handleCountryClick = (countryName) => {
        setCountry(countryName);
        setStateChoices([]);
        setCityChoices([]);
        getStatesData(countryName);
        if (selectedFilter.length < 4 && country === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: countryName,
                type: "Country"
            }
            ]);
        }
    };

    const handleStateClick = (stateName) => {
        setState(stateName);
        setCityChoices([]);
        getCityData(country, stateName);
        if (selectedFilter.length < 4 && state === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: stateName,
                type: "State"
            }
            ]);
        }
    };

    const handleCityClick = (cityName) => {
        setCity(cityName)
        if (selectedFilter.length < 4 && city === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: cityName,
                type: "City"
            }
            ]);
        }
    };

    const handleFilterDelete = (type) => {
        if (type === "Country") {
            const removeItems = selectedFilter.filter(item =>
                item.type
            );
            const updatedCountry =
                selectedFilter.filter(item =>
                    !removeItems.includes(item)
                );
            setSelectedFilter(updatedCountry);
            setCountry("");
            setState("");
            setCity("");
            setAllObservershipApplications([]);
            setPageNo(0);
            getObservershipApplications(searchTerm, "", "", "", 0);
        } else if (type === "State") {
            const removeItems = selectedFilter.filter(item =>
                item.type !== "Country"
            );
            const updatedState =
                selectedFilter.filter(item =>
                    !removeItems.includes(item)
                );
            setSelectedFilter(updatedState);
            setState("");
            setCity("");
            setAllObservershipApplications([]);
            setPageNo(0);
            getObservershipApplications(searchTerm, country, "", "", 0);
        } else if (type === "City") {
            const updatedCity =
                selectedFilter.filter(item =>
                    item.type !== type
                );
            setSelectedFilter(updatedCity);
            setCity("");
            setAllObservershipApplications([]);
            setPageNo(0);
            getObservershipApplications(searchTerm, country, state, "", 0);
        }
    };

    const handleApplyFilterClick = (countryName, stateName, cityName) => {

        setOpenFilterDialog(false);

        setAllObservershipApplications([]);
        setPageNo(0);
        getObservershipApplications(searchTerm, countryName, stateName, cityName, 0);

    };

    return (
        <>

            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="observership-drawer">
                        <Navigation />
                    </div>

                    <div className="observershipCards-container">

                        <div className="observership-fixed-container">
                            
                            <div className="apply-observership-search-filter-div" >

                                <div className="apply-observership-searchbar-textfield">
                                    <TextField fullWidth
                                        placeholder="Search Observership..."
                                        name="searchbar"
                                        id="searchbar"
                                        autoComplete="off"
                                        value={searchTerm}
                                        onChange={(event) => handleSearchResultChange(event.target.value)}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end" >
                                                    < SearchIcon color="primary" />
                                                </InputAdornment>,
                                        }}
                                        className='apply-observership-search-textfield'
                                    />
                                </div>

                                <div className='apply-observership-icon-div'>
                                    <IconButton onClick={handleFilterDialogClickOpen}>
                                        <TuneOutlinedIcon className='apply-observership-filter-icon' />
                                    </IconButton>
                                </div>

                            </div>

                            {selectedFilter.length > 0 &&
                                <>
                                    <div className="observership-filter-container">
                                        {selectedFilter.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <Typography variant="subtitle2" color="text.primary" style={{ display: "none" }}>
                                                        {item.type}
                                                    </Typography>
                                                    <Chip label={item.title}
                                                        variant="outlined"
                                                        color="primary"
                                                        style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                        onDelete={() => handleFilterDelete(item.type)}
                                                    />
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                </>
                            }
                        </div>

                        <div className="observership-scrollable-container" style={{ marginTop: "12px" }}>
                            <InfiniteScroll
                                style={{ overflow: "hidden" }}
                                dataLength={allObservershipApplications.length}
                                next={() => {
                                    getObservershipApplications(searchTerm, country, state, city, pageNo + 1);
                                }}
                                hasMore={!(pageNo + 1 === totalPages)}
                                loader={<YLoader loading={loading} />}
                                endMessage={<span></span>}
                            >
                                <Grid container direction="row" spacing={0}>
                                    {allObservershipApplications.length > 0 ?
                                        allObservershipApplications.map((element, index) => {
                                            return (
                                                <Grid container className='observership-card-container' key={element.id}>
                                                    <Grid item xs={12} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }} key={element.id}>
                                                        <Card className='observership-card'>
                                                            <CardHeader
                                                                avatar={
                                                                    <Avatar src={element.profileLink && element.profileLink.href ? element.profileLink.href : " "} color="primary" aria-label="companylogo" className='observership-img' />
                                                                }
                                                                title={element.userName}
                                                                subheader={element.created ? moment(element.created).format("MMM D, YYYY") : ""}
                                                            />
                                                            <CardContent style={{ padding: "0px 16px 16px 16px" }}>

                                                                <Grid container direction="row">
                                                                    <Grid item xs={12} >
                                                                        <Typography variant="h6" align='left' color="text.primary" className='observership-title'>
                                                                            {element.title}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} >
                                                                        <Typography variant="body2" color="text.primary" gutterBottom>
                                                                            <PlaceIcon color="primary" className='internship-icon' />
                                                                            {element.city && element.state && element.country ? `${element.city}, ${element.state}, ${element.country}` : element.city || element.state || element.country}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container direction="row" style={{ margin: "5px 0px" }}>
                                                                    {element.skills.length > 0 && element.skills.map((value, index) => {
                                                                        return (
                                                                            <Grid item style={{ margin: "0px 6px 6px 0px" }} key={index}>
                                                                                <Chip label={value} variant="outlined" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }} />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>

                                                                <Grid container direction="row">
                                                                    <Grid item xs={12} >
                                                                        <Typography variant="body2" color="text.primary" className='observership-description' >
                                                                            {element.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                            </CardContent>
                                                            <CardActions disableSpacing>
                                                                <Button variant="contained" color="primary" className="observershipCards-button" onClick={() => { handleApplyObservershipClick(element.id) }} >Apply</Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                        :
                                        <Grid container style={{ marginTop: "10px" }}>
                                            <Grid item xs={12}>
                                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                                    No results found
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>

                            </InfiniteScroll>

                        </div>

                        {/* ############################ filter dialog ######################################### */}
                        <Dialog open={openFilterDialog} onClose={handleFilterDialogClickClose} fullScreen={fullScreen} fullWidth>
                            <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                                <IconButton
                                    edge="start"
                                    onClick={handleFilterDialogClickClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                    }}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                                Filters
                            </DialogTitle>
                            <DialogContent>
                                <>

                                    <Grid container style={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="text.primary">
                                                Select Filter
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Chip label="Country"
                                                variant="outlined"
                                                value={filterType}
                                                color="primary"
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                onClick={(e) => setFilterType("Country")}
                                            />
                                        </Grid>
                                        {country === "India" &&
                                            <Grid item>
                                                <Chip label="State"
                                                    variant="outlined"
                                                    value={filterType}
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onClick={(e) => setFilterType("State")}
                                                />
                                            </Grid>
                                        }
                                        {state.length > 0 &&
                                            <Grid item>
                                                <Chip label="City"
                                                    variant="outlined"
                                                    value={filterType}
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onClick={(e) => setFilterType("City")}
                                                />
                                            </Grid>
                                        }
                                    </Grid>

                                    {selectedFilter.length > 0 &&
                                        <>

                                            <Grid container style={{ marginTop: "10px" }}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="text.primary">
                                                        Applied Filters
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                {selectedFilter.map((item, index) => {
                                                    return (
                                                        <Grid item key={index}>
                                                            <Typography variant="subtitle2" color="text.primary" style={{ display: "none" }}>
                                                                {item.type}
                                                            </Typography>
                                                            <Chip label={item.title}
                                                                variant="outlined"
                                                                color="primary"
                                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                onDelete={() => handleFilterDelete(item.type)}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                                }
                                            </Grid>
                                        </>

                                    }

                                    <hr style={{ margin: "10px 0px" }} />

                                    {(() => {
                                        switch (filterType) {
                                            case "Country":
                                                return (
                                                    <>
                                                        {country === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(countryChoices) && countryChoices.map((option, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={option}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={country}
                                                                                    onClick={() => handleCountryClick(option)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "State":
                                                return (
                                                    <>
                                                        {state === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(stateChoices) && stateChoices.map((name, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={name}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={state}
                                                                                    onClick={() => handleStateClick(name)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "City":
                                                return (
                                                    <>
                                                        {state.length > 0 && city === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(cityChoices) && cityChoices.map((element, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={element}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={city}
                                                                                    onClick={() => handleCityClick(element)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            default:
                                                return <></>;
                                        }
                                    })()}

                                </>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" fullWidth className='apply-observership-dialog-button' onClick={() => { handleApplyFilterClick(country, state, city) }}> Apply </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default AllObservershipCards
