import React, { useState } from 'react'
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Typography, Button } from '@mui/material';
import "./alleventpasses.css";
import SingleEventPass from './SingleEventPass';
import ImageNotAvailable from "../../assets/images/imagenotavailable.jpg";

const AllEventPasses = () => {
    const [loading, setLoading] = useState(true);
    const [eventPassOpen, setEventPassOpen] = useState(false);

    const handleEventPassOpen = () => {
        setEventPassOpen(true);
    };

    const handleEventPassClose = () => {
        setEventPassOpen(false);
    };

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="left_Drawer">
                        <Navigation />
                    </div>

                    <div className="alleventpasses-container">

                        {eventPassList.map((item) => {
                            return (
                                <div className="eventpass-card" key={item.id} onClick={handleEventPassOpen}>

                                    <div className='eventpass-img-container'>
                                        <img src={item.eventPicture} alt='event pass' className='eventpass-img' />
                                    </div>

                                    <div className="eventpass-info">

                                        <Typography variant="subtitle1" className='event-text'>
                                            {item.eventName}
                                        </Typography>
                                        <Typography variant="caption" className='event-address'>
                                            {item.eventType}, {item.eventAddress}
                                        </Typography>
                                        <Typography variant="caption">
                                            {item.date}
                                        </Typography>

                                    </div>

                                </div>
                            );
                        })

                        }

                        <div>
                            <SingleEventPass eventPassOpen={eventPassOpen} handleEventPassClose={handleEventPassClose} />
                        </div>

                    </div>

                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default AllEventPasses

const eventPassList = [
    {
        id: 0,
        eventName: "International Conference on Medical & Health Science",
        eventType: "Conference",
        eventAddress: "Pune, MH",
        date: "17-jan-2023",
        eventPicture: "https://images.unsplash.com/photo-1556125574-d7f27ec36a06?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8ZXZlbnRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
    },
    {
        id: 1,
        eventName: "International Conference on Medical and Biological Engineering",
        eventType: "Conference",
        eventAddress: "BEC Mumbai",
        date: "07-feb-2023",
        eventPicture: "https://images.unsplash.com/photo-1549451371-64aa98a6f660?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZXZlbnRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
        id: 2,
        eventName: "Medical Fair india 2023",
        eventType: "Trade Fair",
        eventAddress: "Bombay Exhibition Centre",
        date: "17-apr-2024",
        eventPicture: "https://images.unsplash.com/photo-1674574124475-16dd78234342?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxzZWFyY2h8MXx8ZXZlbnRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
        id: 3,
        eventName: "Annual Conference of Academy of Sciences",
        eventType: "Conference",
        eventAddress: "JW Marriott Pune",
        date: "27-dec-2023",
        eventPicture: "https://images.unsplash.com/photo-1556125574-d7f27ec36a06?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8ZXZlbnRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
    },
    {
        id: 4,
        eventName: "International Conference on Medical & Health Science",
        eventType: "Conference",
        eventAddress: "Kalbhornagar Chinchwad",
        date: "11-jan-2024",
        eventPicture: "https://images.unsplash.com/photo-1530023367847-a683933f4172?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
    },
    {
        id: 5,
        eventName: "International Conference on Medical & Health Science",
        eventType: "Conference",
        eventAddress: "Bombay Exhibition Centre",
        date: "09-feb-2024",
        eventPicture: "https://images.unsplash.com/photo-1556125574-d7f27ec36a06?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8ZXZlbnRzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
    },

]