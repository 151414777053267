import React from "react";
import { Paper, Button, IconButton, Typography } from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import "../../Profile/Profile.css";


const ViewMembership = ({ userMembershipData }) => {

    return (
        <>
            {/* ############################ Membership section ######################################### */}
            <div>

                <div>
                    <Typography align="left" className="view-user-title" color="text.primary">
                        Memberships
                    </Typography>
                </div>

                {userMembershipData && userMembershipData.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>

                            <div className="section_container">
                                <IconButton>
                                    <GroupsIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {value.membership}
                                    </Typography>
                                </div>

                            </div>

                        </Paper>
                    );
                })}

            </div>
        </>
    )
}

export default ViewMembership
