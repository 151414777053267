import React, { useContext } from 'react'
import './queandanscomments.css';
import { TextField, Button, Typography } from '@mui/material';
import { YContext } from "../../Context/YContext";

const Reply = ({ replies }) => {
    const { currentUser } = useContext(YContext);
    return (
        <>
            <div className="comments">

                <div className="que-ans-comment-write-comments">
                    <img src={currentUser.profileLink ? currentUser.profileLink.href : " "} alt='currentUser image' className='user-Picture' />
                    <div className='que-ans-comment-Input'>
                        <TextField fullWidth
                            id="commenttextarea"
                            name="commenttextarea"
                            className='comment-textarea'
                            variant="outlined"
                            placeholder='Add a reply...'
                            // value={message}
                            // onChange={(event) => setMessage(event.target.value)}
                            autoComplete="off"
                            multiline
                        />
                    </div>
                    <Button variant="outlined" className="send-Button">Reply</Button>
                </div>

                {replies.map((item) => {
                    return (
                        <div className="reply_container">
                            <div className="que-ans-comment" key={item.replyid}>
                                <div className="userinfo">
                                    <img src={item.replyPicture} alt='user image' className='que-ans-user-Picture' />
                                    <div className="user_details">
                                        <Typography variant="subtitle2">
                                            {item.replyername}
                                        </Typography>
                                        <Typography variant="caption">
                                            HR Manager
                                        </Typography>
                                    </div>
                                </div>
                                <div className="que-ans-comment-info">
                                    <p className='que-ans-comment-user-comment'>{item.replyMessage}</p>
                                    {/* <span >Reply</span> */}
                                    <span className='que-ans-comment-date'>{item.posted}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}

            </div>
        </>
    )
}

export default Reply