import React, { useState, useEffect, useContext } from "react";
import { Paper, Grid, Button, Typography, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import 'date-fns';
import "../../Profile/Profile.css";
import moment from "moment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAPIRequest from "../../API/useApiRequest";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import StarsIcon from '@mui/icons-material/Stars';
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";


const Awardsection = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    // Variables
    const [userId, setUserId] = useState("");
    const [userType, setUserType] = useState("");
    const [awardDetails, setAwardDetails] = useState([]);
    const [title, setTitle] = useState("");
    const [issuer, setIssuer] = useState("");
    const [issuedDate, setIssuedDate] = useState(null);
    const [awardId, setAwardId] = useState("");
    const [editTitle, setEditTitle] = useState("");
    const [editIssuer, setEditIssuer] = useState("");
    const [editIssuedDate, setEditIssuedDate] = useState(null);

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getUserType } = useUserType();

    const currentYear = new Date().getFullYear();

    // Dialogs
    const [openAwardDialog, setOpenAwardDialog] = React.useState(false);
    const [openEditAwardDialog, setOpenEditAwardDialog] = React.useState(false);

    const handleAwardDialogClickClose = () => {
        setOpenAwardDialog(false);
    };
    const handleAwardDialogClickOpen = () => {
        setOpenAwardDialog(true);
    };
    const handleEditAwardDialogClickClose = () => {
        setOpenEditAwardDialog(false);
    };
    const handleEditAwardDialogClickOpen = (id) => {
        setOpenEditAwardDialog(true);
        getEditAwardDetailsWithId(id);
    };

    // Functions
    const getUserAwardDetails = () => {

        setLoading(true);
        setUserId(currentUser.id);
        setUserType(currentUser.userType);
        setAwardDetails(currentUser.awardDetails);
        setLoading(false);
    }

    useEffect(() => {
        getUserAwardDetails();
    }, [currentUser])


    // Functions for create award

    const handleAddUserAwards = (title, issuer, issuedDate, userType, userId) => {

        let newAwardIssuedDate = moment(issuedDate).format("MMM YYYY");
        const newAwards = {
            title: title,
            issuer: issuer,
            issuedDate: newAwardIssuedDate
        };

        createUserAwards(userType, userId, newAwards);

    };

    const createUserAwards = async (userType, userId, newAwards) => {

        setOpenAwardDialog(false);
        const user = getUserType(userType);
        const awards = [newAwards]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                awardDetails: awards,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
            setTitle('');
            setIssuer('');
            setIssuedDate(null);
        }

    }

    // Functions for edit award

    const getEditAwardDetailsWithId = (id) => {

        let award = awardDetails.find(item => item.id === id)

        let issuedDate = new Date(award.issuedDate);

        setAwardId(award.id);
        setEditTitle(award.title);
        setEditIssuer(award.issuer);
        setEditIssuedDate(issuedDate);

    };

    const handleEditUserAwards = (editTitle, editIssuer, editIssuedDate, awardId, userType, userId) => {

        let editAwardIssuedDate = moment(editIssuedDate).format("MMM YYYY");

        const editAwardDetails = {
            title: editTitle,
            issuer: editIssuer,
            issuedDate: editAwardIssuedDate,
            id: awardId,
        };
        editUserAward(userType, userId, editAwardDetails);
    };

    const editUserAward = async (userType, userId, editAwardDetails) => {

        setOpenEditAwardDialog(false);
        const user = getUserType(userType);
        const awards = [editAwardDetails]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                awardDetails: awards,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
        }

    }

    // Functions for delete award

    const deleteUserAwards = async (userType, id) => {

        const user = getUserType(userType);

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/award/${id}`,
            method: "DELETE",
            addToast,
        });

        if (response.status === 200) {
            getUserInfo();
        };

    }

    const validateAwardForm = () => {

        let valid = true;

        if (title.trim() === '') {
            addToast('Award Title cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (issuer.trim() === '') {
            addToast('Award Issuer cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (issuedDate === null) {
            addToast('Please select a date for Issued date', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleAddAwardSubmit = () => {

        if (validateAwardForm()) {
            handleAddUserAwards(title, issuer, issuedDate, userType, userId)
        }
    };

    const validateEditAwardForm = () => {

        let valid = true;

        if (editTitle.trim() === '') {
            addToast('Award Title cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editIssuer.trim() === '') {
            addToast('Award Issuer cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editIssuedDate === null) {
            addToast('Please select a date for Issued date', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleEditAwardSubmit = () => {

        if (validateEditAwardForm()) {
            handleEditUserAwards(editTitle, editIssuer, editIssuedDate, awardId, userType, userId)
        }
    };

    return (
        <>
            {/* ############################ Award section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleAwardDialogClickOpen} className="profile_btn">
                        Award
                    </Button>
                </div>

                {awardDetails && awardDetails.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>

                            <div className="section_container">
                                <IconButton>
                                    <StarsIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {value.title}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {value.issuer}
                                    </Typography>
                                    <Typography align="left" className="profile_description profile_text" color="text.primary">
                                        {value.issuedDate}
                                    </Typography>
                                </div>
                                <div className="section_iconbtn_container">
                                    <IconButton onClick={() => handleEditAwardDialogClickOpen(value.id)} className="icon_btn">
                                        < EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => deleteUserAwards(userType, value.id)} className="icon_btn">
                                        <DeleteIcon color="primary" />
                                    </IconButton>
                                </div>
                            </div>

                        </Paper>
                    );
                })}

                {/* ############################ Create Award dialog ######################################### */}
                <Dialog open={openAwardDialog} onClose={handleAwardDialogClickClose} fullScreen={fullScreen} >
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleAwardDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Award
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Title"
                                    name="title"
                                    value={title}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setTitle(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="title"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Issuer"
                                    name="issuer"
                                    value={issuer}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setIssuer(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="issuer"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "-4px" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="Issued Date" views={['month', 'year']} disableFuture
                                            value={issuedDate}
                                            onChange={(newValue) => setIssuedDate(newValue)}
                                            minDate={new Date(currentYear - 54, 0, 1)} // Set the minimum date to 54 years ago from the current year
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={handleAddAwardSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                {/* ############################ Edit Award dialog ######################################### */}
                <Dialog open={openEditAwardDialog} onClose={handleEditAwardDialogClickClose} fullScreen={fullScreen} >
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleEditAwardDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Edit Award
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Title"
                                    name="editTitle"
                                    value={editTitle}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setEditTitle(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="editTitle"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Issuer"
                                    name="editIssuer"
                                    value={editIssuer}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setEditIssuer(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="editIssuer"
                                    variant="outlined"
                                    required
                                />

                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "-4px" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="Issued Date" views={['month', 'year']} disableFuture
                                            value={editIssuedDate}
                                            onChange={(newValue) => setEditIssuedDate(newValue)}
                                            minDate={new Date(currentYear - 54, 0, 1)} // Set the minimum date to 54 years ago from the current year
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={handleEditAwardSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Awardsection