import React, { useState, useEffect } from 'react'
import {
    Grid, Typography, Dialog, DialogTitle,
    Button, DialogContent, DialogActions,
    Paper, Chip, Slide
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import PhoneIcon from '@mui/icons-material/Phone';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./productdetails.css";
import ImagesCarousel from '../Components/ImagesCarousel';
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import CurrencyFormat from '../../utils/CurrencyFormat';
import ImageEnlargeDialog from '../Components/ImageEnlargeDialog';
import { BsChat } from "react-icons/bs";
import Constants from "../../MeddyConnectConstant";
import { useHistory } from "react-router-dom";
import UrlRoute from "../../API/UrlRoute";
import ReadMore from "../../components/ReadMore";
import { useParams } from 'react-router-dom';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ProductDetails = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();
    const { productId } = useParams();

    const [productDetails, setProductDetails] = useState(null);
    const [sellerContactNumber, setSellerContactNumber] = useState("");
    const [sellerName, setSellerName] = useState("");
    const [chipData, setChipData] = React.useState([]);
    const [productImages, setProductImages] = useState([]);
    const [clickedImage, setClickedImage] = useState('');

    // Services
    const { apiRequests } = useAPIRequest();

    // Dialogs
    const [enlargeImgDialog, setEnlargeImgDialog] = useState(false);
    const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = React.useState(false);
    const [openShowSellerContactDetailsDialog, setOpenShowSellerContactDetailsDialog] = useState(false);

    const handleAlertVerificationDialogClickOpen = () => {
        setOpenAlertVerificationDialog(true);
    };

    const handleAlertVerificationDialogClickClose = () => {
        setOpenAlertVerificationDialog(false);
    };

    const handleShowSellerContactDetailsDialogClickOpen = () => {
        setOpenShowSellerContactDetailsDialog(true);
    };

    const handleShowSellerContactDetailsDialogClickClose = () => {
        setOpenShowSellerContactDetailsDialog(false);
    };

    const handleVerifyClick = () => {
        history.push(Constants.VERIFICATION);
    };

    const handleEnlargeImgDialogClickOpen = (clickImg) => {
        setEnlargeImgDialog(true);
        setClickedImage(clickImg);
    };

    const handleEnlargeImgDialogClickClose = () => {
        setEnlargeImgDialog(false);
    };

    useEffect(() => {
        getProductDetails(productId);
    }, []);

    const getProductDetails = async (productId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `items/${productId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setProductDetails(data);
            setSellerName(data.sellerName);
            setChipData(data.itemSuitabilities);
            setProductImages(data.itemImages);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const handleImInterested = async (itemId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: "items/interest",
            method: "POST",
            body: {
                itemId: itemId,
            },
            addToast,
        });

        if (response.status === 200) {
            setLoading(false);
            addToast("Thank you for showing interest in our product !", { appearance: "success" });
        } else if (response.status === 409) {
            setLoading(false);
            addToast("You have already shown interest in this product !", { appearance: "success" });
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    }

    const getSellerContactDetails = async (productId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `${UrlRoute.GET_SELLER_CONTACT_DETAILS_URL}/${productId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setSellerContactNumber(data.response);
            setLoading(false);
            handleShowSellerContactDetailsDialogClickOpen();
        } else if (response.status === 403) {
            setLoading(false);
            handleAlertVerificationDialogClickOpen();
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    return (
        <div style={{ height: "auto" }}>

            <div style={{ display: "flex" }}>

                <div className="product-details-drawer">
                    <Navigation />
                </div>

                <div className="product-details-container" >
                    {loading ? (
                        <YLoader loading={loading} />
                    ) : productDetails ? (
                        <div>
                            <Paper elevation={2} className="product-details-paper-container">
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <ImagesCarousel productImages={productImages} handleEnlargeImgDialogClickOpen={handleEnlargeImgDialogClickOpen} />
                                    </Grid>
                                </Grid>

                                <div className="product-details-description">
                                    <Typography variant="h5" align="left" color="text.primary" className="text-capital product-title" gutterBottom>
                                        {productDetails.title}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Seller :</span> {productDetails.sellerName} {productDetails.sellerVerified ? <Chip icon={<VerifiedIcon color="primary" />} label="Verified" variant="outlined" size="small" /> : ""}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'> Product :</span> {productDetails.product}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Manufacturing Year :</span> {productDetails.manufacturingYear ? productDetails.manufacturingYear : "N/A"}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Posting Date :</span> {productDetails.created ? moment(productDetails.created).format("D-MMM-YYYY") : "N/A"}
                                    </Typography>

                                    <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                        <span className='bold-text'>Working Condition :</span>&nbsp;
                                        {productDetails.workingCondition === "WORKING" ? "Working" : productDetails.workingCondition === "NON_WORKING" ? "Not Working" : productDetails.workingCondition === "PARTIALLY_WORKING" ? "Partially Working" : "N/A"}
                                    </Typography>
                                </div>

                                <div className="product-details-grid-container">

                                    <div className='product-details' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                        <Typography variant="subtitle1" align='center' color="text.primary" className='product-details-text'>
                                            <CurrencyFormat currencyPrice={productDetails.price} />
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.primary" align='center'>
                                            Price
                                        </Typography>
                                    </div>

                                    <div className='product-details' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                        <Typography variant="subtitle1" align='center' color="text.primary" className='product-subtitle product-details-text text-capital'>
                                            {productDetails.city ? productDetails.city : "N/A"}
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.primary" align='center'>
                                            City
                                        </Typography>
                                    </div>

                                    <div className='product-details' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                        <Typography variant="subtitle1" align='center' color="text.primary" className='product-subtitle product-details-text text-capital'>
                                            {productDetails.brand ? productDetails.brand : "N/A"}
                                        </Typography>
                                        <Typography variant="subtitle2" align='center' color="text.primary">
                                            Brand
                                        </Typography>
                                    </div>

                                </div>

                                <Grid container direction="row" style={{ marginTop: "14px" }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" align="left" color="text.primary" className='bold-text'>
                                            Product is useful for :
                                        </Typography>
                                    </Grid>
                                    {chipData.length > 0 ?
                                        <Grid item xs={12}>
                                            <Paper elevation={0}
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    listStyle: 'none',
                                                    backgroundColor: 'transparent',
                                                    p: 0.5,
                                                    m: 0,
                                                }}
                                                component="ul"
                                            >
                                                {chipData.map((data) => {

                                                    return (
                                                        <ListItem key={data.id}>
                                                            <Chip variant='outlined'
                                                                label={data.suitableFor}
                                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff", textTransform: "capitalize" }}
                                                            />
                                                        </ListItem>
                                                    );

                                                })
                                                }
                                            </Paper>
                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" color="text.primary">
                                                N/A
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid container direction="column" style={{ marginTop: "14px" }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital bold-text" >
                                            description :
                                        </Typography>
                                    </Grid>
                                    {productDetails.description ?
                                        <Grid item >
                                            {productDetails.description.length < 300 ?
                                                <Typography variant="subtitle2" color="text.primary" component="span" >
                                                    {productDetails.description}
                                                </Typography>
                                                :
                                                <Typography variant="subtitle2" color="text.primary" component="span" >
                                                    <ReadMore>
                                                        {productDetails.description}
                                                    </ReadMore>
                                                </Typography>
                                            }
                                        </Grid> : "No description"}
                                </Grid>

                                <Grid container direction="column" style={{ marginTop: "14px" }}>
                                    <Grid item>
                                        <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital bold-text" >
                                            address :
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" align="left" color="text.primary" className="text-capital" >
                                            {productDetails.city && productDetails.state && productDetails.country ? `${productDetails.city}, ${productDetails.state}, ${productDetails.country}` :
                                                (productDetails.city && productDetails.state ? `${productDetails.city}, ${productDetails.state}` :
                                                    (productDetails.city && productDetails.country ? `${productDetails.city}, ${productDetails.country}` :
                                                        (productDetails.state && productDetails.country ? `${productDetails.state}, ${productDetails.country}` :
                                                            productDetails.city || productDetails.state || productDetails.country
                                                        )
                                                    )
                                                )
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ padding: "10px 0px" }}>
                                    {/* <Grid item xs={6}>
                                    <Button variant="contained" startIcon={<BsChat />} className="product-details-btn" color="primary" fullWidth>
                                        chat
                                    </Button>
                                </Grid> */}
                                    <Grid item xs={12}>
                                        <Button variant="contained" startIcon={<PhoneIcon />} className="product-details-btn" color="primary" fullWidth onClick={() => getSellerContactDetails(productId)}>
                                            call
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" className="product-details-btn" color="primary" fullWidth onClick={() => handleImInterested(productDetails.id)}>
                                            I'm Interested
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    ) : (
                        <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                    No results found
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <div>
                        <ImageEnlargeDialog
                            enlargeImgDialog={enlargeImgDialog}
                            handleEnlargeImgDialogClose={handleEnlargeImgDialogClickClose}
                            clickedImage={clickedImage}
                        />
                    </div>


                    <div>
                        <Dialog open={openAlertVerificationDialog} onClose={handleAlertVerificationDialogClickClose}>
                            <DialogTitle >MeddyConnect</DialogTitle>
                            <DialogContent>

                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    You cannot contact seller as you are not a verified user
                                </Typography>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAlertVerificationDialogClickClose} color="primary" >Cancel</Button>
                                <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog open={openShowSellerContactDetailsDialog} onClose={handleShowSellerContactDetailsDialogClickClose}
                            maxWidth="sm" fullWidth TransitionComponent={Transition}
                        >
                            <DialogTitle >Seller Details</DialogTitle>
                            <DialogContent>

                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    <span className='bold-text'> Seller Name : </span> {sellerName}
                                </Typography>
                                <Typography variant="subtitle1" color="text.primary" align="left">
                                    <span className='bold-text'> Contact No : </span> <a href={`tel:${sellerContactNumber}`} style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1", textDecoration: "none" }}>{sellerContactNumber}</a>
                                </Typography>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleShowSellerContactDetailsDialogClickClose} color="primary" >Cancel</Button>
                            </DialogActions>
                        </Dialog>
                    </div>


                </div>
            </div>


            <YLoader loading={loading} />
        </div>
    )
}

export default ProductDetails