import React, { useState } from "react";
import { Typography, Button } from '@mui/material';
import "./searchuser.css";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";

const AllUserList = () => {
  const { addToast } = useToasts();
  const theme = useTheme();

  return (
    <>
      <div className="searchuser-details">

        {userList.map((value) => {
          return (
            <div className="searchuser-details-container" key={value.id}>
              <img src={value.profilePicture} alt='user image' className='searchuser-Picture' />
              <div className="searchuser-info">
                <Typography variant="subtitle1" align="left" className='searchuser-Name'>
                  {value.name}
                </Typography>
                <Typography variant="subtitle2" >
                  {value.speciality}
                </Typography>

                <div className="searchuser-btn-container" >
                  <Button variant="contained" startIcon={<PhoneIcon />} className="searchuser-btn" fullWidth >
                        call
                    </Button>

                  <Button variant="contained" startIcon={<ChatBubbleOutlineIcon />} className="searchuser-btn" fullWidth>
                    chat
                  </Button>
                </div>

              </div>

            </div>
          );
        })}

      </div>
    </>
  )
}

export default AllUserList

const userList = [
  {
      id: 1,
      name: "Rahul Shah",
      userId: 1,
      profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
      speciality: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
      type:"doctor"
  },
  {
      id: 2,
      name: "Anjali Mehta",
      userId: 2,
      profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
      speciality: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
      type:"hospital"
  },
  {
      id: 3,
      name: "Rahul Shah",
      userId: 1,
      profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
      speciality: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
      type:"vendor"
  },
  {
      id: 4,
      name: "Anjali Mehta",
      userId: 2,
      profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
      speciality: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
      type:"student"
  },
  {
      id: 5,
      name: "Rahul Shah",
      userId: 1,
      profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
      speciality: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
      type:"staff"
  },
  {
      id: 6,
      name: "Anjali Mehta",
      userId: 2,
      profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
      speciality: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
      type:"association"
  },
]



