import React, { useState, useContext } from "react";
import { Dialog, DialogContent, DialogActions, DialogTitle, TextField, Slide, IconButton, Grid, Button } from '@mui/material';
import './attendtraining.css';
import { useToasts } from "react-toast-notifications";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { YContext } from "../../Context/YContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TrainingRegistration = ({ registrationDialogOpen, handleRegistrationDialogClose }) => {
    const theme = useTheme();
    const { addToast } = useToasts();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { currentUser } = useContext(YContext);
    const [mobileNo, setMobileNo] = useState(currentUser.contactNo);
    const [emailId, setEmailId] = useState('');

    const trainingRegistration = () => {
        handleRegistrationDialogClose();
        addToast("You successfully initiated registration for (trainingname) kindly check your email for further communication  !!!", { appearance: "success" });

        // here check user is already registered then show msg "you already registered for these training"
    };

    return (
        <>
            {/* ############################ Registration dialog ######################################### */}
            <Dialog
                open={registrationDialogOpen}
                onClose={handleRegistrationDialogClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                    <IconButton
                        edge="start"
                        onClick={handleRegistrationDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                    Register for Training
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={0.5}>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Full name"
                                variant="outlined"
                                name="fullname"
                                id="fullname"
                                // value={emailId}
                                // onChange={(event) => setEmailId(event.target.value)}
                                autoComplete="off"
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled
                                label="WhatsApp number"
                                variant="outlined"
                                name="mobile"
                                id="mobile"
                                value={mobileNo}
                                onChange={(event) => setMobileNo(event.target.value)}
                                InputProps={{
                                    readOnly: true,
                                }}
                                inputProps={{ maxLength: 10, type: "tel" }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email address"
                                type="email"
                                variant="outlined"
                                name="email"
                                id="email"
                                value={emailId}
                                onChange={(event) => setEmailId(event.target.value)}
                                autoComplete="off"
                                required
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" fullWidth className='register-btn' onClick={trainingRegistration} > Register for Training</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TrainingRegistration