import React, { useState } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, InputAdornment, TextField } from '@mui/material';
import { useToasts } from "react-toast-notifications";
import './attendtraining.css';
import SearchIcon from '@mui/icons-material/Search';
import AttendTrainingCards from './AttendTrainingCards';
import RecommendationCards from './RecommendationCards';


const AttendTraining = () => {
    const [loading, setLoading] = useState(true);
    const { addToast } = useToasts();
   
    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="attendtraining--Drawer">
                        <Navigation />
                    </div>

                    <div className="attendtraining-container">

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    placeholder="Search..."
                                    name="searchbar"
                                    id="searchbar"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end" >
                                                < SearchIcon sx={{ color: "#002E6E" }} />
                                            </InputAdornment>,
                                    }}

                                />
                            </Grid>
                        </Grid>

                        <div>
                            <RecommendationCards />
                        </div>

                        <div>
                            <AttendTrainingCards />
                        </div>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default AttendTraining