import React, { useState, useContext } from "react";
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Slide } from '@mui/material';
import "./deactivateaccount.css";
import { useToasts } from "react-toast-notifications";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { YContext } from "../Context/YContext";
import { useHistory } from "react-router-dom";
import useAPIRequest from "../API/useApiRequest";
import UrlRoute from "../API/UrlRoute";
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeactivateAccount = () => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const theme = useTheme();

  // Services
  const { currentUser, setCurrentUser, setIsAuthenticated } = useContext(YContext);
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openDeactivateAccountDialog, setOpenDeactivateAccountDialog] = useState(false);

  const handleDeactivateAccountDialogClickOpen = () => {
    setOpenDeactivateAccountDialog(true);
  };

  const handleDeactivateAccountDialogClickClose = () => {
    setOpenDeactivateAccountDialog(false);
  };

  const handleYesClick = async () => {
    setOpenDeactivateAccountDialog(false);
    setLoading(true);
    const { response } = await apiRequests({
      endPoint: UrlRoute.DEACTIVATE_ACCOUNT_URL,
      method: "PUT",
      body: {},
      addToast,
    });
    if (response.status === 200) {
      localStorage.clear();
      setCurrentUser({});
      setIsAuthenticated(false);
      addToast("We have received your request to deactivate your account, your account and all the data will be deactivated in 90 days", { appearance: "success" });
      setLoading(false);
      history.push("/login");
    } else {
      setLoading(false);
      addToast("Error occurred while deactivating account!", { appearance: "error" });
    }
  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="deactivate-account-drawer">
            <Navigation />
          </div>

          <div className="deactivate-account-container">

            <div className="deactivate-account-content">
              <Typography variant="h6" align="left" color="text.primary" className="bold-text">
                Deactivate Account
              </Typography>
              <Typography variant="subtitle2" align="left" color="text.primary">
                We’re sorry to see you go! If you choose to deactivate your account, please note the following :
              </Typography>
              <Typography variant="subtitle1" align="left" color="text.primary" className="bold-text">
                Deactivation Process
              </Typography>
              <Typography variant="subtitle2" align="left" color="text.primary">
                Deactivating your account will immediately disable your access to our services. You will no longer be able to log in or use your account features.
              </Typography>
              <Typography variant="subtitle1" align="left" color="text.primary" className="bold-text">
                Data Deletion
              </Typography>
              <Typography variant="subtitle2" align="left" color="text.primary">
                Upon deactivation, your data will be scheduled for deletion from our systems. This process typically takes 90 days. During this period, your data will be inaccessible to you and to others using our services.
              </Typography>
              <Typography variant="subtitle1" align="left" color="text.primary" className="bold-text">
                Recovery Window
              </Typography>
              <Typography variant="subtitle2" align="left" color="text.primary">
                Your account will be immediately inactive. if you want to activate the account again, contact our support email at <a href="mailto:support@meddyconnect.com" style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1", textDecoration: "none" }}>support@meddyconnect.com</a>
                . Also, note that you can only activate the account within 90 days of the deactivation request.Your data will be permanently deleted after 90 days.
              </Typography>
              <Typography variant="subtitle2" align="left" color="text.primary">
                If you have any questions or need further assistance, please contact our support team.
              </Typography>
              <Typography variant="subtitle2" align="left" color="text.primary" className="bold-text">
                Thank you for being a part of our community!
              </Typography>

              <Button variant="contained" startIcon={<NoAccountsIcon />} className="deactivate-button" color="primary" fullWidth onClick={handleDeactivateAccountDialogClickOpen} >
                Deactivate Account
              </Button>

            </div>

            <Dialog
              open={openDeactivateAccountDialog}
              onClose={handleDeactivateAccountDialogClickClose}
              TransitionComponent={Transition}
            >
              <DialogTitle >Deactivate Account</DialogTitle>
              <DialogContent>

                <Typography variant="subtitle1" color="text.primary" align="left">
                  Are you sure you want to deactivate your account ?
                </Typography>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeactivateAccountDialogClickClose} color="primary" >No</Button>
                <Button onClick={handleYesClick} color="primary" >Yes</Button>
              </DialogActions>
            </Dialog>

          </div>
        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default DeactivateAccount