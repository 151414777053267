import React, { useState, useEffect, useContext } from "react";
import { DialogTitle, DialogActions, Dialog, DialogContent, Button, Typography } from '@mui/material';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { YContext } from "../../Context/YContext";
import Sellcontractform from "./Sellcontractform";
import { useHistory } from "react-router-dom";
import "./sellcontractpage.css";
import Constants from "../../MeddyConnectConstant";
import VerificationAlertDialog from '../../components/VerificationAlertDialog';

const Sellcontractpage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  // Services
  const { currentUser, getUserInfo } = useContext(YContext);

  // Dialogs
  const [openBuyPackageDialog, setOpenBuyPackageDialog] = React.useState(false);
  const [openUpgradePackageDialog, setOpenUpgradePackageDialog] = React.useState(false);
  const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = useState(false);

  const handleBuyPackageDialogClickOpen = () => {
    setOpenBuyPackageDialog(true);
  };

  const handleBuyPackageDialogClickClose = () => {
    setOpenBuyPackageDialog(false);
  };

  const handleUpgradePackageDialogClickOpen = () => {
    setOpenUpgradePackageDialog(true);
  };

  const handleUpgradePackageDialogClickClose = () => {
    setOpenUpgradePackageDialog(false);
  };

  const handleCancel = () => {
    history.push(Constants.HOME_SCREEN);
  };

  const handleBuy = () => {
    history.push(Constants.SUBSCRIBE_PACKAGE_DETAILS);
  };

  const handleAlertVerificationDialogClickOpen = () => {
    setOpenAlertVerificationDialog(true);
  };

  const handleAlertVerificationDialogClickClose = () => {
    setOpenAlertVerificationDialog(false);
  };

  useEffect(() => {
    getUserInfo().then(() => {
      checkPackage();
    });
  }, [currentUser.packageType])

  const checkPackage = () => {
    setLoading(true);

    if (currentUser.identityVerified === false) {
      handleAlertVerificationDialogClickOpen();
    } else if (currentUser.packageType === "MEDDY_CONNECT_STARTER") {
      handleBuyPackageDialogClickOpen();
    } else if (currentUser.allowedNoOfProducts - currentUser.activeNoOfProducts <= 0) {
      handleUpgradePackageDialogClickOpen();
    } else {
      console.log("MeddyConnect")
    }

    setLoading(false);
  };

  return (
    <>
      <div style={{ height: "auto" }}>
        <div style={{ display: "flex" }}>
          <div className="sellcontract-drawer">
            <Navigation />
          </div>
          <div className="sellcontract-container" >

            <div>
              <Sellcontractform />
            </div>

            <div>
              <Dialog open={openBuyPackageDialog}
              // onClose={handleBuyPackageDialogClickClose}
              >
                <DialogTitle >MeddyConnect</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1" color="text.primary" align="left">
                    To Sell Contract You Need To Buy Our Package
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancel} color="primary" >Cancel</Button>
                  <Button onClick={handleBuy} color="primary" >Buy</Button>
                </DialogActions>
              </Dialog>
            </div>

            <div>
              <Dialog open={openUpgradePackageDialog}
              // onClose={handleUpgradePackageDialogClickClose}
              >
                <DialogTitle >MeddyConnect</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle1" color="text.primary" align="left" className="text-capital">
                    You have exceeded your limit to sell Contract. If you want to sell more Contracts, please upgrade your current package.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancel} color="primary">Cancel</Button>
                  <Button onClick={handleBuy} color="primary">Upgrade</Button>
                </DialogActions>
              </Dialog>
            </div>

            {/* Verification Alert Dialog */}
            < VerificationAlertDialog
              openAlertVerificationDialog={openAlertVerificationDialog}
              handleAlertVerificationDialogClickClose={handleAlertVerificationDialogClickClose}
              message="Currently, you cannot sell contracts as you are not a verified user."
            />

          </div>
        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default Sellcontractpage