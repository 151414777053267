import { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import { Grid, Typography, Paper } from '@mui/material';
import "./notification.css";
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../API/useApiRequest";
import UrlRoute from "../API/UrlRoute";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import moment from "moment";

const Notification = () => {
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  // Services
  const { apiRequests } = useAPIRequest();

  useEffect(() => {
    getUserNotification();
  }, []);

  const getUserNotification = async () => {
    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: UrlRoute.GET_NOTIFICATION_URL,
      method: "GET",
      addToast,
    });

    if (data) {
      setNotification(data);
      setLoading(false);
    } else {
      addToast("Something went wrong!", { appearance: "error" });
      setLoading(false);
    }

  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="left__Drawer">
            <Navigation />
          </div>

          <div className="notification-container">

            {notification.length > 0 ? (
              notification.map((item) => {
                return (
                  <Paper elevation={6} className="notification-info" key={item.id} >
                    <Grid container >
                      <Grid item xs={2} sm={1}>
                        <NotificationsActiveIcon color="primary" style={{ verticalAlign: "middle" }} />
                      </Grid>
                      <Grid item xs={10} sm={11}>
                        <Typography variant="subtitle1" align="left" color="text.primary" gutterBottom>
                          {item.message}
                        </Typography>
                        <Typography variant="subtitle2" align="left" color="text.primary" gutterBottom>
                          {moment(item.created).format("D-MMM-YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })
            ) : (
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                    No results found
                  </Typography>
                </Grid>
              </Grid>
            )
            }

          </div>
        </div>

        <YLoader loading={loading} />
      </div>
    </>

  )
}

export default Notification