import React, { useState } from 'react'
import "./imagecarousel.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ImageNotAvailable from "../../assets/images/imagenotavailable.jpg";

const ImagesCarousel = ({ productImages, handleEnlargeImgDialogClickOpen }) => {
    const [current, setCurrent] = useState(0);
    const length = productImages.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

  return (
     <section className='slider2'>
            {length > 1 &&
                <>
                    <ArrowBackIosNewIcon className='left-arrow2'
                        onClick={prevSlide}
                    />
                    <ArrowForwardIosIcon className='right-arrow2'
                        onClick={nextSlide}
                    />
                </>
            }
            {length > 0 ?
                productImages.map((value, index) => {
                    return (

                        <div className={index === current ? "slide active2" : "slide2"} key={index}>
                            {index === current && (

                                <img src={value.imageLink?.href} alt="produt images" className='image2' onClick={() => handleEnlargeImgDialogClickOpen(value.imageLink?.href)} />

                            )}
                        </div>
                    )
                })
                :
                <img src={ImageNotAvailable} alt="produt images" className='image2' />
            }
        </section>
  )
}

export default ImagesCarousel