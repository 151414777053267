import React, { useState, useContext } from 'react'
import './queandanscomments.css';
import { TextField, Button, Typography } from '@mui/material';
import Reply from './Reply';
import { YContext } from "../../Context/YContext";
import { ImArrowUp, ImArrowDown } from "react-icons/im";

const QueAndAnsComments = () => {
    const [commentList, setcommentList] = useState(comments);
    const [message, setMessage] = useState('');
    const [repliesOpen, setRepliesOpen] = useState("");
    const { currentUser } = useContext(YContext);

    const commentUserName = currentUser.name;
    const commentUserImage = currentUser.profileLink.href;
    let commentId = comments.map((item) => {
        return item.id
    })

    const handleRepliesOpen = (id) => {
        if (repliesOpen === id) {
            setRepliesOpen("");
        } else {
            setRepliesOpen(id);
        }
    };

    const handleAddComments = (commentUserImage, commentUserName, message) => {
        const newComment = {
            id: commentId++,
            name: commentUserName,
            desc: message,
            profilePicture: commentUserImage,
        }
        setcommentList([newComment, ...commentList]);
        setMessage('');
    };

    return (
        <>
            <div className="comments">

                <div className="que-ans-comment-write-comments">
                    <img src={currentUser.profileLink ? currentUser.profileLink.href : " "} alt='currentUser image' className='user-Picture' />
                    <div className='que-ans-comment-Input'>
                        <TextField fullWidth
                            id="commenttextarea"
                            name="commenttextarea"
                            className='comment-textarea'
                            variant="outlined"
                            placeholder='Add a comment...'
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            autoComplete="off"
                            multiline
                        />
                    </div>
                    <Button variant="outlined" className="send-Button" onClick={() => handleAddComments(commentUserImage, commentUserName, message)}>Add comment</Button>
                </div>

                {commentList.map((value) => {
                    return (
                        <div className="que-ans-comment" key={value.id}>
                            <div className="userinfo">
                                <img src={value.profilePicture} alt='user image' className='que-ans-user-Picture' />
                                <div className="user_details">
                                <Typography variant="subtitle2">
                                {value.name}
                                </Typography>
                                <Typography variant="caption">
                                    HR Manager
                                </Typography>
                                </div>
                            </div>
                            <div className="que-ans-comment-info">

                                <p className='que-ans-comment-user-comment'>{value.desc}</p>

                                <div className="comments-btn-container">
                                    <div className='comments-btn-iconwith-text comments-btn-only-likeicon'>
                                        <ImArrowUp className='comments-icons' />
                                        <Typography variant="subtitle2">
                                            53
                                        </Typography>
                                    </div>
                                    <div className='comments-btn-iconwith-text'>
                                        <span className='tooltip' data-tooltip="Downvote"><ImArrowDown className='comments-icons' /></span>
                                    </div>

                                    <span className='reply-text' onClick={() => handleRepliesOpen(value.id)}>Reply</span>

                                </div>

                                <span className='que-ans-comment-date'> 1 hour ago</span>

                                <div>
                                    {repliesOpen === value.id && <Reply replies={value.replyinfo} />}
                                </div>

                            </div>
                        </div>
                    );
                })}

                <Button variant="outlined" fullWidth className='view-more-btn'>More comments</Button>

            </div>

        </>
    )
}

export default QueAndAnsComments

const comments = [
    {
        id: 1,
        desc: "I am flexible, reliable and possess excellent time keeping skills. I am an enthusiastic, self-motivated, reliable, responsible and hard working person. I am a mature team worker and adaptable to all challenging situations. I am able to work well both in a team environment as well as using own initiative. ",
        name: "Rahul Shah",
        profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
        replyinfo: [
            {
                replyid: 0,
                parentid: 1,
                replyername: "Suraj Rai",
                replyMessage: "The mammalian brain depends on glucose as its main source of energy. In the adult brain, neurons have the highest energy demand [1], requiring continuous delivery of glucose from blood.",
                replyPicture: "https://images.unsplash.com/photo-1488371934083-edb7857977df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1lbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
                posted: "now"
            },
            {
                replyid: 1,
                parentid: 1,
                replyername: "Nishikant Ambhore",
                replyMessage: "The major energy source for the brain is glucose. Indeed, the human brain consumes approximately 20% of the glucose-derived energy ",
                replyPicture: "https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fG1lbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
                posted: "2 min ago"
            }
        ]
    },
    {
        id: 2,
        desc: "I am flexible, reliable and possess excellent time keeping skills. I am an enthusiastic, self-motivated, reliable, responsible and hard working person. I am a mature team worker and adaptable to all challenging situations. I am able to work well both in a team environment as well as using own initiative. ",
        name: "Anjali Mehta",
        profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        replyinfo: [
            {
                replyid: 2,
                parentid: 2,
                replyername: "Suraj Pawar",
                replyMessage: "The major energy source for the brain is glucose. Indeed, the human brain consumes approximately 20% of the glucose-derived energy ",
                replyPicture: "https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fG1lbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
                posted: "2 min ago"
            }
        ]
    },
]