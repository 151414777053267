import { useEffect } from 'react';
import { onMessage } from "firebase/messaging";
import { useToasts } from "react-toast-notifications";
import { messaging } from '../../firebase';

function useFirebaseMessaging() {
  const { addToast, removeToast } = useToasts();

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Received foreground message ", payload);

      const { title, body } = payload.notification;
      const { link } = payload.data;

      const toastId = addToast(
        <div onClick={() => window.location.href = link}>
          {title}: {body}
        </div>,
        {
          appearance: 'info',
          autoDismiss: true,
          onDismiss: () => {
            console.log('Toast dismissed');
            removeToast(toastId); // Ensure the toast is removed
          },
        }
      );
    });

    return () => unsubscribe();
  }, [addToast, removeToast]);
}

export default useFirebaseMessaging;
