import React, { useState } from 'react'
import { Button, Dialog, IconButton, Slide, DialogContent, Grid, DialogActions, Typography, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "./questionandanswer.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AskQuestion = ({ askQuestionDialogOpen, handleAskQuestionDialogClose, allQuestion }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [question, setQuestion] = useState('');

    const filteredQuestion = allQuestion.filter((query) => { return query.question.toLowerCase().includes(question)})

    return (
        <>
            {/* ############################ open wrie a question dialog ######################################### */}
            <Dialog
                open={askQuestionDialogOpen}
                onClose={handleAskQuestionDialogClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent>
                    {/* {writerData.map((element) => {
                        return (
                        );
                    })} */}

                    <div className="responser-container">

                        <div className="responser-info">
                            <Typography variant="h6" align='left' className='question-text'>
                                Ask your question
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={handleAskQuestionDialogClose} >
                                <CloseIcon style={{ color: "black" }} />
                            </IconButton>
                        </div>
                    </div>

                    <Grid container >
                        <Grid item xs={12} style={{marginBottom:"6px"}}>
                            <TextField fullWidth
                                name="askquetextfield"
                                value={question}
                                placeholder='Your question goes here. #hashtag1 #hashtag2'
                                onChange={(event) => setQuestion(event.target.value)}
                                autoComplete="off"
                                multiline
                                minRows={3}
                                id="askquetextfield"
                                variant="outlined"
                            />
                        </Grid>
                        
                        {question.length > 2 && filteredQuestion.map((query) => (
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" >
                                    {query.question}
                                </Typography>
                            </Grid>
                        ))
                        }

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        fullWidth
                        className='add-question-btn'
                    // onClick={handleQuestion(question)}
                    >
                        Add Question
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AskQuestion