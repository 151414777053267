import React from 'react'

const useUserType = () => {

    const getUserType = (userType) => {

        switch (userType) {
            case "DOCTOR":
              return "doctor";

            case "HOSPITAL":
                return "hospital";

            case "VENDOR":
                return "vendor";

            case "STUDENT":
                return "student";

            case "STAFF":
                return "staff";

            case "MEDICAL_ASSOCIATION":
                return "medical-association";

            default:
              break;
          }

    }

  return { getUserType }
}

export default useUserType