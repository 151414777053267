import React, { useState, useEffect } from "react";
import { Paper, Typography, Avatar, Dialog, DialogTitle, DialogContent, Slide, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../Profile/Profile.css";
import ViewCareer from "./ViewCareer";
import ViewStudy from "./ViewStudy";
import ViewAward from "./ViewAward";
import ViewBlog from "./ViewBlog";
import ViewMembership from "./ViewMembership";
import { useToasts } from "react-toast-notifications";
import UrlRoute from "../../API/UrlRoute";
import useAPIRequest from "../../API/useApiRequest";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViewUserProfile = ({ openViewProfileDialog, handleViewProfileDialogClickClose, selectedUserId }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();

    const [userData, setUserData] = useState("");
    const [userCareerData, setUserCareerData] = useState([]);
    const [userStudyData, setUserStudyData] = useState([]);
    const [userAwardData, setUserAwardData] = useState([]);
    const [userMembershipData, setUserMembershipData] = useState([]);
    const [userBlogData, setUserBlogData] = useState([]);

    // Services
    const { apiRequests } = useAPIRequest();

    useEffect(() => {
        if (openViewProfileDialog) {
            getUserProfile(selectedUserId);
        }
    }, [openViewProfileDialog]);

     // Function to get user profile

    const getUserProfile = async (selectedUserId) => {

        const { response, data, error } = await apiRequests({
            endPoint: `${UrlRoute.USER_PROFILE_URL}/${selectedUserId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setUserData(data);
            setUserCareerData(data.careerDetails);
            setUserStudyData(data.studyDetails);
            setUserAwardData(data.awardDetails);
            setUserMembershipData(data.membershipDetails);
            setUserBlogData(data.blogDetails);
        } else {
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const { address, description, name, pinCode, city, speciality, userType, state, profileLink } = userData

    return (
        <>
            <Dialog
                open={openViewProfileDialog}
                onClose={handleViewProfileDialogClickClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                    <IconButton
                        edge="start"
                        onClick={handleViewProfileDialogClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                     Profile
                </DialogTitle>
                <DialogContent style={{ padding: "10px" }}>

                    {(() => {
                        switch (userType) {
                            case 'DOCTOR':
                                return (
                                    <Paper elevation={4} className="paper-container">
                                        <div className="user_profile_container">
                                            <Avatar src={profileLink ? profileLink.href : " "} className='profile_picture' />
                                            <div className='user_name'>
                                                <Typography align="left" className="profile_title" color="text.primary">
                                                    {name ? name : ""}
                                                </Typography>
                                                <Typography align="left" className="profile_subtitle" color="text.primary">
                                                    {speciality ? speciality : ""}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="user_profile_description">
                                            <Typography align="left" className="profile_description" color="text.primary">
                                                {description ? description : ""}
                                            </Typography>
                                        </div>
                                        <div className="user_address_details">
                                            <Typography align="left" className="profile_title" color="text.primary">
                                                Address:
                                            </Typography>
                                            <Typography align="left" className="profile_description" color="text.primary">
                                                {address ? address : ""}
                                            </Typography>
                                            <Typography align="left" className="profile_description" color="text.primary">
                                                {city && state ? `${city}, ${state}` : city || state} {pinCode ? pinCode : ""}
                                            </Typography>
                                        </div>

                                    </Paper>
                                );

                            case 'type2':
                                return (
                                    <Paper elevation={4} className="paper-container">

                                    </Paper>
                                );

                            default:
                                return (
                                    <Paper elevation={4} className="paper-container">
                                        Default User Profile or an error message
                                        <Typography align="left" color="text.primary">
                                            User type not recognized
                                        </Typography>
                                    </Paper>
                                );
                        }
                    })()}

                    {userCareerData.length > 0 && <ViewCareer userCareerData={userCareerData} />}

                    {userStudyData.length > 0 && <ViewStudy userStudyData={userStudyData} />}

                    {userAwardData.length > 0 && <ViewAward userAwardData={userAwardData} />}

                    {userMembershipData.length > 0 && <ViewMembership userMembershipData={userMembershipData} />}

                    {userBlogData.length > 0 && <ViewBlog userBlogData={userBlogData} />}


                </DialogContent>
            </Dialog>
        </>
    )
}

export default ViewUserProfile