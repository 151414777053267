import React, { useState } from "react";
import { Typography, Button } from '@mui/material';
import "./searchuser.css";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { FaHospital } from "react-icons/fa";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";

const HospitalUserList = ({ userList }) => {
    const { addToast } = useToasts();
    const theme = useTheme();

    return (
        <>
            <div className="searchuser-details">

                {userList.map((value) => {
                    return (
                        <div className="searchuser-details-container" key={value.id}>
                            <img src={value.profilePicture} alt='user image' className='searchuser-Picture' />
                            <div className="searchuser-info">

                                <div className="userwith-icon-container">
                                    <Typography variant="subtitle1" className='searchuser-Name'>
                                        {value.name}
                                    </Typography>
                                    <FaHospital className='usertype-icon' />
                                </div>

                                <Typography variant="subtitle2" >
                                    {value.speciality}
                                </Typography>

                                <div className="searchuser-btn-container" >
                                    <Button variant="contained" startIcon={<PhoneIcon />} className="searchuser-btn" fullWidth >
                                        call
                                    </Button>

                                    <Button variant="contained" startIcon={<ChatBubbleOutlineIcon />} className="searchuser-btn" fullWidth>
                                        chat
                                    </Button>
                                </div>

                            </div>

                        </div>
                    );
                })}

            </div>
        </>
    )
}

export default HospitalUserList