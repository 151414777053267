import React, { useState, useEffect, useContext } from "react";
import {
    Paper, Grid, Button, Typography, IconButton, Switch, Dialog, DialogTitle,
    DialogContent, TextField, DialogActions, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Slide
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../Profile/Profile.css";
import 'date-fns';
import useGetData from '../../API/useGetData';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useToasts } from "react-toast-notifications";
import SchoolIcon from '@mui/icons-material/School';
import moment from "moment";
import useAPIRequest from "../../API/useApiRequest";
import YLoader from "../../components/Loader";
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Studysection = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();

    // Variables
    const [userId, setUserId] = useState("");
    const [userType, setUserType] = useState("");
    const [studyDetails, setStudyDetails] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [collegeName, setCollegeName] = useState("");
    const [degree, setDegree] = useState("");
    const [yearOfPassing, setYearOfPassing] = useState(null);
    const [currentCollage, setCurrentCollage] = useState(false);
    const [studyId, setStudyId] = useState("");
    const [editCollegeName, setEditCollegeName] = useState("");
    const [editDegree, setEditDegree] = useState("");
    const [editYearOfPassing, setEditYearOfPassing] = useState(null);
    const [editCurrentCollege, setEditCurrentCollege] = useState(false);
    const [editCountry, setEditCountry] = useState("");
    const [editstate, setEditState] = useState("");
    const [editcity, setEditCity] = useState("");
    const [editStateChoices, setEditStateChoices] = useState("");
    const [editCityChoices, setEditCityChoices] = useState("");
    const [editCountryChoices, setEditCountryChoices] = useState("");

    const currentYear = new Date().getFullYear();

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getCountry, getStates, getCity } = useGetData();
    const { getUserType } = useUserType();

    // Dialogs
    const [openStudyDialog, setOpenStudyDialog] = React.useState(false);
    const [openEditStudyDialog, setOpenEditStudyDialog] = React.useState(false);


    const handleStudyDialogClickClose = () => {
        setOpenStudyDialog(false);
    };
    const handleStudyDialogClickOpen = () => {
        setOpenStudyDialog(true);
    };
    const handleEditStudyDialogClickClose = () => {
        setOpenEditStudyDialog(false);
    };
    const handleEditStudyDialogClickOpen = (id) => {
        setOpenEditStudyDialog(true);
        getEditStudyDetailsWithId(id);
    };

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    useEffect(() => {
        getReferenceData();
    }, []);

    // Functions
    const getUserStudyDetails = () => {
        setLoading(true);
        setUserId(currentUser.id);
        setUserType(currentUser.userType);
        setStudyDetails(currentUser.studyDetails);
        setLoading(false);
    }

    useEffect(() => {
        getUserStudyDetails();
    }, [currentUser])

    // Functions for create study

    const handleAddUserStudy = (collegeName, degree, yearOfPassing, currentCollage, country, state, city, userType, userId) => {

        let newStudyToDate = currentCollage ? null : moment(yearOfPassing).format("YYYY");

        const newStudy = {
            collegeName: collegeName,
            degree: degree,
            to: newStudyToDate,
            currentCollage: currentCollage,
            country: country,
            state: state,
            city: city
        };
        createUserStudy(userType, userId, newStudy);
    };

    const createUserStudy = async (userType, userId, newStudy) => {

        setOpenStudyDialog(false);
        const user = getUserType(userType);
        const study = [newStudy]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                studyDetails: study,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
            setCollegeName('');
            setDegree('');
            setYearOfPassing(null);
            setCurrentCollage(false);
            setCountry('');
            setState('');
            setCity('');
        }

    }

    // Functions for edit study

    const getEditReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setEditCountryChoices(countryOptions);
        }
    };

    const getEditStates = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setEditStateChoices(stateOptions);
            }
        }
    };

    const getEditCity = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setEditCityChoices(cityOptions);
            }
        }
    };

    const getEditStudyDetailsWithId = (id) => {

        getEditReferenceData();

        let study = studyDetails.find(item => item.id === id)

        let passingYear = study.currentCollage ? null : new Date(study.to);

        setStudyId(study.id);
        setEditCollegeName(study.collegeName);
        setEditDegree(study.degree);
        setEditYearOfPassing(passingYear);
        setEditCurrentCollege(study.currentCollage);
        setEditCountry(study.country);
        getEditStates(study.country);
        setEditState(study.state);
        getEditCity(study.country, study.state);
        setEditCity(study.city);

    };

    const handleEditUserStudy = (editCollegeName, editDegree, editYearOfPassing, editCurrentCollege, editCountry, editstate, editcity, studyId, userType, userId) => {

        let editStudyToDate = editCurrentCollege ? null : moment(editYearOfPassing).format("YYYY");

        const editStudyDetails = {
            collegeName: editCollegeName,
            degree: editDegree,
            to: editStudyToDate,
            currentCollage: editCurrentCollege,
            country: editCountry,
            state: editstate,
            city: editcity,
            id: studyId,
        };
        editUserStudy(userType, userId, editStudyDetails);
    };

    const editUserStudy = async (userType, userId, editStudyDetails) => {

        setOpenEditStudyDialog(false);
        const user = getUserType(userType);
        const study = [editStudyDetails]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                studyDetails: study,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
        }

    }

    // Functions for delete study

    const deleteUserStudy = async (userType, id) => {

        const user = getUserType(userType);

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/study/${id}`,
            method: "DELETE",
            addToast,
        });

        if (response.status === 200) {
            getUserInfo();
        };

    }

    const validateStudyForm = () => {

        let valid = true;

        if (collegeName.trim() === '') {
            addToast('College name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (degree.trim() === '') {
            addToast('Degree cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (currentCollage === true && yearOfPassing !== null) {
            addToast('Please select either "Currently Studying?" or provide a year for Year of passing', { appearance: 'error' });
            valid = false;
        } else if (currentCollage === false && yearOfPassing === null) {
            addToast('Please select either "Currently Studying?" or provide a year for Year of passing', { appearance: 'error' });
            valid = false;
        }

        if (country.trim() === '') {
            addToast('Country cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (state.trim() === '') {
            addToast('State cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (city.trim() === '') {
            addToast('City cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleAddStudySubmit = () => {

        if (validateStudyForm()) {
            handleAddUserStudy(collegeName, degree, yearOfPassing, currentCollage, country, state, city, userType, userId);
        }
    };

    const validateEditStudyForm = () => {

        let valid = true;

        if (editCollegeName.trim() === '') {
            addToast('College name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editDegree.trim() === '') {
            addToast('Degree cannot be empty', { appearance: 'error' });
            valid = false;
        }

        // if (editCurrentCollege === true && editYearOfPassing !== null) {
        //     addToast('Please select either "Currently Studying?" or provide a year for Year of passing', { appearance: 'error' });
        //     valid = false;
        // } else if (editCurrentCollege === false && editYearOfPassing === null) {
        //     addToast('Please select either "Currently Studying?" or provide a year for Year of passing', { appearance: 'error' });
        //     valid = false;
        // }

        if (editCountry.trim() === '') {
            addToast('Country cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editstate.trim() === '') {
            addToast('State cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editcity.trim() === '') {
            addToast('City cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleEditStudySubmit = () => {

        if (validateEditStudyForm()) {
            handleEditUserStudy(editCollegeName, editDegree, editYearOfPassing, editCurrentCollege, editCountry, editstate, editcity, studyId, userType, userId)
        }
    };


    return (
        <>  {/* ############################ study section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleStudyDialogClickOpen} className="profile_btn">
                        Education
                    </Button>
                </div>

                {studyDetails && studyDetails.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>

                            <div className="section_container">
                                <IconButton>
                                    <SchoolIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {value.degree}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {value.collegeName}
                                    </Typography>
                                    {value.currentCollage === true ?
                                        <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                            Currently Studying
                                        </Typography>
                                        :
                                        <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                            Year of passing: {value.to}
                                        </Typography>
                                    }
                                    <Typography align="left" className="profile_description profile_text" color="text.primary">
                                        {value.city}, {value.state}, {value.country}
                                    </Typography>
                                </div>
                                <div className="section_iconbtn_container">
                                    <IconButton onClick={() => handleEditStudyDialogClickOpen(value.id)} className="icon_btn">
                                        < EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => deleteUserStudy(userType, value.id)} className="icon_btn">
                                        <DeleteIcon color="primary" />
                                    </IconButton>
                                </div>
                            </div>

                        </Paper>
                    );
                })}

                {/* ############################ Create Study dialog ######################################### */}
                <Dialog open={openStudyDialog} onClose={handleStudyDialogClickClose} fullScreen={fullScreen} TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleStudyDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Study
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={1} style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="College Name"
                                    name="collegename"
                                    value={collegeName}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setCollegeName(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="collegename"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Degree"
                                    name="degree"
                                    value={degree}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setDegree(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="degree"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ margin: "8px 0px" }}>
                            <Grid item xs={12} style={{ marginTop: "2px" }}>
                                <FormControlLabel label="Currently Studying?" labelPlacement="start" style={{ marginLeft: "0px" }}
                                    control={<Switch checked={currentCollage} onChange={(event) => { setCurrentCollage(event.target.checked) }} color="primary" />}
                                />
                            </Grid>
                            {currentCollage === false &&
                                <Grid item xs={12} style={{ marginTop: "-4px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="Year Of Passing" views={['year']} disableFuture
                                                value={yearOfPassing}
                                                onChange={(newValue) => setYearOfPassing(newValue)}
                                                minDate={new Date(currentYear - 54, 0, 1)} // Set the minimum date to 54 years ago from the current year
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            }
                        </Grid>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="country-label"
                                        id="country-picker"
                                        label="Select Country"
                                        value={country}
                                        onChange={(event) => {
                                            setCountry(event.target.value);
                                            setStateChoices([]);
                                            setCityChoices([]);
                                            getStatesData(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""} selected>
                                            Select Country
                                        </MenuItem>
                                        {Array.isArray(countryChoices) &&
                                            countryChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-dialog-select-label">
                                        State
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="state-label"
                                        id="state-picker"
                                        label="Select State"
                                        value={state}
                                        onChange={(event) => {
                                            setState(event.target.value);
                                            setCityChoices([]);
                                            getCityData(country, event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""}>Select State</MenuItem>
                                        {Array.isArray(stateChoices) &&
                                            stateChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        City
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="city-label"
                                        id="city-picker"
                                        label="Select City"
                                        value={city}
                                        onChange={(event) => setCity(event.target.value)}
                                    >
                                        <MenuItem value={""}>Select City</MenuItem>
                                        {Array.isArray(cityChoices) &&
                                            cityChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddStudySubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
                {/* ############################ Edit Study dialog  ######################################### */}

                <Dialog open={openEditStudyDialog} onClose={handleEditStudyDialogClickClose} fullScreen={fullScreen} TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleEditStudyDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Edit Study
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={1} style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="College Name"
                                    name="editCollege"
                                    value={editCollegeName}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setEditCollegeName(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="editCollege"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Degree"
                                    name="editDegree"
                                    value={editDegree}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setEditDegree(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="editDegree"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ margin: "8px 0px" }}>
                            <Grid item xs={12} style={{ marginTop: "2px" }}>
                                <FormControlLabel label="Currently Studying?" labelPlacement="start" style={{ marginLeft: "0px" }}
                                    control={<Switch checked={editCurrentCollege} onChange={(event) => { setEditCurrentCollege(event.target.checked) }} color="primary" />}
                                />
                            </Grid>
                            {editCurrentCollege === false &&
                                <Grid item xs={12} style={{ marginTop: "-4px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="Year Of Passing" views={['year']} disableFuture
                                                value={editYearOfPassing}
                                                onChange={(newValue) => setEditYearOfPassing(newValue)}
                                                minDate={new Date(currentYear - 54, 0, 1)} // Set the minimum date to 54 years ago from the current year
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            }
                        </Grid>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="country-label"
                                        id="country-picker"
                                        label="Select Country"
                                        value={editCountry}
                                        onChange={(event) => {
                                            setEditCountry(event.target.value);
                                            setEditStateChoices([]);
                                            setEditCityChoices([]);
                                            getEditStates(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""} selected>
                                            Select Country
                                        </MenuItem>
                                        {Array.isArray(editCountryChoices) &&
                                            editCountryChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-dialog-select-label">
                                        State
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="state-label"
                                        id="state-picker"
                                        label="Select State"
                                        value={editstate}
                                        onChange={(event) => {
                                            setEditState(event.target.value);
                                            setEditCityChoices([]);
                                            getEditCity(editCountry, event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""}>Select State</MenuItem>
                                        {Array.isArray(editStateChoices) &&
                                            editStateChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        City
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="city-label"
                                        id="city-picker"
                                        label="Select City"
                                        value={editcity}
                                        onChange={(event) => setEditCity(event.target.value)}
                                    >
                                        <MenuItem value={""}>Select City</MenuItem>
                                        {Array.isArray(editCityChoices) &&
                                            editCityChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditStudySubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                <YLoader loading={loading} />
            </div >
        </>
    )
}

export default Studysection
