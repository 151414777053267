import React, { useState, useContext } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, TextField, Paper, Button, IconButton, Typography } from '@mui/material';
import { YContext } from "../../Context/YContext";
import { useToasts } from "react-toast-notifications";
import DescriptionIcon from '@mui/icons-material/Description';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';
import 'date-fns';
import "./postamagazine.css";

const PostAMagazine = () => {
    const [loading, setLoading] = useState(true);
    const [uploadMagazineDate, setUploadMagazineDate] = useState(new Date());
    const { addToast } = useToasts();

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="post_magazine-drawer">
                        <Navigation />
                    </div>

                    <div className="post_magazine-container">

                        <Paper elevation={2} className="post_magazinepaper-container">
                            <Grid container direction="row" spacing={0.8} >
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        label="Magazine Title"
                                        name="title"
                                        // value={productName}
                                        // onChange={(event) => setProductName(event.target.value)}
                                        autoComplete="off"
                                        id="title"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>
                                        Publish Month and Year :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {/* <DatePicker selected={uploadMagazineDate} onChange={(date) => setUploadMagazineDate(date)}
                                        dateFormat="MMM yyyy"
                                        isClearable
                                        showMonthYearPicker
                                        maxDate={addDays(new Date(), 0)}
                                        className='magazine_uploaddate'
                                    /> */}
                                </Grid>
                            </Grid>

                            <Grid container direction="column" style={{ marginTop: "6px" }}>
                                <Grid item xs={12}>
                                    <Typography variant='body1' gutterBottom>
                                        Upload Magazine :
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container direction="column" justifyContent="center" alignItems="center" className="magazine_upload-Container">
                                <Grid item >
                                    <IconButton component="label" style={{ padding: "0px" }}>
                                        <input hidden accept="image/*" type="file" />
                                        <CloudUploadIcon className="magazine_upload--Icon" />
                                    </IconButton>
                                </Grid>
                                <Grid item >
                                    <Typography variant="subtitle1" align="center">
                                        Supported Files
                                    </Typography>
                                    <Typography variant="subtitle2" align="center" gutterBottom>
                                        PDF, JPG, PNG
                                    </Typography>
                                </Grid>
                            </Grid>

                            <div className="magazine_upload-FileInfo">
                                <DescriptionIcon className='magazine_upload-FileIcon' />
                                <Typography variant="subtitle1" className='magazine_file-Name'>
                                    myResume.pdf
                                </Typography>
                                <ClearIcon className='magazine_upload-FileIcon' />
                            </div>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Button variant="contained" fullWidth className='post_magazine-button'>Post Magazine</Button>
                                </Grid>
                            </Grid>

                        </Paper>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default PostAMagazine