import React, { useState, useEffect } from "react";
import { Grid, Typography, Dialog, DialogTitle, DialogContent, Slide, IconButton, Button } from '@mui/material';
import "./identityverification.css";
import { useToasts } from "react-toast-notifications";
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { fileCompressor } from "../../components/FileCompressor";
import UrlRoute from "../../API/UrlRoute";
import useAPIRequest from "../../API/useApiRequest";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateIdentityVerificationDialog = ({ openUpdateIdentityVerificationDialog, handleUpdateIdentityVerificationDialogClickClose, sortedIdentityDocuments, getVerificationStatus }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToasts();

  const [uploadedFrontDocumentImage, setUploadedFrontDocumentImage] = useState({ contentType: null, content: null });
  const [uploadedFrontDocumentImageId, setUploadedFrontDocumentImageId] = useState("");
  const [uploadedFrontDocumentVerificationStatus, setUploadedFrontDocumentVerificationStatus] = useState("");

  const [selectedFrontDocumentImage, setSelectedFrontDocumentImage] = useState({ contentType: null, content: null });
  const [selectedFrontDocumentImageURL, setSelectedFrontDocumentImageURL] = useState("");

  const [uploadedBackDocumentImage, setUploadedBackDocumentImage] = useState({ contentType: null, content: null });
  const [uploadedBackDocumentImageId, setUploadedBackDocumentImageId] = useState("");
  const [uploadedBackDocumentVerificationStatus, setUploadedBackDocumentVerificationStatus] = useState("");

  const [selectedBackDocumentImage, setSelectedBackDocumentImage] = useState({ contentType: null, content: null });
  const [selectedBackDocumentImageURL, setSelectedBackDocumentImageURL] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  useEffect(() => {
    if (openUpdateIdentityVerificationDialog) {
      getLatestIdentityVerificationDocuments();
    }
  }, [openUpdateIdentityVerificationDialog]);

  const getLatestIdentityVerificationDocuments = () => {
    if (sortedIdentityDocuments) {

      sortedIdentityDocuments.forEach(document => {
        if (document.documentType === "ID_CARD_DOCUMENT_BACK") {

          setUploadedBackDocumentImage({ contentType: document.contentType, content: document.content });
          setUploadedBackDocumentImageId(document.id);

          setUploadedBackDocumentVerificationStatus(document.verificationStatus);


        } else if (document.documentType === "ID_CARD_DOCUMENT_FRONT") {

          setUploadedFrontDocumentImage({ contentType: document.contentType, content: document.content });
          setUploadedFrontDocumentImageId(document.id);

          setUploadedFrontDocumentVerificationStatus(document.verificationStatus);

        }

      });
    }

  };

  // Functions to handle front side image select and delete operations

  const handleSelectedFrontDocumentImageDelete = () => {
    setSelectedFrontDocumentImage({ contentType: null, content: null });
    setSelectedFrontDocumentImageURL("");
  };

  const handleDeleteUploadedFrontDocumentImage = async () => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL}/${uploadedFrontDocumentImageId}`,
      method: "DELETE",
      addToast,
    });

    if (data.response === true) {
      addToast('Document front side image deleted successfully !!!', { appearance: 'success' });

      if (uploadedBackDocumentImageId !== "") {
        setFileLoading(false);
        handleDeleteUploadedBackDocumentImage();
        addToast('Document back side image deleted successfully !!!', { appearance: 'success' });
        handleUpdateIdentityVerificationDialogClickClose();
        getVerificationStatus();
      } else {
        handleUpdateIdentityVerificationDialogClickClose();
        setFileLoading(false);
        getVerificationStatus();
      }

    } else {
      setFileLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  }

  const handleSelectedFrontDocumentImageChange = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setFileLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setSelectedFrontDocumentImage({ contentType: contentType, content: imageData });
            setSelectedFrontDocumentImageURL(url);
          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setFileLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setFileLoading(false);
    }

  };


  // Functions to handle back side image select and delete operations

  const handleSelectedBackDocumentImageDelete = () => {
    setSelectedBackDocumentImage({ contentType: null, content: null });
    setSelectedBackDocumentImageURL("");
  };

  const handleDeleteUploadedBackDocumentImage = async () => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL}/${uploadedBackDocumentImageId}`,
      method: "DELETE",
      addToast,
    });

    if (data.response === true) {
      handleUpdateIdentityVerificationDialogClickClose();
      setFileLoading(false);
      getVerificationStatus();
    } else {
      setFileLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  }

  const handleSelectedBackDocumentImageChange = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setFileLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setSelectedBackDocumentImage({ contentType: contentType, content: imageData });
            setSelectedBackDocumentImageURL(url);
          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setFileLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setFileLoading(false);
    }

  };

  const handleUpdateIdentityVerificationSubmit = () => {

    if (validateUpdateIdentityVerification()) {
      uploadUpdateIdentityVerificationDocument();
    }

  };

  const validateUpdateIdentityVerification = () => {

    let valid = true;

    if (uploadedFrontDocumentImage.content === null && selectedFrontDocumentImage.content === null) {
      addToast('Document Front Side Image cannot be empty', { appearance: 'error' });
      valid = false;
    }

    return valid;
  };

  const uploadUpdateIdentityVerificationDocument = async () => {
    let frontDocumentImageRequestBody;

    if (selectedFrontDocumentImage.content) {
      // Use selectedFrontDocumentImage properties
      frontDocumentImageRequestBody = {
        documentType: "ID_CARD_DOCUMENT_FRONT",
        contentType: selectedFrontDocumentImage.contentType,
        content: selectedFrontDocumentImage.content,
      };
    } else if (uploadedFrontDocumentImage.content) {
      // Use uploadedFrontDocumentImage properties
      frontDocumentImageRequestBody = {
        documentType: "ID_CARD_DOCUMENT_FRONT",
        contentType: uploadedFrontDocumentImage.contentType,
        content: uploadedFrontDocumentImage.content,
      };
    } else {
      // Handle the case when neither selectedDocumentImage nor uploadedDocumentImage is present
      console.error('Neither selectedDocumentImage nor uploadedDocumentImage is present.');
      return;
    }

    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL}/${uploadedFrontDocumentImageId}`,
      method: 'PUT',
      body: frontDocumentImageRequestBody,
      addToast,
    });

    if (response.status === 200) {
      addToast('The front side of the document image has been updated successfully !!!', { appearance: 'success' });

      if (uploadedBackDocumentImage.content !== null || selectedBackDocumentImage.content !== null) {

        let backDocumentImageRequestBody;

        if (selectedBackDocumentImage.content) {
          // Use selectedBackDocumentImage properties
          backDocumentImageRequestBody = {
            documentType: "ID_CARD_DOCUMENT_BACK",
            contentType: selectedBackDocumentImage.contentType,
            content: selectedBackDocumentImage.content,
          };
        } else if (uploadedBackDocumentImage.content) {
          // Use uploadedBackDocumentImage properties
          backDocumentImageRequestBody = {
            documentType: "ID_CARD_DOCUMENT_BACK",
            contentType: uploadedBackDocumentImage.contentType,
            content: uploadedBackDocumentImage.content,
          };
        } else {
          // Handle the case when neither selectedDocumentImage nor uploadedDocumentImage is present
          console.error('Neither selectedDocumentImage nor uploadedDocumentImage is present.');
          return;
        }

        const { response, data, error } = await apiRequests({
          endPoint: `${UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL}/${uploadedBackDocumentImageId}`,
          method: 'PUT',
          body: backDocumentImageRequestBody,
          addToast,
        });

        if (response.status === 200) {
          addToast('The back side of the document image has been updated successfully !!!', { appearance: 'success' });
          handleUpdateIdentityVerificationDialogClickClose();
          setSelectedBackDocumentImage({ contentType: null, content: null });
          getVerificationStatus();
        }

      } else {
        handleUpdateIdentityVerificationDialogClickClose();
        getVerificationStatus();
      }

      setSelectedFrontDocumentImage({ contentType: null, content: null });

    }
  };

  return (
    <>
      <Dialog
        open={openUpdateIdentityVerificationDialog}
        onClose={handleUpdateIdentityVerificationDialogClickClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
          <IconButton
            edge="start"
            onClick={handleUpdateIdentityVerificationDialogClickClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          Update Identity Verification
        </DialogTitle>
        <DialogContent>

          <Grid container direction="row" style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" color="text.primary" gutterBottom>
                Front side image:
              </Typography>
            </Grid>
          </Grid>

          {uploadedFrontDocumentVerificationStatus && (
            <Grid container direction="row" style={{ margin: "6px 0px" }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.primary" align="left">
                  Status: {uploadedFrontDocumentVerificationStatus === "PENDING" ? "Pending for verification" : uploadedFrontDocumentVerificationStatus}
                </Typography>
              </Grid>
            </Grid>
          )}

          {selectedFrontDocumentImageURL ? (
            <div className="identity-document-image-container">
              <img src={selectedFrontDocumentImageURL} alt="Identity Document" className="identity-document-image" />
              <CloseIcon className="identity-document-delete-Icon" onClick={handleSelectedFrontDocumentImageDelete} />
            </div>
          ) : uploadedFrontDocumentImage.content ? (
            <div className="identity-document-image-container">
              <img
                src={`data:${uploadedFrontDocumentImage.contentType};base64,${uploadedFrontDocumentImage.content}`}
                alt="Identity Document"
                className="identity-document-image"
              />
              {uploadedFrontDocumentVerificationStatus === "VERIFICATION_FAILED" &&
                <CloseIcon className="identity-document-delete-Icon" onClick={handleDeleteUploadedFrontDocumentImage} />
              }
            </div>
          ) : (
            <div className="identity-document-container">
              <IconButton component="label">
                <input hidden accept="image/*" type="file" onChange={handleSelectedFrontDocumentImageChange} />
                <CloudUploadIcon color="primary" className="identity-document-upload-Icon" />
              </IconButton>
            </div>
          )
          }

          <Grid container direction="row" style={{ marginTop: "6px" }}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" color="text.primary" gutterBottom>
                Back side image:
              </Typography>
            </Grid>
          </Grid>

          {uploadedBackDocumentVerificationStatus && (
            <Grid container direction="row" style={{ margin: "6px 0px" }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.primary" align="left">
                  Status: {uploadedBackDocumentVerificationStatus === "PENDING" ? "Pending for verification" : uploadedBackDocumentVerificationStatus}
                </Typography>
              </Grid>
            </Grid>
          )}

          {selectedBackDocumentImageURL ? (
            <div className="identity-document-image-container">
              <img src={selectedBackDocumentImageURL} alt="Identity Document" className="identity-document-image" />
              <CloseIcon className="identity-document-delete-Icon" onClick={handleSelectedBackDocumentImageDelete} />
            </div>
          ) : uploadedBackDocumentImage.content ? (
            <div className="identity-document-image-container">
              <img
                src={`data:${uploadedBackDocumentImage.contentType};base64,${uploadedBackDocumentImage.content}`}
                alt="Identity Document"
                className="identity-document-image"
              />
              {uploadedFrontDocumentVerificationStatus === "VERIFICATION_FAILED" &&
                <CloseIcon className="identity-document-delete-Icon" onClick={handleDeleteUploadedBackDocumentImage} />
              }
            </div>
          ) : (
            <div className="identity-document-container">
              <IconButton component="label">
                <input hidden accept="image/*" type="file" onChange={handleSelectedBackDocumentImageChange} />
                <CloudUploadIcon color="primary" className="identity-document-upload-Icon" />
              </IconButton>
            </div>
          )
          }

          {(uploadedFrontDocumentVerificationStatus !== "VERIFIED" && uploadedFrontDocumentVerificationStatus !== "PENDING") && (
            <div style={{ marginTop: "16px" }}>
              <Button variant="contained" fullWidth className='identity-submit-button' color="primary"
                onClick={handleUpdateIdentityVerificationSubmit}
              >
                Submit
              </Button>
            </div>
          )}

        </DialogContent>
      </Dialog>

      {
        fileLoading && (
          <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )
      }

    </>
  )
}

export default UpdateIdentityVerificationDialog
