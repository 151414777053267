import React from "react";
import { Dialog, DialogContent, DialogTitle, Slide, IconButton, Typography, Grid } from '@mui/material';
import './singleeventpass.css';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceIcon from '@mui/icons-material/Place';
import LaunchIcon from '@mui/icons-material/Launch';
import QrCode2Icon from '@mui/icons-material/QrCode2';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SingleEventPass = ({ eventPassOpen, handleEventPassClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            {/* ############################ Show event dialog ######################################### */}
            <Dialog
                open={eventPassOpen}
                onClose={handleEventPassClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                    <IconButton
                        edge="start"
                        onClick={handleEventPassClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                    Event Pass
                </DialogTitle>
                <DialogContent style={{ padding: "0px" }}>

                    <div>

                        {eventPassData.map((value) => {
                            return (
                                <>
                                    <div className="eventpass-container">

                                        <div className="eventpassname_details">
                                            <div className="eventpassname_info">
                                                <Typography variant="h6" align="left" className="eventpass_name eventpass_title">
                                                    {value.eventName}
                                                </Typography>
                                            </div>
                                            <div className="eventpasstype_info">
                                                <Typography variant="subtitle2">
                                                    <VideocamIcon className='eventpass_icon' />
                                                    Online
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className="eventpass_details">
                                            <Typography variant="subtitle1" component="span">
                                                <span className='eventpass_text'>Event By&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                                            </Typography>
                                            <div className="eventpass_info">
                                                <Typography variant="subtitle2" component="span">
                                                    &nbsp;{value.eventHostName}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className="eventpass_details">
                                            <Typography variant="subtitle1">
                                                <span className='eventpass_text'>Event Start&nbsp; :</span>
                                            </Typography>
                                            <div className="eventpass_info">
                                                <Typography variant="subtitle2">
                                                    <AccessTimeIcon className='eventpass_icon' />
                                                    {value.eventDate}, {value.eventtime}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className="eventpass_details">
                                        <Typography variant="subtitle1">
                                                <span className='eventpass_text'>Event End&nbsp;&nbsp;&nbsp;:</span>
                                            </Typography>
                                            <div className="eventpass_info">
                                            <Typography variant="subtitle2">
                                                    <AccessTimeIcon className='eventpass_icon' />
                                                    {value.eventDate}, {value.eventtime}
                                                </Typography>
                                            </div>
                                        </div>

                                        {value.mode !== "online" ?                                       
                                            <div className="eventpass_details">
                                                <Typography variant="subtitle1">
                                                    <span className='eventpass_text'>Event Address&nbsp;:</span>
                                                </Typography>
                                                <div className="eventpass_info">
                                                    <Typography variant="subtitle2">
                                                        <PlaceIcon className='eventpass_icon eventpass_address_icon' />
                                                        {value.address}
                                                    </Typography>
                                                </div>
                                            </div>
                                        :                                      
                                            <div className="eventpass_details">
                                                <Typography variant="subtitle1">
                                                    <span className='eventpass_text'>Event Link&nbsp;: </span>
                                                </Typography>
                                                <div className="eventpass_info">
                                                    <Typography variant="subtitle2">
                                                        <LaunchIcon className='eventpass_icon' />
                                                        <a href="#" target="_blank" rel="noreferrer"
                                                            style={{ textDecoration: "none" }}>
                                                            {value.eventlink}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>
                                    }                                     

                                        <div className="eventpass-QR-code">
                                            <div>
                                                <QrCode2Icon className='QR-icon' />
                                            </div>
                                        </div>

                                    </div>
                                </>
                            );
                        })}

                    </div >

                </DialogContent>
            </Dialog>
        </>
    )
}

export default SingleEventPass

const eventPassData = [
    {
        id: 0,
        eventHostName: "MeddyConnect",
        eventName: "International Conference on Medical & Health Science",
        address: "Central Park Manhattan ,New York City.",
        eventtime: "9.00 AM To 5.00 PM",
        mode: "Online",
        eventDate: "May 24, 2023",
        eventtime: "9.00 AM To 5.00 PM",
        eventlink: "https://meet.google.com/ipl-csk7-win",
        profilePicture: "https://images.unsplash.com/photo-1610088441520-4352457e7095?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fG1lbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
    },

]
