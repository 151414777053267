import { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Typography } from '@mui/material';
import "./productcatelog.css";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";
import useAPIRequest from "../../API/useApiRequest";
import { useTheme } from '@mui/material/styles';
import ImageNotAvailable from "../../assets/images/imagenotavailable.jpg";

const NewProductCatelog = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const { addToast } = useToasts();
    const history = useHistory();

    const [manufacturersData, setManufacturersData] = useState([]);

    // Services
    const { apiRequests } = useAPIRequest();

    useEffect(() => {
        getManufacturersInfo();
    }, []);

    const getManufacturersInfo = async () => {
        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `vendor/manufacturers`,
            method: "GET",
            addToast,
        });

        if (data) {
            setManufacturersData(data);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    return (
        <>
            <div style={{ height: "100vh" }}>

                <div style={{ display: "flex" }}>

                    <div className="newproduct-catelog-Drawer">
                        <Navigation />
                    </div>

                    <div className="newproduct-catelog-container">

                        <Typography variant="h6" align="left" style={{ marginLeft: "10px", fontWeight: "bolder" }}>
                            Brand Store
                        </Typography>

                        {manufacturersData.length === 0 ? (
                            <Typography align="center" color="text.primary" variant="subtitle1" style={{ marginTop: "20px" }}>
                                No brands available at the moment. Please check back later.
                            </Typography>
                        ) : (
                            <div className='store-brands-container'>
                                {manufacturersData.map((manufacturer, index) => {
                                    return (
                                        <>
                                            <div className="brands-list-box" key={index} onClick={() => history.push(Constants.NEW_PRODUCT_CATALOGUE)}
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}
                                            >
                                                <div style={{ height: "120px", marginBottom: "4px" }}>

                                                    {manufacturer.logo && manufacturer.logo.href ?
                                                        <img src={manufacturer.logo.href} alt="brand logo" id="brand-logo" />
                                                        :
                                                        <img src={ImageNotAvailable} alt="logo not available" id="brand-logo" />
                                                    }

                                                </div>
                                                <Typography variant="subtitle1" className='brand-title' align="center">
                                                    {manufacturer.name}
                                                </Typography>
                                            </div>
                                        </>
                                    );
                                })
                                }
                            </div>
                        )}

                    </div>

                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default NewProductCatelog
