import React, { useState } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, Typography, Paper, Button } from '@mui/material';
import "./questionandanswer.css";
import { FaRegComment } from "react-icons/fa";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import WriteAnswer from './WriteAnswer';
import { ImArrowUp ,ImArrowDown} from "react-icons/im";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const AllAnswers = () => {
    const [loading, setLoading] = useState(true);
    const [writeAnswerDialogOpen, setWriteAnswerDialogOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleWriteAnswerDialogOpen = () => {
        setWriteAnswerDialogOpen(true);
    };

    const handleWriteAnswerDialogClose = () => {
        setWriteAnswerDialogOpen(false);
    };

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="left-Menu-Drawer">
                        <Navigation />
                    </div>

                    <div className="questionandanswer-container">

                        <div>
                            {questionerData.map((element) => {
                                return (
                                    <Paper elevation={2} className="questionandanswer-paper" key={element.id}>

                                        <div className="questioner-container">
                                            <Typography variant="h6" align='left' className='question-text' gutterBottom>
                                                {element.question}
                                            </Typography>
                                        </div>

                                        <div className="responser-container">
                                            <img src={element.questionerPicture} alt='eventhost image' className='responser-Picture' />
                                            <div className="responser-info">
                                                <Typography variant="subtitle1" className='question-text'>
                                                    {element.questionerName}
                                                </Typography>
                                                <Typography variant="body2" >
                                                    {element.questionerIntro}
                                                </Typography>
                                            </div>
                                        </div>

                                        <div className="write-ans-container">
                                            <Button variant="outlined" startIcon={<CreateOutlinedIcon />} className='answer-btn' onClick={handleWriteAnswerDialogOpen}>
                                                Answer
                                            </Button>
                                        </div>

                                        <div>
                                            <WriteAnswer writeAnswerDialogOpen={writeAnswerDialogOpen} handleWriteAnswerDialogClose={handleWriteAnswerDialogClose} />
                                        </div>

                                    </Paper>
                                );
                            })}

                            {quesandansData.map((value) => {
                                return (
                                    <Paper elevation={2} className="questionandanswer-paper" key={value.id}>

                                        <div className="responser-container">
                                            <img src={value.responserPicture} alt='eventhost image' className='responser-Picture' />
                                            <div className="responser-info">
                                                <Typography variant="subtitle1" className='question-text'>
                                                    {value.responserName}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {value.responserIntro} &middot; 3days ago
                                                </Typography>
                                            </div>
                                        </div>

                                        <Grid container rowSpacing={1} style={{marginTop:"10px"}}>

                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2">
                                                    {value.ans}
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                        <div className="action-btn-container">
                                            <div className='action-btn-iconwith-text action-btn-only-likeicon'>
                                                <ImArrowUp className='action-icons' />
                                                <Typography variant="subtitle2">
                                                {fullScreen ? " ": "Upvote"} &middot; {value.likes}
                                                </Typography>
                                            </div>
                                            <div className='action-btn-iconwith-text'>
                                            <span className='tooltip' data-tooltip="Downvote"><ImArrowDown className='action-icons' /></span>
                                                {/* <Typography variant="subtitle2">
                                                    {value.dislike}
                                                </Typography> */}
                                            </div>

                                            <div className='action-btn-iconwith-text'>
                                                <FaRegComment className='action-react-icons' />
                                                <Typography variant="subtitle2">
                                                    {value.comments}
                                                </Typography>
                                            </div>
                                        </div>

                                    </Paper>

                                );
                            })}

                        </div>

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default AllAnswers

const quesandansData = [
    {
        id: 0,
        question: "What are some unknown facts about the human brain?",
        responserPicture: "https://images.unsplash.com/photo-1602233158242-3ba0ac4d2167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
        responserName: "Neha Batra",
        responserIntro: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
        ans: "Here are some interesting facts about human brain- We tend to like people who make mistakes more Your brain generates enough electricity to power a 25-watt light bulb.A small portion of the population experiences synaesthesia: they may hear colors, smell words or see a concept in a spatial location.Naps improve your brain’s day to day performance",
        likes: 10,
        comments: 8
    },
    {
        id: 1,
        question: "how learn python language?",
        responserPicture: "https://images.unsplash.com/photo-1508341591423-4347099e1f19?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        responserName: "Sushil Salve",
        responserIntro: "internationally qualified Hyperbaric Medicine physician in India ,Nashik",
        ans: "The average brain is believed to generate around 50,000 thoughts per day. Disturbingly, it’s estimated that in most people 70% of these thoughts are negative.",
        likes: 5,
        dislike: 2,
        comments: 7
    },
    {
        id: 2,
        question: "how to handle multipart data in react js?",
        responserPicture: "https://images.unsplash.com/photo-1610088441520-4352457e7095?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fG1lbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
        responserName: "Akshay Gandal",
        responserIntro: "urologist, robotic surgery specialist, kidney transplant surgeon, and researcher ,Pune",
        ans: "One interesting thing to know, is that a gene called the ‘ARHGAP11B’ is found in regions of the human brain. Researchers inserted this gene into mice and found that the mice developed larger brains with the entire outer layer having developed the folds, which you typically find in humans![1]",
        likes: 20,
        comments: 15
    },
    {
        id: 3,
        question: "what is cloud computing?",
        responserPicture: "https://images.unsplash.com/photo-1481214110143-ed630356e1bb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8d29tZW58ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
        responserName: "Shrinithyaa Gopalan",
        responserIntro: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
        ans: "A folded brain equals a greater power for cognitive functioning and processing information.The brain, trapped inside the skull, is limited in size. The gyri (folds), allow the surface area to increase, without having to increase the size of the brain itself. This allows the creation of new neural connections, allowing us to pick up new skills and obtain new knowledge.",
        likes: 10,
        dislike: 6,
        comments: 8
    },
    {
        id: 4,
        question: "how to learn react native?",
        responserPicture: "https://images.unsplash.com/photo-1560087637-bf797bc7796a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fHdvbWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        responserName: "Monika Sveet",
        responserIntro: "MD Alternate medicines, Satara ",
        ans: "About 75 percent of the brain is made up of water. This means that dehydration,even in small amounts, can have a negative effect on the brain functions.The human brain will grow three times its size in the first year of life. It continues to grow until you’re about 18 years old.Headaches are caused by a chemical reaction in your brain combined with the muscles and nerves of your neck and head.Your brain uses 20 percent of the oxygen and blood in your body.",
        likes: 10,
        dislike: 12,
        comments: 8
    },
]

const questionerData = [
    {
        id: 0,
        question: "What are some unknown facts about the human brain?",
        questionerPicture: "https://images.unsplash.com/photo-1508341591423-4347099e1f19?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        questionerName: "Rahul Shah",
        questionerIntro: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
    },
]