import { useState, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import './managejobapplications.css';
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import UrlRoute from "../../API/UrlRoute";
import ManageJobs from '../../Jobs/ManageJobs/ManageJobs';

const ManageJobApplications = () => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const [postedApplicationsByUser, setPostedApplicationsByUser] = useState([]);

    // Services
    const { apiRequests } = useAPIRequest();

    const getPostedApplicationsByUser = async () => {

        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: UrlRoute.GET_POSTED_JOBS_BY_USER_URL,
            method: "GET",
            addToast,
        });

        if (data) {

            setPostedApplicationsByUser(data);
            setLoading(false);

        } else {

            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });

        }

    };

    useEffect(() => {
        getPostedApplicationsByUser();
    }, []);

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="manage-job-applications-drawer">
                        <Navigation />
                    </div>

                    <div className="manage-job-applications-container">

                        <ManageJobs postedApplicationsByUser={postedApplicationsByUser} getPostedApplicationsByUser={getPostedApplicationsByUser} />

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default ManageJobApplications