import { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Typography, Avatar, Grid } from '@mui/material';
import useAPIRequest from "../../API/useApiRequest";
import UrlRoute from "../../API/UrlRoute";
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";
import ApplicantsDetailsDialog from "./ApplicantsDetailsDialog";
import moment from "moment";
import "./viewapplicantsfromjob.css";
import { useParams } from 'react-router-dom';

const ViewApplicantsFromJob = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { jobApplicantsId } = useParams();

  const [applicantsUserList, setApplicantsUserList] = useState([]);
  const [applicantId, setApplicantId] = useState("");
  const [jobId, setJobId] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openApplicantInfoDialog, setOpenApplicantInfoDialog] = useState(false);

  const handleApplicantInfoDialogClickOpen = (id, jobId) => {
    setOpenApplicantInfoDialog(true);
    setApplicantId(id);
    setJobId(jobId);
  };

  const handleApplicantInfoDialogClickClose = () => {
    setOpenApplicantInfoDialog(false);
  };

  useEffect(() => {
    getApplicantsList(jobApplicantsId);
  }, [])

  const getApplicantsList = async (jobApplicantsId) => {
    setLoading(true)
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.GET_APPLICANTS_FOR_JOBS_URL}/${jobApplicantsId}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setApplicantsUserList(data);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }
  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="jobapplicantslist-Drawer">
            <Navigation />
          </div>

          <div className="jobapplicantslist-container">

            <div className="applicants-list">

              {applicantsUserList.length > 0 ?

                applicantsUserList.map((value) => {
                  return (
                    <div className="jobapplicantslist-card" key={value.id} onClick={() => handleApplicantInfoDialogClickOpen(value.id, value.jobId)} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                      <Avatar src={value.appliedProfileLink && value.appliedProfileLink.href ? value.appliedProfileLink.href : " "} className='applicants-Picture' />
                      <div className="jobapplicants-info">

                        <Typography color="text.primary" className="applicant-title">
                          {value.appliedUserName}
                        </Typography>
                        <Typography color="text.primary" className="applicant-subtitle">
                          {value.created ? moment(value.created).format("MMM D, YYYY") : ""}
                        </Typography>

                      </div>
                    </div>
                  );
                })
                :
                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={12}>
                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                      No results found
                    </Typography>
                  </Grid>
                </Grid>
              }


            </div>

            <ApplicantsDetailsDialog
              openApplicantInfoDialog={openApplicantInfoDialog}
              handleApplicantInfoDialogClickClose={handleApplicantInfoDialogClickClose}
              applicantId={applicantId}
              jobId={jobId}
            />

          </div>

        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ViewApplicantsFromJob
