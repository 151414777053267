import React, { useState, useEffect, useRef, useContext } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Paper, Grid, Button, Typography, IconButton, Avatar, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Select, InputLabel, MenuItem, FormControl, Stack } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../Profile.css";
import Awardsection from "../Components/Awardsection";
import MedicalAssociationWorkingHrs from "./MedicalAssociationWorkingHrs";
import Blogsection from "../Components/Blogsection";
import ProfilePhotos from "../Components/ProfilePhotos";
import { useToasts } from "react-toast-notifications";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { fileCompressor } from "../../components/FileCompressor";
import useGetData from '../../API/useGetData';
import useAPIRequest from "../../API/useApiRequest";
import { YContext } from "../../Context/YContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const MedicalAssociationProfile = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToasts();

  // Variables
  const [profile, setProfile] = useState({ contentType: null, content: null });
  const [imageURL, setImageURL] = useState("");
  const inputFile = useRef(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [userId, setUserId] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [associationName, setAssociationName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [pincodes, setPincodes] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [supportMobile, setSupportMobile] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [imageChanged, setImageChanged] = useState(false);

  // Dialogs
  const [openEditProfileDialog, setOpenEditProfileDialog] = React.useState(false);

  // Services
  const { currentUser, getUserInfo } = useContext(YContext);
  const { apiRequests } = useAPIRequest();
  const { getCountry, getStates, getCity } = useGetData();


  useEffect(() => {
    getReferenceData();
  }, []);

  const getReferenceData = async () => {
    const countryOptions = await getCountry(addToast);
    if (countryOptions) {
      //   setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country != "") {
      let stateOptions = [];
      stateOptions = await getStates(country, addToast);
      if (stateOptions) {
        // setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country != "" && state && state != "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state, addToast);
      if (cityOptions) {
        // setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  // Edit Profile Dialog Events

  const handleEditProfileDialogClose = () => {
    setOpenEditProfileDialog(false);
  };
  const handleEditProfileDialogOpen = () => {
    setOpenEditProfileDialog(true);
  };

  // Functions

  const imagePicker = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setFileLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setProfile({ contentType: contentType, content: imageData });
            setImageURL(url);
            setImageChanged(true);

          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setFileLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setFileLoading(false);
    }

  };

  useEffect(() => {

    if (imageChanged) {
      updateUserProfileImg(userId);

      setImageChanged(false);
    }
  }, [profile, imageURL, userId, imageChanged]);

  const updateUserProfileImg = async (userId) => {
    setOpenEditProfileDialog(false);
    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `medical-association/${userId}`,
      method: "PUT",
      body: {
        profile: profile,
      },
      addToast,
    });

    if (data) {
      setLoading(false);
      getUserInfo();
      addToast("Profile Photo updated successfully !!!", { appearance: "success" });
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  }

  const getAssociationDetails = async () => {

    setLoading(true);
    setUserId(currentUser.id)
    setAssociationName(currentUser.name);
    setMobileNo(currentUser.contactNo);
    setEmailId(currentUser.email);
    setDescription(currentUser.description);
    setAddress(currentUser.address);
    setPincodes(currentUser.pinCode);
    setCountry(currentUser.country);
    getStatesData(currentUser.country);
    setState(currentUser.state);
    getCityData(currentUser.country, currentUser.state);
    setCity(currentUser.city);
    setProfileImage(currentUser.profileLink?.href);
    setLoading(false);

  };

  useEffect(() => {
    getAssociationDetails();
  }, [currentUser]);


  const updateMedicalAssociationProfile = async (userId) => {

    setOpenEditProfileDialog(false);
    setLoading(true);

    const { response, data, error } = await apiRequests({
      endPoint: `medical-association/${userId}`,
      method: "PUT",
      body: {
        name: associationName.trim(),
        contactNo: mobileNo,
        email: emailId,
        description: description,
        address: address,
        pinCode: pincodes,
        country: country,
        state: state,
        city: city,
      },
      addToast,
    });

    if (data) {
      setLoading(false);
      getUserInfo();
      addToast("Profile updated successfully !!!", { appearance: "success" });
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  }

  const validateMedicalAssociationProfile = () => {

    let valid = true;

    if (associationName.trim() === '') {
      addToast('Association Name cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      const trimmedName = associationName.trim();
      // Assuming a valid full name is required
      const fullNameRegex = /^(?:[A-Z][a-z]*\.? ?)+[A-Z][a-z]*(?: [A-Z][a-z]*)*$/;

      if (!fullNameRegex.test(trimmedName)) {
        addToast('Invalid Association Name. Please enter a valid full name.', { appearance: 'error' });
        valid = false;
      }
    }

    if (address.trim() === '') {
      addToast('Address cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      // Assuming a valid address format is required
      const addressRegex = /^[a-zA-Z0-9\s,.'/-]+$/;

      if (!addressRegex.test(address)) {
        addToast('Invalid address. Please enter a valid address.', { appearance: 'error' });
        valid = false;
      }
    }

    if (country.trim() === '') {
      addToast('Country cannot be empty', { appearance: 'error' });
      valid = false;
    }

    if (state.trim() === '') {
      addToast('State cannot be empty', { appearance: 'error' });
      valid = false;
    }

    if (city.trim() === '') {
      addToast('City cannot be empty', { appearance: 'error' });
      valid = false;
    }

    if (pincodes.trim() === '') {
      addToast('PinCode cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      // Check if pincodes contains only numbers
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(pincodes)) {
        addToast('Invalid PinCode. Please enter only numbers.', { appearance: 'error' });
        valid = false;
      } else if (pincodes.length !== 6) {
        addToast('PinCode must be 6 digits long', { appearance: 'error' });
        valid = false;
      }
    }

    return valid;
  };

  const handleMedicalAssociationProfileSubmit = () => {

    if (validateMedicalAssociationProfile()) {
      updateMedicalAssociationProfile(userId)
    }

  };

  return (
    <>
      <div style={{ height: "auto" }}>
        <div style={{ display: "flex" }}>
          <div className="user_profile-Drawer">
            <Navigation />
          </div>
          <div className="user_profile-container" >

            <Paper elevation={4} className="paper-container">
              <div className="user_profile_container">
                <Avatar src={profileImage ? profileImage : " "} className='profile_picture' />
                <div className='user_name'>
                  <Typography align="left" className="profile_title" color="text.primary">
                    {associationName}
                  </Typography>
                  {/* <Typography align="left" className="profile_subtitle" color="text.primary">
                    {speciality}
                  </Typography> */}
                </div>
                <IconButton onClick={handleEditProfileDialogOpen} style={{ padding: "0px" }}>
                  < EditIcon color="primary" />
                </IconButton>
              </div>
              <div className="user_profile_description">
                <Typography align="left" className="profile_description" color="text.primary">
                  {description}
                </Typography>
              </div>
              <div className="user_address_details">
                <Typography align="left" className="profile_title" color="text.primary">
                  Address:
                </Typography>
                <Typography align="left" className="profile_description" color="text.primary">
                  {address}
                </Typography>
                <Typography align="left" className="profile_description" color="text.primary">
                  {city}, {state}, {country} {pincodes ? pincodes : ""}
                </Typography>
              </div>

            </Paper>

            {/* ############################ Edit Medical Association Profile dialog ######################################### */}
            <Dialog open={openEditProfileDialog} onClose={handleEditProfileDialogClose} fullScreen={fullScreen} >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleEditProfileDialogClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Edit Medical Association Profile
              </DialogTitle>
              <DialogContent>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    onChange={imagePicker}
                    accept="image/*"
                    multiple={false}
                    style={{ display: "none" }}
                  />

                  <Button
                    disableRipple
                    onClick={() => {
                      inputFile.current.click();
                    }}
                  >
                    <Avatar style={{ height: 100, width: 100 }} src={imageURL} >
                      <AccountCircleIcon style={{ height: 100, width: 100 }} />
                    </Avatar>
                  </Button>
                </Stack>
                <Grid container direction="row" spacing={0.5}>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      placeholder="Association Name"
                      name="associationname"
                      value={associationName}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }
                          })
                          .join(" ");

                        setAssociationName(formattedValue);
                      }}
                      autoComplete="off"
                      id="associationname"
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      color="primary"
                      label="WhatsApp number"
                      variant="outlined"
                      name="mobile"
                      id="mobile"
                      value={mobileNo}
                      onChange={(event) => setMobileNo(event.target.value)}
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{ maxLength: 10, type: "tel" }}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      label="Email address"
                      type="email"
                      variant="outlined"
                      name="email"
                      id="email"
                      value={emailId}
                      onChange={(event) => setEmailId(event.target.value)}
                      InputProps={{
                        readOnly: true,
                      }}
                      autoComplete="off"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      label="Description"
                      name="description"
                      value={description}
                      onChange={(event) => setDescription(event.target.value.toLowerCase()
                        .split(" ")
                        .map(word => {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        })
                        .join(" "))}
                      autoComplete="off"
                      multiline
                      minRows={3}
                      id="description"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      label="Address"
                      name="address"
                      value={address}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .split(" ")
                          .map((word) => {
                            if (word === word.toUpperCase()) {
                              return word;
                            } else {
                              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }
                          })
                          .join(" ");

                        setAddress(formattedValue);
                      }}
                      autoComplete="off"
                      multiline
                      maxRows={2}
                      id="address"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-dialog-select-label">
                        Country
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="country-label"
                        id="country-picker"
                        label="Select Country"
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                          setState("");
                          setCity("");
                          setStateChoices([]);
                          setCityChoices([]);
                          getStatesData(event.target.value);
                        }}
                      >
                        <MenuItem value={""} selected>
                          Select Country
                        </MenuItem>
                        {Array.isArray(countryChoices) &&
                          countryChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>{choice}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-dialog-select-label">
                        State
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="state-label"
                        id="state-picker"
                        label="Select State"
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value);
                          setCity("");
                          setCityChoices([]);
                          getCityData(country, event.target.value);
                        }}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {Array.isArray(stateChoices) &&
                          stateChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>{choice}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-dialog-select-label">
                        City
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="city-label"
                        id="city-picker"
                        label="Select City"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      >
                        <MenuItem value={""}>Select City</MenuItem>
                        {Array.isArray(cityChoices) &&
                          cityChoices.map((choice, index) => {
                            return (
                              <MenuItem value={choice} key={index}>{choice}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth
                      label="Pincode"
                      name="pincode"
                      value={pincodes}
                      onChange={(event) => setPincodes(event.target.value)}
                      inputProps={{ maxLength: 6 }}
                      autoComplete="off"
                      id="pincode"
                      variant="outlined"
                      required
                    />
                  </Grid>

                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleMedicalAssociationProfileSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">  Update Profile </Button>

              </DialogActions>
            </Dialog>


            <div>
              <ProfilePhotos />
            </div>

            <div>
              <MedicalAssociationWorkingHrs />
            </div>

            <div>
              <Awardsection />
            </div>

            <div>
              <Blogsection />
            </div>

          </div>
        </div>

        <YLoader loading={loading} />
      </div>

      {
        fileLoading && (
          <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )
      }

    </>
  )
}

export default MedicalAssociationProfile