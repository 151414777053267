import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';

export default function (ComposedComponent) {


    class NetworkDetector extends Component {
        constructor(props) {
            super(props)
            this.state = {
                isDisconnected: false
            }
        }

        componentDidMount() {
            this.handleConnectionChange();
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
            window.removeEventListener('online', this.handleConnectionChange);
            window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch('//google.com', {
                            mode: 'no-cors',
                        })
                            .then(() => {
                                this.setState({ isDisconnected: false }, () => {
                                    return clearInterval(webPing)
                                });
                            }).catch(() => this.setState({ isDisconnected: true }))
                    }, 2000);
                return;
            }

            return this.setState({ isDisconnected: true });
        }

        render() {
            const { isDisconnected } = this.state;
            // if (isDisconnected) {
            //     return this.props.history.push('network')
            // }
            return (
                <div>
                    { isDisconnected && (<div >
                        <h1>Connect to the internet and try again</h1>
                    </div>)
                    }
                    <ComposedComponent {...this.props} />
                </div>
            );
        }
    }

    return NetworkDetector;
}