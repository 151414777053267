import doctoron from "./doctor_onboarding.png"
import Hospital from "./hospital_onboarding.png"
import Mbuddy from "./meddy_buddy.png"
import vendor from "./vendor_onboarding.png"
import meddyconnectLightThemeLogo from "./logo_light.png"
import meddyconnectDarkThemeLogo from "./logo_dark.png"

export const images = {
  doctoron,Hospital,Mbuddy,vendor,meddyconnectLightThemeLogo,meddyconnectDarkThemeLogo
};
