import { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Avatar, Typography, Grid } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../../API/useApiRequest";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import UrlRoute from "../../API/UrlRoute";
import JobApplicationsDetails from './JobApplicationsDetails';
import './myjobapplications.css';

const MyJobApplications = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();

    const [myJobApplications, setmyJobApplications] = useState([]);
    const [jobApplicationId, setJobApplicationId] = useState("");
    const [jobId, setJobId] = useState("");

    // Services
    const { apiRequests } = useAPIRequest();

    // Dialogs
    const [openJobApplicationsDetailsDialog, setOpenJobApplicationsDetailsDialog] = useState(false);

    const handleJobApplicationsDetailsDialogClickOpen = (id, jobId) => {
        setOpenJobApplicationsDetailsDialog(true);
        setJobApplicationId(id);
        setJobId(jobId);
    };

    const handleJobApplicationsDetailsDialogClickClose = () => {
        setOpenJobApplicationsDetailsDialog(false);
    };

    useEffect(() => {
        getMyJobApplications();
    }, []);

    const getMyJobApplications = async () => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: UrlRoute.USER_JOB_APPLICATIONS_URL,
            method: "GET",
            addToast,
        });

        if (data) {
            setmyJobApplications(data);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="myjobapplications-drawer">
                        <Navigation />
                    </div>

                    <div className="myjobapplications-container">
                        {myJobApplications.length > 0 ?
                            myJobApplications.map((element) => {
                                return (
                                    <Grid container direction="column" className='myjobapplications-card-container' key={element.id}>
                                        <Grid item xs={12} >
                                            <Card className='myjobapplications-card' onClick={() => handleJobApplicationsDetailsDialogClickOpen(element.id, element.jobId)}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar src={element.postedProfileLink && element.postedProfileLink.href ? element.postedProfileLink.href : " "} color="primary" aria-label="companylogo" className='myjobapplications-img'/>
                                                    }
                                                    title={element.postedUserName}
                                                    subheader={element.created ? moment(element.created).format("MMM D, YYYY") : ""}
                                                />
                                                <CardContent className='card-content'>

                                                    <Grid container direction="row" rowSpacing={0.5}>
                                                        <Grid item xs={12} >
                                                            <Typography variant="h6" align='left' color="text.primary" className='myjobapplications-designation myjobapplications-job-title'>
                                                                {element.jobTitle}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Typography variant="body2" color="text.primary" className='myjobapplications-description'>
                                                                {element.coverLetter}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                );
                            })
                            :
                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        No results found
                                    </Typography>
                                </Grid>
                            </Grid>
                        }

                        <div>
                            <JobApplicationsDetails
                                openJobApplicationsDetailsDialog={openJobApplicationsDetailsDialog}
                                handleJobApplicationsDetailsDialogClickClose={handleJobApplicationsDetailsDialogClickClose}
                                jobApplicationId={jobApplicationId}
                                jobId={jobId}
                            />
                        </div>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default MyJobApplications

