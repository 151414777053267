import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, CardMedia, Typography, Grid ,Button} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import './manageevents.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useHistory } from "react-router-dom";

const ArchiveEvents = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  return (
    <>
      <Grid container direction="row" spacing={1} className='manageevent-card-container'>
        {data.map((element) => {
          return (
            <Grid item xs={12} sm={6} key={element.id}>
              <Card elevation={3} className='manageevent-card'>
                <CardMedia
                  component="img"
                  height="180"
                  image={element.eventimage}
                  alt="Event images"
                />
                <CardContent>
                  <Grid container direction="row" rowSpacing={1}>
                    <Grid item xs={12} >
                      <Typography variant="subtitle1" >
                        {element.eventname}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography variant="body2" className='manageevent-textcolor manageevent-description' >
                        {element.eventDescription}
                      </Typography>
                    </Grid>
                  </Grid>

                  <div className="manageevents">
                    <div className="manageevent">
                      <div className="manageevent-Time-and-Place-info">
                        <Typography variant="subtitle2">
                          <AccessTimeIcon className='manageevent-icon' />
                          {element.eventtime}
                        </Typography>
                        <Typography variant="subtitle2" className='manageevent-address'>
                          <PlaceIcon className='manageevent-icon manageevent-address-icon' />
                          {element.address}
                        </Typography>
                      </div>

                      <div className="manageevent-date-info">
                        <Typography variant="subtitle2">
                          {element.month}
                        </Typography>
                        <Typography variant="subtitle2" >
                          {element.date}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>
                <CardActions>
                  <div className='manageevent-btn-container'>
                    <Button variant='contained' className='manageevent-button' onClick={() => history.push("/viewparticipantsfromarchiveevent")}>View Participants</Button>
                    <Button variant='contained' className='manageevent-button'>Delete</Button>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          );
        })
        }
      </Grid>
    </>
  )
}

export default ArchiveEvents

const data =
  [
    {
      id: 0,
      eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      eventname: "NYC Food Festival",
      eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
      address: "Central Park Manhattan ,New York City.",
      eventtime: "9.00 AM To 5.00 PM",
      month: "Oct",
      date: "29"
    },
    {
      id: 1,
      eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
      eventname: "Charity Events",
      eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
      address: " Radisson Blu, Dwarka",
      eventtime: "Friday 1-7 pm",
      month: "Dec",
      date: "10"
    },
    {
      id: 2,
      eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      eventname: "NYC Food Festival",
      eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
      address: "Central Park Manhattan ,New York City.",
      eventtime: "9.00 AM To 5.00 PM",
      month: "Apr",
      date: "02"
    },
    {
      id: 3,
      eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
      eventname: "Charity Events",
      eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
      address: "Prof.Ramkrishna More A.C.S College Akurdi Pune",
      eventtime: "8.00 AM To 3.00 PM",
      month: "Jun",
      date: "20"
    },

  ];
