import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const data = [
   
    {
        month: 'Apr',
        Referred: 15,
    },
    {
        month: 'May',
        Referred: 12,
    },
    {
        month: 'Jun',
        Referred: 22,
    },
    {
        month: 'Jul',
        Referred: 7,
    },
    {
        month: 'Aug',
        Referred: 8,
    },
    {
        month: 'Sep',
        Referred: 11,
    },
    {
        month: 'Oct',
        Referred: 9,
    },
    {
        month: 'Nov',
        Referred: 14,
    },
    {
        month: 'Dec',
        Referred: 28,
    },
    {
        month: 'Jan',
        Referred: 2,
    },
    {
        month: 'Feb',
        Referred: 10,
    },
    {
        month: 'Mar',
        Referred: 6,
    },
];

const LineChartReferredToMe = () => {
    return (
        <>
            <ResponsiveContainer width="100%" aspect={2 / 1}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 5,
                        bottom: 16,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" stroke="#000" label={{ value: 'Month', position: 'bottom' }} />
                    <YAxis type="number" stroke="#000" domain={[0, 30]} tickCount={8} label={{ value: 'Date', angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Line type="monotone" dataKey="Referred" stroke="#002E6E" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

export default LineChartReferredToMe