import React, { useState } from "react";
import { Grid, Typography, Dialog, DialogTitle, DialogContent, Slide, IconButton, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import "./identityverification.css";
import { useToasts } from "react-toast-notifications";
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { fileCompressor } from "../../components/FileCompressor";
import UrlRoute from "../../API/UrlRoute";
import useAPIRequest from "../../API/useApiRequest";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IdentityVerificationDialog = ({ openIdentityVerificationDialog, handleIdentityVerificationDialogClickClose, getVerificationStatus }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToasts();

  const [frontSideImage, setFrontSideImage] = useState({ contentType: null, content: null });
  const [frontSideImageURL, setFrontSideImageURL] = useState("");

  const [backSideImage, setBackSideImage] = useState({ contentType: null, content: null });
  const [backSideImageURL, setBackSideImageURL] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  // Functions

  const handleFrontSideImageChange = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setFileLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setFrontSideImage({ contentType: contentType, content: imageData });
            setFrontSideImageURL(url);
          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setFileLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setFileLoading(false);
    }

  };


  const handleFrontSideImageDelete = () => {
    setFrontSideImage({ contentType: null, content: null });
    setFrontSideImageURL("");
  };

  // Functions

  const handleBackSideImageChange = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setFileLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setBackSideImage({ contentType: contentType, content: imageData });
            setBackSideImageURL(url);
          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setFileLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setFileLoading(false);
    }

  };

  const handleBackSideImageDelete = () => {
    setBackSideImage({ contentType: null, content: null });
    setBackSideImageURL("");
  };

  const handleIdentityVerificationSubmit = () => {

    if (validateIdentityVerificationForm()) {
      uploadIdentityVerificationDocument();
    }

  };

  const validateIdentityVerificationForm = () => {

    let valid = true;

    if (frontSideImage.content === null) {
      addToast('Document Front Side Image cannot be empty', { appearance: 'error' });
      valid = false;
    }

    return valid;
  };

  const uploadIdentityVerificationDocument = async () => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL,
      method: "POST",
      body: {
        documentType: "ID_CARD_DOCUMENT_FRONT",
        contentType: frontSideImage.contentType,
        content: frontSideImage.content
      },
      addToast,
    });

    if (response.status === 200) {
      addToast("Document Front Side image uploaded successfully !!!", { appearance: "success" });

      if (backSideImage.content !== null) {

        const { response, data, error } = await apiRequests({
          endPoint: UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL,
          method: "POST",
          body: {
            documentType: "ID_CARD_DOCUMENT_BACK",
            contentType: backSideImage.contentType,
            content: backSideImage.content
          },
          addToast,
        });

        if (response.status === 200) {
          handleIdentityVerificationDialogClickClose();
          setFileLoading(false);
          addToast("Document Back Side image uploaded successfully !!!", { appearance: "success" });
          getVerificationStatus();
        } else {
          setFileLoading(false);
          addToast("Failed to upload document back side image!", { appearance: "error" });
        }

      } else {
        handleIdentityVerificationDialogClickClose();
        setFileLoading(false);
        getVerificationStatus();
      }
    } else {
      setFileLoading(false);
      addToast("Failed to upload document front side image!", { appearance: "error" });
    }
  };

  return (
    <>
      <Dialog
        open={openIdentityVerificationDialog}
        onClose={handleIdentityVerificationDialogClickClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
          <IconButton
            edge="start"
            onClick={handleIdentityVerificationDialogClickClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          Identity Verification
        </DialogTitle>
        <DialogContent>

          <Grid container direction="row" style={{ marginTop: "5px" }}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" color="text.primary" gutterBottom>
                Front side image:
              </Typography>
            </Grid>
          </Grid>

          {!frontSideImageURL ?
            <div className="identity-document-container">
              <IconButton component="label">
                <input hidden accept="image/*" type="file" onChange={handleFrontSideImageChange} />
                <CloudUploadIcon color="primary" className="identity-document-upload-Icon" />
              </IconButton>
            </div>
            :
            <div className="identity-document-image-container">
              <img src={frontSideImageURL} alt="identity document" className="identity-document-image" />
              <CloseIcon className="identity-document-delete-Icon" onClick={handleFrontSideImageDelete} />
            </div>
          }

          <Grid container direction="row" style={{ marginTop: "6px" }}>
            <Grid item xs={12}>
              <Typography variant="h6" align="left" color="text.primary" gutterBottom>
                Back side image:
              </Typography>
            </Grid>
          </Grid>

          {!backSideImageURL ?
            <div className="identity-document-container">
              <IconButton component="label">
                <input hidden accept="image/*" type="file" onChange={handleBackSideImageChange} />
                <CloudUploadIcon color="primary" className="identity-document-upload-Icon" />
              </IconButton>
            </div>
            :
            <div className="identity-document-image-container">
              <img src={backSideImageURL} alt="identity document" className="identity-document-image" />
              <CloseIcon className="identity-document-delete-Icon" onClick={handleBackSideImageDelete} />
            </div>
          }

          <div style={{ marginTop: "16px" }}>
            <Button variant="contained" fullWidth className='identity-submit-button' color="primary"
              onClick={handleIdentityVerificationSubmit}
            >
              Submit
            </Button>
          </div>

        </DialogContent>
      </Dialog>

      {
        fileLoading && (
          <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )
      }

    </>
  )
}

export default IdentityVerificationDialog
