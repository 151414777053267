import { images } from "../assets/images/images";
import { useTheme } from '@mui/material';

function Logo() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Use the appropriate logo based on the theme
  const logoSrc = isDarkMode ? images.meddyconnectDarkThemeLogo : images.meddyconnectLightThemeLogo;

  return (
    <div>
      <img src={logoSrc} alt="meddyconnect logo" style={{
        maxWidth: "150px",
        minHeight: "100px",
        maxHeight: "150px",
        objectFit: "cover"
      }} />
    </div>
  );
}

export default Logo;