
export const getTheme = (mode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: {
              main: '#002E6E',
            },
            text:{
              primary:"#000000"
            },
            background:{
              paper:"#ffffff",
              default:"#fff"
            },
            divider:'#002E6E'
          }
        : {
            // palette values for dark mode
            primary: {
              main: '#00B9F1',
            },
            text:{
              primary:"#ffffff"
            },
             background:{
              paper:"#000000",
              default:"#000"
            },
            divider:'#00B9F1'
          }),
    },
  });


  