import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';

const ReadMore = ({ children }) => {

    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const theme = useTheme();

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p style={{ width: "100%" }}>
            {isReadMore ? text.slice(0, 300) : text}
            <span onClick={toggleReadMore} style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" , cursor: "pointer" }}>
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    )
}

export default ReadMore
