import React, { useState, useContext } from 'react'
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import './homescreen.css';
import { Avatar, IconButton, Typography } from '@mui/material';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { IoBriefcaseOutline } from "react-icons/io5";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { SiYoutubeshorts } from "react-icons/si";
import { LiaStoreAltSolid } from "react-icons/lia";
import { FaRegPenToSquare } from "react-icons/fa6";
import { LuClipboardSignature } from "react-icons/lu";
import { TbStethoscope } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
import { FaCartFlatbedSuitcase } from "react-icons/fa6";
import { PiTrolleySuitcase } from "react-icons/pi";
import { GiAutoRepair } from "react-icons/gi";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { RiUserShared2Line } from "react-icons/ri";
import { YContext } from "../Context/YContext";
import { useHistory } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Constants from "../MeddyConnectConstant";

const HomeScreen = () => {
    const [loading, setLoading] = useState(false);
    const { currentUser } = useContext(YContext);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    const handleButtonClick = (route, data) => {

        history.push({
            pathname: route,
            state: data,
        });

    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="left-Drawer">
                        <Navigation />
                    </div>

                    <div className="homeScreen-Container">

                        {!(currentUser.userType === "STUDENT" || currentUser.userType === "STAFF" || currentUser.userType === "MEDICAL_ASSOCIATION") ?
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Store
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                    {fullScreen ?
                                        currentUser.userType !== "VENDOR" &&
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => handleButtonClick(Constants.PRODUCT_CATEGORY, { title: 'Buy Instrument' })}>
                                                    <FaCartFlatbedSuitcase className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Buy Medical Instrument
                                            </Typography>
                                        </div>
                                        :
                                        currentUser.userType !== "VENDOR" &&
                                        (
                                            <>
                                                <div className="card-box">
                                                    <Avatar className='Avatar-style'>
                                                        <IconButton onClick={() => handleButtonClick(Constants.PRODUCT_CATEGORY, { title: 'Buy New Instrument' })}>
                                                            <FaCartFlatbedSuitcase className='reacticon-style' />
                                                        </IconButton>
                                                    </Avatar>
                                                    <Typography align="center" className='sub_title' color="text.primary">
                                                        Buy New Medical Instrument
                                                    </Typography>
                                                </div>

                                                <div className="card-box">
                                                    <Avatar className='Avatar-style'>
                                                        <IconButton onClick={() => handleButtonClick(Constants.PRODUCT_CATEGORY, { title: 'Buy Used Instrument' })}>
                                                            <PiTrolleySuitcase className='reacticon-style' />
                                                        </IconButton>
                                                    </Avatar>
                                                    <Typography align="center" className='sub_title' color="text.primary">
                                                        Buy Used Medical Instrument
                                                    </Typography>
                                                </div>
                                            </>
                                        )

                                    }

                                    {!(currentUser.userType === "VENDOR" || currentUser.userType === "STUDENT") &&
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.REPAIR_AND_CONTRACT)}>
                                                    <GiAutoRepair className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Repair & Contract
                                            </Typography>
                                        </div>
                                    }

                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push(Constants.SELL_PRODUCT)}>
                                                <MdOutlineCurrencyRupee className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Sell Medical Instrument
                                        </Typography>
                                    </div>

                                    {currentUser.userType === "VENDOR" ?
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.REPAIR_AND_SELL_CONTRACTS)}>
                                                    <EngineeringOutlinedIcon className='icon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Repair & Sell Contracts
                                            </Typography>
                                        </div>
                                        :
                                        ""}

                                    {currentUser.userType !== "VENDOR" ?
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.NEW_PRODUCT_BRAND_CATALOGUE)}>
                                                    <LiaStoreAltSolid className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Brand Store
                                            </Typography>
                                        </div>
                                        :
                                        ""}

                                    {currentUser.userType === "VENDOR" && currentUser.vendorType === "MANUFACTURER" ?
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.SHOW_CASE_YOUR_PRODUCT)}>
                                                    <FiberNewIcon className='icon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Showcase Your Product
                                            </Typography>
                                        </div>
                                        :
                                        ""}

                                    {/* {currentUser.userType === "VENDOR" ?
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <FiberNewIcon className='icon-style' />
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Analytics
                                            </Typography>
                                        </div>
                                        :
                                        ""} */}

                                </div>
                            </div>
                            :
                            ""}

                        {/* {!(currentUser.userType === "STUDENT" || currentUser.userType === "STAFF") ?
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Advertisement
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton>
                                                <DesktopWindowsOutlinedIcon className='icon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Make an Advertisement
                                        </Typography>
                                    </div>
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton>
                                                <ApartmentSharpIcon className='icon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Advertisement Builder
                                        </Typography>
                                    </div>
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton>
                                                <IoBriefcaseOutline className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Analytics
                                        </Typography>
                                    </div>

                                </div>
                            </div>
                            :
                            ""} */}

                        {currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL" || currentUser.userType === "STAFF" ? (
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Job
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                    {currentUser.userType === "DOCTOR" || currentUser.userType === "STAFF" ? (
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.ALL_JOB_APPLICATIONS)}>
                                                    <FaRegPenToSquare className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Apply For Job (Employee)
                                            </Typography>
                                        </div>
                                    )
                                        : null
                                    }

                                    {currentUser.userType === "DOCTOR" || currentUser.userType === "STAFF" ? (
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.MY_JOB_APPLICATIONS)}>
                                                    <LuClipboardSignature className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                My Job Applications
                                            </Typography>
                                        </div>
                                    )
                                        : null
                                    }

                                    {currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL" ? (
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.POST_A_JOB)}>
                                                    <IoBriefcaseOutline className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Post a Job (Employer)
                                            </Typography>
                                        </div>
                                    )
                                        : null
                                    }


                                </div>
                            </div>
                        )
                            : null
                        }

                        {currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL" || currentUser.userType === "STUDENT" ? (
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Observership
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                    {currentUser.userType === "STUDENT" ? (
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.ALL_OBSERVERSHIP_APPLICATIONS)}>
                                                    <FaRegPenToSquare className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Apply for Observership
                                            </Typography>
                                        </div>
                                    )
                                        : null
                                    }

                                    {currentUser.userType === "STUDENT" ? (
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.MY_OBSERVERSHIP_APPLICATIONS)}>
                                                    <LuClipboardSignature className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                My Observership Applications
                                            </Typography>
                                        </div>
                                    )
                                        : null
                                    }

                                    {currentUser.userType === "DOCTOR" || currentUser.userType === "HOSPITAL" ? (
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push(Constants.POST_AN_OBSERVERSHIP)}>
                                                    <IoBriefcaseOutline className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Post Observership
                                            </Typography>
                                        </div>
                                    )
                                        : null
                                    }

                                </div>
                            </div>
                        )
                            : null
                        }

                        {/* <div className="main-Div">
                            <div className='header-Div'>
                                <Typography align="left" className='main_title'>
                                    Events
                                </Typography>
                            </div>
                            <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                {!(currentUser.userType === "STAFF" || currentUser.userType === "STUDENT") ?
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push("/organizeevent")}>
                                                <SettingsIcon className='icon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Organize Event
                                        </Typography>
                                    </div>
                                    : ""
                                }
                                <div className="card-box">
                                    <Avatar className='Avatar-style'>
                                        <IconButton onClick={() => history.push("/allevents")}>
                                            <FaRegCalendarAlt className='reacticon-style' />
                                        </IconButton>
                                    </Avatar>
                                    <Typography align="center" className='sub_title' color="text.primary">
                                        Upcoming Events
                                    </Typography>
                                </div>
                                <div className="card-box">
                                    <Avatar className='Avatar-style'>
                                        <IconButton onClick={() => history.push("/alleventpasses")}>
                                            <FaPassport className='reacticon-style' />
                                        </IconButton>
                                    </Avatar>
                                    <Typography align="center" className='sub_title' color="text.primary">
                                        Event Pass
                                    </Typography>
                                </div>

                                {currentUser.userType === "VENDOR" ?
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <FaPassport className='reacticon-style' />
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Sponser Event
                                        </Typography>
                                    </div>
                                    :
                                    ""}

                            </div>
                        </div> */}

                        {/* {currentUser.userType !== "MEDICAL_ASSOCIATION" ?
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Training
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                    <>
                                        {!(currentUser.userType === "STAFF" || currentUser.userType === "STUDENT") ?
                                            <>
                                                <div className="card-box">
                                                    <Avatar className='Avatar-style'>
                                                        <IconButton onClick={() => history.push("/organizetraining")}>
                                                            <SearchIcon className='icon-style' />
                                                        </IconButton>
                                                    </Avatar>
                                                    <Typography align="center" className='sub_title' color="text.primary">
                                                        Organize Training
                                                    </Typography>
                                                </div>
                                            </>

                                            : ""
                                        }
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton onClick={() => history.push("/attendtraining")}>
                                                    <IoBriefcaseOutline className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Attend Training
                                            </Typography>
                                        </div>
                                    </>
                                    {!(currentUser.userType === "STAFF" || currentUser.userType === "STUDENT") ?
                                        <div className="card-box">
                                            <Avatar className='Avatar-style'>
                                                <IconButton>
                                                    <FaBlogger className='reacticon-style' />
                                                </IconButton>
                                            </Avatar>
                                            <Typography align="center" className='sub_title' color="text.primary">
                                                Sponser Training
                                            </Typography>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                            :
                            ""} */}

                        {(currentUser.userType === "DOCTOR") &&
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Referral
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push(Constants.REFER_A_PATIENT)}>
                                                <TbStethoscope className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Refer a Patient
                                        </Typography>
                                    </div>
                                    <div className="card-box base">
                                        {/* <div className='indicator'>
                                            <div className='noti_count'>
                                                10
                                            </div>
                                        </div> */}
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push(Constants.REFERRED_TO_ME)}>
                                                <IoIosPeople className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Referrals
                                        </Typography>
                                    </div>
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push(Constants.REFERRED_BY_ME)}>
                                                <RiUserShared2Line className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Refer By Me
                                        </Typography>
                                    </div>
                                    {/* <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push("/referraldashboard")}>
                                                <IoBriefcaseOutline className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Dashboard
                                        </Typography>
                                    </div> */}

                                </div>
                            </div>
                        }

                        {/* {(currentUser.userType === "MEDICAL_ASSOCIATION") &&
                            <div className="main-Div">
                                <div className='header-Div'>
                                    <Typography align="left" className='main_title'>
                                        Magazine
                                    </Typography>
                                </div>
                                <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push("/postamagazine")}>
                                                <SearchIcon className='icon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Post a Magazine
                                        </Typography>
                                    </div>

                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push("/viewmagazine")}>
                                                <SearchIcon className='icon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            View Magazines
                                        </Typography>
                                    </div>

                                </div>
                            </div>
                        } */}


                        {/* <div className="main-Div">
                            <div className='header-Div'>
                                <Typography align="left" className='main_title'>
                                    Miscellaneous
                                </Typography>
                            </div>
                            <div className='grid-container' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                <div className="card-box">
                                    <Avatar className='Avatar-style'>
                                        <IconButton onClick={() => history.push(Constants.CREATE_SHORT)}>
                                            <SiYoutubeshorts className='reacticon-style' />
                                        </IconButton>
                                    </Avatar>
                                    <Typography align="center" className='sub_title' color="text.primary">
                                        Shorts
                                    </Typography>
                                </div>

                                {!(currentUser.userType === "STAFF" || currentUser.userType === "STUDENT") ?
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push("/searchuser")}>
                                                <SearchIcon className='icon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Search
                                        </Typography>
                                    </div>
                                    : ""
                                }
                                <div className="card-box">
                                    <Avatar className='Avatar-style'>
                                        <IconButton onClick={() => history.push("/storyboard")}>
                                            <IoBriefcaseOutline className='reacticon-style' />
                                        </IconButton>
                                    </Avatar>
                                    <Typography align="center" className='sub_title' color="text.primary">
                                        Post Stories
                                    </Typography>
                                </div>
                                <div className="card-box">
                                    <Avatar className='Avatar-style'>
                                        <IconButton onClick={() => history.push("/q&a")}>
                                            <CreateIcon className='icon-style' />
                                        </IconButton>
                                    </Avatar>
                                    <Typography align="center" className='sub_title' color="text.primary">
                                        Q&A
                                    </Typography>
                                </div>
                                {!(currentUser.userType === "STAFF" || currentUser.userType === "STUDENT" || currentUser.userType === "MEDICAL_ASSOCIATION") ?
                                    <div className="card-box">
                                        <Avatar className='Avatar-style'>
                                            <IconButton onClick={() => history.push("/viewmagazine")}>
                                                <FaInstagram className='reacticon-style' />
                                            </IconButton>
                                        </Avatar>
                                        <Typography align="center" className='sub_title' color="text.primary">
                                            Magazine
                                        </Typography>
                                    </div>
                                    : ""
                                }
                            </div>
                        </div> */}


                    </div>
                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default HomeScreen