import React, { useState, useEffect } from 'react'
import {
    Grid, Select, FormLabel, Chip, TextField, Paper, Button, Autocomplete, Typography,
    FormControlLabel, InputLabel, MenuItem, FormControl, Radio, RadioGroup, IconButton
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import "./sellproductpage.css";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import useGetData from '../../API/useGetData';
import { fileCompressor } from "../../components/FileCompressor";
import UrlRoute from "../../API/UrlRoute";
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router-dom";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateField } from '@mui/x-date-pickers/DateField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Constants from "../../MeddyConnectConstant";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Updatesellproduct = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const { addToast } = useToasts();

    const [updateProductId, setUpdateProductId] = useState(props.location.state.data);
    const [condition, setCondition] = React.useState('');
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [value, setValue] = React.useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [numberOfItems, setNumberOfItems] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [subCategoryChoices, setSubCategoryChoices] = useState([]);
    const [category, setCategory] = useState("");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [brandName, setBrandName] = useState(null);
    const [brandInputValue, setBrandInputValue] = useState('');
    const [brandOptions, setBrandOptions] = useState([]);
    const [specialityData, setSpecialityData] = useState([]);
    const [specialityName, setSpecialityName] = useState(null);
    const [specialityInputValue, setSpecialityInputValue] = useState('');
    const [specialityOptions, setSpecialityOptions] = useState([]);
    const history = useHistory();
    const [manufacturingYear, setManufacturingYear] = useState(new Date());
    const [price, setPrice] = useState('');
    const [pincodes, setPincodes] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);
    const [compressedImages, setCompressedImages] = useState([]);
    const [selectedImagesCount, setSelectedImagesCount] = useState(0);
    const [showUploadContainer, setShowUploadContainer] = useState(true);

    // Services
    const { apiRequests } = useAPIRequest();
    const { getBrandNames, getSpecialities, getCountry, getStates, getCity, getProductCategories } = useGetData();

    const handleChange = (event) => {
        setCondition(event.target.value);
    };

    const handleRadioButtonChange = (event) => {
        setValue(event.target.value);
    };

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        getReferenceData();
        getCategories();
        getBrandNamesData("");
        getSellProduct(updateProductId);
    }, []);

    useEffect(() => {
        getSpecialitiesData("");
    }, [specialityData]);

    useEffect(() => {
        if (category) {
            handleCategoryWiseFilter(category);
        }
    }, [category, categoryOptions]);

    useEffect(() => {
        // Show the container if either the image count or the PDF count is less than 5
        if (selectedImagesCount < 5) {
            setShowUploadContainer(true);
        } else {
            setShowUploadContainer(false);
        }
    }, [selectedImagesCount]);

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            //   setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                // setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                // setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        handleCategoryWiseFilter(event.target.value);
    };

    const handleCategoryWiseFilter = (category) => {
        let selectedCategory = categoryOptions.find(item => item.categoryLink?.rel === category);
        let selectedSubCategory = selectedCategory ? selectedCategory.subcategoryLink : [];
        setSubCategoryChoices(selectedSubCategory)
    };

    const handleSubCategoryChange = (event) => {
        setSubCategory(event.target.value);
    };

    const getCategories = async () => {
        const categoriesOptions = await getProductCategories(addToast);
        if (categoriesOptions) {
            setCategoryOptions(categoriesOptions);
        }
    };

    const getBrandNamesData = async (brand) => {
        const brandOptions = await getBrandNames(brand, addToast);
        if (brandOptions) {
            setBrandName(null);
            setBrandOptions(brandOptions);
        }
    };

    const getSpecialitiesData = async (speciality) => {
        const specialityOptions = await getSpecialities(speciality, addToast);
        if (specialityOptions) {
            setSpecialityOptions(specialityOptions);
        }
    };

    const handleAddSpecialities = (newSpeciality) => {
        if (newSpeciality && newSpeciality.trim() !== '' && !specialityData.some((item) => item.suitableFor === newSpeciality)) {
            setSpecialityData((prevSpecialityData) => [...prevSpecialityData, { suitableFor: newSpeciality }]);
            setSpecialityInputValue('');
            setSpecialityName(null);
        }
    };

    const handleSpecialityDelete = (specialityname) => {
        setSpecialityData(
            specialityData.filter(chips =>
                chips.suitableFor !== specialityname
            )
        );
    }


    const getSellProduct = async (updateProductId) => {
        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `items/${updateProductId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setValue(data.isUsed);
            setTitle(data.title);
            setCategory(data.category);
            setSubCategory(data.product);
            setBrandInputValue(data.brand);
            setDescription(data.description);
            setPrice(data.price);
            setManufacturingYear(data.manufacturingYear ? new Date(data.manufacturingYear, 1, 1) : null);
            setCondition(data.workingCondition);
            setNumberOfItems(data.noOfItems !== null ? String(data.noOfItems) : "");
            setSpecialityData(data.itemSuitabilities);
            setSelectedImages(data.itemImages);
            setPincodes(data.pinCode);
            setCountry(data.country);
            getStatesData(data.country);
            setState(data.state);
            getCityData(data.country, data.state);
            setCity(data.city);
            setLoading(false);

        } else {
            addToast("Something went wrong!", { appearance: "error" });
            setLoading(false);
        }
    };


    const handleImageChange = async (e) => {
        setLoading(true);
        const files = e.target.files;
        const selectedImagesArray = Array.from(files);
        const compressedImagesArray = [];

        if (selectedImagesArray.length > 5) {
            setLoading(false);
            addToast("You can not upload more than five images", { appearance: "error" });
            return;
        }

        const showImages = selectedImagesArray.map((file) => URL.createObjectURL(file));
        setSelectedImages((prevImages) => prevImages.concat(showImages));

        setSelectedImagesCount(prevCount => prevCount + selectedImagesArray.length);

        for (const image of selectedImagesArray) {

            try {

                const compressedImage = await fileCompressor(image, addToast);
                const imageData = {
                    type: image.type,
                    compressedImage: compressedImage
                };
                compressedImagesArray.push(imageData);

            } catch (error) {
                setLoading(false);
                console.error('Error compressing image:', error);
            }
        }

        setCompressedImages(prevCompressedImages => [...prevCompressedImages, ...compressedImagesArray]);
        setLoading(false);
    };

    const handleImageDelete = (id) => {

        const newImages = selectedImages.filter((photo, index) => index !== id)
        setSelectedImages(newImages)

        const fileCompressedArray = compressedImages.filter((photo, index) => index !== id)
        setCompressedImages(fileCompressedArray)

        // Update the image count after deleting a file
        setSelectedImagesCount(prevCount => prevCount - 1);

    };

    const handleDeleteImageFromApi = async (productId, imgId) => {
        setLoading(true);
        const { response, data, error } = await apiRequests(
            {
                endPoint: `items/image/${productId}/${imgId}`,
                method: "DELETE",
                addToast
            }
        );
        if (response.status === 200) {
            const newImages = selectedImages.filter((element, index) => element.id !== imgId)
            setSelectedImages(newImages)
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    // Function to handle delete action
    const handleDelete = (item, index, updateProductId) => {

        if (item.id) {
            handleDeleteImageFromApi(updateProductId, item.id);
        } else {
            handleImageDelete(index);
        }

    };

    function base64ToBlob(base64String) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray]);
    }

    const updateSellProduct = async (productId) => {
        setIsApiCallInProgress(true);
        setLoading(true);
        const manufacturingYearInNumber = manufacturingYear ? manufacturingYear.getFullYear() : null;

        const { response, data, error } = await apiRequests({
            endPoint: `items/${productId}`,
            method: "PUT",
            body: {
                id: productId,
                itemType: "PRODUCT",
                isUsed: value,
                title: title,
                category: category,
                product: subCategory,
                brand: brandInputValue,
                description: description,
                price: price,
                manufacturingYear: manufacturingYearInNumber,
                workingCondition: condition,
                noOfItems: numberOfItems,
                itemSuitabilities: specialityData,
                pinCode: pincodes,
                country: country,
                state: state,
                city: city,
            },
            addToast,
        });

        if (data) {

            addToast("Product updated successfully !!!", { appearance: "success" });
            if (compressedImages.length !== 0) {

                const photos = new FormData()

                // Iterate over the compressedImages array
                compressedImages.forEach((imageData, index) => {
                    try {
                        const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob

                        // Append the blob with the correct MIME type and filename
                        photos.append("images", blob, `image_${index}.${imageData.type}`, `image/${imageData.type}`);
                    } catch (error) {
                        console.error("Error converting image:", error);
                    }
                });

                const { response, data, error } = await apiRequests({
                    endPoint: `${UrlRoute.UPLOAD_ITEM_IMAGES_URL}/${productId}`,
                    method: "POST",
                    body: photos,
                    addToast,
                });
                if (data) {
                    setLoading(false);
                    setIsApiCallInProgress(false);
                    addToast("Product images updated successfully !!!", { appearance: "success" });
                    history.push(Constants.INVENTORY);
                } else {
                    setLoading(false);
                    setIsApiCallInProgress(false);
                    addToast("Product Updated Successfully, but there's an error uploading images, Please upload again in Edit Product.!", { appearance: "error" });
                    history.push(Constants.INVENTORY);
                }
            } else {
                setLoading(false);
                setIsApiCallInProgress(false);
                history.push(Constants.INVENTORY);
            }
        } else {
            setLoading(false);
            setIsApiCallInProgress(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    }

    const handleUpdateProductSubmit = () => {

        if (validateUpdateProductForm()) {
            updateSellProduct(updateProductId);
        }

    };

    const validateUpdateProductForm = () => {

        let valid = true;

        if (title.trim() === '') {
            addToast('Product Title cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (brandInputValue.trim() === '') {
            addToast('Brand cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (description.trim() === '') {
            addToast('Description cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (String(price).trim() === '') {
            addToast('Price cannot be empty', { appearance: 'error' });
            valid = false;
        } else if (!/^[0-9]*$/.test(price)) {
            addToast('Price must contain only numbers', { appearance: 'error' });
            valid = false;
        }

        if (category.trim() === '') {
            addToast('Product Category cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (subCategory.trim() === '') {
            addToast('Product Name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (!condition) {
            addToast('Please select the working condition', { appearance: 'error' });
            valid = false;
        }

        if (numberOfItems.trim() !== '') {
            if (!/^[0-9]*$/.test(numberOfItems)) {
                addToast('Number of Items must contain only numbers', { appearance: 'error' });
                valid = false;
            }
        }

        if (country.trim() === '') {
            addToast('Country cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (compressedImages.length > 5) {
            addToast("You can not upload more than five images", { appearance: "error" });
            valid = false;
        }

        return valid;
    };

    return (
        <>
            <div style={{ height: "auto" }}>
                <div style={{ display: "flex" }}>
                    <div className="sellproduct-drawer">
                        <Navigation />
                    </div>
                    <div className="sellproduct-container">

                        <Paper elevation={2} className="sellproduct-paper-container">
                            <Grid container direction="row" spacing={0.5}>
                                <Grid item xs={12} style={{ marginBottom: "-12px" }}>
                                    <FormControl>
                                        <FormLabel id="sell-product-radio-buttons-group" style={{ color: theme.palette.mode === "light" ? "#000000" : "#fff" }} className='sell-product-label'>Product is ?</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="sell-product-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={handleRadioButtonChange}
                                        >
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio />}
                                                label="New"
                                                disabled
                                            />
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio />}
                                                label="Used"
                                                disabled
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth required
                                        label="Product Title"
                                        name="title"
                                        inputProps={{ style: { textTransform: "capitalize" } }}
                                        value={title}
                                        onChange={(event) => {
                                            const inputValue = event.target.value;
                                            const formattedValue = inputValue
                                                .split(" ")
                                                .map((word) => {
                                                    if (word === word.toUpperCase()) {
                                                        return word;
                                                    } else {
                                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                    }
                                                })
                                                .join(" ");

                                            setTitle(formattedValue);
                                        }}
                                        autoComplete="off"
                                        id="title"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="category-select-label" htmlFor="category-picker">
                                            Product Category
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="category-label"
                                            id="category-picker"
                                            label="Select Category"
                                            value={category}
                                            onChange={handleCategoryChange}
                                        >
                                            <MenuItem value={""}>Select Category</MenuItem>
                                            {
                                                categoryOptions.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice.categoryLink?.rel} key={index}>{choice.categoryLink?.rel}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="subCategory-label">Product Name</InputLabel>
                                        <Select
                                            labelId="subCategory-label"
                                            id="subCategory"
                                            value={subCategory}
                                            label="Product Name"
                                            onChange={handleSubCategoryChange}
                                            disabled={!category}
                                        >
                                            {subCategoryChoices && subCategoryChoices.map((option, index) => (
                                                <MenuItem key={index} value={option.rel}>
                                                    {option.rel}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        freeSolo
                                        id="brandname"
                                        disableClearable
                                        options={brandOptions.map((option) => option)}
                                        value={brandName}
                                        onChange={(event, newValue) => {
                                            setBrandName(newValue);
                                        }}
                                        inputValue={brandInputValue}
                                        onInputChange={(event, newInputValue) => {

                                            const capitalizedBrandInput = newInputValue.toLowerCase()
                                                .split(" ")
                                                .map(word => {
                                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                                })
                                                .join(" ")

                                            setBrandInputValue(capitalizedBrandInput);

                                            if (!brandOptions.find((option) => option === capitalizedBrandInput)) {
                                                getBrandNamesData(capitalizedBrandInput);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Brand Name"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        label="Product Description"
                                        name="description"
                                        inputProps={{ style: { textTransform: "capitalize" } }}
                                        value={description}
                                        onChange={(event) => setDescription(event.target.value.toLowerCase()
                                            .split(" ")
                                            .map(word => {
                                                return word.charAt(0).toUpperCase() + word.slice(1);
                                            })
                                            .join(" "))}
                                        autoComplete="off"
                                        multiline
                                        minRows={3}
                                        id="description"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        label="Price"
                                        name="price"
                                        type='tel'
                                        value={price}
                                        onChange={(event) => setPrice(event.target.value)}
                                        inputProps={{ pattern: '[0-9]*' }}
                                        autoComplete="off"
                                        id="price"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker sx={{ width: "100% " }}
                                                label="Manufacturing Year"
                                                openTo="year"
                                                views={['year']}
                                                value={manufacturingYear}
                                                onChange={(newValue) => setManufacturingYear(newValue)}
                                                disableFuture
                                                minDate={new Date(currentYear - 60, 0, 1)} // Previous year
                                                maxDate={new Date(currentYear, 11, 31)} // Current year
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Working Condition"
                                        select
                                        value={condition}
                                        onChange={handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="WORKING">Working</MenuItem>
                                        <MenuItem value="NON_WORKING">Not Working</MenuItem>
                                        <MenuItem value="PARTIALLY_WORKING">Partially Working</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        label="No of Items"
                                        name="items"
                                        value={numberOfItems}
                                        onChange={(event) => setNumberOfItems(event.target.value)}
                                        autoComplete="off"
                                        id="items"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        freeSolo
                                        id="specialityname"
                                        disableClearable
                                        options={specialityOptions.map((option) => option)}
                                        value={specialityName}
                                        onChange={(event, newValue) => {
                                            setSpecialityName(newValue);
                                            if (!specialityData.some((item) => item.suitableFor === newValue)) {
                                                handleAddSpecialities(newValue);
                                            }
                                        }}
                                        inputValue={specialityInputValue}
                                        onInputChange={(event, newInputValue) => {

                                            const capitalizedInput = newInputValue.toLowerCase()
                                                .split(" ")
                                                .map(word => {
                                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                                })
                                                .join(" ")

                                            setSpecialityInputValue(capitalizedInput)

                                            if (!specialityOptions.includes(capitalizedInput)) {
                                                getSpecialitiesData(capitalizedInput);
                                            }

                                        }}
                                        onBlur={() => {
                                            if (!specialityOptions.find((option) => option === specialityInputValue)) {
                                                handleAddSpecialities(specialityInputValue);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Suitable For"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {specialityData.length > 0 &&
                                    <Grid container direction="column">
                                        <Grid container direction="row" style={{ padding: "10px", marginTop: "10px" }}>

                                            <Grid item >
                                                <Paper elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flexWrap: 'wrap',
                                                        listStyle: 'none',
                                                        backgroundColor: 'transparent',
                                                        p: 0.5,
                                                        m: 0,
                                                    }}
                                                    component="ul"
                                                >
                                                    {specialityData.map((data, index) => {
                                                        return (
                                                            <ListItem key={index}>
                                                                <Chip variant='outlined'
                                                                    color="primary"
                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                    label={data.suitableFor}
                                                                    onDelete={() => data.suitableFor === '' ? undefined : handleSpecialityDelete(data.suitableFor)}
                                                                />
                                                            </ListItem>
                                                        );

                                                    })
                                                    }
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>

                            {/* ############################  product upload images section ######################################### */}

                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                <Typography color="text.primary" gutterBottom className='sell-product-label'>
                                    Product Images
                                </Typography>
                            </Grid>

                            {showUploadContainer && (
                                <Grid container direction="column" justifyContent="center" alignItems="center" className="sell-product-upload-container">
                                    <Grid item>
                                        <IconButton component="label" style={{ padding: "0px" }}>
                                            <input hidden accept="image/*" multiple type="file" onChange={handleImageChange} />
                                            <CloudUploadIcon color="primary" className="sell-product-upload-Icon" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" color="text.primary" align="center" gutterBottom>
                                            Upload Images
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            <div className="gallery">
                                {selectedImages.map((item, index) => {
                                    return (
                                        <div key={index} style={{ position: "relative" }}>
                                            <img src={item.imageLink ? item.imageLink?.href : item} alt="upload" height="100%" width="100%" />
                                            <CloseIcon style={{ position: "absolute", top: "6px", right: "6px", fontSize: fullScreen ? "18px" : "22px", color: "red", cursor: "pointer" }} onClick={() => handleDelete(item, index, updateProductId)} />
                                        </div>
                                    )
                                })}
                            </div>

                            <Grid container direction="row" spacing={0.5}>
                                <Grid item xs={12}>
                                    <Typography align="left" color="text.primary" className='sell-product-label' style={{ marginTop: "10px", marginBottom: "-6px" }}>
                                        Can be sold at
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FormControl fullWidth>
                                        <InputLabel id="country-select-label" htmlFor="country-picker">
                                            Country
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="country-label"
                                            id="country-picker"
                                            label="Select Country"
                                            value={country}
                                            onChange={(event) => {
                                                setCountry(event.target.value);
                                                setStateChoices([]);
                                                setCityChoices([]);
                                                getStatesData(event.target.value);
                                            }}
                                        >
                                            <MenuItem value={""} selected>
                                                Select Country
                                            </MenuItem>
                                            {Array.isArray(countryChoices) &&
                                                countryChoices.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FormControl fullWidth >
                                        <InputLabel id="state-select-label" htmlFor="state-picker">
                                            State
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="state-label"
                                            id="state-picker"
                                            label="Select State"
                                            value={state}
                                            onChange={(event) => {
                                                setState(event.target.value);
                                                setCityChoices([]);
                                                getCityData(country, event.target.value);
                                            }}
                                        >
                                            <MenuItem value={""}>Select State</MenuItem>
                                            {Array.isArray(stateChoices) &&
                                                stateChoices.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-dialog-select-label" htmlFor="city-picker">
                                            City
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="city-label"
                                            id="city-picker"
                                            label="Select City"
                                            value={city}
                                            onChange={(event) => setCity(event.target.value)}
                                        >
                                            <MenuItem value={""}>Select City</MenuItem>
                                            {Array.isArray(cityChoices) &&
                                                cityChoices.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth
                                        label="Pincode"
                                        name="pincode"
                                        value={pincodes}
                                        onChange={(event) => setPincodes(event.target.value)}
                                        inputProps={{ maxLength: 6 }}
                                        autoComplete="off"
                                        id="pincode"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                        </Paper>

                        {!isApiCallInProgress && (
                            <Grid container direction="row" style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}>
                                <Grid item xs={12}>
                                    <Button variant="contained" fullWidth className='sellproduct-button' color="primary" onClick={handleUpdateProductSubmit}>Update</Button>
                                </Grid>
                            </Grid>
                        )}

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Updatesellproduct
