import { useState, useEffect } from "react";
import { Chip, IconButton, Typography, Avatar, Grid, Button, TextField, Paper } from '@mui/material';
import "./jobdetails.css";
import { useToasts } from "react-toast-notifications";
import { useTheme } from '@mui/material/styles';
import { FaRupeeSign } from 'react-icons/fa';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import useAPIRequest from "../../API/useApiRequest";
import useMediaQuery from '@mui/material/useMediaQuery';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PlaceIcon from '@mui/icons-material/Place';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment";
import { fileCompressor } from "../../components/FileCompressor";
import CurrencyFormat from '../../utils/CurrencyFormat';
import ReadMore from "../../components/ReadMore";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";


const JobDetails = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { jobApplicationID } = useParams();
  const history = useHistory();

  const [jobApplicationDetails, setJobApplicationDetails] = useState("");
  const [jobSkills, setJobSkills] = useState([]);
  const [jobTags, setJobTags] = useState([]);
  const [coverLetter, setCoverLetter] = useState("");
  const [selectedFile, setSelectedFile] = useState({ contentType: null, content: null, name: null });

  // Services
  const { apiRequests } = useAPIRequest();

  useEffect(() => {
    getJobApplicationDetails(jobApplicationID);
  }, []);

  const getJobApplicationDetails = async (jobApplicationID) => {
    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `jobs/${jobApplicationID}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setJobApplicationDetails(data);
      setJobSkills(data.skills);
      setJobTags(data.tags);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const handleFileChange = async (event) => {

    setLoading(true);

    const file = event.target.files[0];

    if (!file) {
      setLoading(false);
      return;
    }

    let fileName = file.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "pdf") {

      if (extFile === "pdf" && file.size > 4 * 1024 * 1024) {
        addToast("PDF file size should be 4 MB or less", { appearance: "error" });
        setLoading(false);
        return;
      }

      let fileData = await fileCompressor(file, addToast);

      if (fileData) {

        let contentType = extFile === "pdf" ? "application/pdf" : `image/${extFile}`;

        setSelectedFile({ contentType: contentType, content: fileData, name: fileName });

      }
    } else {
      addToast("Please upload valid (jpeg/png/pdf) files!!!", {
        appearance: "error",
      });
    }

    setLoading(false);
  };

  const handleFileDelete = () => {
    setSelectedFile({ contentType: null, content: null, name: null });
  };

  const validateJobForm = () => {

    let valid = true;

    if (coverLetter.trim() === '') {
      addToast('Work Experience cannot be empty', { appearance: 'error' });
      valid = false;
    }

    // if (selectedFile.content === null && selectedFile.contentType === null) {
    //   addToast('Please upload your resume', { appearance: 'error' });
    //   valid = false;
    // }

    return valid;
  };

  const handleApplyForJobSubmit = () => {

    if (validateJobForm()) {
      submitJobApplication();
    }

  };

  const submitJobApplication = async () => {

    setLoading(true);

    const { response, data, error } = await apiRequests({
      endPoint: "jobs/application",
      method: "POST",
      body: {
        jobId: jobApplicationID,
        coverLetter: coverLetter,
      },
      addToast,
    });

    if (data) {

      let applicationID = data.id;

      addToast("You have applied for the job successfully !!!", { appearance: "success" });

      if (selectedFile.contentType !== null && selectedFile.content !== null) {

        const { response, data, error } = await apiRequests({
          endPoint: `jobs/application/upload-resume/${applicationID}`,
          method: "POST",
          body: { contentType: selectedFile.contentType, content: selectedFile.content },
          addToast,
        });
        if (response.status === 200) {

          addToast("Resume uploaded successfully !!!", { appearance: "success" });
          setCoverLetter("");
          setSelectedFile({ contentType: null, content: null, name: null });
          setLoading(false);
          history.push(Constants.MY_JOB_APPLICATIONS);

        } else {
          setLoading(false);
          addToast("You have applied for the job successfully, but there's an error uploading resume.!", { appearance: "error" });
          history.push(Constants.MY_JOB_APPLICATIONS);
        }

      } else {
        setCoverLetter("");
        setSelectedFile({ contentType: null, content: null, name: null });
        setLoading(false);
        history.push(Constants.MY_JOB_APPLICATIONS);
      }
    } else if (response.status === 409) {
      addToast("You already applied for this job successfully !!!", { appearance: "success" });
      setCoverLetter("");
      setSelectedFile({ contentType: null, content: null, name: null });
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="apply-job-details-drawer">
            <Navigation />
          </div>

          <div className="apply-job-details-container">

            <Paper elevation={2} className="apply-job-details-paper">
              <div className="company">

                <div className="company-container">
                  <Avatar src={jobApplicationDetails.profileLink && jobApplicationDetails.profileLink.href ? jobApplicationDetails.profileLink.href : " "} color="primary" className='company-picture' />
                  <div className="company-info">
                    <Typography variant="subtitle1" align="left" style={{ fontWeight: "bold" }}>
                      {jobApplicationDetails.userName}
                    </Typography>
                    <Typography variant="subtitle2" align="left">
                      Posted on : {jobApplicationDetails.created ? moment(jobApplicationDetails.created).format("MMM D, YYYY") : ""}
                    </Typography>
                  </div>
                </div>

                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Typography align="left" className="job_designation">
                      {jobApplicationDetails.title}
                    </Typography>
                  </Grid>
                </Grid>

                {jobSkills.length > 0 && (
                  <Grid container direction="row" >
                    {jobSkills.map((value, index) => {
                      return (
                        <Grid item style={{ margin: "0px 6px 6px 0px" }} key={index}>
                          <Chip label={value} variant="outlined" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }} />
                        </Grid>
                      );
                    })
                    }
                  </Grid>
                )
                }

                {jobTags.length > 0 && (
                  <Grid container direction="row" >
                    {jobTags.map((item, index) => {
                      return (
                        <Grid item style={{ margin: "0px 10px 6px 0px" }} key={index}>
                          <Typography variant="subtitle1" color="text.primary" >
                            {item}
                          </Typography>
                        </Grid>
                      );
                    })
                    }
                  </Grid>
                )
                }

                <Grid container direction="row" >
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" >
                      <span className='description-title'>Job Description :</span>
                    </Typography>

                    {jobApplicationDetails.description ?
                      <Grid item xs={12}>
                        {jobApplicationDetails.description.length < 300 ?
                          <Typography align="left" variant='subtitle1' color="text.primary">
                            {jobApplicationDetails.description}
                          </Typography>
                          :
                          <Typography align="left" variant='subtitle1' color="text.primary">
                            <ReadMore>
                              {jobApplicationDetails.description}
                            </ReadMore>
                          </Typography>
                        }
                      </Grid>
                      :
                      "No description"}
                  </Grid>
                </Grid>

                <div className="jobDetails-Container">
                  <div className="jobDetails--WithIcon">
                    <Avatar className='jobAvatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                      <FaRupeeSign className='jobDetails--ReactIcon' />
                    </Avatar>
                    <Typography variant="subtitle2" >
                      Salary
                    </Typography>
                    <Typography variant="subtitle1" >
                      {
                        jobApplicationDetails.expectedSalary ?
                          <CurrencyFormat currencyPrice={jobApplicationDetails.expectedSalary} />
                          :
                          "N/A"
                      }
                    </Typography>
                  </div>

                  <div className="jobDetails--WithIcon">
                    <Avatar className='jobAvatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                      <AccessAlarmsIcon className='jobDetails--materialuiIcon' />
                    </Avatar>
                    <Typography variant="subtitle2" >
                      Job Type
                    </Typography>
                    <Typography variant="subtitle1" >
                      {jobApplicationDetails.jobType && jobApplicationDetails.jobType === "PART_TIME" ? "Part Time" : "Full Time"}
                    </Typography>
                  </div>

                  <div className="jobDetails--WithIcon">
                    <Avatar className='jobAvatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                      <PlaceIcon className='jobDetails--materialuiIcon' />
                    </Avatar>
                    <Typography variant="subtitle2" >
                      City
                    </Typography>
                    <Typography variant="subtitle1" >
                      {jobApplicationDetails.city ? jobApplicationDetails.city : "N/A"}
                    </Typography>
                  </div>

                </div>

                <Grid container >
                  <Grid item xs={12}>
                    <Typography className='description-title' gutterBottom>
                      Work Experience :
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      placeholder="Work Experience"
                      name="workexperience"
                      value={coverLetter}
                      onChange={(event) => setCoverLetter(event.target.value)}
                      autoComplete="off"
                      multiline
                      minRows={5}
                      id="workexperience"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="column" >
                  <Grid item xs={12}>
                    <Typography className='description-title'>
                      Upload Resume :
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="column" justifyContent="center" alignItems="center" className="upload-container">
                  <Grid item>
                    <IconButton component="label" style={{ padding: "0px" }} >
                      <input hidden accept="image/*,.pdf" type="file" onChange={handleFileChange} />
                      <CloudUploadIcon color="primary" className="upload-icon" />
                    </IconButton>
                  </Grid>
                  <Grid item >
                    <Typography variant="subtitle1" align="center">
                      Supported Files
                    </Typography>
                    <Typography variant="subtitle2" align="center" gutterBottom>
                      PDF, JPG, PNG
                    </Typography>
                  </Grid>
                </Grid>

                {
                  selectedFile && selectedFile.contentType && selectedFile.content && selectedFile.name && (
                    <div className="job-pdf-container">
                      {
                        selectedFile.contentType.startsWith('image') ? (
                          <ImageIcon className='job-pdf-icon' />
                        )
                          : selectedFile.contentType === 'application/pdf' ? (
                            <PictureAsPdfIcon className='job-pdf-icon' />
                          )
                            : null
                      }
                      <Typography variant="subtitle1" className='job-pdf-filename'>
                        {selectedFile.name}
                      </Typography>
                      <ClearIcon className='job-pdf-icon' onClick={handleFileDelete} />
                    </div>
                  )
                }

              </div>
            </Paper>

            <Grid container direction="row" style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth className='apply-button' onClick={handleApplyForJobSubmit}>Apply for Job</Button>
              </Grid>
            </Grid>

          </div>
        </div>
        <YLoader loading={loading} />
      </div>

    </>

  )
}

export default JobDetails