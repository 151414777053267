import React, { useState } from 'react'
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import "./viewmagazine.css";
import MedicalAssociationYearList from './MedicalAssociationYearList';

const MedicalAssociationYear = () => {
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();

  return (
    <>
      <div style={{ backgroundColor: "#fff", height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="view-magazine-drawer">
            <Navigation />
          </div>

          <div className="view-magazine-container">

            <MedicalAssociationYearList />

          </div>
        </div>
        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default MedicalAssociationYear