import React from "react";
import { YContext } from "../../Context/YContext";
import YLoader from "../../components/Loader";
function AuthWrap({ children }) {
  const { isLoading } = React.useContext(YContext);

  if (isLoading) {
    return <YLoader loading={isLoading} />;
  }

  return <>{children}</>;
}

export default AuthWrap;
