import React, { useState, useContext, useEffect } from "react";
import { Grid, Button, Tab, Tabs, Dialog, DialogTitle, DialogContent, Typography, DialogActions, } from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./inventorypage.css";
import Product from "./Product/Product";
import Contract from "./Contract/Contract";
import Archive from "./Archive/Archive";
import NewLaunch from "./NewLaunch/NewLaunch";
import { YContext } from "../../Context/YContext";
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../../API/useApiRequest";
import { useHistory } from "react-router-dom";

const Inventorypage = () => {
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const history = useHistory();

    const [selectedTab, setSelectedTab] = useState(0);
    const [currentPackage, setCurrentPackage] = useState("");
    const [inventory, setInventory] = useState([])
    const [getProductId, setGetProductId] = useState('');

    // Services
    const { apiRequests } = useAPIRequest();
    const { currentUser, getUserInfo } = useContext(YContext);

    // Dialogs
    const [openRemoveInventoryItemDialog, setOpenRemoveInventoryItemDialog] = React.useState(false);
    const [openSubscriptionDialog, setOpenSubscriptionDialog] = React.useState(false);
    const [openUpgradePackageDialog, setOpenUpgradePackageDialog] = React.useState(false);


    const handleRemoveInventoryItemDialogClickOpen = (id) => {
        setOpenRemoveInventoryItemDialog(true);
        setGetProductId(id);
    };

    const handleRemoveInventoryItemDialogClickClose = () => {
        setOpenRemoveInventoryItemDialog(false);
    };

    const handleSubscriptionDialogClickOpen = () => {
        setOpenSubscriptionDialog(true);
    };

    const handleSubscriptionDialogClickClose = () => {
        setOpenSubscriptionDialog(false);
    };

    const handleUpgradePackageDialogClickClose = () => {
        setOpenUpgradePackageDialog(false);
    };

    const handleUpgradePackageDialogClickOpen = () => {
        setOpenUpgradePackageDialog(true);
    };

    const handleBuy = () => {
        history.push("/packagedetails");
    };

    useEffect(() => {
        getPackages();
        getUserInventory();
        checkPackage();
    }, []);

    const getPackages = async () => {

        const { response, data, error } = await apiRequests({
            endPoint: `orders/active-package`,
            method: "GET",
            addToast,
        });

        if (data) {
            setCurrentPackage(data);
        } else {
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const getUserInventory = async () => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `items/findByUser`,
            method: "GET",
            addToast,
        });

        if (data) {
            setInventory(data);
            getUserInfo();
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    let typeProduct = inventory.filter(member => member.itemType === "PRODUCT" && member.active === true)

    let typeContract = inventory.filter(member => member.itemType === "CONTRACT" && member.active === true)

    let typeNewProductLaunch = inventory.filter(member => member.itemType === "NEW_LAUNCH" && member.active === true)

    let typeArchive = inventory.filter(member => member.active === false)


    const handleDelete = async (getId) => {
        setOpenRemoveInventoryItemDialog(false);
        setLoading(true)
        const { response, data, error } = await apiRequests(
            {
                endPoint: `/items/activate/${getId}/false`,
                method: "PUT",
                addToast
            }
        );
        if (data) {
            getUserInventory();
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const checkPackage = () => {
        if (currentUser.packageType === "MEDDY_CONNECT_STARTER") {
            handleSubscriptionDialogClickOpen()
        }
        else {
            console.log("meddyconnect")
        }
    };

    const handleInventoryActive = async (getArchiveId) => {
        setLoading(true)
        if (currentUser.allowedNoOfProducts - currentUser.activeNoOfProducts <= 0) {
            setLoading(false);
            handleUpgradePackageDialogClickOpen()
        } else {
            const { response, data, error } = await apiRequests(
                {
                    endPoint: `items/activate/${getArchiveId}/true`,
                    method: "PUT",
                    addToast
                }
            );
            if (response.status === 200) {
                getUserInventory();
                setLoading(false);
            } else {
                setLoading(false);
                addToast("Something went wrong!", { appearance: "error" });
            }
        }

    };

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="inventory-Drawer">
                        <Navigation />
                    </div>

                    <div className="inventory-container">
                        <div className="tabs-container">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons={false}>
                                        <Tab label="Product" />
                                        <Tab label="Repair & Contract" />
                                        <Tab label="New Launch" />
                                        <Tab label="Archive" />
                                    </Tabs>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="content-container">
                            {selectedTab === 0 && <Product typeProduct={typeProduct} handleRemoveProductDialogClickOpen={handleRemoveInventoryItemDialogClickOpen} />}
                            {selectedTab === 1 && <Contract typeContract={typeContract} handleRemoveContractDialogClickOpen={handleRemoveInventoryItemDialogClickOpen} />}
                            {selectedTab === 2 && <NewLaunch typeNewProductLaunch={typeNewProductLaunch} handleRemoveNewProductLaunchDialogClickOpen={handleRemoveInventoryItemDialogClickOpen} />}
                            {selectedTab === 3 && <Archive typeArchive={typeArchive} handleInventoryActive={handleInventoryActive} />}
                        </div>
                    </div>

                </div>

                <Dialog
                    open={openRemoveInventoryItemDialog}
                    onClose={handleRemoveInventoryItemDialogClickClose}
                    maxWidth="xs"
                >
                    <DialogTitle>
                        MeddyConnect
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" color="text.primary">
                            Are you sure you want to remove it?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRemoveInventoryItemDialogClickClose} className="alert_btn" color="primary" disableRipple>Cancel</Button>
                        <Button onClick={() => { handleDelete(getProductId) }} className="alert_btn" color="primary" disableRipple>
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openSubscriptionDialog}
                    onClose={handleSubscriptionDialogClickClose}
                    maxWidth="xs"
                >
                    <DialogTitle>
                        MeddyConnect
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" color="text.primary">
                            You have't subscribed to any plan, please complete your payment to use this feature.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubscriptionDialogClickClose} className="alert_btn" color="primary" disableRipple>Cancel</Button>
                        <Button onClick={handleBuy} className="alert_btn" color="primary" disableRipple>
                            Buy
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openUpgradePackageDialog} onClose={handleUpgradePackageDialogClickClose}>
                    <DialogTitle >MeddyConnect</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" color="text.primary">
                            You have reached the limit of active items, your subscription allow {currentUser.allowedNoOfProducts} active items only.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpgradePackageDialogClickClose} className="alert_btn" color="primary">Cancel</Button>
                        <Button onClick={handleBuy} className="alert_btn" color="primary">Upgrade</Button>
                    </DialogActions>
                </Dialog>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Inventorypage