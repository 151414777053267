import React, { useState } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid,Tabs,Tab } from '@mui/material';
import UpcomingTrainings from "./UpcomingTrainings";
import ArchiveTrainings from "./ArchiveTrainings";
import "./managetrainingcards.css";


const ManageTrainingCards = () => {
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="manage-training-Drawer">
                        <Navigation />
                    </div>

                    <div className="manage-training-container">

                        <Grid container>
                            <Grid item xs={12}>
                                <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons={false}>
                                    <Tab label="Upcoming" />
                                    <Tab label="Archive" />
                                </Tabs>
                            </Grid>
                        </Grid>
                        {selectedTab === 0 && <UpcomingTrainings />}
                        {selectedTab === 1 && <ArchiveTrainings />}

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default ManageTrainingCards
