import React from "react";
import { Dialog, DialogTitle, DialogContent, Slide, Typography, DialogActions, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import Constants from "../MeddyConnectConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VerificationAlertDialog = ({ openAlertVerificationDialog, handleAlertVerificationDialogClickClose, message }) => {
    const history = useHistory();

    const handleVerifyClick = () => {
        history.push(Constants.VERIFICATION);
    };

    const handleCancel = () => {
        history.push(Constants.HOME_SCREEN);
    };

    return (
        <>
            <Dialog
                open={openAlertVerificationDialog}
                // onClose={handleAlertVerificationDialogClickClose}
                TransitionComponent={Transition}
            >
                <DialogTitle>MeddyConnect</DialogTitle>
                <DialogContent>

                    <Typography variant="subtitle1" color="text.primary" align="left">
                        {message}
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" >Cancel</Button>
                    <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerificationAlertDialog