export const razorPayment=(orderId,amount,name,email,contact,callbackHandler)=>{
 
    let options = {
      "key": process.env.REACT_APP_RAZOR_PAY_KEY,
      "order_id":orderId,
      "amount": amount, 
      "name": process.env.REACT_APP_RAZOR_MERCHANT_NAME,
      "description": process.env.REACT_APP_RAZOR_DESCRIPTION,
      "image": process.env.REACT_APP_RAZOR_IMAGE,
      "handler": function (response){
       callbackHandler("success",response.razorpay_order_id,response.razorpay_payment_id,response.razorpay_signature);
      },
      "prefill": {
        name,
        email,
        contact,        
      },
    //   "notes": {
    //     "description": "Find your soulmate"
    //   },
      "theme": {      
        "color": "#00B9F1"
      }
    };

    let rzp = new window.Razorpay(options);
    rzp.open();
    rzp.on('payment.failed',function(response){
        const metadata = response.error.metadata;
        callbackHandler("failed",metadata.order_id,metadata.payment_id);
    });
  
  }