import { useState, useEffect, useContext } from "react";
import { Paper, Grid, Button, Typography, IconButton, FormControlLabel, Dialog, DialogTitle, DialogContent, Switch, DialogActions, Divider } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import "../Profile.css";
import { useToasts } from "react-toast-notifications";
import YLoader from "../../components/Loader";
import moment from "moment";
import 'date-fns';
import useAPIRequest from "../../API/useApiRequest";
import { YContext } from "../../Context/YContext";

const MedicalAssociationWorkingHrs = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    // Variables
    const [workingHoursDetails, setWorkingHoursDetails] = useState([]);
    const [userId, setUserId] = useState("");

    const [monID, setMonID] = useState(null);
    const [monHoliday, setMonHoliday] = useState(false);
    const [monTwentyFourXSeven, setMonTwentyFourXSeven] = useState(false);
    const [monOpenTime, setMonOpenTime] = useState(new Date());
    const [monCloseTime, setMonCloseTime] = useState(new Date());

    const [tueID, setTueID] = useState(null);
    const [tueHoliday, setTueHoliday] = useState(false);
    const [tueTwentyFourXSeven, setTueTwentyFourXSeven] = useState(false);
    const [tueOpenTime, setTueOpenTime] = useState(new Date());
    const [tueCloseTime, setTueCloseTime] = useState(new Date());

    const [wedID, setWedID] = useState(null);
    const [wedHoliday, setWedHoliday] = useState(false);
    const [wedTwentyFourXSeven, setWedTwentyFourXSeven] = useState(false);
    const [wedOpenTime, setWedOpenTime] = useState(new Date());
    const [wedCloseTime, setWedCloseTime] = useState(new Date());

    const [thurID, setThurID] = useState(null);
    const [thursHoliday, setThursHoliday] = useState(false);
    const [thursTwentyFourXSeven, setThursTwentyFourXSeven] = useState(false);
    const [thursOpenTime, setThursOpenTime] = useState(new Date());
    const [thursCloseTime, setThursCloseTime] = useState(new Date());

    const [friID, setFriID] = useState(null);
    const [friHoliday, setFriHoliday] = useState(false);
    const [friTwentyFourXSeven, setFriTwentyFourXSeven] = useState(false);
    const [friOpenTime, setFriOpenTime] = useState(new Date());
    const [friCloseTime, setFriCloseTime] = useState(new Date());

    const [satID, setSatID] = useState(null);
    const [satHoliday, setSatHoliday] = useState(false);
    const [satTwentyFourXSeven, setSatTwentyFourXSeven] = useState(false);
    const [satOpenTime, setSatOpenTime] = useState(new Date());
    const [satCloseTime, setSatCloseTime] = useState(new Date());

    const [sunID, setSunID] = useState(null);
    const [sunHoliday, setSunHoliday] = useState(false);
    const [sunTwentyFourXSeven, setSunTwentyFourXSeven] = useState(false);
    const [sunOpenTime, setSunOpenTime] = useState(new Date());
    const [sunCloseTime, setSunCloseTime] = useState(new Date());

    const [isTwentyFourXSevenOpen, setIsTwentyFourXSevenOpen] = useState(false);
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();

    // Dialogs
    const [openWorkingHrsDialog, setOpenWorkingHrsDialog] = useState(false);


    const handleWorkingHrsDialogClickClose = () => {
        setOpenWorkingHrsDialog(false);
    };
    const handleWorkingHrsDialogClickOpen = () => {
        setOpenWorkingHrsDialog(true);
    };

    const getHospitalWorkingHrs = () => {

        setLoading(true);
        setUserId(currentUser.id);
        setWorkingHoursDetails(currentUser.workingHourDetails);
        setLoading(false);

    };

    // useEffect(() => {
    //     getHospitalWorkingHrs();
    // }, [currentUser]);

    const sortedWorkingHours = workingHoursDetails && workingHoursDetails.sort((item1, item2) => {
        return days.indexOf(item1.day) - days.indexOf(item2.day);
    });

    useEffect(() => {

        getHospitalWorkingHrs();

        // Iterate through sortedWorkingHours and update state accordingly
        sortedWorkingHours?.forEach((workingHour) => {

            switch (workingHour.day) {
                case 'Monday':
                    setMonID(workingHour.id);
                    // Update state for Monday
                    setMonTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setMonHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        // Convert start time to JavaScript Date
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setMonOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        // Convert end time to JavaScript Date
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setMonCloseTime(date);
                    }
                    break;

                case 'Tuesday':
                    setTueID(workingHour.id);
                    // Update state for Tuesday
                    setTueTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setTueHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setTueOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setTueCloseTime(date);
                    }
                    break;

                case 'Wednesday':
                    setWedID(workingHour.id);
                    // Update state for Wednesday
                    setWedTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setWedHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setWedOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setWedCloseTime(date);
                    }
                    break;

                case 'Thursday':
                    setThurID(workingHour.id);
                    // Update state for Thursday
                    setThursTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setThursHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setThursOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setThursCloseTime(date);
                    }
                    break;

                case 'Friday':
                    setFriID(workingHour.id);
                    // Update state for Friday
                    setFriTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setFriHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setFriOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setFriCloseTime(date);
                    }
                    break;

                case 'Saturday':
                    setSatID(workingHour.id);
                    // Update state for Saturday
                    setSatTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setSatHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setSatOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setSatCloseTime(date);
                    }
                    break;

                case 'Sunday':
                    setSunID(workingHour.id);
                    // Update state for Sunday
                    setSunTwentyFourXSeven(workingHour.twentyFourXSeven ?? false);
                    setSunHoliday(workingHour.holiday ?? false);

                    if (workingHour.startTime && workingHour.startTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.startTime}`);
                        setSunOpenTime(date);
                    }

                    if (workingHour.endTime && workingHour.endTime.trim() !== '') {
                        const date = new Date(`January 1, 2000 ${workingHour.endTime}`);
                        setSunCloseTime(date);
                    }
                    break;

                default:
                    // Default case (if needed)
                    break;
            }
        });
    }, [currentUser, sortedWorkingHours]);

    const handleAddHospitalWorkingsHrs = (
        monID, monOpenTime, monCloseTime, monTwentyFourXSeven, monHoliday,
        tueID, tueOpenTime, tueCloseTime, tueTwentyFourXSeven, tueHoliday,
        wedID, wedOpenTime, wedCloseTime, wedTwentyFourXSeven, wedHoliday,
        thurID, thursOpenTime, thursCloseTime, thursTwentyFourXSeven, thursHoliday,
        friID, friOpenTime, friCloseTime, friTwentyFourXSeven, friHoliday,
        satID, satOpenTime, satCloseTime, satTwentyFourXSeven, satHoliday,
        sunID, sunOpenTime, sunCloseTime, sunTwentyFourXSeven, sunHoliday, userId) => {

        let newMonOpenTime = moment(monOpenTime).format('h:mm a');
        let newMonCloseTime = moment(monCloseTime).format('h:mm a');

        let newTueOpenTime = moment(tueOpenTime).format('h:mm a');
        let newTueCloseTime = moment(tueCloseTime).format('h:mm a');

        let newWedOpenTime = moment(wedOpenTime).format('h:mm a');
        let newWedCloseTime = moment(wedCloseTime).format('h:mm a');

        let newThursOpenTime = moment(thursOpenTime).format('h:mm a');
        let newThursCloseTime = moment(thursCloseTime).format('h:mm a');

        let newFriOpenTime = moment(friOpenTime).format('h:mm a');
        let newFriCloseTime = moment(friCloseTime).format('h:mm a');

        let newSatOpenTime = moment(satOpenTime).format('h:mm a');
        let newSatCloseTime = moment(satCloseTime).format('h:mm a');

        let newSunOpenTime = moment(sunOpenTime).format('h:mm a');
        let newSunCloseTime = moment(sunCloseTime).format('h:mm a');

        const workingHourDetails = [
            {
                id: monID,
                day: "Monday",
                startTime: (monTwentyFourXSeven || monHoliday) ? "" : newMonOpenTime,
                endTime: (monTwentyFourXSeven || monHoliday) ? "" : newMonCloseTime,
                twentyFourXSeven: monTwentyFourXSeven,
                holiday: monHoliday,
            },
            {
                id: tueID,
                day: "Tuesday",
                startTime: (tueTwentyFourXSeven || tueHoliday) ? "" : newTueOpenTime,
                endTime: (tueTwentyFourXSeven || tueHoliday) ? "" : newTueCloseTime,
                twentyFourXSeven: tueTwentyFourXSeven,
                holiday: tueHoliday,
            },
            {
                id: wedID,
                day: "Wednesday",
                startTime: (wedTwentyFourXSeven || wedHoliday) ? "" : newWedOpenTime,
                endTime: (wedTwentyFourXSeven || wedHoliday) ? "" : newWedCloseTime,
                twentyFourXSeven: wedTwentyFourXSeven,
                holiday: wedHoliday,
            },
            {
                id: thurID,
                day: "Thursday",
                startTime: (thursTwentyFourXSeven || thursHoliday) ? "" : newThursOpenTime,
                endTime: (thursTwentyFourXSeven || thursHoliday) ? "" : newThursCloseTime,
                twentyFourXSeven: thursTwentyFourXSeven,
                holiday: thursHoliday,
            },
            {
                id: friID,
                day: "Friday",
                startTime: (friTwentyFourXSeven || friHoliday) ? "" : newFriOpenTime,
                endTime: (friTwentyFourXSeven || friHoliday) ? "" : newFriCloseTime,
                twentyFourXSeven: friTwentyFourXSeven,
                holiday: friHoliday,
            },
            {
                id: satID,
                day: "Saturday",
                startTime: (satTwentyFourXSeven || satHoliday) ? "" : newSatOpenTime,
                endTime: (satTwentyFourXSeven || satHoliday) ? "" : newSatCloseTime,
                twentyFourXSeven: satTwentyFourXSeven,
                holiday: satHoliday,
            },
            {
                id: sunID,
                day: "Sunday",
                startTime: (sunTwentyFourXSeven || sunHoliday) ? "" : newSunOpenTime,
                endTime: (sunTwentyFourXSeven || sunHoliday) ? "" : newSunCloseTime,
                twentyFourXSeven: sunTwentyFourXSeven,
                holiday: sunHoliday,
            },
        ];

        createHospitalWorkingsHrs(userId, workingHourDetails)

    };

    const createHospitalWorkingsHrs = async (userId, workingHourDetails) => {

        setOpenWorkingHrsDialog(false);
        setLoading(true);

        const { response, data, error } = await apiRequests({
            endPoint: `hospital/${userId}`,
            method: "PUT",
            body: {
                workingHourDetails: workingHourDetails,
            },
            addToast,
        });
        if (data) {
            setLoading(false);
            getUserInfo();
        }

    }

    const handleIsTwentyFourXSevenOpenChange = (event) => {

        if (event.target.checked) {

            setIsTwentyFourXSevenOpen(true);
            setMonTwentyFourXSeven(true);
            setMonHoliday(false);
            setTueTwentyFourXSeven(true);
            setTueHoliday(false);
            setWedTwentyFourXSeven(true);
            setWedHoliday(false);
            setThursTwentyFourXSeven(true);
            setThursHoliday(false);
            setFriTwentyFourXSeven(true);
            setFriHoliday(false);
            setSatTwentyFourXSeven(true);
            setSatHoliday(false);
            setSunTwentyFourXSeven(true);
            setSunHoliday(false);

        } else {

            setIsTwentyFourXSevenOpen(false);

        }
    };

    return (
        <> {/* ############################ working hrs section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< EditIcon />} color="primary" onClick={handleWorkingHrsDialogClickOpen} className="profile_btn">
                        Working Hours
                    </Button>
                </div>

                <Paper elevation={4} className="paper-container">
                    {workingHoursDetails && workingHoursDetails.map((value) => {

                        const startTime = value.startTime || '';
                        const endTime = value.endTime || '';

                        return (

                            <div className="section_container" key={value.id} style={{ marginBottom: "6px" }}>
                                <IconButton>
                                    <DateRangeOutlinedIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" component="span">
                                        {value.day} :&nbsp;
                                    </Typography>
                                    {(value.twentyFourXSeven === null || value.twentyFourXSeven === false) &&
                                        startTime === '' && endTime === ''
                                        ? (
                                            (value.holiday === null || value.holiday === false) ?
                                                (<span>-</span>) :
                                                (
                                                    <Typography align="left" className="profile_description profile_text" color="text.primary" component="span">
                                                        Holiday
                                                    </Typography>
                                                )
                                        ) :
                                        value.twentyFourXSeven !== null && value.twentyFourXSeven === true ?
                                            (
                                                <Typography align="left" className="profile_description profile_text" color="text.primary" component="span">
                                                    Open 24 hours
                                                </Typography>
                                            )
                                            :
                                            (
                                                <Typography align="left" className="profile_description" color="text.primary" component="span">
                                                    {`${startTime} to ${endTime}`}
                                                </Typography>
                                            )
                                    }

                                </div>

                            </div>

                        );
                    })}
                </Paper>
                {/* ############################ Create Working Hrs dialog ######################################### */}
                <Dialog open={openWorkingHrsDialog} onClose={handleWorkingHrsDialogClickClose} fullScreen={fullScreen} maxWidth="sm" fullWidth>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleWorkingHrsDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Working Hours
                    </DialogTitle>
                    <DialogContent style={{ padding: "20px" }}>
                        <Grid container direction="row">

                            <Grid container direction="row">

                                <Grid container justifyContent="center" alignItems="center">

                                    <Grid item xs={12}>
                                        <FormControlLabel label="Is it working 24 * 7" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={isTwentyFourXSevenOpen} onChange={handleIsTwentyFourXSevenOpenChange} />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "6px 0px" }} />
                                    </Grid>

                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Monday :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="monday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={monTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setMonTwentyFourXSeven(true);
                                                        setMonHoliday(false);
                                                    } else {
                                                        setMonTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={monHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={monHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setMonHoliday(true);
                                                        setMonTwentyFourXSeven(false);
                                                    } else {
                                                        setMonHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={monTwentyFourXSeven === true}
                                        />
                                    </Grid>

                                </Grid>

                                {!(monTwentyFourXSeven || monHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={monOpenTime}
                                                        onChange={(newValue) => setMonOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Closing Time"
                                                        value={monCloseTime}
                                                        onChange={(newValue) => setMonCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>}

                                <Grid container>
                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "6px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Tuesday :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="tuesday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={tueTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setTueTwentyFourXSeven(true);
                                                        setTueHoliday(false);
                                                    } else {
                                                        setTueTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={tueHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={tueHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setTueHoliday(true);
                                                        setTueTwentyFourXSeven(false);
                                                    } else {
                                                        setTueHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={tueTwentyFourXSeven === true}
                                        />
                                    </Grid>
                                </Grid>

                                {!(tueTwentyFourXSeven || tueHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={tueOpenTime}
                                                        onChange={(newValue) => setTueOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Closing Time"
                                                        value={tueCloseTime}
                                                        onChange={(newValue) => setTueCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>}

                                <Grid container>
                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "6px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Wednesday :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="wednesday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={wedTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setWedTwentyFourXSeven(true);
                                                        setWedHoliday(false);
                                                    } else {
                                                        setWedTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={wedHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={wedHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setWedHoliday(true);
                                                        setWedTwentyFourXSeven(false);
                                                    } else {
                                                        setWedHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={wedTwentyFourXSeven === true}
                                        />
                                    </Grid>
                                </Grid>

                                {!(wedTwentyFourXSeven || wedHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={wedOpenTime}
                                                        onChange={(newValue) => setWedOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={wedCloseTime}
                                                        onChange={(newValue) => setWedCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>}

                                <Grid container>
                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "6px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Thursday :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="thursday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={thursTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setThursTwentyFourXSeven(true);
                                                        setThursHoliday(false);
                                                    } else {
                                                        setThursTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={thursHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={thursHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setThursHoliday(true);
                                                        setThursTwentyFourXSeven(false);
                                                    } else {
                                                        setThursHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={thursTwentyFourXSeven === true}
                                        />
                                    </Grid>
                                </Grid>

                                {!(thursTwentyFourXSeven || thursHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={thursOpenTime}
                                                        onChange={(newValue) => setThursOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={thursCloseTime}
                                                        onChange={(newValue) => setThursCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>}

                                <Grid container>
                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "6px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Friday :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="friday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={friTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setFriTwentyFourXSeven(true);
                                                        setFriHoliday(false);
                                                    } else {
                                                        setFriTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={friHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={friHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setFriHoliday(true);
                                                        setFriTwentyFourXSeven(false);
                                                    } else {
                                                        setFriHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={friTwentyFourXSeven === true}
                                        />
                                    </Grid>
                                </Grid>

                                {!(friTwentyFourXSeven || friHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={friOpenTime}
                                                        onChange={(newValue) => setFriOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={friCloseTime}
                                                        onChange={(newValue) => setFriCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>}

                                <Grid container>
                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "6px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Saturday :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="saturday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={satTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSatTwentyFourXSeven(true);
                                                        setSatHoliday(false);
                                                    } else {
                                                        setSatTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={satHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={satHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSatHoliday(true);
                                                        setSatTwentyFourXSeven(false);
                                                    } else {
                                                        setSatHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={satTwentyFourXSeven === true}
                                        />
                                    </Grid>
                                </Grid>

                                {!(satTwentyFourXSeven || satHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={satOpenTime}
                                                        onChange={(newValue) => setSatOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={satCloseTime}
                                                        onChange={(newValue) => setSatCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>}

                                <Grid container>
                                    <Grid item xs={12} >
                                        <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "6px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="body1" color="text.primary">
                                            Sunday :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7}>
                                        <FormControlLabel label="24Hrs Open" id="sunday" labelPlacement="start" style={{ marginLeft: "0px" }}
                                            control={<Switch checked={sunTwentyFourXSeven}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSunTwentyFourXSeven(true);
                                                        setSunHoliday(false);
                                                    } else {
                                                        setSunTwentyFourXSeven(false);
                                                        setIsTwentyFourXSevenOpen(false);
                                                    }
                                                }}
                                            />}
                                            disabled={sunHoliday === true}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <FormControlLabel label="Holiday" labelPlacement="start"
                                            control={<Switch checked={sunHoliday}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSunHoliday(true);
                                                        setSunTwentyFourXSeven(false);
                                                    } else {
                                                        setSunHoliday(false);
                                                    }
                                                }}
                                            />}
                                            disabled={sunTwentyFourXSeven === true}
                                        />
                                    </Grid>
                                </Grid>

                                {!(sunTwentyFourXSeven || sunHoliday) &&
                                    <Grid container justifyContent="space-evenly" spacing={1}>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={sunOpenTime}
                                                        onChange={(newValue) => setSunOpenTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DemoContainer components={['TimeField']}>
                                                    <TimeField label="Opening Time"
                                                        value={sunCloseTime}
                                                        onChange={(newValue) => setSunCloseTime(newValue)}
                                                        format="hh:mm a"
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Divider color="divider" style={{ margin: "10px 0px 8px 4px" }} />
                                        </Grid>
                                    </Grid>}
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleAddHospitalWorkingsHrs(
                                monID, monOpenTime, monCloseTime, monTwentyFourXSeven, monHoliday,
                                tueID, tueOpenTime, tueCloseTime, tueTwentyFourXSeven, tueHoliday,
                                wedID, wedOpenTime, wedCloseTime, wedTwentyFourXSeven, wedHoliday,
                                thurID, thursOpenTime, thursCloseTime, thursTwentyFourXSeven, thursHoliday,
                                friID, friOpenTime, friCloseTime, friTwentyFourXSeven, friHoliday,
                                satID, satOpenTime, satCloseTime, satTwentyFourXSeven, satHoliday,
                                sunID, sunOpenTime, sunCloseTime, sunTwentyFourXSeven, sunHoliday, userId)
                        }}
                            fullWidth variant="contained"
                            className='dialog-button1'
                            color="primary"
                        >
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>


                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default MedicalAssociationWorkingHrs