import React, { useState, useContext, useEffect } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, Slide, IconButton, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import "./professionverification.css";
import { useToasts } from "react-toast-notifications";
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { fileCompressor } from "../../components/FileCompressor";
import { YContext } from "../../Context/YContext";
import useAPIRequest from "../../API/useApiRequest";
import UrlRoute from "../../API/UrlRoute";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const documents = [
  { id: 1, documentname: 'Medical Registration Certificate', documenttype: 'MEDICAL_REGISTRATION_NUMBER', usertype: "DOCTOR" },
  { id: 2, documentname: 'Shop Establishment Certificate', documenttype: 'SHOP_ESTABLISHMENT_CERTIFICATE', usertype: "VENDOR" },
  { id: 3, documentname: 'GST Certificate', documenttype: 'GST_NUMBER', usertype: "VENDOR" },
  { id: 4, documentname: 'Office ID Card', documenttype: 'PROFESSIONAL_ID_DOCUMENT', usertype: "STAFF" },
  { id: 5, documentname: 'College ID Card', documenttype: 'STUDENT_ID_DOCUMENT', usertype: "STUDENT" },
  { id: 6, documentname: 'Medical Association Certificate', documenttype: 'ASSOCIATION_CERTIFICATE', usertype: "MEDICAL_ASSOCIATION" },
  { id: 7, documentname: 'Shop Establishment Certificate', documenttype: 'SHOP_ESTABLISHMENT_CERTIFICATE', usertype: "HOSPITAL" },
  { id: 8, documentname: 'GST Certificate', documenttype: 'GST_NUMBER', usertype: "HOSPITAL" },
];

const UpdateProfessionVerificationDialog = ({ openUpdateProfessionVerificationDialog, handleUpdateProfessionVerificationDialogClickClose, latestProfessionDocument, getVerificationStatus }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToasts();

  const [selectedDocumentType, setSelectedDocumentType] = useState("");

  const [uploadedDocumentImage, setUploadedDocumentImage] = useState({ contentType: null, content: null });
  const [uploadedDocumentImageId, setUploadedDocumentImageId] = useState("");

  const [selectedDocumentImage, setSelectedDocumentImage] = useState({ contentType: null, content: null });
  const [selectedDocumentImageURL, setSelectedDocumentImageURL] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");

  // Services
  const { currentUser } = useContext(YContext);
  const { apiRequests } = useAPIRequest();

  const documentsOptions = documents.filter(option => option.usertype === currentUser.userType);

  useEffect(() => {
    if (openUpdateProfessionVerificationDialog) {
      getLatestProfessionVerificationDocuments();
    }
  }, [openUpdateProfessionVerificationDialog]);

  const getLatestProfessionVerificationDocuments = () => {
    if (latestProfessionDocument) {

      setUploadedDocumentImage({ contentType: latestProfessionDocument.contentType, content: latestProfessionDocument.content });
      setUploadedDocumentImageId(latestProfessionDocument.id);

      setSelectedDocumentType(latestProfessionDocument.documentType);
      setVerificationStatus(latestProfessionDocument.verificationStatus);
    }
  };

  const handleDeleteUploadedDocumentImage = async () => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL}/${uploadedDocumentImageId}`,
      method: "DELETE",
      addToast,
    });

    if (data.response === true) {
      handleUpdateProfessionVerificationDialogClickClose();
      setFileLoading(false);
      addToast('Document image deleted successfully !!!', { appearance: 'success' });
      getVerificationStatus();
    } else {
      setFileLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  }

  const handleSelectedDocumentImageDelete = () => {
    setSelectedDocumentImage({ contentType: null, content: null });
    setSelectedDocumentImageURL("");
  };

  const handleSelectedDocumentImageChange = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setFileLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setSelectedDocumentImage({ contentType: contentType, content: imageData });
            setSelectedDocumentImageURL(url);
          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setFileLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setFileLoading(false);
    }

  };

  const handleUpdateProfessionVerificationSubmit = () => {

    if (validateUpdateProfessionVerification()) {
      uploadUpdateProfessionVerificationDocument();
    }

  };

  const validateUpdateProfessionVerification = () => {

    let valid = true;

    if (!selectedDocumentType) {
      addToast('Please select the document type', { appearance: 'error' });
      valid = false;
    }

    if (uploadedDocumentImage.content === null && selectedDocumentImage.content === null) {
      addToast('Document image cannot be empty', { appearance: 'error' });
      valid = false;
    }

    return valid;
  };

  const uploadUpdateProfessionVerificationDocument = async () => {
    let requestBody;

    if (selectedDocumentImage.content) {
      // Use selectedDocumentImage properties
      requestBody = {
        documentType: selectedDocumentType,
        contentType: selectedDocumentImage.contentType,
        content: selectedDocumentImage.content,
      };
    } else if (uploadedDocumentImage.content) {
      // Use uploadedDocumentImage properties
      requestBody = {
        documentType: selectedDocumentType,
        contentType: uploadedDocumentImage.contentType,
        content: uploadedDocumentImage.content,
      };
    } else {
      // Handle the case when neither selectedDocumentImage nor uploadedDocumentImage is present
      console.error('Neither selectedDocumentImage nor uploadedDocumentImage is present.');
      return;
    }

    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.VERIFICATION_DOCUMENT_UPLOAD_URL}/${uploadedDocumentImageId}`,
      method: 'PUT',
      body: requestBody,
      addToast,
    });

    if (response.status === 200) {
      addToast('Document image uploaded successfully !!!', { appearance: 'success' });
      handleUpdateProfessionVerificationDialogClickClose();
      setSelectedDocumentImage({ contentType: null, content: null });
      getVerificationStatus();
    }
  };

  return (
    <>
      <Dialog
        open={openUpdateProfessionVerificationDialog}
        onClose={handleUpdateProfessionVerificationDialogClickClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
          <IconButton
            edge="start"
            onClick={handleUpdateProfessionVerificationDialogClickClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          Update Profession Verification
        </DialogTitle>
        <DialogContent>

          <Grid container direction="row" style={{ margin: "10px 0px" }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="document-select-label">
                  Document Type
                </InputLabel>
                <Select
                  fullWidth
                  label="Document Type"
                  labelId="document-select-label"
                  id="document-type-picker"
                  value={selectedDocumentType}
                  onChange={(event) => {
                    setSelectedDocumentType(event.target.value);
                  }}
                  disabled={verificationStatus === "VERIFIED" || verificationStatus === "PENDING"}
                >
                  <MenuItem value={""}>Select Document</MenuItem>
                  {
                    documentsOptions.map((item) => {
                      return (
                        <MenuItem value={item.documenttype} key={item.id}>{item.documentname}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {verificationStatus && (
            <Grid container direction="row" style={{ margin: "10px 0px" }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.primary" align="left">
                  Status: {verificationStatus === "PENDING" ? "Pending for verification" : verificationStatus}
                </Typography>
              </Grid>
            </Grid>
          )}

          {selectedDocumentImageURL ? (
            <div className="profession-document-image-container">
              <img src={selectedDocumentImageURL} alt="Professional Document" className="profession-document-image" />
              <CloseIcon className="profession-document-delete-Icon" onClick={handleSelectedDocumentImageDelete} />
            </div>
          ) : uploadedDocumentImage.content ? (
            <div className="profession-document-image-container">
              <img
                src={`data:${uploadedDocumentImage.contentType};base64,${uploadedDocumentImage.content}`}
                alt="Professional Document"
                className="profession-document-image"
              />
              {verificationStatus === "VERIFICATION_FAILED" &&
                <CloseIcon className="profession-document-delete-Icon" onClick={handleDeleteUploadedDocumentImage} />
              }
            </div>
          ) : (
            <div className="profession-document-container">
              <IconButton component="label">
                <input hidden accept="image/*" type="file" onChange={handleSelectedDocumentImageChange} />
                <CloudUploadIcon color="primary" className="profession-document-upload-Icon" />
              </IconButton>
            </div>
          )}

          {(verificationStatus !== "VERIFIED" && verificationStatus !== "PENDING") && (
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" fullWidth className='profession-submit-button' color="primary"
                onClick={handleUpdateProfessionVerificationSubmit}
              >
                Submit
              </Button>
            </div>
          )}

        </DialogContent>
      </Dialog>

      {
        fileLoading && (
          <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )
      }

    </>
  )
}

export default UpdateProfessionVerificationDialog
