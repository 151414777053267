import React, { useState } from 'react'
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./questionandanswer.css";
import QuestionAndAnswer from './QuestionAndAnswer';

const AllQueAndAnsPage = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="left-Menu-Drawer">
                        <Navigation />
                    </div>

                    <div className="questionandanswer-container">

                        <div>
                        <QuestionAndAnswer /> 
                        </div>

                    </div>
                </div>


                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default AllQueAndAnsPage