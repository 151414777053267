import { useState } from 'react';
import { Card, CardContent, CardActions, Typography, Grid, Button, Chip, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { useHistory } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useAPIRequest from "../../API/useApiRequest";
import UrlRoute from "../../API/UrlRoute";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import Constants from "../../MeddyConnectConstant";
import moment from "moment";
import './managejobs.css';

const ManageJobs = ({ postedApplicationsByUser, getPostedApplicationsByUser }) => {
  const history = useHistory();
  const theme = useTheme();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const [jobId, setJobId] = useState('');

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openRemoveJobDialog, setOpenRemoveJobDialog] = useState(false);

  const handleRemoveJobDialogClickOpen = (id) => {
    setOpenRemoveJobDialog(true);
    setJobId(id);
  };

  const handleRemoveJobDialogClickClose = () => {
    setOpenRemoveJobDialog(false);
    setJobId('');
  };

  const handleDeleteApplications = async (id) => {

    setOpenRemoveJobDialog(false);
    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.JOBS_URL}/${id}`,
      method: "DELETE",
      addToast,
    });

    if (data.response === true) {
      setJobId('');
      setLoading(false);
      getPostedApplicationsByUser();
    } else {
      addToast("Something went wrong!", { appearance: "error" });
      setJobId('');
      setLoading(false);
    }

  }

  const handleViewApplicantsFromJob = (id) => {
    history.push(`${Constants.VIEW_JOB_APPLICANTS}/${id}`);
  };

  const handleEditJob = (id) => {
    history.push({
      pathname: Constants.EDIT_POST_A_JOB,
      state: {
        data: id
      }
    })
  };


  return (
    <>
      <Grid container direction="column">
        {postedApplicationsByUser.length > 0 ?
          postedApplicationsByUser.map((element) => {
            return (
              <Grid item xs={12} key={element.id} style={{ marginTop: "16px" }}>
                <Card className='managejobs-card' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid item xs={12} >
                        <Typography variant="h6" align='left' color="text.primary" className='managejobs-title'>
                          {element.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} >
                        <Typography variant="subtitle1" color="text.primary">
                          {element.created ? moment(element.created).format("MMM D, YYYY") : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} >
                        <Typography variant="body2" gutterBottom color="text.primary">
                          <PlaceIcon color="primary" className='managejobs-icon' />
                          {element.city && element.state && element.country ? `${element.city}, ${element.state}, ${element.country}` : element.city || element.state || element.country}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" style={{ margin: "5px 0px" }}>
                      {element.skills.length > 0 && element.skills.map((value, index) => {
                        return (
                          <Grid item style={{ margin: "0px 6px 6px 0px" }} key={index}>
                            <Chip label={value} variant="outlined" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }} />
                          </Grid>
                        );
                      })
                      }
                    </Grid>

                    <Grid container direction="row">
                      <Grid item xs={12} >
                        <Typography variant="body2" color="text.primary" className='managejobs-description' >
                          {element.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions disableSpacing style={{ padding: "0px 8px 8px 8px" }}>
                    <div className='managejobs-btn'>
                      <Button variant="contained" color="primary" className="managejobs-button" onClick={() => handleViewApplicantsFromJob(element.id)}>View Applicants</Button>
                      <Button variant="contained" color="primary" className="managejobs-button" onClick={() => handleEditJob(element.id)}>Edit</Button>
                      <Button variant="contained" className="managejobs-button managejobs-delete-btn" onClick={() => { handleRemoveJobDialogClickOpen(element.id) }}>Delete</Button>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
          :
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                No results found
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>

      <Dialog
        open={openRemoveJobDialog}
        onClose={handleRemoveJobDialogClickClose}
      >
        <DialogTitle>
          MeddyConnect
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="text.primary">
            Are you sure you want to remove it?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveJobDialogClickClose} color="primary" disableRipple>Cancel</Button>
          <Button onClick={() => { handleDeleteApplications(jobId) }} color="primary" disableRipple>
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <YLoader loading={loading} />
    </>
  )
}

export default ManageJobs
