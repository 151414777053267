import React, { useState } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import Constants from "../../MeddyConnectConstant";
import { Typography, Grid } from '@mui/material';
import "./buycategory.css";
import { useHistory } from "react-router-dom";
import { useTheme } from '@mui/material/styles';


const BuySubCategory = (props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const categories = props.location.state.data;

    const selectedCategory = categories[0]?.categoryLink?.rel || undefined;

    const selectedSubCategory = categories[0]?.subcategoryLink || [];

    const title = categories[1]?.title || undefined;

    const handleSubCategoryClick = (subCategory) => {

        const selectedData = {
            category: selectedCategory,
            subCategory: subCategory,
            title: title,
        };

        if (selectedData.title === "Buy Instrument") {

            history.push({
                pathname: Constants.BUY_MEDICAL_INSTRUMENT,
                state: {
                    data: selectedData
                }
            })

        } else if (selectedData.title === "Buy New Instrument") {

            history.push({
                pathname: Constants.BUY_New_MEDICAL_INSTRUMENT,
                state: {
                    data: selectedData
                }
            })

        } else if (selectedData.title === "Buy Used Instrument") {

            history.push({
                pathname: Constants.BUY_Used_MEDICAL_INSTRUMENT,
                state: {
                    data: selectedData
                }
            })
        }

    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="buynew-category-Drawer">
                        <Navigation />
                    </div>

                    <div className="buynew-category-container">

                        <div className='buynew-subcategory-container'>
                            {selectedSubCategory.length > 0 ? (
                                selectedSubCategory.map((element, index) => {
                                    return (
                                        <div className="buynew-subcategory-box" key={index} onClick={() => handleSubCategoryClick(element.rel)} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                            <div style={{ height: "120px", marginBottom: "4px" }}>
                                                <img src={element.href ? element.href : ""} alt="subcategory image" className="subcategory-img" />
                                            </div>
                                            <Typography className='category-list-Name categories_title' align="center" color="text.primary">
                                                {element.rel}
                                            </Typography>
                                        </div>
                                    );
                                })
                            ) : (
                                <Grid container style={{ marginTop: "10px" }}>
                                    <Grid item xs={12}>
                                        <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                            No results found
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                            }
                        </div>
                    </div>

                </div>

                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default BuySubCategory

