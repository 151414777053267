import React, { useState, useEffect, useContext } from "react";
import { Paper, Grid, Button, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Slide } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../Profile/Profile.css";
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../../API/useApiRequest";
import YLoader from "../../components/Loader";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Blogsection = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    // Variables
    const [userId, setUserId] = useState("");
    const [userType, setUserType] = useState("");
    const [blogDetails, setBlogDetails] = useState([]);
    const [userLink, setUserLink] = useState("");
    const [blogId, setBlogId] = useState("");
    const [editUserLink, setEditUserLink] = useState("");

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getUserType } = useUserType();

    // Dialogs
    const [openBlogDialog, setOpenBlogDialog] = React.useState(false);
    const [openEditBlogDialog, setOpenEditBlogDialog] = React.useState(false);

    const handleBlogDialogClickClose = () => {
        setOpenBlogDialog(false);
    };
    const handleBlogDialogClickOpen = () => {
        setOpenBlogDialog(true);
    };

    const handleEditBlogDialogClickClose = () => {
        setOpenEditBlogDialog(false);
    };
    const handleEditBlogDialogClickOpen = (id) => {
        setOpenEditBlogDialog(true);
        getEditBlogDetailsWithId(id);
    };

    // Functions
    const getUserBlogDetails = () => {

        setLoading(true);
        setUserId(currentUser.id);
        setUserType(currentUser.userType);
        setBlogDetails(currentUser.blogDetails);
        setLoading(false);
    }

    useEffect(() => {
        getUserBlogDetails();
    }, [currentUser])

    // Functions for create blog

    const handleAddUserBlogs = (userLink, userType, userId) => {

        const newBlogs = { link: userLink };
        createUserBlog(userType, userId, newBlogs);

    };

    const createUserBlog = async (userType, userId, newBlogs) => {
        setOpenBlogDialog(false);
        const user = getUserType(userType);
        const blogs = [newBlogs]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                blogDetails: blogs,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
            setUserLink('');
        }

    }

    // Functions for edit blog

    const getEditBlogDetailsWithId = (id) => {

        let blog = blogDetails.find(item => item.id === id)

        setBlogId(blog.id);
        setEditUserLink(blog.link.href);

    };

    const handleEditUserBlogs = (editUserLink, blogId, userType, userId) => {

        const editBlogDetails = {
            link: editUserLink,
            id: blogId,
        };
        editUserBlog(userType, userId, editBlogDetails);

    };

    const editUserBlog = async (userType, userId, editBlogDetails) => {

        setOpenEditBlogDialog(false);
        const user = getUserType(userType);
        const blogs = [editBlogDetails]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                blogDetails: blogs,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
        }

    }

    // Functions for delete blog

    const deleteUserBlogs = async (userType, id) => {

        const user = getUserType(userType);

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/blog/${id}`,
            method: "DELETE",
            addToast,
        });

        if (response.status === 200) {
            getUserInfo();
        };

    }

    const validateBlogForm = () => {

        let valid = true;

        if (userLink.trim() === '') {
            addToast('Blog url cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleAddBlogSubmit = () => {

        if (validateBlogForm()) {
            handleAddUserBlogs(userLink, userType, userId);
        }
    };

    const validateEditBlogForm = () => {

        let valid = true;

        if (editUserLink.trim() === '') {
            addToast('Blog url cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleEditBlogSubmit = () => {

        if (validateEditBlogForm()) {
            handleEditUserBlogs(editUserLink, blogId, userType, userId);
        }
    };

    return (
        <>
            {/* ############################ Blog section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleBlogDialogClickOpen} className="profile_btn">
                        Blog
                    </Button>
                </div>

                {blogDetails && blogDetails.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>
                            {value.link.href ?
                                <div className="section_container">
                                    <IconButton>
                                        <AutoStoriesIcon color="primary" fontSize="large" />
                                    </IconButton>
                                    <div className='section_details'>
                                        <a href="#" target="_blank" rel="noreferrer"
                                            style={{ textDecoration: "none", color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                                            {value.link.href}
                                        </a>
                                    </div>
                                    <div className="section_iconbtn_container">
                                        <IconButton onClick={() => handleEditBlogDialogClickOpen(value.id)} className="icon_btn">
                                            <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => deleteUserBlogs(userType, value.id)} className="icon_btn">
                                            <DeleteIcon color="primary" />
                                        </IconButton>
                                    </div>

                                </div>
                                : " "}
                        </Paper>
                    );
                })}
                {/* ############################ Create Blog dialog ######################################### */}
                <Dialog open={openBlogDialog} onClose={handleBlogDialogClickClose} fullScreen={fullScreen} maxWidth="sm" fullWidth TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleBlogDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Blog
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row" spacing={1}>

                            <Grid item xs={12}>
                                <TextField fullWidth
                                    placeholder="Link (e.g. http://example.com)"
                                    name="link"
                                    value={userLink}
                                    onChange={(event) => setUserLink(event.target.value)}
                                    autoComplete="off"
                                    id="link"
                                    variant="outlined"
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={handleAddBlogSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                {/* ############################ Edit Blog dialog ######################################### */}
                <Dialog open={openEditBlogDialog} onClose={handleEditBlogDialogClickClose} fullScreen={fullScreen} maxWidth="sm" fullWidth TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleEditBlogDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Edit Blog
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    placeholder="Link (e.g. http://example.com)"
                                    name="link"
                                    value={editUserLink}
                                    onChange={(event) => setEditUserLink(event.target.value)}
                                    autoComplete="off"
                                    id="link"
                                    variant="outlined"
                                    required
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={handleEditBlogSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>


                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Blogsection
