import React, { useState } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, TextField, InputAdornment, Tabs, Tab } from '@mui/material';
import "./searchuser.css";
import SearchIcon from '@mui/icons-material/Search';
import { useToasts } from "react-toast-notifications";
import AllUserList from "./AllUserList";
import DoctorUserList from "./DoctorUserList";
import HospitalUserList from "./HospitalUserList";
import VendorUserList from "./VendorUserList";
import StudentUserList from "./StudentUserList";
import MedicalStaffUserList from "./MedicalStaffUserList";
import MedicalAssociationUserList from "./MedicalAssociationUserList";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const SearchUser = () => {
    const [loading, setLoading] = useState(true);
    const { addToast } = useToasts();
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div className="search-user-div" >
                    <Grid container spacing={0}
                        className={fullScreen ? "searchbar_with_smallspacing" : "user-searchbar"}
                    >
                        <Grid item xs={12}>
                            <TextField fullWidth
                                placeholder="Search ..."
                                name="usersearchbar"
                                id="usersearchbar"
                                // value={searchTerm}
                                // onChange={(event) => handleSearchResultChange(event.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end" >
                                            < SearchIcon sx={{ color: "#002E6E" }} />
                                        </InputAdornment>,
                                }}

                            />
                        </Grid>
                    </Grid>
                </div>

                <div style={{ display: "flex" }}>

                    <div className="searchuser-drawer">
                        <Navigation />
                    </div>

                    <div className="searchuser-container">

                        <Grid container>
                            <Grid item xs={12}>
                                <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons={false}>
                                    <Tab label="All" />
                                    <Tab label="Doctor" />
                                    <Tab label="Hospital" />
                                    <Tab label="Vendor" />
                                    <Tab label="Medical Student" />
                                    <Tab label="Medical Staff" />
                                    <Tab label="Medical Association" />
                                </Tabs>
                            </Grid>
                        </Grid>
                        {selectedTab === 0 && <AllUserList />}
                        {selectedTab === 1 && <DoctorUserList userList={userList} />}
                        {selectedTab === 2 && <HospitalUserList userList={userList} />}
                        {selectedTab === 3 && <VendorUserList userList={userList} />}
                        {selectedTab === 4 && <StudentUserList userList={userList} />}
                        {selectedTab === 5 && <MedicalStaffUserList userList={userList} />}
                        {selectedTab === 6 && <MedicalAssociationUserList userList={userList} />}

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default SearchUser

const userList = [
    {
        id: 1,
        name: "Rahul Shah",
        userId: 1,
        profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
        speciality: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
    },
    {
        id: 2,
        name: "Anjali Mehta",
        userId: 2,
        profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        speciality: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
    },
    {
        id: 3,
        name: "Rahul Shah",
        userId: 1,
        profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
        speciality: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
    },
    {
        id: 4,
        name: "Anjali Mehta",
        userId: 2,
        profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        speciality: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
    },
    {
        id: 5,
        name: "Rahul Shah",
        userId: 1,
        profilePicture: "https://images.unsplash.com/photo-1611608822772-43a28a830759?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fG1hbiUyMGZhY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=900&q=60",
        speciality: "MCh (Paediatric Surgery), MS (Gen Surgery) ,Pune",
    },
    {
        id: 6,
        name: "Anjali Mehta",
        userId: 2,
        profilePicture: "https://images.unsplash.com/photo-1532074205216-d0e1f4b87368?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Z2lybHMlMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        speciality: "Cataract and Lasik Eye Specialist ,Navi Mumbai",
    },
]



