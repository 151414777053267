import React, { useState } from 'react'
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './storyboardpage.css';
import {
    Box, Tab, Tabs, Grid, Typography, Dialog, DialogTitle, DialogContent, TextField,
    IconButton, DialogActions, FormControl, Button, RadioGroup, Radio, FormControlLabel
} from '@mui/material';
import EventStories from './Events/EventStories';
import Jobs from './Jobs/Jobs';
import AllStories from './AllStories/AllStories';
import BuyNew from './BuyProducts/BuyProduct';
import Shorts from './Shorts/Shorts';
import NewLaunch from './NewLaunch/NewLaunch';
import QuestionAndAnswer from '../Miscellaneous/QAndA/QuestionAndAnswer';
import PostPage from '../Miscellaneous/Post/PostPage';
import Trainings from './Trainings/Trainings';
import CloseIcon from '@mui/icons-material/Close';

const StoryBoardPage = () => {
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const showAd = useMediaQuery(theme.breakpoints.up('lg'));
    const [reportThisAdDialogOpen, setReportThisAdDialogOpen] = useState(false);
    const [value, setValue] = useState('female');

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleReportThisAdDialogOpen = () => {
        setReportThisAdDialogOpen(true);
    };

    const handleReportThisAdDialogClose = () => {
        setReportThisAdDialogOpen(false);
    };

    const handleRadioBtnChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="Drawer">
                        <Navigation />
                    </div>

                    <div className="storyBoard-Container">

                        <Typography variant="h6" color="text.primary" align='center' style={{marginTop:"30px"}}>
                            Coming soon...
                        </Typography>

                        {/* <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <Tabs value={selectedTab}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons={false}
                                        className='storyBoard-tabs'
                                    >
                                        <Tab label="All" />
                                        <Tab label="Buy Product" />
                                        <Tab label="New Launch" />
                                        <Tab label="Events" />
                                        <Tab label="Trainings" />
                                        <Tab label="Jobs" />
                                        <Tab label="Stories" />
                                        <Tab label="Q&A" />
                                    </Tabs>
                                </Box>
                            </Grid>
                        </Grid>

                        <div className='storyBoard-content'>
                            {selectedTab === 0 && <AllStories />}
                            {selectedTab === 1 && <BuyNew />}
                            {selectedTab === 2 && <NewLaunch />}
                            {selectedTab === 3 && <EventStories />}
                            {selectedTab === 4 && <Trainings />}
                            {selectedTab === 5 && <Jobs />}
                            {selectedTab === 6 && <PostPage />}
                            {selectedTab === 7 && <QuestionAndAnswer />}

                        </div> */}

                    </div>

                    {/* {showAd &&
                        <div className='advertisement-container'>
                            <div className='advertisement-img-container'>
                                {adImages.map((value) => {
                                    return (
                                        <Grid container style={{ position: "relative" }}>
                                            <Grid item>
                                                <img src={value.imgUrl} alt="advertisement img" className='ad-img' />
                                            </Grid>
                                            <Grid item className='report-ad' onClick={handleReportThisAdDialogOpen}>
                                                <Typography variant="subtitle2" >
                                                    Report this ad
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </div>
                        </div>
                    } */}

                    {/* ############################ Report this ad dialog ######################################### */}
                    {/* <Dialog open={reportThisAdDialogOpen} onClose={handleReportThisAdDialogClose} fullScreen={fullScreen} fullWidth>
                        <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                            <IconButton
                                edge="start"
                                onClick={handleReportThisAdDialogClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon style={{ color: "white" }} />
                            </IconButton>
                            Report Ad
                        </DialogTitle>
                        <DialogContent>

                            <Grid container direction="row" rowSpacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" align='left'>
                                        Why are you reporting this ad ?
                                    </Typography>
                                    <FormControl className='form-control-radio'>
                                        <RadioGroup
                                            name="report-this-ad-buttons-group"
                                            value={value}
                                            onChange={handleRadioBtnChange}
                                        >
                                            <FormControlLabel value="inappropriate" control={<Radio />} label="Inappropriate" />
                                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth className='form-control-radio'
                                        multiline
                                        required
                                        name="posttextarea"
                                        id="posttextarea"
                                        // value={postText}
                                        // onChange={(event) => setPostText(event.target.value)}
                                        placeholder="Reason"
                                        autoComplete="off"
                                        minRows={4}
                                        variant="outlined"
                                    />
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" fullWidth className='report-ad-btn'>Send</Button>
                        </DialogActions>
                    </Dialog> */}


                </div>


                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default StoryBoardPage

const adImages = [
    {
        id: 1,
        imgUrl: "https://images.unsplash.com/photo-1526930382372-67bf22c0fce2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8bWVkaWNhbCUyMGVxdWlwbWVudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=900&q=60"
    },
    {
        id: 2,
        imgUrl: "https://images.unsplash.com/photo-1565647946321-a146ac24a220?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fG1lZGljYWwlMjBlcXVpcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=900&q=60"
    },
]