import React, { useState } from "react";
import { Typography, Button } from '@mui/material';
import "./searchuser.css";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { FaStethoscope } from "react-icons/fa";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";

const MedicalAssociationUserList = ({ userList }) => {
  const { addToast } = useToasts();
  const theme = useTheme();

  return (
    <>
      <div className="searchuser-details">

        {userList.map((value) => {
          return (
            <div className="searchuser-details-container" key={value.id}>
              <img src={value.profilePicture} alt='user image' className='searchuser-Picture' />
              <div className="searchuser-info">

                <div className="userwith-icon-container">
                  <Typography variant="subtitle1" className='searchuser-Name'>
                    {value.name}
                  </Typography>
                  <Typography variant="subtitle2" className='searchuser-Name medical-association-text'>
                    (IMA)
                  </Typography>
                  
                </div>

                <Typography variant="body2" >
                  {value.speciality}
                </Typography>

                <div className="searchuser-btn-container" >
                  {/* <Button variant="contained" startIcon={<PhoneIcon />} className="searchuser-btn" fullWidth >
                    call
                  </Button> */}

                  <Button variant="contained" startIcon={<ChatBubbleOutlineIcon />} className="searchuser-btn" fullWidth>
                    chat
                  </Button>
                </div>

              </div>

            </div>
          );
        })}

      </div>
    </>
  )
}

export default MedicalAssociationUserList