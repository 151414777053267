import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardActions, CardMedia, Typography, Grid } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import './alleventcards.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventDialog from './EventDialog';

const AllEventCards = () => {
    const [loading, setLoading] = useState(true);
    const { addToast } = useToasts();
    const [eventDialogOpen, setEventDialogOpen] = useState(false);

    const handleEventDialogOpen = () => {
        setEventDialogOpen(true);
    };

    const handleEventDialogClose = () => {
        setEventDialogOpen(false);
    };
   
    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="leftside--Drawer">
                        <Navigation />
                    </div>

                    <div className="eventCards-container">
                                                                                                                                                                                                  
                        <Grid container direction="row" spacing={2} className='event-card-container'>
                            {data.map((element) => {
                                return (
                                    <Grid item xs={12} sm={6} key={element.id}>
                                        <Card elevation={3} className='event-card' onClick={handleEventDialogOpen} >
                                            <CardMedia
                                                component="img"
                                                height="180"
                                                image={element.eventimage}
                                                alt="Event images"
                                            />
                                            <CardContent>
                                                <Grid container direction="row" rowSpacing={1}>
                                                    <Grid item xs={12} >
                                                        <Typography variant="h6" align='left' className='event_title_bold'>
                                                            {element.eventname}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Typography variant="body2" className='event-description' >
                                                            {element.eventDescription}
                                                        </Typography>
                                                    </Grid>                                                  
                                                </Grid>

                                                <div className="events">
                                                    <div className="event">
                                                        <div className="event-Time-and-Place-info">
                                                            <Typography variant="subtitle2">
                                                                <AccessTimeIcon className='storyboard-icon' />
                                                                {/* Friday 1-7 pm */}
                                                                {element.eventtime}
                                                            </Typography>
                                                            <Typography variant="subtitle2" className='event-address'>
                                                                <PlaceIcon className='storyboard-icon address-icon' />
                                                                {/* Radisson Blu, Dwarka */}
                                                                {element.address}
                                                            </Typography>
                                                        </div>

                                                        <div className="event-date-info">
                                                            <Typography variant="subtitle2">
                                                            {element.month}
                                                            </Typography>
                                                            <Typography variant="subtitle2" >
                                                            {element.date}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })
                            }
                        </Grid>

                        <div>
                            <EventDialog eventDialogOpen={eventDialogOpen} handleEventDialogClose={handleEventDialogClose} />
                        </div>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default AllEventCards

const data =
    [
        {
            id: 0,
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "NYC Food Festival",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: "Central Park Manhattan ,New York City.",
            eventtime: "9.00 AM To 5.00 PM",
            month: "Oct",
            date:"29"
        },
        {
            id: 1,
            eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
            eventname: "Charity Events",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: " Radisson Blu, Dwarka",
            eventtime: "Friday 1-7 pm",
            month: "Dec",
            date:"10"
        },
        {
            id: 2,
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "NYC Food Festival",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: "Central Park Manhattan ,New York City.",
            eventtime: "9.00 AM To 5.00 PM",
            month: "Apr",
            date:"02"
        },
        {
            id: 3,
            eventimage: "https://images.unsplash.com/photo-1569863959165-56dae551d4fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGV2ZW50c3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
            eventname: "Charity Events",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.",
            address: "Prof.Ramkrishna More A.C.S College Akurdi Pune",
            eventtime: "8.00 AM To 3.00 PM",
            month: "Jun",
            date:"20"
        },

    ];

