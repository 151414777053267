import React, { useState, useEffect } from 'react'
import {
  Box, Button
} from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./viewparticipants.css";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'applicantsName',
    headerName: 'Applicants name',
    width: 150,
    editable: true,
  },
  {
    field: 'applydate',
    headerName: 'Apply Date',
    width: 150,
    editable: true,
  },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

const rows = [
  { id: 1, applicantsName: 'Nishikant Ambhore', applydate: "Feb 24, 2023"},
  { id: 2, applicantsName: 'Sushil Salve', applydate: "Jan 24, 2022"},
  { id: 3, applicantsName: 'Ganesh Jadhav', applydate: "May 24, 2021" },
  { id: 4, applicantsName: 'Akshay Gandal', applydate: "Jan 24, 2023"},
  { id: 5, applicantsName: 'Praneet Metange', applydate: "Dec 05, 2021"},
  { id: 6, applicantsName: "Rohan Dayal", applydate: "Nov 16, 2023"},
  { id: 7, applicantsName: 'Rohit Waghmare', applydate: "Mar 08, 2022" },
  { id: 8, applicantsName: 'Mangesh Aakhade', applydate: "Apr 29, 2022" },
];


const ViewParticipantsFromArchiveTraining = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <div style={{ backgroundColor: "#fff", height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="manage-training-table-Drawer">
            <Navigation />
          </div>

          <div className="manage-training-table-container">

            <Box sx={{ height: "auto", width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pdateSize={5}
                rowsPerPdateOptions={[5]}
                // checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>

          </div>
        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ViewParticipantsFromArchiveTraining

