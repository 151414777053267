import React, { useState, useEffect } from "react";
import { Chip, Dialog, DialogTitle, DialogContent, Slide, IconButton, Typography, Avatar, Grid } from '@mui/material';
import { useToasts } from "react-toast-notifications";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FaRupeeSign } from 'react-icons/fa';
import useAPIRequest from "../../API/useApiRequest";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PlaceIcon from '@mui/icons-material/Place';
import { FaRegFilePdf } from "react-icons/fa";
import ImageIcon from '@mui/icons-material/Image';
import moment from "moment";
import PDFViewer from "../../components/PDFViewer";
import "./jobapplicationsdetails.css";
import UrlRoute from "../../API/UrlRoute";
import CurrencyFormat from '../../utils/CurrencyFormat';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ReadMore from "../../components/ReadMore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobApplicationsDetails = ({ openJobApplicationsDetailsDialog, handleJobApplicationsDetailsDialogClickClose, jobApplicationId, jobId }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addToast } = useToasts();

  const [applicationDetails, setApplicationDetails] = useState({});
  const [jobDetails, setJobDetails] = useState({});
  const [combinedApplicationData, setCombinedApplicationData] = useState({});
  const [uploadedFile, setUploadedFile] = useState({ contentType: null, content: null });
  const [fileType, setFileType] = useState("");

  const [fileLoading, setFileLoading] = useState(false);

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

  const handleDocumentDialogClickOpen = (type) => {
    setOpenDocumentDialog(true);
    setFileType(type);
  };

  const handleDocumentDialogClickClose = () => {
    setOpenDocumentDialog(false);
  };

  useEffect(() => {
    if (openJobApplicationsDetailsDialog) {
      getJobApplicationDetails(jobId, jobApplicationId);
      getJobDetails(jobId);
    }
  }, [openJobApplicationsDetailsDialog]);


  const getJobApplicationDetails = async (jobId, jobApplicationId) => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `jobs/${jobId}/application/${jobApplicationId}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setApplicationDetails(data);

      setUploadedFile({ contentType: data.contentType, content: data.content });
      setFileLoading(false);
    } else {
      setFileLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const getJobDetails = async (jobId) => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.JOBS_URL}/${jobId}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setJobDetails(data);
      setFileLoading(false);
    } else {
      setFileLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  useEffect(() => {

    const combinedData = {
      application: applicationDetails,
      job: jobDetails
    };
    setCombinedApplicationData(combinedData)

  }, [applicationDetails, jobDetails])

  return (
    <>
      {/* ############################ Show apply for job dialog ######################################### */}
      <Dialog
        open={openJobApplicationsDetailsDialog}
        onClose={handleJobApplicationsDetailsDialogClickClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
          <IconButton
            edge="start"
            onClick={handleJobApplicationsDetailsDialogClickClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          {combinedApplicationData.application?.jobTitle}
        </DialogTitle>
        <DialogContent>

          <div className="company">

            <div className="company-container">
              <Avatar src={combinedApplicationData.application?.postedProfileLink?.href ?? ""} color="primary" aria-label="companylogo" className='jobapplications-img' />
              <div className="company-info">
                <Typography variant="subtitle1" align="left" style={{ fontWeight: "bold" }}>
                  {combinedApplicationData.application?.postedUserName}
                </Typography>
                <Typography variant="subtitle2" align="left">
                  Posted on : {combinedApplicationData.application && combinedApplicationData.application?.created ? moment(combinedApplicationData.application.created).format("MMM D, YYYY") : "N/A"}
                </Typography>
              </div>
            </div>

            {combinedApplicationData.job && combinedApplicationData.job.skills && (
              <Grid container direction="row">
                {combinedApplicationData.job.skills.length > 0 && combinedApplicationData.job.skills.map((value, index) => {
                  return (
                    <Grid item style={{ margin: "0px 6px 6px 0px" }} key={index}>
                      <Chip label={value} variant="outlined" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }} />
                    </Grid>
                  );
                })
                }
              </Grid>
            )}

            {combinedApplicationData.job && combinedApplicationData.job.tags && (
              <Grid container direction="row" >
                {combinedApplicationData.job.tags.length > 0 && combinedApplicationData.job.tags.map((item, index) => {
                  return (
                    <Grid item style={{ margin: "0px 10px 6px 0px" }} key={index}>
                      <Typography variant="subtitle1" color="text.primary" >
                        {item}
                      </Typography>
                    </Grid>
                  );
                })
                }
              </Grid>
            )}

            <Grid container direction="row" >
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.primary">
                  <span className='description-title'>Job Description :</span>
                </Typography>
              </Grid>

              {combinedApplicationData.job && combinedApplicationData.job.description ? (
                <Grid item xs={12}>
                  {combinedApplicationData.job.description.length < 300 ?
                    <Typography align="left" variant='subtitle1' color="text.primary">
                      {combinedApplicationData.job.description}
                    </Typography>
                    :
                    <Typography align="left" variant='subtitle1' color="text.primary">
                      <ReadMore>
                        {combinedApplicationData.job.description}
                      </ReadMore>
                    </Typography>
                  }
                </Grid>)
                :
                "No description"
              }
            </Grid>

            <div className="job-details-container">
              <div className="job-details-withicon">
                <Avatar className='job-avatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                  <FaRupeeSign className='job-details-reacticon' />
                </Avatar>
                <Typography variant="subtitle2" color="text.primary">
                  Salary
                </Typography>
                <Typography variant="subtitle1" >
                  {combinedApplicationData.job && combinedApplicationData.job.expectedSalary ?
                    <CurrencyFormat currencyPrice={combinedApplicationData.job.expectedSalary} />
                    :
                    "N/A"
                  }
                </Typography>
              </div>

              <div className="job-details-withicon">
                <Avatar className='job-avatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                  <AccessAlarmsIcon className='job-details-material-ui-Icon' />
                </Avatar>
                <Typography variant="subtitle2" color="text.primary" >
                  Job Type
                </Typography>
                <Typography variant="subtitle1" >
                  {combinedApplicationData.job && combinedApplicationData.job.jobType === "PART_TIME" ? "Part Time" : "Full Time"}
                </Typography>
              </div>

              <div className="job-details-withicon">
                <Avatar className='job-avatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                  <PlaceIcon className='job-details-material-ui-Icon' />
                </Avatar>
                <Typography variant="subtitle2" color="text.primary">
                  City
                </Typography>
                <Typography variant="subtitle1" color="text.primary">
                  {combinedApplicationData.job && combinedApplicationData.job.city ? combinedApplicationData.job.city : "N/A"}
                </Typography>
              </div>

            </div>

            <Grid container >
              <Grid item xs={12}>
                <Typography className='description-title' color="text.primary">
                  Work Experience :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.primary">
                  {combinedApplicationData.application && combinedApplicationData.application.coverLetter ? combinedApplicationData.application.coverLetter : "N/A"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="column" >
              <Grid item xs={12}>
                <Typography className='description-title' color="text.primary">
                  Uploaded Resume :
                </Typography>
              </Grid>
            </Grid>

            {
              uploadedFile && uploadedFile.contentType && uploadedFile.content ?
                <div className="applicant-uploaded-container">
                  {
                    (uploadedFile.contentType.startsWith('image') || uploadedFile.contentType.startsWith('img')) ? (
                      <IconButton onClick={() => handleDocumentDialogClickOpen('image')} disableRipple>
                        <ImageIcon style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' />
                      </IconButton>
                    )
                      : uploadedFile.contentType === 'application/pdf' ? (
                        <IconButton onClick={() => handleDocumentDialogClickOpen('pdf')} disableRipple>
                          <FaRegFilePdf style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' />
                        </IconButton>
                      )
                        : null
                  }
                </div>
                :
                <Typography variant='subtitle1' align="center" color="text.primary">
                  No resume uploaded
                </Typography>
            }

            <Dialog
              open={openDocumentDialog}
              onClose={handleDocumentDialogClickClose}
              fullScreen={fullScreen}
              maxWidth="md"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleDocumentDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                View Document
              </DialogTitle>
              <DialogContent style={{ padding: "0px" }}>

                {fileType === 'image' ? (
                  <img src={`data:${uploadedFile.contentType};base64,${uploadedFile.content}`} alt="uploaded image" style={{ width: "100%", height: "100%" }} />
                ) : fileType === 'pdf' ? (
                  <PDFViewer pdfData={`data:${uploadedFile.contentType};base64,${uploadedFile.content}`} />
                ) : null}

              </DialogContent>
            </Dialog>

          </div>

        </DialogContent>
      </Dialog >

      {
        fileLoading && (
          <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )
      }

    </>
  )
}

export default JobApplicationsDetails
