import React from 'react'
import { Dialog, DialogContent, IconButton, Grid, Slide } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ImageEnlargeDialog = ({ enlargeImgDialog, handleEnlargeImgDialogClose, clickedImage }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {/* ############################ selected image enlarge dialog ######################################### */}
            <Dialog
                open={enlargeImgDialog}
                onClose={handleEnlargeImgDialogClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent style={{ padding: "0px", overflow: "hidden" ,display:'flex', justifyContent:"center", alignItems:"center" }}>

                    <Grid container direction="column" spacing={0} justifyContent="center" alignItems="center">
                        <Grid item>
                            <IconButton
                                edge="start"
                                onClick={handleEnlargeImgDialogClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                }}
                            >
                                <CloseIcon style={{ color: "black" }} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <img src={clickedImage} alt="clicked images" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default ImageEnlargeDialog