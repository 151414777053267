import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import {
    Grid, Select, Typography, FormLabel, RadioGroup, Radio,
    Chip, TextField, Paper, Button, Autocomplete,
    FormControlLabel, InputLabel, MenuItem, FormControl,
} from '@mui/material';
import { YContext } from "../../Context/YContext";
import { useToasts } from "react-toast-notifications";
import { styled } from '@mui/material/styles';
import "./organizetraining.css";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const OrganizeTraining = () => {
    const [loading, setLoading] = useState(true);
    const { addToast } = useToasts();
    const { currentUser } = useContext(YContext);
    const [pincodes, setPincodes] = useState(currentUser.pinCode);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [trainingType, setTrainingType] = useState('');
    const [specialityData, setSpecialityData] = React.useState([]);
    const [specialityTag, setSpecialityTag] = useState(null);
    const [value, setValue] = useState('');

    // useEffect(() => {
    //     getReferenceData();
    // }, []);

    // const getReferenceData = async () => {
    //     const countryOptions = await getData.getCountry(addToast);
    //     if (countryOptions) {
    //         // setCountry("");
    //         setCountryChoices(countryOptions);
    //     }
    // };

    // const getStates = async (country) => {

    //     if (country && country != "") {
    //         let stateOptions = [];
    //         stateOptions = await getData.getStates(country, addToast);
    //         if (stateOptions) {
    //             // setState("");
    //             setStateChoices(stateOptions);
    //         }
    //     }
    // };

    // const getCity = async (country, state) => {

    //     if (country && country != "" && state && state != "") {
    //         let cityOptions = [];
    //         cityOptions = await getData.getCities(country, state, addToast);
    //         if (cityOptions) {
    //             // setCity("");
    //             setCityChoices(cityOptions);
    //         }
    //     }
    // };

    const handleSpecialityChange = (event, newValue) => {
        setSpecialityTag(newValue);
        handleAddSpecialityTag(newValue)
    };

    const handleChange = (event) => {
        setTrainingType(event.target.value);
    };

    const handleDelete = (chipname) => () => {
        setSpecialityData(
            specialityData.filter(chips =>
                chips.suitableFor !== chipname
            )
        );
    };

    const handleAddSpecialityTag = (newSpeciality) => {
        setSpecialityData([...specialityData, { suitableFor: newSpeciality }]);
    };

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="training-drawer">
                        <Navigation />
                    </div>

                    <div className="organize-training-container">

                        <div>
                            <Paper elevation={2} className="organize-training-paper-container">
                                <Grid container direction="row" spacing={0.5} >
                                    <Grid item xs={12}>
                                        <TextField fullWidth
                                            label="Training Title"
                                            name="trainingtitle"
                                            required
                                            // value={productName}
                                            // onChange={(event) => setProductName(event.target.value)}
                                            autoComplete="off"
                                            id="trainingtitle"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "-8px" }}>
                                        <FormControl style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <FormLabel id="training-type-radio-btngroup" className='radio-btn-label' style={{color:"#000000"}}>Training Type ?</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="training-type-radio-btngroup"
                                                name="training-type-radio-btn"
                                                value={trainingType}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="online" control={<Radio />} label="Online" />
                                                <FormControlLabel value="offline" control={<Radio />} label="Offline" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Training Details :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className='trainingeditor-container'>
                                        {/* <ReactQuill theme="snow" modules={modules} value={value} onChange={setValue} className='training-texteditor' /> */}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField fullWidth
                                            label="Seating Capacity"
                                            name="seatingcapacity"
                                            required
                                            // value={productName}
                                            // onChange={(event) => setProductName(event.target.value)}
                                            autoComplete="off"
                                            id="seatingcapacity"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField fullWidth
                                            label="Training Cost (&#8377;)"
                                            name="trainingcost"
                                            required
                                            // value={productName}
                                            // onChange={(event) => setProductName(event.target.value)}
                                            autoComplete="off"
                                            id="trainingcost"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            freeSolo
                                            id="specialityautocomplete"
                                            disableClearable
                                            options={SpecialityOptions.map((option) => option.title)}
                                            value={specialityTag}
                                            onChange={handleSpecialityChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Speciality"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {specialityData.length > 0 &&
                                        <Grid container direction="column">
                                            <Grid container direction="row" style={{ padding: "10px", marginTop: "10px" }}>

                                                <Grid item >
                                                    <Paper elevation={0}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            flexWrap: 'wrap',
                                                            listStyle: 'none',
                                                            backgroundColor: 'transparent',
                                                            p: 0.5,
                                                            m: 0,
                                                        }}
                                                        component="ul"
                                                    >
                                                        {specialityData.map((data, index) => {
                                                            return (
                                                                <ListItem key={index}>
                                                                    <Chip
                                                                        label={data.suitableFor}
                                                                        onDelete={data.suitableFor === '' ? undefined : handleDelete(data.suitableFor)}

                                                                    />
                                                                </ListItem>
                                                            );

                                                        })
                                                        }
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {trainingType === "online" ?
                                        <Grid item xs={12} >
                                            <TextField fullWidth
                                                label="Meeting Link"
                                                name="traininglink"
                                                // value={brandName}
                                                // onChange={(event) => setBrandName(event.target.value)}
                                                autoComplete="off"
                                                id="traininglink"
                                                variant="outlined"
                                                required
                                            />
                                        </Grid>
                                        :
                                        <>
                                            <Grid item xs={12} style={{ marginTop: "-6px" }}>
                                                <TextField fullWidth
                                                    label="Address"
                                                    name="address"
                                                    required
                                                    // value={productName}
                                                    // onChange={(event) => setProductName(event.target.value)}
                                                    autoComplete="off"
                                                    id="address"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-dialog-select-label">
                                                        Country
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="country-label"
                                                        id="country-picker"
                                                        label="Select Country"
                                                        value={country}
                                                        onChange={(event) => {
                                                            setCountry(event.target.value);
                                                            setStateChoices([]);
                                                            setCityChoices([]);
                                                            getStates(event.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value={""} selected>
                                                            Select Country
                                                        </MenuItem>
                                                        {Array.isArray(countryChoices) &&
                                                            countryChoices.map((choice) => {
                                                                return (
                                                                    <MenuItem value={choice}>{choice}</MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-dialog-select-label">
                                                        State
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="state-label"
                                                        id="state-picker"
                                                        label="Select State"
                                                        value={state}
                                                        onChange={(event) => {
                                                            setState(event.target.value);
                                                            setCityChoices([]);
                                                            getCity(country, event.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value={""}>Select State</MenuItem>
                                                        {Array.isArray(stateChoices) &&
                                                            stateChoices.map((choice) => {
                                                                return (
                                                                    <MenuItem value={choice}>{choice}</MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-dialog-select-label">
                                                        City
                                                    </InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="city-label"
                                                        id="city-picker"
                                                        label="Select City"
                                                        value={city}
                                                        onChange={(event) => setCity(event.target.value)}
                                                    >
                                                        <MenuItem value={""}>Select City</MenuItem>
                                                        {Array.isArray(cityChoices) &&
                                                            cityChoices.map((choice) => {
                                                                return (
                                                                    <MenuItem value={choice}>{choice}</MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </Grid> */}
                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth
                                                    label="Pincode"
                                                    name="pincode"
                                                    value={pincodes}
                                                    onChange={(event) => setPincodes(event.target.value)}
                                                    inputProps={{ maxLength: 6, type: "tel" }}
                                                    autoComplete="off"
                                                    id="pincode"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </>
                                    }
                                </Grid>

                                <div>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button variant="contained" fullWidth className='organize-training-button'>Organize Training</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </div>

                    </div>
                </div>
                <YLoader loading={loading} />
            </div >
        </>
    )
}

export default OrganizeTraining

const SpecialityOptions = [
    { title: 'Radiology', id: 0 },
    { title: 'Orthology', id: 1 },
    { title: 'Pathology', id: 2 },
    { title: 'Surgeon', id: 3 },
    { title: 'Dentist', id: 4 },
    { title: "Neurology", id: 5 },
    { title: 'Pediatrics', id: 6 },
]