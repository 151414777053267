import React, { useState, useRef, useEffect } from "react";
import {
  Container, Grid, Paper, TextField, Typography, InputAdornment, Button, InputLabel, MenuItem, FormControlLabel, FormLabel, RadioGroup, Radio,
  Avatar, Link, Dialog, DialogContent, DialogActions, Slide, DialogTitle, IconButton, FormControl, Select
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import "./SignupForm.css";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import DialpadIcon from "@mui/icons-material/Dialpad";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { fileCompressor } from "../components/FileCompressor";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import HttpsIcon from "@mui/icons-material/Https";
import useAPIRequest from "../API/useApiRequest";
import userPublicAPIRequest from "../API/useApiPublicRequest"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { YContext } from "../Context/YContext";
import useGetData from '../API/useGetData';
import YLoader from "../components/Loader";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useTokenService from "../utils/useTokenService";
import Constants from "../MeddyConnectConstant";
import UrlRoute from "../API/UrlRoute";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const VendorUserSignup = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  // Variables
  const [profile, setProfile] = useState({ contentType: null, content: null });
  const [userData, setUserData] = useState({
    userId: "",
    countryCode: "",
    contactNo: "",
  });
  const [imageURL, setImageURL] = useState("");
  const inputFile = useRef(null);
  const [vendorName, setVendorName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [emailId, setEmailId] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [otp, setOtp] = useState("");
  const [otpId, setOtpId] = useState("");
  const [vendorType, setVendorType] = useState('');

  // Services
  const { apiRequests } = useAPIRequest();
  const { apiPublicRequests } = userPublicAPIRequest();
  const { getCountry, getStates, getCity } = useGetData();
  const { setCurrentUser, setIsAuthenticated } = React.useContext(YContext);
  const { getOrGenerateToken, saveCredentials } = useTokenService();

  // Dialogs
  const [openMobileOTPDialog, setOpenMobileOTPDialog] = useState(false);

  const handleMobileOTPDialogClickOpen = () => {
    setOpenMobileOTPDialog(true);
  };

  const handleMobileOTPDialogClickClose = () => {
    setOpenMobileOTPDialog(false);
  };

  const handleVendorTypeChange = (event) => {
    setVendorType(event.target.value);
  };

  useEffect(() => {
    getReferenceData();
  }, []);

  const getReferenceData = async () => {
    const countryOptions = await getCountry(addToast);
    if (countryOptions) {
      setCountry("");
      setCountryChoices(countryOptions);
    }
  };

  const getStatesData = async (country) => {
    if (country && country != "") {
      let stateOptions = [];
      stateOptions = await getStates(country, addToast);
      if (stateOptions) {
        setState("");
        setStateChoices(stateOptions);
      }
    }
  };

  const getCityData = async (country, state) => {
    if (country && country != "" && state && state != "") {
      let cityOptions = [];
      cityOptions = await getCity(country, state, addToast);
      if (cityOptions) {
        setCity("");
        setCityChoices(cityOptions);
      }
    }
  };

  const imagePicker = async (event) => {
    try {
      const img = event.target.files[0];

      if (img) {
        let fileName = img.name;
        let idxDot = fileName.lastIndexOf(".") + 1;
        let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
          // Show loader while processing
          setLoading(true);

          let url = URL.createObjectURL(img);
          let imageData = await fileCompressor(img, addToast);

          if (imageData) {
            let contentType =
              extFile.toLowerCase() === "png" ? "image/png" : "image/jpeg";

            setProfile({ contentType: contentType, content: imageData });
            setImageURL(url);
          } else {
            addToast("An error occurred while compressing the image", {
              appearance: "error",
            });
          }

          // Hide loader after processing
          setLoading(false);
        } else {
          addToast("Please upload valid(jpeg/png) image!!!", {
            appearance: "error",
          });
        }
      }

    } catch (error) {
      addToast("An error occurred while processing the image", {
        appearance: "error",
      });
      // Hide loader in case of error
      setLoading(false);
    }

  };

  const handleVendorSignupSubmit = () => {

    if (validateVendorSignupForm()) {
      submitVendor();
    }

  };

  const validateVendorSignupForm = () => {

    let valid = true;

    if (vendorName.trim() === '') {
      addToast('Vendor Name cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      const trimmedName = vendorName.trim();
      // Assuming a valid full name is required
      const fullNameRegex = /^(?:[A-Z][a-z]*\.? ?)+[A-Z][a-z]*(?: [A-Z][a-z]*)*$/;

      if (!fullNameRegex.test(trimmedName)) {
        addToast('Invalid Vendor Name. Please enter a valid name.', { appearance: 'error' });
        valid = false;
      }
    }

    if (!vendorType) {
      addToast('Please select whether you are a Manufacturer or Distributor', { appearance: 'error' });
      valid = false;
    }

    if (mobileNo.trim() === '') {
      addToast('WhatsApp number cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      // Check if mobileNo contains only numbers
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(mobileNo)) {
        addToast('Invalid WhatsApp number. Please enter only numbers.', { appearance: 'error' });
        valid = false;
      } else if (mobileNo.length !== 10) {
        addToast('WhatsApp number must be 10 digits long', { appearance: 'error' });
        valid = false;
      }
    }

    if (emailId.trim() === '') {
      addToast('Email address cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      // Check for a valid email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(emailId)) {
        addToast('Invalid email address. Please enter a valid email.', { appearance: 'error' });
        valid = false;
      }
    }

    if (password.trim() === '') {
      addToast('Password cannot be empty', { appearance: 'error' });
      valid = false;
    } else {

      const passwordRegex = /^(.*?)$/;

      if (!passwordRegex.test(password)) {
        addToast('Invalid password format', { appearance: 'error' });
        valid = false;
      }

    }

    if (websiteLink.trim() !== '') {
      // Check for a valid URL format
      const urlRegEx = /^(https?:\/\/)?(www\.)?([-a-z0-9]{1,63}\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\.[a-z]{2,6}(\/[-\w@+\.~#?&/=%]*)?$/;

      if (!urlRegEx.test(websiteLink)) {
        addToast('Invalid website link. Please enter a valid URL.', { appearance: 'error' });
        valid = false;
      }
    }

    if (address.trim() === '') {
      addToast('Address cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      // Assuming a valid address format is required
      const addressRegex = /^[\w\s.,'’\-()&@#:;/\\+=]*$/;

      if (!addressRegex.test(address)) {
        addToast('Invalid address. Please enter a valid address.', { appearance: 'error' });
        valid = false;
      }
    }

    if (country.trim() === '') {
      addToast('Country cannot be empty', { appearance: 'error' });
      valid = false;
    }

    if (state.trim() === '') {
      addToast('State cannot be empty', { appearance: 'error' });
      valid = false;
    }

    if (city.trim() === '') {
      addToast('City cannot be empty', { appearance: 'error' });
      valid = false;
    }

    if (pinCode.trim() === '') {
      addToast('PinCode cannot be empty', { appearance: 'error' });
      valid = false;
    } else {
      // Check if pincodes contains only numbers
      const numericRegex = /^[0-9]+$/;

      if (!numericRegex.test(pinCode)) {
        addToast('Invalid PinCode. Please enter only numbers.', { appearance: 'error' });
        valid = false;
      } else if (pinCode.length !== 6) {
        addToast('PinCode must be 6 digits long', { appearance: 'error' });
        valid = false;
      }
    }

    return valid;
  };

  const submitVendor = async () => {
    try {
      !(await checkUserExists())
        ? submitSignupData()
        : addToast("User already exist!!!", { appearance: "error" });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const checkUserExists = async () => {
    setLoading(true);

    const { response, data, error } = await apiPublicRequests({
      endPoint: UrlRoute.USER_EXISTS_URL,
      method: "POST",
      body: {
        countryCode: "+91",
        contactNo: mobileNo,
        email: emailId,
      },
      addToast,
    });
    setLoading(false);
    if (data.response) {
      return data;
    }
    return false;
  };

  const submitSignupData = async () => {
    setLoading(true);
    const { response, data, error } = await apiPublicRequests({
      endPoint: UrlRoute.CREATE_VENDOR_URL,
      method: "POST",
      body: {
        name: vendorName.trim(),
        countryCode: "+91",
        contactNo: mobileNo,
        email: emailId,
        vendorType: vendorType,
        password: password,
        websiteLink: websiteLink,
        country: country,
        state: state,
        city: city,
        address: address,
        pinCode: pinCode,
        profile: profile
      },
      addToast,
    });
    if (data) {
      setLoading(false);
      handleMobileOTPDialogClickOpen();
      const userDetails = {
        countryCode: "+91",
        contactNo: data.contactNo,
      };
      setUserData(userDetails);
      generateOTP(userDetails);
      saveCredentials(countryCode, mobileNo, password);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }
  };

  const getUser = async () => {
    const { data } = await apiRequests({
      endPoint: UrlRoute.USER_INFO_URL,
      method: "GET",
      addToast,
    });
    return data;
  };

  const generateOTP = async (userData) => {
    setLoading(true);
    setOtp("");
    const { data } = await apiPublicRequests({
      endPoint: UrlRoute.GENERATE_OTP_URL,
      method: "POST",
      body: {
        countryCode: "+91",
        contactNo: mobileNo,
      },
      addToast,
    });
    if (data) {
      setOtpId(data.id);
      setUserData({ userId: data.userId, countryCode: data.countryCode, contactNo: data.contactNo })
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }
  };

  const validateOTP = async () => {
    setLoading(true);
    const { data } = await apiPublicRequests({
      endPoint: UrlRoute.VALIDATE_OTP_URL,
      method: "PUT",
      body: { id: otpId, otp: otp, ...userData },
      addToast,
    });
    if (data.response === true) {
      handleMobileOTPDialogClickClose();
      const token = await getOrGenerateToken();
      if (token) {
        const userData = await getUser();
        if (userData) {
          setCurrentUser(userData);
          setIsAuthenticated(true);
          setLoading(false);
          addToast("Sign up successful! Welcome to our platform.!", { appearance: "success" });
          history.push(Constants.HOME_SCREEN);
        } else {
          setLoading(false);
          addToast("Something went wrong during sign up. Please try again later.!", { appearance: "error" });
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      addToast("Please enter valid OTP!!!", { appearance: "error" });
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={0}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Paper elevation={24} className="paper">
            <Grid container spacing={1}>
              <Grid item container justifyContent="center">
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  onChange={imagePicker}
                  accept="image/*"
                  multiple={false}
                  style={{ display: "none" }}
                />

                <Button
                  disableRipple
                  onClick={() => {
                    inputFile.current.click();
                  }}
                >
                  <Avatar style={{ height: 100, width: 100 }} src={imageURL}>
                    <AccountCircleIcon style={{ height: 100, width: 100 }} />
                  </Avatar>
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h5" color="text.primary">
                  Sign up
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="left"
                  variant="h6"
                  color="text.primary"
                  style={{ marginBottom: "-1em" }}
                >
                  As a vendor
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Company name"
                  name="companyname"
                  id="companyname"
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  value={vendorName}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const formattedValue = inputValue
                      .split(" ")
                      .map((word) => {
                        if (word === word.toUpperCase()) {
                          return word;
                        } else {
                          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                        }
                      })
                      .join(" ");

                    setVendorName(formattedValue);
                  }}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ margin: "-6px 0px" }}>
                <FormControl >
                  <FormLabel id="vendor-type-radio-btngroup" style={{ color: theme.palette.mode === "light" ? "#000000" : "#fff" }}>Are You ?</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="vendor-type-radio-btngroup"
                    name="vendor-type-radio-btn"
                    value={vendorType}
                    onChange={handleVendorTypeChange}
                  >
                    <FormControlLabel value="MANUFACTURER" control={<Radio />} label="Manufacturer" />
                    <FormControlLabel value="DISTRIBUTOR" control={<Radio />} label="Distributor" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel id="country-code">Country Code</InputLabel>
                  <Select
                    labelId="country-code"
                    id="_countryCode"
                    value={countryCode}
                    label="Country Code"
                    fullWidth
                    onChange={(event) => {
                      setCountryCode();
                    }}
                  >
                    <MenuItem value={"+91"}>(+91) India</MenuItem>
                    <MenuItem value={"+1"}>(+1) USA</MenuItem>
                    <MenuItem value={"+44"}>(+44) Canada</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  color="primary"
                  label="Company WhatsApp number"
                  variant="outlined"
                  name="mobilenumber"
                  id="mobilenumber"
                  autoComplete="off"
                  value={mobileNo}
                  onChange={(event) => setMobileNo(event.target.value)}
                  inputProps={{ maxLength: 10, type: "tel" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Company Email address"
                  type="email"
                  variant="outlined"
                  name="emailaddress"
                  id="emailaddress"
                  value={emailId}
                  onChange={(event) => setEmailId(event.target.value)}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  placeholder="Password"
                  color="primary"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HttpsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Company Website Link"
                  color="primary"
                  id="companywebsitelink"
                  name="companywebsitelink"
                  value={websiteLink}
                  onChange={(event) => setWebsiteLink(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InsertLinkIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth
                  label="Address"
                  name="address"
                  value={address}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const formattedValue = inputValue
                      .split(" ")
                      .map((word) => {
                        if (word === word.toUpperCase()) {
                          return word;
                        } else {
                          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                        }
                      })
                      .join(" ");

                    setAddress(formattedValue);
                  }}
                  autoComplete="off"
                  multiline
                  maxRows={4}
                  id="address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PinDropOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <FormControl fullWidth>
                  <InputLabel id="country-select-label" htmlFor="contract-country">
                    Country
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="country-label"
                    id="contract-country"
                    label="Select Country"
                    value={country}
                    onChange={(event) => {
                      setCountry(event.target.value);
                      setStateChoices([]);
                      setCityChoices([]);
                      getStatesData(event.target.value);
                    }}
                  >
                    <MenuItem value={""} selected>
                      Select Country
                    </MenuItem>
                    {Array.isArray(countryChoices) &&
                      countryChoices.map((choice, index) => {
                        return (
                          <MenuItem value={choice} key={index}>{choice}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} >
                <FormControl fullWidth >
                  <InputLabel id="state-select-label" htmlFor="contract-state">
                    State
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="state-label"
                    id="contract-state"
                    label="Select State"
                    value={state}
                    onChange={(event) => {
                      setState(event.target.value);
                      setCityChoices([]);
                      getCityData(country, event.target.value);
                    }}
                  >
                    <MenuItem value={""}>Select State</MenuItem>
                    {Array.isArray(stateChoices) &&
                      stateChoices.map((choice, index) => {
                        return (
                          <MenuItem value={choice} key={index}>{choice}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-dialog-select-label" htmlFor="contract-city">
                    City
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="city-label"
                    id="contract-city"
                    label="Select City"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                  >
                    <MenuItem value={""}>Select City</MenuItem>
                    {Array.isArray(cityChoices) &&
                      cityChoices.map((choice, index) => {
                        return (
                          <MenuItem value={choice} key={index}>{choice}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth
                  placeholder="Pincode"
                  type="tel"
                  name="pincode"
                  value={pinCode}
                  onChange={(event) => setPinCode(event.target.value)}
                  inputProps={{ maxLength: 6 }}
                  autoComplete="off"
                  id="pincode"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DialpadIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: "1em" }}
              >
                <Grid item>
                  <Typography variant="subtitle2" align="center" color="text.primary">
                    {" "}
                    By creating an account, you agree to our
                  </Typography>
                </Grid>
                <Grid item>
                  <a
                    href=" http://www.meddyconnect.com/terms-conditions.html"
                    target="_blank" rel="noreferrer"
                    style={{ textDecoration: "none", color: "#00B9F1", fontWeight: "bold", fontSize: "14px", marginLeft: "0.5em" }}
                  >
                    Terms & Conditions
                  </a>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  className="btn-primary"
                  variant="contained"
                  onClick={handleVendorSignupSubmit}
                >
                  Create Account
                </Button>
              </Grid>
              <Grid
                container
                justifyContent="center"
                style={{ marginTop: "0.5em", marginBottom: "1.6em" }}
              >
                <Grid item>
                  <Typography variant="body1" align="center" color="text.primary">
                    {" "}
                    Already have an account?
                    <Link
                      component={RouterLink}
                      to="/login"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        marginLeft: "0.5em",
                        color: "#00B9F1",
                      }}
                      variant="body1"
                    >
                      Sign In
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* create dialog for generate otp */}
        <Dialog
          open={openMobileOTPDialog}
          // onClose={handleMobileOTPDialogClickClose}
          fullScreen={fullScreen}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
        >
          <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
            <IconButton
              edge="start"
              onClick={handleMobileOTPDialogClickClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
            OTP Verification
          </DialogTitle>
          <DialogContent style={{ padding: "16px 12px" }}>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignContent="center"
            >
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  color="primary"
                  id="_otp"
                  label="WhatsApp Number OTP"
                  autoFocus={true}
                  inputProps={{ maxLength: 6 }}
                  variant="outlined"
                  value={otp}
                  autoComplete="off"
                  onChange={(event) => setOtp(event.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "0px 12px 16px 12px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignContent="center"
            >
              <Grid item xs={6} sm={6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  className="dialog-button"
                  onClick={validateOTP}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  className="dialog-button"
                  onClick={generateOTP}
                >
                  Resend
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        <YLoader loading={loading} />
      </Container>
    </>
  )
}

export default VendorUserSignup