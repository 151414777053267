import React from 'react'

const usePublicAPIRequest = () => {

    const apiPublicRequests = async (data) => {
        
        const { endPoint, method, body, addToast } = data;
        const axios = require("axios").create({
          baseURL: process.env.REACT_APP_URL,
        });
      
        try {
          
          let response = null;
          if (method === "POST") {
            response = await axios.post(endPoint, body);
          } else if (method === "PUT") {
            response = await axios.put(endPoint, body);
          } else if (method === "DELETE") {
            response = await axios.delete(endPoint);
          } else if (method === "PDFVIEW") {
            response = await axios.get(endPoint, {
              responseType: "blob",
            });
          }  
          else {
            response = await axios.get(endPoint);
          }
          if (response.status === 200) {
            return { response, data: response.data, error: null };
          }
        } catch (error) {
          
           if (error.response.status === 401) {  
              addToast("Unauthorized User!!!", {
                appearance: "error",
              });
              localStorage.clear();
            } 
          return { response: error.response, data: null, error };
      
        }
        
      };

  return { apiPublicRequests }
    
}

export default usePublicAPIRequest