import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, TextField, Paper, Button, InputLabel, Select, MenuItem, FormControl, Autocomplete, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { YContext } from "../../Context/YContext";
import VerificationAlertDialog from '../../components/VerificationAlertDialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import useGetData from '../../API/useGetData';
import Constants from "../../MeddyConnectConstant";
import UrlRoute from "../../API/UrlRoute";
import "./postajob.css";


const PostAJob = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const history = useHistory();
    const { addToast } = useToasts();

    const [jobTitle, setJobTitle] = useState("");
    const [description, setDescription] = useState('');
    const [qualification, setQualification] = useState("");
    const [jobType, setJobType] = useState('');
    const [expectedSalary, setExpectedSalary] = useState("");
    const [skillsData, setSkillsData] = useState([]);
    const [skills, setSkills] = useState(null);
    const [skillsInputValue, setSkillsInputValue] = useState('');
    const [skillsOptions, setSkillsOptions] = useState([]);
    const [hashtagsData, setHashtagsData] = useState([]);
    const [hashtag, setHashtag] = useState(null);
    const [hashtagsInputValue, setHashtagsInputValue] = useState('');
    const [hashtagsOptions, setHashtagsOptions] = useState([]);
    const [pinCode, setPinCode] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");

    // Services
    const { currentUser } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getCountry, getStates, getCity, getSkills, getHashtags } = useGetData();

    //Dialogs
    const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = useState(false);

    const handleAlertVerificationDialogClickOpen = () => {
        setOpenAlertVerificationDialog(true);
    };

    const handleAlertVerificationDialogClickClose = () => {
        setOpenAlertVerificationDialog(false);
    };

    useEffect(() => {
        if (currentUser.identityVerified === true) {
            getReferenceData();
        } else {
            handleAlertVerificationDialogClickOpen();
        }
    }, [currentUser.identityVerified]);

    useEffect(() => {
        getSkillsData("");
    }, [skillsData]);

    useEffect(() => {
        getHashtagsData("");
    }, [hashtagsData]);

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    const handleJobTypeChange = (event) => {
        setJobType(event.target.value);
    };

    const getSkillsData = async (skill) => {
        const skillsOptions = await getSkills(skill, addToast);
        if (skillsOptions) {
            setSkillsOptions(skillsOptions);
        }
    };

    const handleAddSkills = (newSkill) => {
        if (newSkill && newSkill.trim() !== '' && !skillsData.includes(newSkill)) {
            setSkillsData((prevSkillsData) => [...prevSkillsData, newSkill]);
            setSkillsInputValue('');
            setSkills(null);
        }
    };

    const handleSkillDelete = (deletedSkill) => {
        setSkillsData(
            skillsData.filter(item =>
                item !== deletedSkill
            )
        );
    }

    const getHashtagsData = async (tags) => {
        const tagsOptions = await getHashtags(tags, addToast);
        if (tagsOptions) {
            setHashtagsOptions(tagsOptions);
        }
    };

    const handleAddHashtags = (newHashtag) => {
        // Trim leading and trailing spaces
        const trimmedInputValue = newHashtag.trim();

        // Remove leading # and capitalize the first letter of each word
        const formattedHashtag = `#${trimmedInputValue.replace(/^#+/, '').split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}`;

        if (formattedHashtag !== '#' && !hashtagsData.includes(formattedHashtag)) {
            setHashtagsData((prevHashtagsData) => [...prevHashtagsData, formattedHashtag]);
            setHashtagsInputValue('');
        }
    };

    const handleHashtagDelete = (deletedHashtag) => {
        setHashtagsData(
            hashtagsData.filter(element =>
                element !== deletedHashtag
            )
        );
    }

    const validateJobForm = () => {

        let valid = true;

        if (jobTitle.trim() === '') {
            addToast('Job title cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (description.trim() === '') {
            addToast('Description cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (qualification.trim() === '') {
            addToast('Qualification cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (!jobType) {
            addToast('Please select the job type', { appearance: 'error' });
            valid = false;
        }

        if (!/^[0-9]*$/.test(expectedSalary)) {
            addToast('Expected salary must contain only numbers', { appearance: 'error' });
            valid = false;
        }

        if (country.trim() === '') {
            addToast('Country cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (state.trim() === '') {
            addToast('State cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (city.trim() === '') {
            addToast('City cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (pinCode.trim() === '') {
            addToast('PinCode cannot be empty', { appearance: 'error' });
            valid = false;
        } else {
            // Check if pincodes contains only numbers
            const numericRegex = /^[0-9]+$/;

            if (!numericRegex.test(pinCode)) {
                addToast('Invalid PinCode. Please enter only numbers.', { appearance: 'error' });
                valid = false;
            } else if (pinCode.length !== 6) {
                addToast('PinCode must be 6 digits long', { appearance: 'error' });
                valid = false;
            }
        }

        return valid;
    };

    const createJobApplication = async () => {
        setIsApiCallInProgress(true);
        setLoading(true);

        const { response, data, error } = await apiRequests({
            endPoint: UrlRoute.JOBS_URL,
            method: "POST",
            body: {
                title: jobTitle,
                description: description,
                qualification: qualification,
                jobType: jobType,
                expectedSalary: expectedSalary,
                country: country,
                state: state,
                city: city,
                pinCode: pinCode,
                skills: skillsData,
                tags: hashtagsData
            },
            addToast,
        });

        if (data) {

            setLoading(false);
            setIsApiCallInProgress(false);
            addToast("Posted the job successfully !!!", { appearance: "success" });
            history.push(Constants.MANAGE_JOB_APPLICATIONS);

        } else {
            setLoading(false);
            setIsApiCallInProgress(false);
            addToast("Something went wrong!", { appearance: "error" });
        }
    };

    const handlePostAJobSubmit = () => {

        if (validateJobForm()) {
            createJobApplication();
        }

    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="drawer-leftside">
                        <Navigation />
                    </div>

                    <div className="postjobs-container">

                        <Paper elevation={2} className="jobspaper-container">
                            <Grid container direction="row" spacing={0.5} >
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        label="Job Title"
                                        name="jobtitle"
                                        value={jobTitle}
                                        onChange={(event) => {
                                            const inputValue = event.target.value;
                                            const formattedValue = inputValue
                                                .split(" ")
                                                .map((word) => {
                                                    if (word === word.toUpperCase()) {
                                                        return word;
                                                    } else {
                                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                    }
                                                })
                                                .join(" ");

                                            setJobTitle(formattedValue);
                                        }}
                                        autoComplete="off"
                                        id="jobtitle"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth
                                        label="Job Description"
                                        name="description"
                                        inputProps={{ style: { textTransform: "capitalize" } }}
                                        value={description}
                                        onChange={(event) => setDescription(event.target.value)}
                                        autoComplete="off"
                                        multiline
                                        minRows={3}
                                        id="description"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth
                                        label="Qualification"
                                        name="qualification"
                                        value={qualification}
                                        onChange={(event) => {
                                            const inputValue = event.target.value;
                                            const formattedValue = inputValue
                                                .split(" ")
                                                .map((word) => {
                                                    if (word === word.toUpperCase()) {
                                                        return word;
                                                    } else {
                                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                    }
                                                })
                                                .join(" ");

                                            setQualification(formattedValue);
                                        }}
                                        autoComplete="off"
                                        id="qualification"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField fullWidth
                                        label="Salary(per month)"
                                        name="salary"
                                        value={expectedSalary}
                                        onChange={(event) => setExpectedSalary(event.target.value.toLowerCase()
                                            .split(" ")
                                            .map(word => {
                                                return word.charAt(0).toUpperCase() + word.slice(1);
                                            })
                                            .join(" "))}
                                        autoComplete="off"
                                        id="salary"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        freeSolo
                                        id="skills"
                                        disableClearable
                                        options={skillsOptions.map((option) => option)}
                                        value={skills}
                                        onChange={(event, newValue) => {
                                            setSkills(newValue);
                                            if (!skillsData.some((item) => item === newValue)) {
                                                handleAddSkills(newValue);
                                            }
                                        }}
                                        inputValue={skillsInputValue}
                                        onInputChange={(event, newInputValue) => {

                                            const capitalizedInput = newInputValue.toLowerCase()
                                                .split(" ")
                                                .map(word => {
                                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                                })
                                                .join(" ")

                                            setSkillsInputValue(capitalizedInput)

                                            if (!skillsOptions.includes(capitalizedInput)) {
                                                getSkillsData(capitalizedInput);
                                            }

                                        }}
                                        onBlur={() => {
                                            if (!skillsOptions.find((option) => option === skillsInputValue)) {
                                                handleAddSkills(skillsInputValue);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Skills"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                {skillsData.length > 0 && (
                                    <div className="skills-container">
                                        {skillsData.map((skill, index) => {
                                            return (
                                                <div key={index} style={{ margin: '5px' }}>
                                                    <Chip
                                                        variant='outlined'
                                                        color="primary"
                                                        style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                        label={skill}
                                                        onDelete={() => handleSkillDelete(skill)}
                                                    />
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                )}

                                <Grid item xs={12}>
                                    <TextField
                                        label="Job type"
                                        select
                                        value={jobType}
                                        onChange={handleJobTypeChange}
                                        fullWidth
                                    >
                                        <MenuItem value="PART_TIME">Part Time</MenuItem>
                                        <MenuItem value="FULL_TIME">Full Time</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        freeSolo
                                        id="hashtags"
                                        disableClearable
                                        options={hashtagsOptions.map((option) => option)}
                                        value={hashtag}
                                        onChange={(event, newValue) => {
                                            setHashtag(newValue);
                                            if (!hashtagsData.some((item) => item === newValue)) {
                                                handleAddHashtags(newValue);
                                            }
                                        }}
                                        inputValue={hashtagsInputValue}
                                        onInputChange={(event, newInputValue) => {

                                            const capitalizedInput = newInputValue.toLowerCase()
                                                .split(" ")
                                                .map(word => {
                                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                                })
                                                .join(" ")

                                            setHashtagsInputValue(capitalizedInput)

                                            if (!hashtagsOptions.includes(capitalizedInput)) {
                                                getHashtagsData(capitalizedInput);
                                            }

                                        }}
                                        onBlur={() => {
                                            if (!hashtagsOptions.find((option) => option === hashtagsInputValue)) {
                                                handleAddHashtags(hashtagsInputValue);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Hashtags"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {hashtagsData.length > 0 && (
                                    <div className="skills-container">
                                        {hashtagsData.map((hashtag, index) => {
                                            return (
                                                <div key={index} style={{ margin: '5px' }}>
                                                    <Chip
                                                        variant='outlined'
                                                        color="primary"
                                                        style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                        label={hashtag}
                                                        onDelete={() => handleHashtagDelete(hashtag)}
                                                    />
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                )}

                            </Grid>

                            <Grid container direction="row" spacing={0.5} >
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="country-select-label" htmlFor="country-picker">
                                            Country
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="country-label"
                                            id="country-picker"
                                            label="Select Country"
                                            value={country}
                                            onChange={(event) => {
                                                setCountry(event.target.value);
                                                setStateChoices([]);
                                                setCityChoices([]);
                                                getStatesData(event.target.value);
                                            }}
                                        >
                                            <MenuItem value={""} selected>
                                                Select Country
                                            </MenuItem>
                                            {Array.isArray(countryChoices) &&
                                                countryChoices.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FormControl fullWidth >
                                        <InputLabel id="state-select-label" htmlFor="state-picker">
                                            State
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="state-label"
                                            id="state-picker"
                                            label="Select State"
                                            value={state}
                                            onChange={(event) => {
                                                setState(event.target.value);
                                                setCityChoices([]);
                                                getCityData(country, event.target.value);
                                            }}
                                        >
                                            <MenuItem value={""}>Select State</MenuItem>
                                            {Array.isArray(stateChoices) &&
                                                stateChoices.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-dialog-select-label" htmlFor="city-picker">
                                            City
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="city-label"
                                            id="city-picker"
                                            label="Select City"
                                            value={city}
                                            onChange={(event) => setCity(event.target.value)}
                                        >
                                            <MenuItem value={""}>Select City</MenuItem>
                                            {Array.isArray(cityChoices) &&
                                                cityChoices.map((choice, index) => {
                                                    return (
                                                        <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth
                                        label="Pincode"
                                        name="pincode"
                                        value={pinCode}
                                        onChange={(event) => setPinCode(event.target.value)}
                                        inputProps={{ maxLength: 6 }}
                                        autoComplete="off"
                                        id="pincode"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>

                        </Paper>

                        {!isApiCallInProgress && (
                            <Grid container direction="row" style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}>
                                <Grid item xs={12}>
                                    <Button variant="contained" fullWidth className='jobs-button' color="primary" onClick={handlePostAJobSubmit}>Post a Job</Button>
                                </Grid>
                            </Grid>
                        )}

                        {/* Verification Alert Dialog */}
                        < VerificationAlertDialog
                            openAlertVerificationDialog={openAlertVerificationDialog}
                            handleAlertVerificationDialogClickClose={handleAlertVerificationDialogClickClose}
                            message="Currently, you cannot post a job because you are not a verified user."
                        />

                    </div>
                </div>
                <YLoader loading={loading} />
            </div>


        </>
    )
}

export default PostAJob