import React, { useState, useEffect } from 'react';
import { Grid, Typography, Dialog, DialogTitle, DialogContent, Paper, Slide, Stack, Avatar, IconButton, Button } from '@mui/material';
import './referred.css';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../../API/useApiRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import YLoader from "../../components/Loader";
import Navigation from "../../Navigation/Navigation";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import PDFViewer from "../../components/PDFViewer";
import ReadMore from "../../components/ReadMore";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReferredByMe = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addToast } = useToasts();

  const [referredByMeData, setReferredByMeData] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [uploadedAttachment, setUploadedAttachment] = useState([]);
  const [selectedPdfContent, setSelectedPdfContent] = useState({ contentType: null, content: null });
  const [selectedImageContent, setSelectedImageContent] = useState({ contentType: null, content: null });

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openReferredByMeDialog, setOpenReferredByMeDialog] = useState(false);
  const [openPdfViewerDialog, setOpenPdfViewerDialog] = useState(false);
  const [openImageViewerDialog, setOpenImageViewerDialog] = useState(false);

  const handleReferredByMeDialogClickOpen = (id) => {
    setOpenReferredByMeDialog(true);
    getPatientDetails(id);
  };

  const handleReferredByMeDialogClickClose = () => {
    setOpenReferredByMeDialog(false);
  };

  const handleImageViewerDialogClickOpen = (contentType, attachmentLink) => {
    setOpenImageViewerDialog(true);
    setSelectedImageContent({ contentType: contentType, content: attachmentLink });
  };

  const handleImageViewerDialogClickClose = () => {
    setOpenImageViewerDialog(false);
  };

  const handlePdfViewerDialogClickOpen = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const base64String = await blobToBase64(blob);
      setSelectedPdfContent({ contentType: blob.type, content: base64String });
      setOpenPdfViewerDialog(true);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handlePdfViewerDialogClickClose = () => {
    setOpenPdfViewerDialog(false);
    setSelectedPdfContent({ contentType: null, content: null });
  };

  useEffect(() => {
    getReferredByMeDetails(pageNo);
  }, []);

  const getReferredByMeDetails = async (pageNo) => {

    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `patient-referrals/by-referring-doc?page=${pageNo}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setReferredByMeData((prevData) => {
        const uniqueEntities = data.entities.filter(newEntity =>
          !prevData.some(existingEntity => existingEntity.id === newEntity.id)
        );
        return [...prevData, ...uniqueEntities];
      });

      setTotalPages(data.totalPages);
      setPageNo(data.page);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const getPatientDetails = async (id) => {
    setFileLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `patient-referrals/${id}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setPatientDetails(data);
      setUploadedAttachment(data.patientReferralDocs);
      setFileLoading(false);
    } else {
      setFileLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="referred-drawer">
            <Navigation />
          </div>

          <div className="referred-container">

            <div style={{ marginTop: "12px" }}>
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={referredByMeData.length}
                next={() => {
                  getReferredByMeDetails(pageNo + 1);
                }}
                hasMore={!(pageNo + 1 === totalPages)}
                loader={<YLoader loading={loading} />}
                endMessage={<span></span>}
              >
                <Grid container direction="row" spacing={1}>
                  {referredByMeData.length > 0 ?
                    referredByMeData.map((value) => {
                      return (
                        <Grid item xs={12} key={value.id}>
                          <div className="referred-patient" onClick={() => handleReferredByMeDialogClickOpen(value.id)} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                            <Avatar src={value.referredDoctorProfile?.href} className='referred-doctor-picture' />
                            <div className="referred-patient-info">
                              <Typography align="left" color="text.primary" className='referred-title'>
                                {value.patientName}
                              </Typography>
                              <Typography align="left" color="text.primary" className='referred-subtitle'>
                                Referred To : {value.referredDoctorName}, {value.referredDoctorSpeciality}
                              </Typography>
                              <Typography align="left" color="text.primary" className="referred-description">
                                {value.created ? moment(value.created).format("D-MMM-YYYY") : ""}
                              </Typography>

                            </div>
                          </div>
                        </Grid>
                      );
                    })
                    :
                    <Grid container style={{ marginTop: "10px" }}>
                      <Grid item xs={12}>
                        <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                          No results found
                        </Typography>
                      </Grid>
                    </Grid>
                  }

                </Grid>
              </InfiniteScroll>

            </div>

            {/* ############################ ReferredByMe Patient Info Details dialog ######################################### */}
            <Dialog open={openReferredByMeDialog} onClose={handleReferredByMeDialogClickClose} fullScreen={fullScreen} TransitionComponent={Transition}>
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleReferredByMeDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Patient Details
              </DialogTitle>
              <DialogContent>

                <Grid container direction="row" style={{ marginTop: "12px" }} rowSpacing={1}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center" >
                      <Avatar src={patientDetails.referredDoctorProfile?.href} className='referred-picture' />
                      <Stack spacing={0.2} >

                        <Typography align="left" color="text.primary" className='referred-to-me-title'>
                          {patientDetails.patientName}
                        </Typography>

                        <Typography align="left" color="text.primary">
                          <span className='referred-title-text'> Referred To :</span> {patientDetails.referredDoctorName}, {patientDetails.referredDoctorSpeciality}
                        </Typography>

                      </Stack>

                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography align="left" color="text.primary" className='referred-title-text' gutterBottom component="span">
                      Patient Contact Number
                    </Typography>
                    <Typography align="left" variant='subtitle1' color="text.primary">
                      {patientDetails.patientContactNo}
                    </Typography>
                  </Grid>

                  <Grid container direction="row" style={{ paddingTop: "8px" }}>
                    <Grid item xs={12}>
                      <Typography align="left" color="text.primary" className='referred-title-text' gutterBottom component="span">
                        Problem Details
                      </Typography>
                    </Grid>

                    {patientDetails.patientMedicalHistory ?
                      <Grid item xs={12}>
                        {patientDetails.patientMedicalHistory.length < 300 ?
                          <Typography align="left" variant='subtitle1' color="text.primary">
                            {patientDetails.patientMedicalHistory}
                          </Typography>
                          :
                          <Typography align="left" variant='subtitle1' color="text.primary">
                            <ReadMore>
                              {patientDetails.patientMedicalHistory}
                            </ReadMore>
                          </Typography>
                        }
                      </Grid> : "No description"}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography align="left" color="text.primary" className='referred-title-text' gutterBottom>
                      Reports
                    </Typography>

                  </Grid>
                </Grid>

                {
                  uploadedAttachment && uploadedAttachment.length > 0 && (
                    <div className="refer-doc-gallery">
                      {uploadedAttachment && uploadedAttachment.map((item, index) => {
                        return (
                          item.contentType !== 'application/pdf' && (
                            <div key={item.id} className="refer-image-container">
                              <img src={item.attachmentLink.href} alt="Preview" onClick={() => handleImageViewerDialogClickOpen(item.contentType, item.attachmentLink.href)} />
                            </div>
                          )
                        );
                      })}
                    </div>
                  )
                }

                {
                  uploadedAttachment && uploadedAttachment.length > 0 && (
                    uploadedAttachment && uploadedAttachment.map((obj, index) => {
                      return (
                        obj.contentType === 'application/pdf' && (
                          <IconButton key={obj.id} onClick={() => handlePdfViewerDialogClickOpen(obj.attachmentLink.href)} disableRipple>
                            <FaRegFilePdf style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' />
                          </IconButton>
                        )
                      );
                    })
                  )
                }

              </DialogContent>

            </Dialog>

            {/* ############################ Image viewer dialog ######################################### */}

            <Dialog
              open={openImageViewerDialog}
              onClose={handleImageViewerDialogClickClose}
              fullScreen={fullScreen}
              maxWidth="md"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleImageViewerDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Image Preview
              </DialogTitle>
              <DialogContent style={{ padding: "0px" }}>

                <img src={selectedImageContent.content} alt="Preview" style={{ width: "100%", height: "auto", objectFit: "contain" }} />

              </DialogContent>
            </Dialog>

            {/* ############################ PDF viewer dialog ######################################### */}

            <Dialog
              open={openPdfViewerDialog}
              onClose={handlePdfViewerDialogClickClose}
              fullScreen={fullScreen}
              maxWidth="md"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handlePdfViewerDialogClickClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                PDF Preview
              </DialogTitle>
              <DialogContent style={{ padding: "0px" }}>

                <PDFViewer pdfData={`data:${selectedPdfContent.contentType};base64,${selectedPdfContent.content}`} />

              </DialogContent>
            </Dialog>

          </div>
        </div>

        <YLoader loading={loading} />
      </div>

      {
        fileLoading && (
          <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        )
      }

    </>
  )
}

export default ReferredByMe

