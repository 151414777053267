import React, { useState, useEffect, useContext } from "react";
import { Paper, Grid, Button, Slide, IconButton, Dialog, DialogTitle, DialogContent, Autocomplete, DialogActions, Chip, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../Profile/Profile.css";
import useAPIRequest from "../../API/useApiRequest";
import YLoader from "../../components/Loader";
import useGetData from '../../API/useGetData';
import { useToasts } from "react-toast-notifications";
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Specialities = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    // Variables
    const [userId, setUserId] = useState("");
    const [userType, setUserType] = useState("");
    const [specialityData, setSpecialityData] = useState([]);
    const [specialityName, setSpecialityName] = useState(null);
    const [specialityInputValue, setSpecialityInputValue] = useState('');
    const [specialityOptions, setSpecialityOptions] = useState([]);

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getSpecialities } = useGetData();
    const { getUserType } = useUserType();

    // Dialogs
    const [openAddSpecialitiesDialog, setOpenAddSpecialitiesDialog] = React.useState(false);

    const handleAddSpecialitiesDialogClickClose = () => {
        setOpenAddSpecialitiesDialog(false);
    };
    const handleAddSpecialitiesDialogClickOpen = () => {
        setOpenAddSpecialitiesDialog(true);
    };

    // Functions
    const getHospitalSpecialities = () => {

        setLoading(true);
        setUserId(currentUser.id);
        setUserType(currentUser.userType);
        setSpecialityData(currentUser.specialityDetails);
        setLoading(false);
    }

    const getSpecialitiesData = async (speciality) => {
        const specialityOptions = await getSpecialities(speciality, addToast);
        if (specialityOptions) {
            setSpecialityOptions(specialityOptions);
        }
    };

    const handleAddSpecialities = (userType, userId, newSpeciality) => {
        if (newSpeciality && newSpeciality.trim() !== '' && !specialityData.some((item) => item.speciality === newSpeciality)) {
            addSpecialityToUserDetails(userType, userId, newSpeciality);
        } else {
            addToast("Speciality already exists!", { appearance: "error" });
        }

    };

    // Function to handle delete action

    const handleDeleteSpecialityFromApi = async (userType, specialityId) => {
        setLoading(true);
        const user = getUserType(userType);

        const { response, data, error } = await apiRequests(
            {
                endPoint: `${user}/speciality/${specialityId}`,
                method: "DELETE",
                addToast
            }
        );
        if (response.status === 200) {
            setLoading(false);
            addToast("Speciality deleted successfully!", { appearance: "success" });
            getUserInfo();
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const handleSpecialityDelete = (specialityIndex) => {
        setSpecialityData(
            specialityData.filter((item, index) =>
                index !== specialityIndex
            )
        );
    }

    const addSpecialityToUserDetails = async (userType, userId, newSpeciality) => {

        setOpenAddSpecialitiesDialog(false);
        setLoading(true);
        const user = getUserType(userType);

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                specialityDetails: [...specialityData, { speciality: newSpeciality }],
            },
            addToast,
        });
        if (data) {
            setSpecialityInputValue('');
            setSpecialityName(null);
            setLoading(false);
            addToast("Speciality added successfully!", { appearance: "success" });
            getUserInfo();
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    }

    useEffect(() => {
        getHospitalSpecialities();
    }, [currentUser])

    useEffect(() => {
        getSpecialitiesData("");
    }, [specialityData]);

    return (
        <>
            {/* ############################ Specialities section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleAddSpecialitiesDialogClickOpen} className="profile_btn">
                        Speciality
                    </Button>
                </div>

                {specialityData && specialityData.length > 0 && (
                    <Paper elevation={4} className="paper-container">
                        <div className="specialities-container">
                            {specialityData.map((data, index) => {
                                return (
                                    <Chip variant='outlined' key={data.id}
                                        color="primary"
                                        style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff", marginBottom: "6px" }}
                                        label={data.speciality}
                                        onDelete={() => data.speciality === '' ? undefined : handleDeleteSpecialityFromApi(userType, data.id)}
                                    />
                                );
                            })
                            }
                        </div>
                    </Paper>
                )
                }

                {/* ############################ Add Specialities dialog ######################################### */}
                <Dialog open={openAddSpecialitiesDialog} onClose={handleAddSpecialitiesDialogClickClose} fullScreen={fullScreen} maxWidth="sm" fullWidth TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleAddSpecialitiesDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Speciality
                    </DialogTitle>
                    <DialogContent style={{ padding: "10px 24px" }}>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Autocomplete
                                    freeSolo
                                    id="specialityname"
                                    disableClearable
                                    options={specialityOptions.map((option) => option)}
                                    value={specialityName}
                                    onChange={(event, newValue) => {
                                        setSpecialityName(newValue);
                                    }}
                                    inputValue={specialityInputValue}
                                    onInputChange={(event, newInputValue) => {

                                        const capitalizedInput = newInputValue.toLowerCase()
                                            .split(" ")
                                            .map(word => {
                                                return word.charAt(0).toUpperCase() + word.slice(1);
                                            })
                                            .join(" ")

                                        setSpecialityInputValue(capitalizedInput)

                                        if (!specialityOptions.includes(capitalizedInput)) {
                                            getSpecialitiesData(capitalizedInput);
                                        }

                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Specialities"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ padding: "0px 8px 8px 8px" }}>
                        <Button onClick={() => handleAddSpecialities(userType, userId, specialityInputValue)} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default Specialities