import { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Avatar, Typography, Grid } from '@mui/material';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import useAPIRequest from "../../API/useApiRequest";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import UrlRoute from "../../API/UrlRoute";
import ObservershipApplicationsDetails from './ObservershipApplicationsDetails';
import './myobservershipapplications.css';


const MyObservershipApplications = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { addToast } = useToasts();

  const [myObservershipApplications, setMyObservershipApplications] = useState([]);
  const [observershipApplicationId, setObservershipApplicationId] = useState("");
  const [observershipId, setObservershipId] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openObservershipApplicationsDetailsDialog, setOpenObservershipApplicationsDetailsDialog] = useState(false);

  const handleObservershipApplicationsDetailsDialogClickOpen = (id, internshipId) => {
    setOpenObservershipApplicationsDetailsDialog(true);
    setObservershipApplicationId(id);
    setObservershipId(internshipId);
  };

  const handleObservershipApplicationsDetailsDialogClickClose = () => {
    setOpenObservershipApplicationsDetailsDialog(false);
  };

  useEffect(() => {
    getMyObservershipApplications();
  }, []);

  const getMyObservershipApplications = async () => {
    setLoading(true)
    const { response, data, error } = await apiRequests({
      endPoint: UrlRoute.USER_OBSERVERSHIP_APPLICATIONS_URL,
      method: "GET",
      addToast,
    });

    if (data) {
      setMyObservershipApplications(data);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }
  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="my-observership-applications-drawer">
            <Navigation />
          </div>

          <div className="my-observership-applications-container">
            {myObservershipApplications.length > 0 ?
              myObservershipApplications.map((element) => {
                return (
                  <Grid container direction="column" className='my-observership-applications-card-container' key={element.id}>
                    <Grid item xs={12} >
                      <Card className='my-observership-applications-card' onClick={() => handleObservershipApplicationsDetailsDialogClickOpen(element.id, element.internshipId)}>
                        <CardHeader
                          avatar={
                            <Avatar src={element.postedProfileLink && element.postedProfileLink.href ? element.postedProfileLink.href : " "} color="primary" aria-label="companylogo" className='my-observership-img' />
                          }
                          title={element.postedUserName}
                          subheader={element.created ? moment(element.created).format("MMM D, YYYY") : ""}
                        />
                        <CardContent className='observership-card-content'>

                          <Grid container direction="row" rowSpacing={0.5}>
                            <Grid item xs={12} >
                              <Typography variant="h6" align='left' color="text.primary" className='my-observership-applications-designation my-observership-title'>
                                {element.internshipTitle}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} >
                              <Typography variant="body2" color="text.primary" className='my-observership-applications-description'>
                                {element.coverLetter}
                              </Typography>
                            </Grid>
                          </Grid>

                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                );
              })
              :
              <Grid container style={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                    No results found
                  </Typography>
                </Grid>
              </Grid>
            }

            <div>
              <ObservershipApplicationsDetails
                openObservershipApplicationsDetailsDialog={openObservershipApplicationsDetailsDialog}
                handleObservershipApplicationsDetailsDialogClickClose={handleObservershipApplicationsDetailsDialogClickClose}
                observershipApplicationId={observershipApplicationId}
                observershipId={observershipId}
              />
            </div>

          </div>
        </div>
        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default MyObservershipApplications