import React, { useState } from 'react'
import { Button, Dialog, IconButton, Slide, DialogContent, Typography, Grid,DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "./writeanswer.css";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

const WriteAnswer = ({ writeAnswerDialogOpen, handleWriteAnswerDialogClose }) => {
    const theme = useTheme();
    const [value, setValue] = useState('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {/* ############################ open wrie a answer dialog ######################################### */}
            <Dialog
                open={writeAnswerDialogOpen}
                onClose={handleWriteAnswerDialogClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent>
                    {writerData.map((element) => {
                        return (
                            <>
                                <div className="answer-container">

                                    <div className="writer-info">
                                        <Typography variant="h6" align='left' className='question-text'>
                                            {element.question}
                                        </Typography>
                                    </div>
                                    <div>
                                        <IconButton onClick={handleWriteAnswerDialogClose} >
                                            <CloseIcon style={{ color: "black" }} />
                                        </IconButton>
                                    </div>
                                </div>

                                <Grid container>
                                    <Grid item xs={12} className='writeanswer-editor-container'>
                                        {/* <ReactQuill theme="snow" modules={modules} value={value} onChange={setValue} className='writeanswer-editor' /> */}
                                    </Grid>
                                </Grid>
                            </>

                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" fullWidth className='post-btn' >Post</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default WriteAnswer

const writerData = [
    {
        id: 0,
        question: "What are some unknown facts about the human brain?",
        writerPicture: "https://images.unsplash.com/photo-1508341591423-4347099e1f19?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
        writerName: "Rahul Shah",
    },
]