import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Slide, IconButton, Typography, Avatar, Grid, Chip } from '@mui/material';
import "./observershipapplicationsdetails.css";
import { useToasts } from "react-toast-notifications";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FaRupeeSign } from 'react-icons/fa';
import { FaRegFilePdf } from "react-icons/fa";
import useAPIRequest from "../../API/useApiRequest";
import ReadMore from "../../components/ReadMore";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PlaceIcon from '@mui/icons-material/Place';
import ImageIcon from '@mui/icons-material/Image';
import PDFViewer from "../../components/PDFViewer";
import moment from "moment";
import UrlRoute from "../../API/UrlRoute";
import CurrencyFormat from '../../utils/CurrencyFormat';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ObservershipApplicationsDetails = ({ openObservershipApplicationsDetailsDialog, handleObservershipApplicationsDetailsDialogClickClose, observershipApplicationId, observershipId }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();

    const [observershipApplicationDetails, setObservershipApplicationDetails] = useState({});
    const [observershipDetails, setObservershipDetails] = useState({});
    const [combinedApplicationData, setCombinedApplicationData] = useState({});
    const [uploadedFile, setUploadedFile] = useState({ contentType: null, content: null });
    const [fileType, setFileType] = useState("");

    const [fileLoading, setFileLoading] = useState(false);
    
    // Services
    const { apiRequests } = useAPIRequest();

    // Dialogs
    const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

    const handleDocumentDialogClickOpen = (type) => {
        setOpenDocumentDialog(true);
        setFileType(type);
    };

    const handleDocumentDialogClickClose = () => {
        setOpenDocumentDialog(false);
    };

    useEffect(() => {
        if (openObservershipApplicationsDetailsDialog) {
            getObservershipApplicationDetails(observershipId, observershipApplicationId);
            getObservershipDetails(observershipId);
        }
    }, [openObservershipApplicationsDetailsDialog]);


    const getObservershipApplicationDetails = async (observershipId, observershipApplicationId) => {
        setFileLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `internships/${observershipId}/application/${observershipApplicationId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setObservershipApplicationDetails(data);

            setUploadedFile({ contentType: data.contentType, content: data.content });
            setFileLoading(false);
        } else {
            setFileLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const getObservershipDetails = async (observershipId) => {
        setFileLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `${UrlRoute.OBSERVERSHIP_URL}/${observershipId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setObservershipDetails(data);
            setFileLoading(false);
        } else {
            setFileLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    useEffect(() => {

        const combinedData = {
            application: observershipApplicationDetails,
            observership: observershipDetails
        };
        setCombinedApplicationData(combinedData)

    }, [observershipApplicationDetails, observershipDetails])

    return (
        <>
            <>
                {/* ############################ Show apply for observership dialog ######################################### */}
                <Dialog
                    open={openObservershipApplicationsDetailsDialog}
                    onClose={handleObservershipApplicationsDetailsDialogClickClose}
                    fullScreen={fullScreen}
                    maxWidth="sm"
                    fullWidth
                    TransitionComponent={Transition}
                >
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleObservershipApplicationsDetailsDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        {combinedApplicationData.application?.internshipTitle}
                    </DialogTitle>
                    <DialogContent>

                        <div className="observership">

                            <div className="observership-container">
                                <Avatar src={combinedApplicationData.application?.postedProfileLink?.href ?? ""} color="primary" aria-label="companylogo" className='observership-company-picture' />
                                <div className="observership-info">
                                    <Typography variant="subtitle1" align="left" style={{ fontWeight: "bold" }}>
                                        {combinedApplicationData.application?.postedUserName}
                                    </Typography>
                                    <Typography variant="subtitle2" align="left">
                                        Posted on :{combinedApplicationData.application && combinedApplicationData.application?.created ? moment(combinedApplicationData.application.created).format("MMM D, YYYY") : "N/A"}
                                    </Typography>
                                </div>
                            </div>

                            {combinedApplicationData.observership && combinedApplicationData.observership.skills && (
                                <Grid container direction="row">
                                    {combinedApplicationData.observership.skills.length > 0 && combinedApplicationData.observership.skills.map((value, index) => {
                                        return (
                                            <Grid item style={{ margin: "0px 6px 6px 0px" }} key={index}>
                                                <Chip label={value} variant="outlined" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }} />
                                            </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                            )}

                            {combinedApplicationData.observership && combinedApplicationData.observership.tags && (
                                <Grid container direction="row" >
                                    {combinedApplicationData.observership.tags.length > 0 && combinedApplicationData.observership.tags.map((item, index) => {
                                        return (
                                            <Grid item style={{ margin: "0px 10px 6px 0px" }} key={index}>
                                                <Typography variant="subtitle1" color="text.primary" >
                                                    {item}
                                                </Typography>
                                            </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                            )}

                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="text.primary">
                                        <span className='observership-description-title'>Observership Description :</span>
                                    </Typography>
                                </Grid>


                                {combinedApplicationData.observership && combinedApplicationData.observership.description ? (
                                    <Grid item xs={12}>
                                        {combinedApplicationData.observership.description.length < 300 ?
                                            <Typography align="left" variant='subtitle1' color="text.primary">
                                                {combinedApplicationData.observership.description}
                                            </Typography>
                                            :
                                            <Typography align="left" variant='subtitle1' color="text.primary">
                                                <ReadMore>
                                                    {combinedApplicationData.observership.description}
                                                </ReadMore>
                                            </Typography>
                                        }
                                    </Grid>)
                                    :
                                    "No description"
                                }
                            </Grid>

                            <div className="observership-container">
                                <div className="observership-details-with-icon">
                                    <Avatar className='observership-avatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                                        <FaRupeeSign className='observership-details-react-icon' />
                                    </Avatar>
                                    <Typography variant="subtitle2" >
                                        Charges
                                    </Typography>
                                    <Typography variant="subtitle1" >
                                        {combinedApplicationData.observership && combinedApplicationData.observership.feesPerMonth ?
                                            <CurrencyFormat currencyPrice={combinedApplicationData.observership.feesPerMonth} />
                                            :
                                            "N/A"
                                        }
                                    </Typography>
                                </div>

                                <div className="observership-details-with-icon">
                                    <Avatar className='observership-avatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                                        <AccessAlarmsIcon className='observership-details-material-ui-icon' />
                                    </Avatar>
                                    <Typography variant="subtitle2" >
                                        Duration
                                    </Typography>
                                    <Typography variant="subtitle1" >
                                        {combinedApplicationData.observership && combinedApplicationData.observership.duration ? combinedApplicationData.observership.duration : "N/A"}
                                    </Typography>
                                </div>

                                <div className="observership-details-with-icon">
                                    <Avatar className='observership-avatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                                        <PlaceIcon className='observership-details-material-ui-icon' />
                                    </Avatar>
                                    <Typography variant="subtitle2" >
                                        City
                                    </Typography>
                                    <Typography variant="subtitle1" >
                                        {combinedApplicationData.observership && combinedApplicationData.observership.city ? combinedApplicationData.observership.city : "N/A"}
                                    </Typography>
                                </div>

                            </div>

                            <Grid container >
                                <Grid item xs={12}>
                                    <Typography className='observership-description-title' gutterBottom>
                                        Cover Letter :
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" color="text.primary">
                                        {combinedApplicationData.application && combinedApplicationData.application.coverLetter ? combinedApplicationData.application.coverLetter : "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>


                            <Grid container direction="column" style={{ marginTop: "6px" }}>
                                <Grid item xs={12}>
                                    <Typography className='observership-description-title' gutterBottom>
                                        Upload Resume :
                                    </Typography>
                                </Grid>
                            </Grid>

                            {
                                uploadedFile && uploadedFile.contentType && uploadedFile.content ?
                                    <div className="applicant-uploaded-container">
                                        {
                                            (uploadedFile.contentType.startsWith('image') || uploadedFile.contentType.startsWith('img')) ? (
                                                <IconButton onClick={() => handleDocumentDialogClickOpen('image')} disableRipple>
                                                    <ImageIcon style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' />
                                                </IconButton>
                                            )
                                                : uploadedFile.contentType === 'application/pdf' ? (
                                                    <IconButton onClick={() => handleDocumentDialogClickOpen('pdf')} disableRipple>
                                                        <FaRegFilePdf style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' />
                                                    </IconButton>
                                                )
                                                    : null
                                        }
                                    </div>
                                    :
                                    <Typography variant='subtitle1' align="center" color="text.primary">
                                        No resume uploaded
                                    </Typography>
                            }

                            <Dialog
                                open={openDocumentDialog}
                                onClose={handleDocumentDialogClickClose}
                                fullScreen={fullScreen}
                                maxWidth="md"
                                fullWidth
                                TransitionComponent={Transition}
                            >
                                <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                                    <IconButton
                                        edge="start"
                                        onClick={handleDocumentDialogClickClose}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                        }}
                                    >
                                        <CloseIcon style={{ color: "white" }} />
                                    </IconButton>
                                    View Document
                                </DialogTitle>
                                <DialogContent style={{ padding: "0px" }}>

                                    {fileType === 'image' ? (
                                        <img src={`data:${uploadedFile.contentType};base64,${uploadedFile.content}`} alt="uploaded image" style={{ width: "100%", height: "100%" }} />
                                    ) : fileType === 'pdf' ? (
                                        <PDFViewer pdfData={`data:${uploadedFile.contentType};base64,${uploadedFile.content}`} />
                                    ) : null}

                                </DialogContent>
                            </Dialog>


                        </div>
                    </DialogContent>
                </Dialog>

                {
                    fileLoading && (
                        <Backdrop open={fileLoading} sx={{ zIndex: 1301 }}>
                            <CircularProgress color="primary" />
                        </Backdrop>
                    )
                }

            </>
        </>
    )
}

export default ObservershipApplicationsDetails