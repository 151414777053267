import React, { useState } from 'react'
import Navigation from "../Navigation/Navigation";
import YLoader from "../components/Loader";
import "./chatpage.css";
import { Typography } from '@mui/material';

const ComingSoon = () => {
    const [loading, setLoading] = useState(false);
    
    return (
        <> <div style={{ height: "auto" }}>

            <div style={{ display: "flex" }}>

                <div className="chat-drawer">
                    <Navigation />
                </div>

                <div className="chat-container">


                    <Typography variant="h6" color="text.primary" align='center' style={{ marginTop: "30px" }}>
                        Coming soon...
                    </Typography>


                </div>

            </div>

            <YLoader loading={loading} />
        </div>

        </>
    )
}

export default ComingSoon
