import React, { useState, useEffect, useContext } from "react";
import {
    Paper, Grid, Button, Typography, IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Select,
    InputLabel, MenuItem, FormControl, FormControlLabel, Switch, Slide
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import 'date-fns';
import "../../Profile/Profile.css";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import useGetData from '../../API/useGetData';
import YLoader from "../../components/Loader";
import moment from "moment";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Careersection = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    // Variables
    const [userId, setUserId] = useState("");
    const [userType, setUserType] = useState("");
    const [careerDetails, setCareerDetails] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [company, setCompany] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [editFromDate, setEditFromDate] = useState(null);
    const [editToDate, setEditToDate] = useState(null);
    const [editCompany, setEditCompany] = useState("");
    const [currentEmployer, setCurrentEmployer] = useState(false);
    const [careerId, setCareerId] = useState("");
    const [editCountry, setEditCountry] = useState("");
    const [editstate, setEditState] = useState("");
    const [editcity, setEditCity] = useState("");
    const [editStateChoices, setEditStateChoices] = useState("");
    const [editCityChoices, setEditCityChoices] = useState("");
    const [editCountryChoices, setEditCountryChoices] = useState("");
    const [editCurrentEmployer, setEditCurrentEmployer] = useState(false);

    const currentYear = new Date().getFullYear();

    // Services
    const { currentUser, getUserInfo } = useContext(YContext);
    const { apiRequests } = useAPIRequest();
    const { getCountry, getStates, getCity } = useGetData();
    const { getUserType } = useUserType();

    //  Dialogs
    const [openCareerDialog, setOpenCareerDialog] = React.useState(false);
    const [openEditCareerDialog, setOpenEditCareerDialog] = useState(false);

    const handleCareerDialogClickClose = () => {
        setOpenCareerDialog(false);
    };
    const handleCareerDialogClickOpen = () => {
        setOpenCareerDialog(true);
    };
    const handleEditCareerDialogClickClose = () => {
        setOpenEditCareerDialog(false);
    };
    const handleEditCareerDialogClickOpen = (id) => {
        setOpenEditCareerDialog(true);
        getEditCareerDetailsWithId(id);
    };

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    useEffect(() => {
        getReferenceData();
    }, []);

    // Functions
    const getUserCareerDetails = () => {
        setLoading(true);
        setUserId(currentUser.id);
        setUserType(currentUser.userType);
        setCareerDetails(currentUser.careerDetails);
        setLoading(false);
    }

    useEffect(() => {
        getUserCareerDetails();
    }, [currentUser])

    // Functions for create career

    const handleAddUserCareer = (company, fromDate, toDate, currentEmployer, country, state, city, userType, userId) => {

        let newCareerFromDate = moment(fromDate).format("MMM YYYY");
        let newCareerToDate = currentEmployer ? null : moment(toDate).format("MMM YYYY");

        const newCareer = {
            company: company,
            from: newCareerFromDate,
            to: newCareerToDate,
            currentEmployer: currentEmployer,
            country: country,
            state: state,
            city: city
        };
        createUserCareer(userType, userId, newCareer);
    };

    const createUserCareer = async (userType, userId, newCareer) => {

        setOpenCareerDialog(false);
        const user = getUserType(userType);
        const career = [newCareer]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                careerDetails: career,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
            setCompany('');
            setFromDate(null);
            setToDate(null);
            setCurrentEmployer(false);
            setCountry('');
            setState('');
            setCity('');
        }

    }

    // Functions for edit career

    const getEditReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setEditCountryChoices(countryOptions);
        }
    };

    const getEditStates = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setEditStateChoices(stateOptions);
            }
        }
    };

    const getEditCity = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setEditCityChoices(cityOptions);
            }
        }
    };

    const getEditCareerDetailsWithId = (id) => {

        getEditReferenceData();

        let career = careerDetails.find(item => item.id === id)

        let fromDate = new Date(career.from);
        let toDate = career.currentEmployer ? null : new Date(career.to);

        setCareerId(career.id);
        setEditCompany(career.company);
        setEditFromDate(fromDate);
        setEditToDate(toDate);
        setEditCurrentEmployer(career.currentEmployer);
        setEditCountry(career.country);
        getEditStates(career.country);
        setEditState(career.state);
        getEditCity(career.country, career.state);
        setEditCity(career.city);

    };

    const handleEditUserCareer = (editCompany, editFromDate, editToDate, editCurrentEmployer, editCountry, editstate, editcity, careerId, userType, userId) => {

        let editCareerFromDate = moment(editFromDate).format("MMM YYYY");
        let editCareerToDate = editCurrentEmployer ? null : moment(editToDate).format("MMM YYYY");

        const editCareerDetails = {
            company: editCompany,
            from: editCareerFromDate,
            to: editCareerToDate,
            currentEmployer: editCurrentEmployer,
            country: editCountry,
            state: editstate,
            city: editcity,
            id: careerId,
        };
        editUserCareer(userType, userId, editCareerDetails);
    };

    const editUserCareer = async (userType, userId, editCareerDetails) => {

        setOpenEditCareerDialog(false);
        const user = getUserType(userType);
        const career = [editCareerDetails]

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/${userId}`,
            method: "PUT",
            body: {
                careerDetails: career,
            },
            addToast,
        });
        if (data) {
            getUserInfo();
        }

    }

    // Functions for delete career

    const deleteUserCareer = async (userType, id) => {

        const user = getUserType(userType);

        const { response, data, error } = await apiRequests({
            endPoint: `${user}/career/${id}`,
            method: "DELETE",
            addToast,
        });

        if (response.status === 200) {
            getUserInfo();
        };

    }

    const validateCareerForm = () => {

        let valid = true;

        if (company.trim() === '') {
            addToast('Hospital name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (fromDate === null) {
            addToast('Please select a date for From date', { appearance: 'error' });
            valid = false;
        }

        if (currentEmployer === true && toDate !== null) {
            addToast('Please select either "Currently working here?" or provide a date for To date', { appearance: 'error' });
            valid = false;
        } else if (currentEmployer === false && toDate === null) {
            addToast('Please select either "Currently working here?" or provide a date for To date', { appearance: 'error' });
            valid = false;
        } else if (fromDate && toDate && toDate < fromDate) {
            addToast('To date cannot be smaller than From date', { appearance: 'error' });
            valid = false;
        }

        if (country.trim() === '') {
            addToast('Country cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (state.trim() === '') {
            addToast('State cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (city.trim() === '') {
            addToast('City cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleAddCareerSubmit = () => {

        if (validateCareerForm()) {
            handleAddUserCareer(company, fromDate, toDate, currentEmployer, country, state, city, userType, userId);
        }
    };


    const validateEditCareerForm = () => {

        let valid = true;

        if (editCompany.trim() === '') {
            addToast('Hospital name cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editFromDate === null) {
            addToast('Please select a date for From date', { appearance: 'error' });
            valid = false;
        }

        if (editFromDate && editToDate && editToDate < editFromDate) {
            addToast('To date cannot be smaller than From date', { appearance: 'error' });
            valid = false;
        }

        // if (editCurrentEmployer === true && editToDate !== null) {
        //     addToast('Please select either "Currently working here?" or provide a date for To date', { appearance: 'error' });
        //     valid = false;
        // } else if (editCurrentEmployer === false && editToDate === null) {
        //     addToast('Please select either "Currently working here?" or provide a date for To date', { appearance: 'error' });
        //     valid = false;
        // } else if (editFromDate && editToDate && editToDate < editFromDate) {
        //     addToast('To date cannot be smaller than From date', { appearance: 'error' });
        //     valid = false;
        // }

        if (editCountry.trim() === '') {
            addToast('Country cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editstate.trim() === '') {
            addToast('State cannot be empty', { appearance: 'error' });
            valid = false;
        }

        if (editcity.trim() === '') {
            addToast('City cannot be empty', { appearance: 'error' });
            valid = false;
        }

        return valid;
    };

    const handleEditCareerSubmit = () => {

        if (validateEditCareerForm()) {
            handleEditUserCareer(editCompany, editFromDate, editToDate, editCurrentEmployer, editCountry, editstate, editcity, careerId, userType, userId)
        }
    };

    return (
        <>
            {/* ############################ career section ######################################### */}
            <div>

                <div className="btn_container">
                    <Button variant="contained" endIcon={< AddIcon />} color="primary" onClick={handleCareerDialogClickOpen} className="profile_btn">
                        Career
                    </Button>
                </div>

                {careerDetails && careerDetails.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>

                            <div className="section_container">
                                <IconButton>
                                    <ApartmentIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary">
                                        {value.company}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {value.from} - {value.currentEmployer === true ? "Present" : value.to}
                                    </Typography>
                                    <Typography align="left" className="profile_description profile_text" color="text.primary">
                                        {value.city}, {value.state}, {value.country}
                                    </Typography>
                                </div>

                                <div className="section_iconbtn_container">
                                    <IconButton onClick={() => handleEditCareerDialogClickOpen(value.id)} className="icon_btn">
                                        < EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => deleteUserCareer(userType, value.id)} className="icon_btn">
                                        <DeleteIcon color="primary" />
                                    </IconButton>
                                </div>
                            </div>

                        </Paper>
                    );
                })}

                {/* ############################ Create Career dialog ######################################### */}
                <Dialog open={openCareerDialog} onClose={handleCareerDialogClickClose} fullScreen={fullScreen} TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleCareerDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Add Career
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Hospital Name"
                                    name="company"
                                    value={company}
                                    inputProps={{ style: { textTransform: "capitalize" } }}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setCompany(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="company"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "8px" }}>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="From date" views={['month', 'year']} disableFuture
                                            value={fromDate}
                                            onChange={(newValue) => setFromDate(newValue)}
                                            minDate={new Date(currentYear - 54, 0, 1)} // Set the minimum date to 54 years ago from the current year
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingTop: fullScreen ? "8px" : "0px" }}>
                                <FormControlLabel label="Currently working here?" labelPlacement="start" style={{ marginLeft: fullScreen ? "0px" : "16px" }}
                                    control={<Switch checked={currentEmployer} onChange={(event) => { setCurrentEmployer(event.target.checked) }} color="primary" />}
                                />
                            </Grid>
                            {currentEmployer === false &&
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="To date" views={['month', 'year']} disableFuture
                                                value={toDate}
                                                onChange={(newValue) => setToDate(newValue)}
                                                minDate={fromDate}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            }
                        </Grid>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="country-label"
                                        id="country-picker"
                                        label="Select Country"
                                        value={country}
                                        onChange={(event) => {
                                            setCountry(event.target.value);
                                            setStateChoices([]);
                                            setCityChoices([]);
                                            getStatesData(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""} selected>
                                            Select Country
                                        </MenuItem>
                                        {Array.isArray(countryChoices) &&
                                            countryChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl fullWidth >
                                    <InputLabel id="demo-dialog-select-label">
                                        State
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="state-label"
                                        id="state-picker"
                                        label="Select State"
                                        value={state}
                                        onChange={(event) => {
                                            setState(event.target.value);
                                            setCityChoices([]);
                                            getCityData(country, event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""}>Select State</MenuItem>
                                        {Array.isArray(stateChoices) &&
                                            stateChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        City
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="city-label"
                                        id="city-picker"
                                        label="Select City"
                                        value={city}
                                        onChange={(event) => setCity(event.target.value)}
                                    >
                                        <MenuItem value={""}>Select City</MenuItem>
                                        {Array.isArray(cityChoices) &&
                                            cityChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddCareerSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
                {/* ############################ Edit Career dialog ######################################### */}
                <Dialog open={openEditCareerDialog} onClose={handleEditCareerDialogClickClose} fullScreen={fullScreen} TransitionComponent={Transition}>
                    <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                        <IconButton
                            edge="start"
                            onClick={handleEditCareerDialogClickClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                        Edit Career
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" style={{ marginTop: "8px" }}>
                            <Grid item xs={12}>
                                <TextField fullWidth
                                    label="Hospital Name"
                                    name="companyName"
                                    value={editCompany}
                                    onChange={(event) => {
                                        const inputValue = event.target.value;
                                        const formattedValue = inputValue
                                            .split(" ")
                                            .map((word) => {
                                                if (word === word.toUpperCase()) {
                                                    return word;
                                                } else {
                                                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                }
                                            })
                                            .join(" ");

                                        setEditCompany(formattedValue);
                                    }}
                                    autoComplete="off"
                                    id="companyName"
                                    variant="outlined"
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginBottom: "8px" }}>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker label="From date" views={['month', 'year']} disableFuture
                                            value={editFromDate}
                                            onChange={(newValue) => setEditFromDate(newValue)}
                                            minDate={new Date(currentYear - 54, 0, 1)} // Set the minimum date to 54 years ago from the current year
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ paddingTop: fullScreen ? "8px" : "0px" }}>
                                <FormControlLabel label="Currently working here?" labelPlacement="start" style={{ marginLeft: fullScreen ? "0px" : "16px" }}
                                    control={<Switch checked={editCurrentEmployer} onChange={(event) => { setEditCurrentEmployer(event.target.checked) }} color="primary" />}
                                />
                            </Grid>
                            {editCurrentEmployer === false &&
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="To date" views={['month', 'year']} disableFuture
                                                value={editToDate}
                                                onChange={(newValue) => setEditToDate(newValue)}
                                                minDate={editFromDate}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            }
                        </Grid>

                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="country-label"
                                        id="country-picker"
                                        label="Select Country"
                                        value={editCountry}
                                        onChange={(event) => {
                                            setEditCountry(event.target.value);
                                            setEditStateChoices([]);
                                            setEditCityChoices([]);
                                            getEditStates(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""} selected>
                                            Select Country
                                        </MenuItem>
                                        {Array.isArray(editCountryChoices) &&
                                            editCountryChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-dialog-select-label">
                                        State
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="state-label"
                                        id="state-picker"
                                        label="Select State"
                                        value={editstate}
                                        onChange={(event) => {
                                            setEditState(event.target.value);
                                            setEditCityChoices([]);
                                            getEditCity(editCountry, event.target.value);
                                        }}
                                    >
                                        <MenuItem value={""}>Select State</MenuItem>
                                        {Array.isArray(editStateChoices) &&
                                            editStateChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-dialog-select-label">
                                        City
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="city-label"
                                        id="city-picker"
                                        label="Select City"
                                        value={editcity}
                                        onChange={(event) => setEditCity(event.target.value)}
                                    >
                                        <MenuItem value={""}>Select City</MenuItem>
                                        {Array.isArray(editCityChoices) &&
                                            editCityChoices.map((choice, index) => {
                                                return (
                                                    <MenuItem value={choice} key={index}>{choice}</MenuItem>
                                                );
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditCareerSubmit} fullWidth variant="contained" className='dialog-button1' color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                <YLoader loading={loading} />
            </div >
        </>
    )
}

export default Careersection
