import {
    Button, Grid, Typography,
    Card, CardActionArea, CardMedia, CardContent, CardActions,
} from '@mui/material';
import "./newlaunch.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ImageNotAvailable from "../../../assets/images/imagenotavailable.jpg";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Constants from "../../../MeddyConnectConstant";

const NewLaunch = ({ typeNewProductLaunch, handleRemoveNewProductLaunchDialogClickOpen }) => {
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleEditNewProductLaunch = (newProductId) => {
        history.push({
            pathname: '/editnewproductlaunch',
            state: {
                data: newProductId
            }
        })
    };

    const handleShowInterested = (newProductId) => {
        history.push(`${Constants.SHOW_INTERESTED_USER}/${newProductId}`);
    };

    return (
        <>
            {fullScreen ? (
                <Grid container direction="row" spacing={2} >

                    {typeNewProductLaunch && typeNewProductLaunch.length > 0 ? (
                        typeNewProductLaunch.map((item) => {
                            return (
                                <Grid item xs={12} key={item.id}>
                                    <Card className='inventory-newlaunch-card' style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                                        <CardActionArea >
                                            {item.itemImages && item.itemImages[0] && item.itemImages[0].imageLink ?

                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={item.itemImages[0].imageLink.href}
                                                    style={{ objectFit: "fill" }}
                                                    alt="inventory newlaunch picture"
                                                />
                                                :
                                                <CardMedia
                                                    component="img"
                                                    height="180"
                                                    image={ImageNotAvailable}
                                                    style={{ objectFit: "fill" }}
                                                    alt="inventory newlaunch picture"
                                                />
                                            }

                                            <CardContent>
                                                <Grid container direction="row" alignItems="center" >
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" align="left" color="text.primary" className="newlaunch-text-capital  newlaunch-title" >
                                                            {item.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" align="left" color="text.primary" className='newlaunch-description'>
                                                            {item.description}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle2" align="left" color="text.primary" className="newlaunch-text-capital ">
                                                            Posted on: {moment(item.created).format("D-MMM-YYYY")}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>

                                            </CardContent>
                                        </CardActionArea>

                                        <CardActions style={{ padding: "0px 8px 8px 8px" }}>
                                            <Grid container direction="column">
                                                <Grid item xs={12} >
                                                    <Button variant="contained" fullWidth className="newlaunchcard-btn" color="primary"
                                                        onClick={() => handleShowInterested(item.id)}
                                                    >
                                                        Show interested
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Button variant="contained" fullWidth className="newlaunchcard-btn" color="primary"
                                                        onClick={() => handleEditNewProductLaunch(item.id)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <Button variant="contained" fullWidth className="newlaunchcard-btn newlaunchdelete-btn"
                                                        onClick={() => { handleRemoveNewProductLaunchDialogClickOpen(item.id) }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>

                                    </Card>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                    Your inventory is currently empty.
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                    }

                </Grid>
            )
                :
                (
                    <>
                        {typeNewProductLaunch && typeNewProductLaunch.length > 0 ? (
                            typeNewProductLaunch.map((item) => {
                                return (
                                    <div className="newlaunch-card" key={item.id} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>

                                        {item.itemImages && item.itemImages[0] && item.itemImages[0].imageLink ?
                                            <div className='newlaunch-img-container'>
                                                <img src={item.itemImages[0].imageLink.href} alt="inventory newlaunch" className='newlaunch-img' />
                                            </div>
                                            :
                                            <div className='newlaunch-img-container'>
                                                <img src={ImageNotAvailable} alt="inventory newlaunch" className='newlaunch-img' />
                                            </div>
                                        }

                                        <div className="newlaunch-info">
                                            <Typography variant="h6" align="left" color="text.primary" className="newlaunch-text-capital  newlaunch-title" >
                                                {item.title}
                                            </Typography>
                                            <Typography variant="subtitle1" align="left" color="text.primary" className='newlaunch-description'>
                                                {item.description}
                                            </Typography>
                                            <Typography variant="subtitle2" align="left" color="text.primary" className="newlaunch-text-capital ">
                                                Posted on: {moment(item.created).format("D-MMM-YYYY")}
                                            </Typography>

                                            <div className='newlaunch-btn-container'>
                                                <Button variant="contained" color="primary" className="newlaunch-button" onClick={() => handleShowInterested(item.id)}>Show interested</Button>
                                                <Button variant="contained" color="primary" className="newlaunch-button" onClick={() => handleEditNewProductLaunch(item.id)}>Edit</Button>
                                                <Button variant="contained" className="newlaunch-button newlaunchdelete-btn" onClick={() => { handleRemoveNewProductLaunchDialogClickOpen(item.id) }}>Delete</Button>
                                            </div>

                                        </div>

                                    </div>
                                );
                            })
                        ) : (
                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        Your inventory is currently empty.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )

                        }
                    </>
                )
            }

        </>
    )
}

export default NewLaunch
