import { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Typography, Avatar, Grid } from '@mui/material';
import useAPIRequest from "../../API/useApiRequest";
import UrlRoute from "../../API/UrlRoute";
import { useTheme } from '@mui/material/styles';
import { useToasts } from "react-toast-notifications";
import ObservershipApplicantDetailsDialog from "./ObservershipApplicantDetailsDialog";
import moment from "moment";
import "./viewapplicantsfromobservership.css";
import { useParams } from 'react-router-dom';

const ViewApplicantsFromObservership = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { observershipApplicantsId } = useParams();

  const [observershipApplicantsList, setObservershipApplicantsList] = useState([]);
  const [applicantId, setApplicantId] = useState("");
  const [observershipId, setObservershipId] = useState("");

  // Services
  const { apiRequests } = useAPIRequest();

  // Dialogs
  const [openApplicantInfoDialog, setOpenApplicantInfoDialog] = useState(false);

  const handleApplicantInfoDialogClickOpen = (id, internshipId) => {
    setOpenApplicantInfoDialog(true);
    setApplicantId(id);
    setObservershipId(internshipId);
  };

  const handleApplicantInfoDialogClickClose = () => {
    setOpenApplicantInfoDialog(false);
  };

  useEffect(() => {
    getObservershipApplicantsList(observershipApplicantsId);
  }, [])

  const getObservershipApplicantsList = async (observershipApplicantsId) => {
    setLoading(true)
    const { response, data, error } = await apiRequests({
      endPoint: `${UrlRoute.GET_APPLICANTS_FOR_OBSERVERSHIP_URL}/${observershipApplicantsId}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setObservershipApplicantsList(data);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }
  };

  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="observershipapplicantslist-Drawer">
            <Navigation />
          </div>

          <div className="observershipapplicantslist-container">

            <div className="observership-list">

              {observershipApplicantsList.length > 0 ?

                observershipApplicantsList.map((value) => {
                  return (
                    <div className="observershipapplicantslist-card" key={value.id} onClick={() => handleApplicantInfoDialogClickOpen(value.id, value.internshipId)} style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929" }}>
                      <Avatar src={value.appliedProfileLink && value.appliedProfileLink.href ? value.appliedProfileLink.href : " "} className='observership-Picture' />
                      <div className="observershipapplicants-info">

                        <Typography color="text.primary" className="observership-title">
                        {value.appliedUserName}
                        </Typography>
                        <Typography color="text.primary" className="observership-subtitle">
                          {value.created ? moment(value.created).format("MMM D, YYYY") : ""}
                        </Typography>

                      </div>
                    </div>
                  );
                })
                :
                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={12}>
                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                      No results found
                    </Typography>
                  </Grid>
                </Grid>
              }


            </div>

            <ObservershipApplicantDetailsDialog
              openApplicantInfoDialog={openApplicantInfoDialog}
              handleApplicantInfoDialogClickClose={handleApplicantInfoDialogClickClose}
              applicantId={applicantId}
              observershipId={observershipId}
            />

          </div>

        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ViewApplicantsFromObservership
