import usePublicAPIRequest from '../API/useApiPublicRequest';
import useAPIRequest from "../API/useApiRequest";
import UrlRoute from "../API/UrlRoute";

const useGetData = () => {
    const { apiPublicRequests } = usePublicAPIRequest();
    const { apiRequests } = useAPIRequest();

    const getProfession = async (addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: UrlRoute.REFERENCES_PROFESSIONS_URL,
            method: "GET",
            addToast,
        });
        if (data.response) {
            return data.response;
        }
    };

    const getCountry = async (addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: UrlRoute.REFERENCES_COUNTRIES_URL,
            method: "GET",
            addToast,
        });
        if (data.response) {
            return data.response;
        }
    }

    const getStates = async (city, addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: `references/${city}/states`,
            method: "GET",
            addToast,
        });
        if (data.response) {
            return data.response;
        }
    }

    const getCity = async (country, state, addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: `references/${country}/${state}/cities`,
            method: "GET",
            addToast,
        });
        if (data.response) {
            return data.response;
        }
    }

    const getBrandNames = async (brandName, addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: `search/brand?q=${brandName}`,
            method: "GET",
            addToast,
        });
        if (data) {
            return data;
        }
    };

    const getSpecialities = async (speciality, addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: `search/speciality?q=${speciality}`,
            method: "GET",
            addToast,
        });
        if (data) {
            return data;
        }
    };

    const getProductCategories = async (addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: UrlRoute.PRODUCT_CATEGORIES_URL,
            method: "GET",
            addToast,
        });
        if (data) {
            return data;
        }
    };

    const getSkills = async (skills, addToast) => {
        const { data } = await apiRequests({
            endPoint: `search/skills?q=${skills}`,
            method: "GET",
            addToast,
        });
        if (data) {
            return data;
        }
    };

    const getCollege = async (college, addToast) => {
        const { data } = await apiPublicRequests({
            endPoint: `search/college?q=${college}`,
            method: "GET",
            addToast,
        });
        if (data) {
            return data;
        }
    };

    const getHashtags = async (hashtags, addToast) => {
        const { data } = await apiRequests({
            endPoint: `search/tags?q=${hashtags}`,
            method: "GET",
            addToast,
        });
        if (data) {
            return data;
        }
    };

    return {
        getProfession,
        getCountry,
        getStates,
        getCity,
        getBrandNames,
        getSpecialities,
        getProductCategories,
        getSkills,
        getCollege,
        getHashtags
    }
}

export default useGetData