import React from "react";
import { Paper, Button, Typography, IconButton } from "@mui/material";
import "../../Profile/Profile.css";
import StarsIcon from '@mui/icons-material/Stars';

const ViewAward = ({ userAwardData }) => {

    return (
        <>
            {/* ############################ Award section ######################################### */}
            <div>

                <div>
                    <Typography align="left" className="view-user-title" color="text.primary">
                        Award
                    </Typography>
                </div>

                {userAwardData && userAwardData.map((value) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={value.id}>

                            <div className="section_container">
                                <IconButton>
                                    <StarsIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {value.title}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {value.issuer}
                                    </Typography>
                                    <Typography align="left" className="profile_description profile_text" color="text.primary">
                                        {value.issuedDate}
                                    </Typography>
                                </div>

                            </div>

                        </Paper>
                    );
                })}
            </div>
        </>
    )
}

export default ViewAward
