import React from 'react'

const CurrencyFormat = ({currencyPrice}) => {
  return (
    <>
     {Intl.NumberFormat("en-IN", { style: "currency", currency: "INR", minimumFractionDigits: 0, }).format(currencyPrice)}
    </>
  )
}

export default CurrencyFormat