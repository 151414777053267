 
export default function swDev() {
    let swUrl = `${process.env.PUBLIC_URL}/sw.js`
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register(swUrl).then(response => {
            // console.log('dev Service worker', response)
        }).catch(error => {
            // console.log(error, 'error adding service worker')
        })
    } else { console.log('service worker not supported by browser'); }
}
