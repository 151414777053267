import React, { useState, useContext, useEffect } from 'react';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, TextField, Paper, Button, InputLabel, Select, MenuItem, FormControl, Typography, FormControlLabel, Radio, RadioGroup, FormLabel, IconButton } from '@mui/material';
import { YContext } from "../../Context/YContext";
import { useToasts } from "react-toast-notifications";
import "./organizeevent.css";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { addMonths } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import eventbackgroundimg from "../../assets/images/eventbackgroundimg.jpg";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

const OrganizeEvent = () => {
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  const { currentUser } = useContext(YContext);
  const [pincodes, setPincodes] = useState(currentUser.pinCode);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [stateChoices, setStateChoices] = useState("");
  const [cityChoices, setCityChoices] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [value, setValue] = useState('');
  const [eventStartDate, setEventStartDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());
  const [eventStartTime, setEventStartTime] = useState(new Date());
  const [eventEndTime, setEventEndTime] = useState(new Date());
  const [eventType, setEventType] = useState('');
  const [eventImage, setEventImage] = useState('');
  const [imagesLIst, setImagesLIst] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  // useEffect(() => {
  //   getReferenceData();
  // }, []);

  // const getReferenceData = async () => {
  //   const countryOptions = await getData.getCountry(addToast);
  //   if (countryOptions) {
  //     // setCountry("");
  //     setCountryChoices(countryOptions);
  //   }
  // };

  // const getStates = async (country) => {

  //   if (country && country != "") {
  //     let stateOptions = [];
  //     stateOptions = await getData.getStates(country, addToast);
  //     if (stateOptions) {
  //       // setState("");
  //       setStateChoices(stateOptions);
  //     }
  //   }
  // };

  // const getCity = async (country, state) => {

  //   if (country && country != "" && state && state != "") {
  //     let cityOptions = [];
  //     cityOptions = await getData.getCities(country, state, addToast);
  //     if (cityOptions) {
  //       // setCity("");
  //       setCityChoices(cityOptions);
  //     }
  //   }
  // };

  const handleChange = (event) => {
    setEventType(event.target.value);
  };

  const changeHandler = (e) => {
    const img = e.target.files[0];
    setImagesLIst(img)
    let url = URL.createObjectURL(img);
    setEventImage(url)
  }

  const handleImageDelete = () => {
    setEventImage("");
  };

  return (
    <>
      <div style={{ backgroundColor: "#fff", height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="event-drawer">
            <Navigation />
          </div>

          <div className="organize-event-container">

            <div>
              <Paper elevation={2} className="organize-eventpaper-container">

                <Grid container direction="row" rowSpacing={1} className="organize-event-upload-Container">
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Event Image :
                    </Typography>
                  </Grid>
                  {!eventImage ?
                    <Grid item xs={12} style={{ position: "relative", height: "300px", objectFit: "cover" }}>
                      <img src={eventbackgroundimg} alt="eventpattern" height="100%" width="100%" />
                      <IconButton component="label" style={{ fontSize: fullScreen ? "18px" : "22px" }} className='edit-iconbtn'>
                        <input hidden accept="image/*" type="file" onChange={changeHandler} />
                        <EditIcon style={{ color: "#002E6E" }} />
                      </IconButton>
                    </Grid>
                    :
                    <Grid item xs={12} style={{ position: "relative", height: "300px", objectFit: "cover" }}>
                      <img src={eventImage} alt="eventuploadimg" height="100%" width="100%" />
                      <CloseIcon style={{ position: "absolute", top: "10px", right: "6px", fontSize: fullScreen ? "18px" : "22px" }} onClick={handleImageDelete} />
                    </Grid>
                  }
                </Grid>

                <Grid container direction="row" spacing={0.5} >
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="event-type-radio-btngroup" style={{color:"#000000"}}>Event Type :</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="event-type-radio-btngroup"
                        name="event-type-radio-btn"
                        value={eventType}
                        onChange={handleChange}
                      >
                        <FormControlLabel value="online" control={<Radio />} label="Online" />
                        <FormControlLabel value="inperson" control={<Radio />} label="In Person" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "-10px" }}>
                    <TextField fullWidth
                      label="Event Name"
                      name="eventname"
                      // value={productName}
                      // onChange={(event) => setProductName(event.target.value)}
                      autoComplete="off"
                      id="eventname"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginTop: "4px" }}>
                      Description :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className='event-description-container'>
                    {/* <ReactQuill theme="snow" modules={modules} value={value} onChange={setValue} className='event-description-editor' /> */}
                  </Grid>
                  <Grid item xs={12} >
                    <Typography variant="body1" gutterBottom style={{ marginTop: "10px" }}>
                      Start Date And Time:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <DatePicker selected={eventStartDate} onChange={(date) => setEventStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      isClearable
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 5)}
                      showDisabledMonthNavigation
                      className='organize-event-datepicker'
                      portalId="root-portal"
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <DatePicker
                      selected={eventStartTime}
                      onChange={(date) => setEventStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      isClearable
                      className='organize-event-datepicker'
                    /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom style={{ marginTop: "10px" }}>
                      End Date And Time:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <DatePicker selected={eventEndDate} onChange={(date) => setEventEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      isClearable
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 5)}
                      showDisabledMonthNavigation
                      className='organize-event-datepicker'
                      portalId="root-portal"
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <DatePicker
                      selected={eventEndTime}
                      onChange={(date) => setEventEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      isClearable
                      className='organize-event-datepicker'
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth
                      label="Event Ticket Price"
                      name="ticketprice"
                      // value={brandName}
                      // onChange={(event) => setBrandName(event.target.value)}
                      autoComplete="off"
                      id="ticketprice"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth
                      label="Seating Capacity"
                      name="capacity"
                      required
                      // value={productName}
                      // onChange={(event) => setProductName(event.target.value)}
                      autoComplete="off"
                      id="capacity"
                      variant="outlined"
                    />
                  </Grid>
                  {eventType === "online" ?
                    <Grid item xs={12} >
                      <TextField fullWidth
                        label="External Event Link"
                        name="eventlink"
                        // value={brandName}
                        // onChange={(event) => setBrandName(event.target.value)}
                        autoComplete="off"
                        id="eventlink"
                        variant="outlined"
                      />
                    </Grid>
                    : "" }
                    {eventType === "inperson" ?
                    <>
                      <Grid item xs={12}>
                        <TextField fullWidth
                          label="Address"
                          name="address"
                          // value={brandName}
                          // onChange={(event) => setBrandName(event.target.value)}
                          autoComplete="off"
                          id="address"
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-dialog-select-label">
                            Country
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="country-label"
                            id="country-picker"
                            label="Select Country"
                            value={country}
                            onChange={(event) => {
                              setCountry(event.target.value);
                              setStateChoices([]);
                              setCityChoices([]);
                              getStates(event.target.value);
                            }}
                          >
                            <MenuItem value={""} selected>
                              Select Country
                            </MenuItem>
                            {Array.isArray(countryChoices) &&
                              countryChoices.map((choice) => {
                                return (
                                  <MenuItem value={choice}>{choice}</MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                          <InputLabel id="demo-dialog-select-label">
                            State
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="state-label"
                            id="state-picker"
                            label="Select State"
                            value={state}
                            onChange={(event) => {
                              setState(event.target.value);
                              setCityChoices([]);
                              getCity(country, event.target.value);
                            }}
                          >
                            <MenuItem value={""}>Select State</MenuItem>
                            {Array.isArray(stateChoices) &&
                              stateChoices.map((choice) => {
                                return (
                                  <MenuItem value={choice}>{choice}</MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-dialog-select-label">
                            City
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="city-label"
                            id="city-picker"
                            label="Select City"
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                          >
                            <MenuItem value={""}>Select City</MenuItem>
                            {Array.isArray(cityChoices) &&
                              cityChoices.map((choice) => {
                                return (
                                  <MenuItem value={choice}>{choice}</MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <TextField fullWidth
                          label="Pincode"
                          name="pincode"
                          value={pincodes}
                          onChange={(event) => setPincodes(event.target.value)}
                          inputProps={{ maxLength: 6 }}
                          autoComplete="off"
                          id="pincode"
                          variant="outlined"
                        />
                      </Grid>
                    </>
                    : "" }
                </Grid>
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button variant="contained" fullWidth className='organize-event-button'>Organize Event</Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>

          </div>
        </div>
        <YLoader loading={loading} />
      </div >

    </>
  )
}

export default OrganizeEvent