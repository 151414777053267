import React from "react";
import { Paper, IconButton, Typography, Button } from "@mui/material";
import SchoolIcon from '@mui/icons-material/School';
import "../../Profile/Profile.css";

const ViewStudy = ({ userStudyData }) => {

    return (
        <>  {/* ############################ study section ######################################### */}
            <div>

                <div>
                    <Typography align="left" className="view-user-title" color="text.primary">
                        Education
                    </Typography>
                </div>

                {userStudyData && userStudyData.map((item) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={item.id}>

                            <div className="section_container">
                                <IconButton>
                                    <SchoolIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {item.degree}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {item.collegeName}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {item.from} - {item.currentCollage === true ? "Present" : item.to}
                                    </Typography>
                                    <Typography align="left" className="profile_description profile_text" color="text.primary">
                                        {item.city}, {item.state}, {item.country}
                                    </Typography>
                                </div>

                            </div>

                        </Paper>
                    );
                })}

            </div>
        </>
    )
}

export default ViewStudy
