import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Slide, IconButton, Typography, Grid, Button } from '@mui/material';
import './alleventcards.css';
import { useToasts } from "react-toast-notifications";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceIcon from '@mui/icons-material/Place';
import VideocamIcon from '@mui/icons-material/Videocam';
import LaunchIcon from '@mui/icons-material/Launch';
import GroupsIcon from '@mui/icons-material/Groups';
import CurrencyFormat from '../../utils/CurrencyFormat';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="eventDescription-text">
            {isReadMore ? text.slice(0, 300) : text}
            <span onClick={toggleReadMore} className="read-or-hide-eventdescription">
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};

const EventDialog = ({ eventDialogOpen, handleEventDialogClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {/* ############################ Show event dialog ######################################### */}
            <Dialog
                open={eventDialogOpen}
                onClose={handleEventDialogClose}
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
                TransitionComponent={Transition}
            >
                <DialogContent style={{ padding: "0px" }}>
                    {data.map((element) => {
                        return (
                            <>
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <IconButton
                                            edge="start"
                                            onClick={handleEventDialogClose}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                            }}
                                        >
                                            <CloseIcon style={{ color: "white" }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img src={element.eventimage} alt="event images" className='event-image' />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" rowSpacing={0.5} style={{ padding: "12px" }}>
                                    <Grid item xs={12}>
                                        <div className="eventname_details">
                                            <div className="eventname_info">
                                                <Typography variant="h6" align="left" className="text-utility event-title">
                                                    {element.eventname}
                                                </Typography>
                                            </div>
                                            <div className="eventtype_info">
                                                <Typography variant="subtitle2">
                                                    <VideocamIcon className='eventDialog-icon' />
                                                    Online
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="eventdialog_details">
                                            <Typography variant="subtitle1" component="span">
                                                <span className='eventcard-text '>Event By&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                                            </Typography>
                                            <div className="eventdialog_info">
                                                <Typography variant="subtitle2" component="span">
                                                    &nbsp;{element.eventhostname}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="eventdialog_details">
                                            <Typography variant="subtitle1">
                                                <span className='eventcard-text'>Event Start&nbsp;:</span>
                                            </Typography>
                                            <div className="eventdialog_info">
                                                <Typography variant="subtitle2">
                                                    <AccessTimeIcon className='eventDialog-icon' />
                                                    {element.eventDate}, {element.eventtime}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="eventdialog_details">
                                            <Typography variant="subtitle1">
                                                <span className='eventcard-text'>Event End&nbsp;&nbsp;:</span>
                                            </Typography>
                                            <div className="eventdialog_info">
                                                <Typography variant="subtitle2">
                                                    <AccessTimeIcon className='eventDialog-icon' />
                                                    {element.eventDate}, {element.eventtime}
                                                </Typography>
                                            </div>
                                        </div>                                                                                                                                                                                      
                                    </Grid>
                                    {element.eventMode !== "online" ?
                                        <Grid item xs={12}>
                                            <div className="eventdialog_details">
                                                <Typography variant="subtitle1">
                                                    <span className='eventcard-text '>Event Address&nbsp;:</span>
                                                </Typography>
                                                <div className="eventdialog_info">
                                                    <Typography variant="subtitle2">
                                                        <PlaceIcon className='eventDialog-icon eventDialog_address-icon' />
                                                        {element.address}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <div className="eventdialog_details">
                                                <Typography variant="subtitle1" component="span">
                                                    <span className='eventcard-text '>Event Link&nbsp;: </span>
                                                </Typography>
                                                <div className="eventdialog_info">
                                                    <Typography variant="subtitle2" component="span">
                                                        <LaunchIcon className='eventDialog-icon' />
                                                        <a href="#" target="_blank" rel="noreferrer"
                                                            style={{ textDecoration: "none" }}>
                                                            {element.eventlink}
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <div className="eventdialog_details">
                                            <Typography variant="subtitle1" component="span">
                                                <span className='eventcard-text '>Capacity&nbsp;&nbsp;&nbsp;&nbsp;:</span>
                                            </Typography>

                                            <div className="eventdialog_info">
                                                <Typography variant="subtitle2" component="span">
                                                    &nbsp;&nbsp;<GroupsIcon className='eventDialog-icon' />
                                                    &nbsp;<span style={{ verticalAlign: "middle" }}>200</span>
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="eventdialog_details">
                                            <Typography variant="subtitle1" component="span">
                                                <span className='eventcard-text '>Ticket Price&nbsp;:  </span>
                                            </Typography>
                                            <div className="eventdialog_info">
                                                <Typography variant="subtitle2" component="span">
                                                    &nbsp;<CurrencyFormat currencyPrice={element.price} />
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography variant="subtitle1" >
                                            <span className='eventcard-text '> Event Description : </span>
                                        </Typography>

                                        <Typography variant="subtitle2" >
                                            <ReadMore>
                                                {element.eventDescription}
                                            </ReadMore>
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </>
                        );
                    })
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" fullWidth className='register-button'>Register</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EventDialog

const data =
    [
        {
            id: 0,
            eventhostname: "Dr.Rahul Shah",
            eventimage: "https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            eventname: "NYC Food Festival",
            price: "2000",
            eventDescription: " This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels,if you like.Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken and chorizo in the pan.",
            address: "Central Park Manhattan ,New York City.",
            eventDate: "May 24, 2023",
            eventtime: "9.00 AM To 5.00 PM",
            eventMode: "online",
            eventlink: "https://meet.google.com/mgo-bvfg-pol",
            month: "Oct",
            date: "29"
        },

    ];