import React, { useState, useEffect, useContext } from "react";
import { Paper, Grid, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "../../Profile/Profile.css";
import useAPIRequest from "../../API/useApiRequest";
import YLoader from "../../components/Loader";
import { fileCompressor } from "../../components/FileCompressor";
import { useToasts } from "react-toast-notifications";
import { YContext } from "../../Context/YContext";
import useUserType from "../../utils/useUserType";
import UrlRoute from "../../API/UrlRoute";

const ProfilePhotos = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  // Variables
  const [fetchedImages, setFetchedImages] = useState([]);
  const [showSelectedImages, setShowSelectedImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);

  // Services
  const { currentUser, getUserInfo } = useContext(YContext);
  const { apiRequests } = useAPIRequest();
  const { getUserType } = useUserType();

  // Functions
  const getProfilePhotos = () => {

    setLoading(true);
    setFetchedImages(currentUser.photos);
    setLoading(false);
  }

  useEffect(() => {
    getProfilePhotos();
  }, [currentUser])


  const handleImageChange = async (e) => {
    setLoading(true);
    const files = e.target.files;
    const selectedImagesArray = Array.from(files);
    const compressedImagesArray = [];

    if (selectedImagesArray.length > 5) {
      setLoading(false);
      addToast("You can not upload more than five images", { appearance: "error" });
      return;
    }

    const showImages = selectedImagesArray.map((file) => URL.createObjectURL(file));
    setShowSelectedImages((prevImages) => prevImages.concat(showImages));

    for (const image of selectedImagesArray) {

      try {

        const compressedImage = await fileCompressor(image, addToast);
        const imageData = {
          type: image.type,
          compressedImage: compressedImage
        };
        compressedImagesArray.push(imageData);

      } catch (error) {
        setLoading(false);
        console.error('Error compressing image:', error);
      }
    }

    setCompressedImages(prevCompressedImages => [...prevCompressedImages, ...compressedImagesArray]);
    setLoading(false);
  };

  const handleImageDelete = (id) => {

    const newImages = showSelectedImages.filter((photo, index) => index !== id)
    setShowSelectedImages(newImages)

    const fileCompressedArray = compressedImages.filter((photo, index) => index !== id)
    setCompressedImages(fileCompressedArray)

  };

  const handleDeleteImageFromApi = async (imgId) => {
    setLoading(true);
    const { response } = await apiRequests(
      {
        endPoint: `${UrlRoute.DELETE_PROFILE_PHOTO_URL}/${imgId}`,
        method: "DELETE",
        addToast
      }
    );
    if (response.status === 200) {
      setLoading(false);
      addToast("Image deleted successfully!", { appearance: "success" });
      getUserInfo();
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  }

  const uploadProfilePhotos = async () => {
    setLoading(true);

    if (showSelectedImages.length !== 0) {

      const photos = new FormData()

      // Iterate over the compressedImages array
      compressedImages.forEach((imageData, index) => {
        try {
          const blob = base64ToBlob(imageData.compressedImage); // Convert base64 string to blob

          // Append the blob with the correct MIME type and filename
          photos.append("profiles", blob, `image_${index}.${imageData.type}`, `image/${imageData.type}`);
        } catch (error) {
          console.error("Error converting image:", error);
        }
      });

      const { response, data, error } = await apiRequests({
        endPoint: `${UrlRoute.UPLOAD_PROFILE_PHOTOS_URL}`,
        method: "POST",
        body: photos,
        addToast,
      });
      if (data) {
        setShowSelectedImages([]);
        setCompressedImages([]);
        setLoading(false);
        addToast("Images uploaded successfully !!!", { appearance: "success" });
        getUserInfo();
      } else {
        setShowSelectedImages([]);
        setCompressedImages([]);
        setLoading(false);
        addToast("Image upload failed. Please try uploading again!", { appearance: "error" });
      }
    } else {
      setLoading(false);
      addToast("Please select at least one image for upload.!", { appearance: "error" });
    }

  };

  return (
    <>
      {/* ############################ photos section ######################################### */}
      <div>

        <div className="btn_container">
          <Button variant="contained" endIcon={< AddIcon />} component="label" color="primary" className="profile_btn">
            Photos
            <input hidden accept="image/*" multiple type="file" onChange={handleImageChange} />
          </Button>
        </div>

        {
          fetchedImages && fetchedImages.length > 0 && (
            <Paper elevation={4} className="paper-container">
              <div className="profile-gallery">
                {fetchedImages && fetchedImages.map((item, index) => {
                  return (
                    <div key={item.id} className="image-container">
                      <img src={item.profileLink ? item.profileLink?.href : item} alt="profile photos" />
                      <CloseIcon className="delete-icon" style={{ fontSize: fullScreen ? "18px" : "22px" }} onClick={() => handleDeleteImageFromApi(item.id)} />
                    </div>
                  );
                })}
              </div>
            </Paper>
          )
        }

        {
          showSelectedImages.length > 0 &&
          <Paper elevation={4} className="paper-container">
            <div className="profile-gallery">
              {showSelectedImages && showSelectedImages.map((value, index) => {
                return (
                  <div key={index} className="image-container">
                    <img src={value} alt="profile photos" />
                    <CloseIcon className="delete-icon" style={{ fontSize: fullScreen ? "18px" : "22px" }} onClick={() => handleImageDelete(index)} />
                  </div>
                );
              })}
            </div>

            <Grid container direction="row">
              <Grid item xs={12}>
                <Button variant="contained" fullWidth className='upload-image-button' color="primary" onClick={uploadProfilePhotos}>Upload Images</Button>
              </Grid>
            </Grid>

          </Paper>
        }

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ProfilePhotos