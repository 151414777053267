import React, { useState, useEffect } from 'react'
import {
    Grid, Typography, Dialog, DialogTitle, IconButton,
    Button, DialogContent, DialogActions,
    Paper, Chip, Slide, TableBody, TableHead, Table, TableCell, TableRow, TableContainer
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import PhoneIcon from '@mui/icons-material/Phone';
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./annualcontractdetails.css";
import ImagesCarousel from '../Components/ImagesCarousel';
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import ImageEnlargeDialog from '../Components/ImageEnlargeDialog';
import moment from "moment";
import { BsChat } from "react-icons/bs";
import Constants from "../../MeddyConnectConstant";
import { useHistory } from "react-router-dom";
import UrlRoute from "../../API/UrlRoute";
import { FaRegFilePdf } from "react-icons/fa";
import PDFViewer from "../../components/PDFViewer";
import CloseIcon from '@mui/icons-material/Close';
import ReadMore from "../../components/ReadMore";
import { useParams } from 'react-router-dom';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AnnualContractDetails = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const { addToast } = useToasts();
    const { contractId } = useParams();

    const [contractDetails, setContractDetails] = useState(null);
    const [sellerContactNumber, setSellerContactNumber] = useState("");
    const [sellerName, setSellerName] = useState("");
    const [chipData, setChipData] = React.useState([]);
    const [productImages, setProductImages] = useState([]);
    const [clickedImage, setClickedImage] = useState('');
    const [annualItemPackages, setAnnualItemPackages] = useState([]);
    const [selectedPDF, setSelectedPDF] = useState({ contentType: null, content: null });
    const [uploadedAttachment, setUploadedAttachment] = useState([]);

    // Services
    const { apiRequests } = useAPIRequest();

    // Dialogs
    const [enlargeImgDialog, setEnlargeImgDialog] = useState(false);
    const [openAlertVerificationDialog, setOpenAlertVerificationDialog] = React.useState(false);
    const [openShowSellerContactDetailsDialog, setOpenShowSellerContactDetailsDialog] = useState(false);
    const [openDocumentDialog, setOpenDocumentDialog] = useState(false);

    const handleAlertVerificationDialogClickOpen = () => {
        setOpenAlertVerificationDialog(true);
    };

    const handleAlertVerificationDialogClickClose = () => {
        setOpenAlertVerificationDialog(false);
    };

    const handleShowSellerContactDetailsDialogClickOpen = () => {
        setOpenShowSellerContactDetailsDialog(true);
    };

    const handleShowSellerContactDetailsDialogClickClose = () => {
        setOpenShowSellerContactDetailsDialog(false);
    };

    const handleVerifyClick = () => {
        history.push(Constants.VERIFICATION);
    };

    const handleEnlargeImgDialogClickOpen = (clickImg) => {
        setEnlargeImgDialog(true);
        setClickedImage(clickImg);
    };

    const handleEnlargeImgDialogClickClose = () => {
        setEnlargeImgDialog(false);
    };

    const handleDocumentDialogClickOpen = async (pdfUrl) => {
        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();
            const base64String = await blobToBase64(blob);
            setSelectedPDF({ contentType: blob.type, content: base64String });
            setOpenDocumentDialog(true);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleDocumentDialogClickClose = () => {
        setOpenDocumentDialog(false);
        setSelectedPDF({ contentType: null, content: null });
    };

    useEffect(() => {
        getAnnualContractDetails(contractId);
    }, []);

    const getAnnualContractDetails = async (contractId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `items/${contractId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setContractDetails(data);
            setSellerName(data.sellerName);
            setChipData(data.itemSuitabilities);
            setAnnualItemPackages(data.itemPackages);
            setProductImages(data.itemImages);
            setUploadedAttachment(data.itemAttachments);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }
        setLoading(false);
    };

    const handleImInterested = async (itemId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: "items/interest",
            method: "POST",
            body: {
                itemId: itemId,
            },
            addToast,
        });

        if (response.status === 200) {
            setLoading(false);
            addToast("Thank you for showing interest in our product !", { appearance: "success" });
        } else if (response.status === 409) {
            setLoading(false);
            addToast("You have already shown interest in this product !", { appearance: "success" });
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    }

    const getSellerContactDetails = async (productId) => {
        setLoading(true)
        const { response, data, error } = await apiRequests({
            endPoint: `${UrlRoute.GET_SELLER_CONTACT_DETAILS_URL}/${productId}`,
            method: "GET",
            addToast,
        });

        if (data) {
            setSellerContactNumber(data.response);
            setLoading(false);
            handleShowSellerContactDetailsDialogClickOpen();
        } else if (response.status === 403) {
            setLoading(false);
            handleAlertVerificationDialogClickOpen();
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }
    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="annualcontract-product-drawer">
                        <Navigation />
                    </div>

                    <div className="annualcontract-product-container" >
                        {loading ? (
                            <YLoader loading={loading} />
                        ) : contractDetails ? (
                            <div>
                                <Paper elevation={2} className="annualcontract-product-paper-container">
                                    <Grid container direction="row" >
                                        <Grid item xs={12}>
                                            <ImagesCarousel productImages={productImages} handleEnlargeImgDialogClickOpen={handleEnlargeImgDialogClickOpen} />
                                        </Grid>
                                    </Grid>

                                    <div className="annual_product_info">
                                        <Typography variant="h5" align="left" color="text.primary" className="text-capital annualcontract-product-title" gutterBottom>
                                            {contractDetails.title}
                                        </Typography>

                                        <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                            <span className='bold-text'>Seller :</span> {contractDetails.sellerName} {contractDetails.sellerVerified ? <Chip icon={<VerifiedIcon color="primary" />} label="Verified" variant="outlined" size="small" /> : ""}
                                        </Typography>

                                        <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                            <span className='bold-text'> Product :</span> {contractDetails.product}
                                        </Typography>

                                        <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital">
                                            <span className='bold-text'>Posting Date :</span> {contractDetails.created ? moment(contractDetails.created).format("D-MMM-YYYY") : "N/A"}
                                        </Typography>

                                        <Typography variant="subtitle1" align="left" className="text-capital" color="text.primary">
                                            <span className='bold-text'>Visiting Charges :</span> 1000
                                        </Typography>
                                    </div>

                                    <Grid container direction="row" style={{ marginTop: "14px" }}>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.primary" align="left" className='bold-text' >
                                                Product is useful for :
                                            </Typography>
                                        </Grid>
                                        {chipData.length > 0 ?
                                            <Grid item xs={12}>
                                                <Paper elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        listStyle: 'none',
                                                        backgroundColor: 'transparent',
                                                        p: 0.5,
                                                        m: 0,
                                                    }}
                                                    component="ul"
                                                >
                                                    {chipData.map((data) => {

                                                        return (
                                                            <ListItem key={data.id}>
                                                                <Chip variant="outlined"
                                                                    label={data.suitableFor}
                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff", textTransform: "capitalize" }}
                                                                />
                                                            </ListItem>
                                                        );

                                                    })
                                                    }
                                                </Paper>
                                            </Grid>
                                            :
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" color="text.primary">
                                                    N/A
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>

                                    {annualItemPackages.length > 0 ?
                                        <Grid container direction="column" style={{ marginTop: "14px" }}>
                                            <Grid item>
                                                <Typography variant="subtitle1" color="text.primary" align="left" className="text-capital bold-text" gutterBottom >
                                                    packages :
                                                </Typography>
                                            </Grid>

                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                                                        <TableRow>
                                                            <TableCell className="packagetable-header" align="center">Package Name</TableCell>
                                                            <TableCell className="packagetable-header" align="center">Duration</TableCell>
                                                            <TableCell className="packagetable-header" align="center">Price&nbsp;(&#8377;)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {annualItemPackages.map((value) => (
                                                            <TableRow
                                                                key={value.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="center">{value.packageName}</TableCell>
                                                                <TableCell align="center">{value.duration} {value.contractTerm}</TableCell>
                                                                <TableCell align="center"> &#8377; {value.price}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid> : ""}

                                    <Grid container direction="column" style={{ marginTop: "14px" }}>
                                        <Grid item>
                                            <Typography variant="subtitle1" align="left" color="text.primary" className="text-capital bold-text" >
                                                description :
                                            </Typography>
                                        </Grid>
                                        {contractDetails.description ?
                                            <Grid item >
                                                {contractDetails.description.length < 300 ?
                                                    <Typography variant="subtitle2" color="text.primary" component="span" >
                                                        {contractDetails.description}
                                                    </Typography>
                                                    :
                                                    <Typography variant="subtitle2" color="text.primary" component="span" >
                                                        <ReadMore>
                                                            {contractDetails.description}
                                                        </ReadMore>
                                                    </Typography>
                                                }
                                            </Grid> : "No description"}
                                    </Grid>

                                    <Grid container direction="column" style={{ marginTop: "14px" }}>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.primary" align="left" className="text-capital bold-text" >
                                                address :
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" color="text.primary" align="left" className="text-capital" >
                                                {contractDetails.city && contractDetails.state && contractDetails.country ? `${contractDetails.city}, ${contractDetails.state}, ${contractDetails.country}` :
                                                    (contractDetails.city && contractDetails.state ? `${contractDetails.city}, ${contractDetails.state}` :
                                                        (contractDetails.city && contractDetails.country ? `${contractDetails.city}, ${contractDetails.country}` :
                                                            (contractDetails.state && contractDetails.country ? `${contractDetails.state}, ${contractDetails.country}` :
                                                                contractDetails.city || contractDetails.state || contractDetails.country
                                                            )
                                                        )
                                                    )
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {uploadedAttachment && uploadedAttachment.length > 0 ?
                                        <Grid container direction="column" style={{ marginTop: "14px" }}>
                                            <Grid item>
                                                <Typography variant="subtitle1" color="text.primary" align="left" className="text-capital bold-text" >
                                                    uploaded attachment :
                                                </Typography>
                                            </Grid>
                                        </Grid> : ""}

                                    {uploadedAttachment && (
                                        <div className="pdf-container">
                                            {uploadedAttachment.map((element, index) => {
                                                return (
                                                    <div key={element.id} style={{ position: "relative" }}>
                                                        <FaRegFilePdf style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }} className='dialog-uploaded-icon' onClick={() => handleDocumentDialogClickOpen(element.attachmentLink.href)} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}


                                    <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ padding: "10px 0px" }}>
                                        {/* <Grid item xs={6}>
                                            <Button variant="contained" startIcon={<BsChat />} className="annualcontract-details-btn " fullWidth color="primary">
                                                chat
                                            </Button>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <Button variant="contained" startIcon={<PhoneIcon />} className="annualcontract-details-btn " fullWidth color="primary" onClick={() => getSellerContactDetails(contractId)} >
                                                call
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" className="annualcontract-details-btn" fullWidth color="primary" onClick={() => handleImInterested(contractDetails.id)}>
                                                I'm Interested
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        ) : (
                            <Grid container style={{ marginTop: "10px" }}>
                                <Grid item xs={12}>
                                    <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                        No results found
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        <div>
                            <ImageEnlargeDialog
                                enlargeImgDialog={enlargeImgDialog}
                                handleEnlargeImgDialogClose={handleEnlargeImgDialogClickClose}
                                clickedImage={clickedImage}
                            />
                        </div>

                        <div>
                            <Dialog open={openAlertVerificationDialog} onClose={handleAlertVerificationDialogClickClose}>
                                <DialogTitle >MeddyConnect</DialogTitle>
                                <DialogContent>

                                    <Typography variant="subtitle1" color="text.primary" align="left">
                                        You cannot contact seller as you are not a verified user
                                    </Typography>

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleAlertVerificationDialogClickClose} color="primary" >Cancel</Button>
                                    <Button onClick={handleVerifyClick} color="primary" >Verify</Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <div>
                            <Dialog open={openShowSellerContactDetailsDialog} onClose={handleShowSellerContactDetailsDialogClickClose}
                                maxWidth="sm" fullWidth TransitionComponent={Transition}
                            >
                                <DialogTitle >Seller Details</DialogTitle>
                                <DialogContent>

                                    <Typography variant="subtitle1" color="text.primary" align="left">
                                        <span className='bold-text'> Seller Name : </span> {sellerName}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.primary" align="left">
                                        <span className='bold-text'> Contact No : </span> <a href={`tel:${sellerContactNumber}`} style={{ color: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1", textDecoration: "none" }}>{sellerContactNumber}</a>
                                    </Typography>

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleShowSellerContactDetailsDialogClickClose} color="primary" >Cancel</Button>
                                </DialogActions>
                            </Dialog>
                        </div>

                        {/* ############################ PDF viewer dialog ######################################### */}

                        <Dialog
                            open={openDocumentDialog}
                            onClose={handleDocumentDialogClickClose}
                            fullScreen={fullScreen}
                            maxWidth="md"
                            fullWidth
                            TransitionComponent={Transition}
                        >
                            <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                                <IconButton
                                    edge="start"
                                    onClick={handleDocumentDialogClickClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                    }}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                                View Document
                            </DialogTitle>
                            <DialogContent style={{ padding: "0px" }}>

                                <PDFViewer pdfData={`data:${selectedPDF.contentType};base64,${selectedPDF.content}`} />

                            </DialogContent>
                        </Dialog>

                    </div>
                </div>

            </div>
        </>
    )
}

export default AnnualContractDetails