// Token Related Constants
const Constants = {
    TOKEN : "token",
    TOKEN_GENERATION_TIME: "tokenGenerationTime",
    TOKEN_EXPIRE : 4,
    COUNTRY_CODE :"countryCode",
    CONTACT_NO : "contactNo",
    PASSWORD : "password",
    DEVICE_TOKEN : "deviceToken",

    // Usertype
    DOCTOR : "doctor",
    HOSPITAL : "hospital",
    VENDOR : "vendor",
    STUDENT: "medical student",
    STAFF : "medical staff",
    ASSOCIATION : "medical association",

    // Pages
    HOME_SCREEN : "/home",

    // Marketplace
    PRODUCT_CATEGORY : "/productcategory",
    PRODUCT_SUBCATEGORY : "/productsubcategory",
    BUY_MEDICAL_INSTRUMENT : "/buymedicalinstrument",
    BUY_New_MEDICAL_INSTRUMENT : "/buynew",
    BUY_Used_MEDICAL_INSTRUMENT : "/buyused",
    SUBSCRIBE_PACKAGE_DETAILS : "/packagedetails",
    BUY_NEW_SINGLE_PRODUCT_DETAILS : '/productdetails',
    BUY_USED_SINGLE_PRODUCT_DETAILS : '/usedproductdetails',
    BUY_SINGLE_PRODUCT_DETAILS : '/allproductdetails',
    BUY_PRODUCT_DETAILS : '/productdetails',
    REPAIR_AND_CONTRACT : "/annualcontracts",
    REPAIR_AND_CONTRACT_DETAILS : '/contractdetails',
    SELL_PRODUCT : "/sell",
    REPAIR_AND_SELL_CONTRACTS : "/sellcontract",
    NEW_PRODUCT_BRAND_CATALOGUE : "/newproductcatelog",
    NEW_PRODUCT_CATALOGUE : "/allnewproductcatelog",
    NEW_PRODUCT_LAUNCH_DETAILS : '/newproductlaunchdetails',
    SHOW_CASE_YOUR_PRODUCT : "/showcaseproduct",

    // Verification
    VERIFICATION : "/verification",

    // Manage Products
    INVENTORY : "/inventory",
    SHOW_INTERESTED_USER : "/showinteresteduser",

    // Referral
    VIEW_USER_PROFILE : "/viewprofile",
    REFER_A_PATIENT : "/referpatient",
    REFERRED_TO_ME : "/referredtome",
    REFERRED_BY_ME : "/referredbyme",

    // Job and observership
    MANAGE_JOB_APPLICATIONS : "/managejobapplications",
    MANAGE_OBSERVERSHIP_APPLICATIONS : "/manageobservershipapplications",
    POST_A_JOB : "/postajobs",
    POST_AN_OBSERVERSHIP : "/postanobservership",
    ALL_JOB_APPLICATIONS : "/alljobs",
    JOB_DETAILS : "/jobdetails",
    ALL_OBSERVERSHIP_APPLICATIONS : "/allobservership",
    OBSERVERSHIP_DETAILS : "/observershipdetails",
    MY_JOB_APPLICATIONS : "/myjobapplications",
    MY_OBSERVERSHIP_APPLICATIONS : "/myobservershipapplications",
    VIEW_JOB_APPLICANTS : "/viewapplicantsfromjob",
    VIEW_OBSERVERSHIP_APPLICANTS : "/viewapplicantsfromobservership",
    EDIT_POST_A_JOB : "/editpostajob",
    EDIT_POST_AN_OBSERVERSHIP : "/editpostanobservership",

    // Chat
    CHAT_SCREEN : "/chat",

    // Notifications
    NOTIFICATION_SCREEN : "/notification",

    // Deactivate Account
    DEACTIVATE_ACCOUNT : "/deactivateaccount",

    // Shorts
    CREATE_SHORT : "/createshort",

}

export default Constants