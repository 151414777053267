import React from 'react'
import { Typography, } from '@mui/material';
import { useHistory } from "react-router-dom";
import "./viewmagazine.css";

const MedicalAssociationYearList = () => {
    const history = useHistory();

  return (
    <>
        <div className='medical-association-yearlist-container'>
                {medicalAssociationYearList.map((item) => {
                    return (
                        <>
                            <div className="medical-association-year_list" key={item.id} onClick={() => history.push("/allmagazines")}>
                                <div className='medical-association-year'>

                                    <Typography variant="subtitle1" align="center" className='medicalyear_text'>
                                        {item.name}
                                    </Typography>

                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
    </>
  )
}

export default MedicalAssociationYearList

const medicalAssociationYearList = [
    { name: '2023', id: 0},
    { name: '2022', id: 1 },
    { name: '2021', id: 2 },
    { name: '2019', id: 3},
    { name: '2018', id: 4},
    { name: "2017", id: 5  },
    { name: '2016', id: 6 },
    { name: '2015', id: 7  },
]