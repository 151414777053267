import { useState, useEffect } from "react";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import { Grid, TextField, Paper, Button, Chip, Typography, IconButton, Avatar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useAPIRequest from "../../API/useApiRequest";
import { FaRupeeSign } from 'react-icons/fa';
import { useToasts } from "react-toast-notifications";
import "./observershipdetails.css";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PlaceIcon from '@mui/icons-material/Place';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment";
import { fileCompressor } from "../../components/FileCompressor";
import CurrencyFormat from '../../utils/CurrencyFormat';
import ReadMore from "../../components/ReadMore";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Constants from "../../MeddyConnectConstant";

const ObservershipDetails = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { observershipApplicationID } = useParams();
  const history = useHistory();

  const [observershipApplicationDetails, setObservershipApplicationDetails] = useState("");
  const [observershipSkills, setObservershipSkills] = useState([]);
  const [observershipTags, setObservershipTags] = useState([]);
  const [coverLetter, setCoverLetter] = useState("");
  const [selectedFile, setSelectedFile] = useState({ contentType: null, content: null, name: null });

  // Services
  const { apiRequests } = useAPIRequest();

  useEffect(() => {
    getObservershipApplicationDetails(observershipApplicationID);
  }, []);

  const getObservershipApplicationDetails = async (observershipApplicationID) => {
    setLoading(true);
    const { response, data, error } = await apiRequests({
      endPoint: `internships/${observershipApplicationID}`,
      method: "GET",
      addToast,
    });

    if (data) {
      setObservershipApplicationDetails(data);
      setObservershipSkills(data.skills);
      setObservershipTags(data.tags);
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };

  const handleFileChange = async (event) => {

    setLoading(true);

    const file = event.target.files[0];

    if (!file) {
      setLoading(false);
      return;
    }

    let fileName = file.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "pdf") {

      if (extFile === "pdf" && file.size > 4 * 1024 * 1024) {
        addToast("PDF file size should be 4 MB or less", { appearance: "error" });
        setLoading(false);
        return;
      }

      let fileData = await fileCompressor(file, addToast);

      if (fileData) {

        let contentType = extFile === "pdf" ? "application/pdf" : `image/${extFile}`;

        setSelectedFile({ contentType: contentType, content: fileData, name: fileName });

      }
    } else {
      addToast("Please upload valid (jpeg/png/pdf) files!!!", {
        appearance: "error",
      });
    }

    setLoading(false);
  };

  const handleFileDelete = () => {
    setSelectedFile({ contentType: null, content: null, name: null });
  };


  const validateObservershipForm = () => {

    let valid = true;

    if (coverLetter.trim() === '') {
      addToast('Cover letter cannot be empty', { appearance: 'error' });
      valid = false;
    }

    // if (selectedFile.content === null && selectedFile.contentType === null) {
    //   addToast('Please upload your resume', { appearance: 'error' });
    //   valid = false;
    // }

    return valid;
  };

  const handleApplyForJobSubmit = () => {

    if (validateObservershipForm()) {
      submitObservershipApplication();
    }

  };

  const submitObservershipApplication = async () => {

    setLoading(true);

    const { response, data, error } = await apiRequests({
      endPoint: "internships/application",
      method: "POST",
      body: {
        internshipId: observershipApplicationID,
        coverLetter: coverLetter,
      },
      addToast,
    });

    if (data) {

      let applicationID = data.id;

      addToast("You have applied for the observership successfully !!!", { appearance: "success" });

      if (selectedFile.contentType !== null && selectedFile.content !== null) {

        const { response, data, error } = await apiRequests({
          endPoint: `internships/application/upload-resume/${applicationID}`,
          method: "POST",
          body: { contentType: selectedFile.contentType, content: selectedFile.content },
          addToast,
        });
        if (response.status === 200) {

          addToast("Resumes uploaded successfully !!!", { appearance: "success" });
          setCoverLetter("");
          setSelectedFile({ contentType: null, content: null, name: null });
          setLoading(false);
          history.push(Constants.MY_OBSERVERSHIP_APPLICATIONS);

        } else {
          setLoading(false);
          addToast("You have applied for the observership successfully, but there's an error uploading resume.!", { appearance: "error" });
          history.push(Constants.MY_OBSERVERSHIP_APPLICATIONS);
        }

      } else {
        setCoverLetter("");
        setSelectedFile({ contentType: null, content: null, name: null });
        setLoading(false);
        history.push(Constants.MY_OBSERVERSHIP_APPLICATIONS);
      }
    } else if (response.status === 409) {
      addToast("You already applied for this observership successfully !!!", { appearance: "success" });
      setCoverLetter("");
      setSelectedFile({ contentType: null, content: null, name: null });
      setLoading(false);
    } else {
      setLoading(false);
      addToast("Something went wrong!", { appearance: "error" });
    }

  };


  return (
    <>
      <div style={{ height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="apply-observership-details-drawer">
            <Navigation />
          </div>

          <div className=" apply-observership-details-container">

            <Paper elevation={2} className="apply-observership-details-paper">

              <div className="internship">

                <div className="internship-Container">
                  <Avatar src={observershipApplicationDetails.profileLink && observershipApplicationDetails.profileLink.href ? observershipApplicationDetails.profileLink.href : " "} color="primary" className='internship-company-picture' />
                  <div className="internship-info">
                    <Typography variant="subtitle1" align="left" style={{ fontWeight: "bold" }}>
                      {observershipApplicationDetails.userName}
                    </Typography>
                    <Typography variant="subtitle2" align="left">
                      Posted on : {observershipApplicationDetails.created ? moment(observershipApplicationDetails.created).format("MMM D, YYYY") : ""}
                    </Typography>
                  </div>
                </div>

                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Typography align="left" gutterBottom className="internship_designation">
                      {observershipApplicationDetails.title}
                    </Typography>
                  </Grid>
                </Grid>

                {observershipSkills.length > 0 && (
                  <Grid container direction="row" >
                    {observershipSkills.map((value, index) => {
                      return (
                        <Grid item style={{ margin: "0px 6px 6px 0px" }} key={index}>
                          <Chip label={value} variant="outlined" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }} />
                        </Grid>
                      );
                    })
                    }
                  </Grid>
                )
                }

                {observershipTags.length > 0 && (
                  <Grid container direction="row" >
                    {observershipTags.map((item, index) => {
                      return (
                        <Grid item style={{ margin: "0px 10px 6px 0px" }} key={index}>
                          <Typography variant="subtitle1" color="text.primary" >
                            {item}
                          </Typography>
                        </Grid>
                      );
                    })
                    }
                  </Grid>
                )
                }


                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" >
                      <span className='internship-description-title'>Observership Description :</span>
                    </Typography>

                    {observershipApplicationDetails.description ?
                      <Grid item xs={12}>
                        {observershipApplicationDetails.description.length < 300 ?
                          <Typography align="left" variant='subtitle1' color="text.primary">
                            {observershipApplicationDetails.description}
                          </Typography>
                          :
                          <Typography align="left" variant='subtitle1' color="text.primary">
                            <ReadMore>
                              {observershipApplicationDetails.description}
                            </ReadMore>
                          </Typography>
                        }
                      </Grid>
                      :
                      "No description"}
                  </Grid>
                </Grid>

                <div className="internship-details-container">
                  <div className="internshipDetails--WithIcon">
                    <Avatar className='internshipAvatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                      <FaRupeeSign className='internshipDetails--ReactIcon' />
                    </Avatar>
                    <Typography variant="subtitle2" >
                      Charges
                    </Typography>
                    <Typography variant="subtitle1" >
                      {
                        observershipApplicationDetails.feesPerMonth ?
                          <CurrencyFormat currencyPrice={observershipApplicationDetails.feesPerMonth} />
                          :
                          "N/A"
                      }
                    </Typography>
                  </div>

                  <div className="internshipDetails--WithIcon">
                    <Avatar className='internshipAvatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                      <AccessAlarmsIcon className='internshipDetails--materialuiIcon' />
                    </Avatar>
                    <Typography variant="subtitle2" >
                      Duration
                    </Typography>
                    <Typography variant="subtitle1" >
                      {observershipApplicationDetails.duration ? `${observershipApplicationDetails.duration} Week` : "N/A"}
                    </Typography>
                  </div>

                  <div className="internshipDetails--WithIcon">
                    <Avatar className='internshipAvatar-style' style={{ backgroundColor: theme.palette.mode === "light" ? "#002E6E" : "#00B9F1" }}>
                      <PlaceIcon className='internshipDetails--materialuiIcon' />
                    </Avatar>
                    <Typography variant="subtitle2" >
                      City
                    </Typography>
                    <Typography variant="subtitle1" >
                      {observershipApplicationDetails.city ? observershipApplicationDetails.city : "N/A"}
                    </Typography>
                  </div>

                </div>

                <Grid container >
                  <Grid item xs={12}>
                    <Typography className='internship-description-title' gutterBottom>
                      Cover Letter :
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth
                      label="Cover Letter"
                      name="coverLetter"
                      value={coverLetter}
                      onChange={(event) => setCoverLetter(event.target.value)}
                      autoComplete="off"
                      multiline
                      minRows={5}
                      id="coverLetter"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>


                <Grid container direction="column" style={{ marginTop: "6px" }}>
                  <Grid item xs={12}>
                    <Typography className='internship-description-title' gutterBottom>
                      Upload Resume :
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="column" justifyContent="center" alignItems="center" className="internship-upload--Container">
                  <Grid item>
                    <IconButton component="label" style={{ padding: "0px" }} >
                      <input hidden accept="image/*,.pdf" type="file" onChange={handleFileChange} />
                      <CloudUploadIcon color="primary" className="internship-upload--Icon" />
                    </IconButton>
                  </Grid>
                  <Grid item >
                    <Typography variant="subtitle1" align="center">
                      Supported Files
                    </Typography>
                    <Typography variant="subtitle2" align="center" gutterBottom>
                      PDF, JPG, PNG
                    </Typography>
                  </Grid>
                </Grid>

                {
                  selectedFile && selectedFile.contentType && selectedFile.content && selectedFile.name && (
                    <div className="job-pdf-container">
                      {
                        selectedFile.contentType.startsWith('image') ? (
                          <ImageIcon className='job-pdf-icon' />
                        )
                          : selectedFile.contentType === 'application/pdf' ? (
                            <PictureAsPdfIcon className='job-pdf-icon' />
                          )
                            : null
                      }
                      <Typography variant="subtitle1" className='job-pdf-filename'>
                        {selectedFile.name}
                      </Typography>
                      <ClearIcon className='job-pdf-icon' onClick={handleFileDelete} />
                    </div>
                  )
                }

              </div>

            </Paper>

            <Grid container direction="row" style={{ padding: fullScreen ? "0px 6px" : "0px 10px" }}>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth className='internship-apply-button' onClick={handleApplyForJobSubmit}>Apply for Observership</Button>
              </Grid>
            </Grid>

          </div>
        </div>
        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ObservershipDetails

