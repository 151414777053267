import { useState, useEffect } from "react";
import {
    Grid, Card, InputAdornment, CardActionArea, CardMedia, CardContent, TextField,
    Typography, Dialog, DialogTitle, DialogContent, Chip, IconButton, DialogActions, Button
} from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import PlaceIcon from '@mui/icons-material/Place';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import InfiniteScroll from "react-infinite-scroll-component";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import useAPIRequest from "../../API/useApiRequest";
import { useToasts } from "react-toast-notifications";
import "./buymedicalinstrument.css";
import ImageNotAvailable from "../../assets/images/imagenotavailable.jpg";
import CurrencyFormat from "../../utils/CurrencyFormat";
import useGetData from '../../API/useGetData';
import Constants from "../../MeddyConnectConstant";

let nextId = 0;

const BuyMedicalInstrument = (props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { addToast } = useToasts();
    const history = useHistory();

    const [buyProduct, setBuyProduct] = useState([]);
    const [pageNo, setPageNo] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [stateChoices, setStateChoices] = useState("");
    const [cityChoices, setCityChoices] = useState("");
    const [countryChoices, setCountryChoices] = useState("");
    const [productChoices, setProductChoices] = useState(['New', 'Used']);
    const [productType, setProductType] = useState("");
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [filterType, setFilterType] = useState("");
    const [isUsed, setIsUsed] = useState("");

    const selectedData = props.location.state.data;

    const { category, subCategory } = selectedData;

    // Services
    const { apiRequests } = useAPIRequest();
    const { getCountry, getStates, getCity } = useGetData();

    // Dialogs
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    const handleFilterDialogClickOpen = () => {
        setOpenFilterDialog(true);
    };

    const handleFilterDialogClickClose = () => {
        setOpenFilterDialog(false);
    };

    useEffect(() => {
        getReferenceData();
        getBuyProduct(searchTerm, category, subCategory, country, state, city, isUsed, pageNo);
    }, []);

    const getReferenceData = async () => {
        const countryOptions = await getCountry(addToast);
        if (countryOptions) {
            setCountry("");
            setCountryChoices(countryOptions);
        }
    };

    const getStatesData = async (country) => {
        if (country && country != "") {
            let stateOptions = [];
            stateOptions = await getStates(country, addToast);
            if (stateOptions) {
                setState("");
                setStateChoices(stateOptions);
            }
        }
    };

    const getCityData = async (country, state) => {
        if (country && country != "" && state && state != "") {
            let cityOptions = [];
            cityOptions = await getCity(country, state, addToast);
            if (cityOptions) {
                setCity("");
                setCityChoices(cityOptions);
            }
        }
    };

    const getBuyProduct = async (searchTerm, category, subCategory, country, state, city, isUsed, pageNo) => {

        setLoading(true);
        const { response, data, error } = await apiRequests({
            endPoint: `search/all-products?q=${searchTerm}&category=${category}&product=${subCategory}&country=${country}&state=${state}&city=${city}&used=${isUsed}&page=${pageNo}`,
            method: "GET",
            addToast,
        });

        if (data) {

            if (searchTerm || country || state || city || isUsed === true || isUsed === false) {
                setBuyProduct(data.entities);
            } else {
                setBuyProduct((prevData) => {
                    const uniqueEntities = data.entities.filter(newEntity =>
                        !prevData.some(existingEntity => existingEntity.id === newEntity.id)
                    );
                    return [...prevData, ...uniqueEntities];
                });
            }

            setTotalPages(data.totalPages);
            setPageNo(data.page);
            setLoading(false);
        } else {
            setLoading(false);
            addToast("Something went wrong!", { appearance: "error" });
        }

    };

    const handleClickCard = (id) => {
        history.push(`${Constants.BUY_PRODUCT_DETAILS}/${id}`);
    };

    // handle search related functions

    const handleSearchResultChange = (searchTerm) => {
        setSearchTerm(searchTerm);
        if (searchTerm.length === 0 || searchTerm.length >= 3) {
            setBuyProduct([]);
            setPageNo(0);
            getBuyProduct(searchTerm, category, subCategory, country, state, city, isUsed, 0);
        }
    };

    // handle filter related functions

    const handleCountryClick = (countryName) => {
        setCountry(countryName);
        setStateChoices([]);
        setCityChoices([]);
        getStatesData(countryName);
        if (selectedFilter.length < 5 && country === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: countryName,
                type: "Country"
            }
            ]);
        }
    };

    const handleStateClick = (stateName) => {
        setState(stateName);
        setCityChoices([]);
        getCityData(country, stateName);
        if (selectedFilter.length < 5 && state === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: stateName,
                type: "State"
            }
            ]);
        }
    };

    const handleCityClick = (cityName) => {
        setCity(cityName)
        if (selectedFilter.length < 5 && city === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: cityName,
                type: "City"
            }
            ]);
        }
    };

    const handleProductTypeClick = (typeName) => {
        setProductType(typeName)
        if (selectedFilter.length < 5 && productType === "") {
            setSelectedFilter([...selectedFilter,
            {
                id: nextId++,
                title: typeName,
                type: "InstrumentType"
            }
            ]);
        }
    };


    const handleFilterDelete = (type) => {
        if (type === "Country") {
            const removeItems = selectedFilter.filter(item =>
                item.type !== "InstrumentType"
            );
            const updatedCountry =
                selectedFilter.filter(item =>
                    !removeItems.includes(item)
                );
            setSelectedFilter(updatedCountry);
            setCountry("");
            setState("");
            setCity("");
            setBuyProduct([]);
            setPageNo(0);
            getBuyProduct(searchTerm, category, subCategory, "", "", "", isUsed, 0);
        } else if (type === "State") {
            const removeItems = selectedFilter.filter(item =>
                item.type !== "Country" && item.type !== "InstrumentType"
            );
            const updatedState =
                selectedFilter.filter(item =>
                    !removeItems.includes(item)
                );
            setSelectedFilter(updatedState);
            setState("");
            setCity("");
            setBuyProduct([]);
            setPageNo(0);
            getBuyProduct(searchTerm, category, subCategory, country, "", "", isUsed, 0);
        } else if (type === "City") {
            const updatedCity =
                selectedFilter.filter(item =>
                    item.type !== type
                );
            setSelectedFilter(updatedCity);
            setCity("");
            setBuyProduct([]);
            setPageNo(0);
            getBuyProduct(searchTerm, category, subCategory, country, state, "", isUsed, 0);
        } else if (type === "InstrumentType") {
            const updatedProductType =
                selectedFilter.filter(item =>
                    item.type !== type
                );
            setSelectedFilter(updatedProductType);
            setProductType("");
            setIsUsed("");
            setBuyProduct([]);
            setPageNo(0);
            getBuyProduct(searchTerm, category, subCategory, country, state, city, "", 0);
        }
    };

    const handleApplyFilterClick = (countryName, stateName, cityName, productType) => {

        setOpenFilterDialog(false);

        let updatedIsUsed;

        if (productType === "New") {
            updatedIsUsed = false;
        } else if (productType === "Used") {
            updatedIsUsed = true;
        } else {
            updatedIsUsed = "";
        }

        setBuyProduct([]);
        setPageNo(0);
        getBuyProduct(searchTerm, category, subCategory, countryName, stateName, cityName, updatedIsUsed, 0);
        setIsUsed(updatedIsUsed);

    };

    return (
        <>
            <div style={{ height: "auto" }}>

                <div className="buyinstrument-filter-container" style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000" }}>
                    <div className="buyinstrument-search-container" >
                        <div className="buyinstrument-search-filter-div" >

                            <div className="buyinstrument-searchbar-textfield">
                                <TextField fullWidth
                                    placeholder="Search Product..."
                                    name="searchbar"
                                    id="searchbar"
                                    autoComplete="off"
                                    onChange={(event) => handleSearchResultChange(event.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end" >
                                                < SearchIcon color="primary" />
                                            </InputAdornment>,
                                    }}
                                    className='buyinstrument-search-textfield'
                                />
                            </div>

                            <div className='icon-div'>
                                <IconButton onClick={handleFilterDialogClickOpen}>
                                    <TuneOutlinedIcon className='buyinstrument-filter-icon' color="primary" />
                                </IconButton>
                            </div>

                        </div>
                    </div>
                </div>

                <div style={{ display: "flex" }}>

                    <div className="buyinstrument-drawer">
                        <Navigation />
                    </div>

                    <div className="buyinstrument-container">

                        {selectedFilter.length > 0 &&
                            <>
                                <Grid container spacing={0.8} style={{ margin: "2px 0px 12px 0px" }}>
                                    {selectedFilter.map((item, index) => {
                                        return (
                                            <Grid item key={index}>
                                                <Typography variant="subtitle2" color="text.primary" style={{ display: "none" }}>
                                                    {item.type}
                                                </Typography>
                                                <Chip label={item.title}
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onDelete={() => handleFilterDelete(item.type)}
                                                />
                                            </Grid>
                                        );
                                    })
                                    }
                                </Grid>
                            </>
                        }

                        <div style={{ marginTop: "12px" }}>
                            <InfiniteScroll
                                style={{ overflow: "hidden" }}
                                dataLength={buyProduct.length}
                                next={() => {
                                    getBuyProduct(searchTerm, category, subCategory, country, state, city, isUsed, pageNo + 1);
                                }}
                                hasMore={!(pageNo + 1 === totalPages)}
                                loader={<YLoader loading={loading} />}
                                endMessage={<span></span>}
                            >
                                <Grid container direction="row" spacing={2}>
                                    {buyProduct.length > 0 ?
                                        buyProduct.map((value, index) => {
                                            return (
                                                <Grid item xs={12} sm={6} key={value.id}>
                                                    <Card className="buyinstrument-cards">
                                                        <CardActionArea onClick={() => { handleClickCard(value.id) }} >
                                                            {value.imageLink ?
                                                                <CardMedia
                                                                    component="img"
                                                                    height="180"
                                                                    image={value.imageLink.href}
                                                                    style={{ objectFit: "fill" }}
                                                                    alt="hosital equipment"
                                                                /> :
                                                                <CardMedia
                                                                    component="img"
                                                                    height="180"
                                                                    image={ImageNotAvailable}
                                                                    style={{ objectFit: "fill" }}
                                                                    alt="hosital equipment"
                                                                />
                                                            }
                                                            <CardContent>
                                                                <Grid container direction="row" alignItems="center" >
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6" color="text.primary" align="left" className="buyinstrument-text-capital buyinstrument-product-title buyinstrument-text">
                                                                            {value.title}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="subtitle1" color="text.primary" align="left" className="buyinstrument-text-capital">
                                                                            {value.brand}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Typography variant="subtitle1" color="text.primary" align="left" className="buyinstrument-text">
                                                                            <CurrencyFormat currencyPrice={value.price} />
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Typography variant="body1" color="text.primary" className="buyinstrument-card-details" >
                                                                            {value.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body2" color="text.primary" className="buyinstrument-text-capital" >
                                                                            <PlaceIcon color="primary" style={{ fontSize: fullScreen ? "20px" : "24px", verticalAlign: "middle", marginLeft: "-4px" }} />
                                                                            {value.city ? value.city : "N/A"}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            );
                                        })
                                        :
                                        <Grid container style={{ marginTop: "10px" }}>
                                            <Grid item xs={12}>
                                                <Typography align="center" color="text.primary" variant="h6" gutterBottom>
                                                    No results found
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                            </InfiniteScroll>

                        </div>

                        {/* ############################ filter dialog ######################################### */}
                        <Dialog open={openFilterDialog} onClose={handleFilterDialogClickClose} fullScreen={fullScreen} fullWidth>
                            <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                                <IconButton
                                    edge="start"
                                    onClick={handleFilterDialogClickClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                    }}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                                Filters
                            </DialogTitle>
                            <DialogContent>
                                <>

                                    <Grid container style={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" color="text.primary">
                                                Select Filter
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Chip label="Country"
                                                variant="outlined"
                                                value={filterType}
                                                color="primary"
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                onClick={(e) => setFilterType("Country")}
                                            />
                                        </Grid>
                                        {country === "India" &&
                                            <Grid item>
                                                <Chip label="State"
                                                    variant="outlined"
                                                    value={filterType}
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onClick={(e) => setFilterType("State")}
                                                />
                                            </Grid>
                                        }
                                        {state.length > 0 &&
                                            <Grid item>
                                                <Chip label="City"
                                                    variant="outlined"
                                                    value={filterType}
                                                    color="primary"
                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                    onClick={(e) => setFilterType("City")}
                                                />
                                            </Grid>
                                        }
                                        <Grid item>
                                            <Chip label="New/Used"
                                                variant="outlined"
                                                value={filterType}
                                                color="primary"
                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                onClick={(e) => setFilterType("InstrumentType")}
                                            />
                                        </Grid>
                                    </Grid>

                                    {selectedFilter.length > 0 &&
                                        <>

                                            <Grid container style={{ marginTop: "10px" }}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="text.primary">
                                                        Applied Filters
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                {selectedFilter.map((item, index) => {
                                                    return (
                                                        <Grid item key={index}>
                                                            <Typography variant="subtitle2" color="text.primary" style={{ display: "none" }}>
                                                                {item.type}
                                                            </Typography>
                                                            <Chip label={item.title}
                                                                variant="outlined"
                                                                color="primary"
                                                                style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                onDelete={() => handleFilterDelete(item.type)}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                                }
                                            </Grid>
                                        </>

                                    }

                                    <hr style={{ margin: "10px 0px" }} />

                                    {(() => {
                                        switch (filterType) {
                                            case "Country":
                                                return (
                                                    <>
                                                        {country === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(countryChoices) && countryChoices.map((option, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={option}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={country}
                                                                                    onClick={() => handleCountryClick(option)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "State":
                                                return (
                                                    <>
                                                        {state === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary" >
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(stateChoices) && stateChoices.map((name, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={name}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={state}
                                                                                    onClick={() => handleStateClick(name)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "City":
                                                return (
                                                    <>
                                                        {state.length > 0 && city === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(cityChoices) && cityChoices.map((element, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={element}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={city}
                                                                                    onClick={() => handleCityClick(element)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            case "InstrumentType":
                                                return (
                                                    <>
                                                        {productType === "" &&
                                                            <Grid container>
                                                                <Grid container style={{ marginTop: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1" color="text.primary">
                                                                            Options
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0.8} style={{ marginTop: "10px" }}>
                                                                    {Array.isArray(productChoices) && productChoices.map((element, index) => {
                                                                        return (
                                                                            <Grid item key={index}>
                                                                                <Chip label={element}
                                                                                    variant="outlined"
                                                                                    color="primary"
                                                                                    style={{ backgroundColor: theme.palette.mode === "light" ? "#fff" : "#292929", color: theme.palette.mode === "light" ? "#000" : "#fff" }}
                                                                                    value={productType}
                                                                                    onClick={() => handleProductTypeClick(element)}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </>
                                                );

                                            default:
                                                return <></>;
                                        }
                                    })()}

                                </>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="primary" fullWidth className='buyinstrument-dialog-button' onClick={() => { handleApplyFilterClick(country, state, city, productType) }} > Apply </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                </div>

                <YLoader loading={loading} />
            </div>

        </>
    )
}

export default BuyMedicalInstrument