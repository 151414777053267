import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { YContext } from "../../Context/YContext";
import Constants from "../../MeddyConnectConstant";
import YLoader from "../../components/Loader";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, setCurrentUser, setIsAuthenticated } = React.useContext(YContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
    
    // Check if the authentication token is present in localStorage
    const token = localStorage.getItem(Constants.TOKEN);

    if (!token) {
      setCurrentUser({});
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }

    // Set loading to false after the check is complete
    setLoading(false);
  }

  checkAuthentication();

  }, [setCurrentUser, setIsAuthenticated]);

  if (loading) {
    return  <YLoader loading={loading} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};
export default PrivateRoute;
