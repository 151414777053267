import React, { useState, useEffect } from 'react'
import {
  Box, Button, Slide, IconButton,Grid,TextField
  , Dialog, DialogTitle, DialogContent, DialogActions,
} from "@mui/material";
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import "./viewparticipants.css";
import { DataGrid } from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'applicantsName',
    headerName: 'Applicants name',
    width: 150,
    editable: true,
  },
  {
    field: 'applydate',
    headerName: 'Apply Date',
    width: 150,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 110,
    editable: true,
  },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

const rows = [
  { id: 1, applicantsName: 'Nishikant Ambhore', applydate: "Feb 24, 2023", status: "respondent" },
  { id: 2, applicantsName: 'Sushil Salve', applydate: "Jan 24, 2022", status: "null" },
  { id: 3, applicantsName: 'Ganesh Jadhav', applydate: "May 24, 2021", status: "respondent" },
  { id: 4, applicantsName: 'Akshay Gandal', applydate: "Jan 24, 2023", status: "null" },
  { id: 5, applicantsName: 'Praneet Metange', applydate: "Dec 05, 2021", status: "null" },
  { id: 6, applicantsName: "Rohan Dayal", applydate: "Nov 16, 2023", status: "respondent" },
  { id: 7, applicantsName: 'Rohit Waghmare', applydate: "Mar 08, 2022", status: "respondent" },
  { id: 8, applicantsName: 'Mangesh Aakhade', applydate: "Apr 29, 2022", status: "null" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewParticipantsFromUpcomingTraining = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [bulkResponseDialogOpen, setBulkResponseDialogOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleClickOpen = () => {
    setBulkResponseDialogOpen(true);
  };

  const handleClose = () => {
    setBulkResponseDialogOpen(false);
  };


  return (
    <>
      <div style={{ backgroundColor: "#fff", height: "auto" }}>

        <div style={{ display: "flex" }}>

          <div className="manage-training-table-Drawer">
            <Navigation />
          </div>

          <div className="manage-training-table-container">

            <div className='send-response-btn-container'>
              <Button variant="contained" className="send-response-btn" onClick={handleClickOpen} >Bulk Response</Button>
            </div>

            <Box sx={{ height: "auto", width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pdateSize={5}
                rowsPerPdateOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>

            {/* ############################ Show apply for job dialog ######################################### */}
            <Dialog
              open={bulkResponseDialogOpen}
              onClose={handleClose}
              fullScreen={fullScreen}
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
            >
              <DialogTitle style={{ backgroundColor: "#00B9F1", color: "white" }}>
                <IconButton
                  edge="start"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
                Bulk Response
              </DialogTitle>
              <DialogContent>

                <Grid container direction="row" spacing={0.5} disabled>
                  <Grid item xs={12} className='bulk-response-textarea'>
                    {/* <ReactQuill theme="snow" modules={modules} value={value} onChange={setValue} className='bulk-response-editor' /> */}
                  </Grid>
                </Grid>

              </DialogContent>
              <DialogActions>
                <Button variant="contained" fullWidth className='bulk-response-button'>Send</Button>
              </DialogActions>
            </Dialog>


          </div>
        </div>

        <YLoader loading={loading} />
      </div>
    </>
  )
}

export default ViewParticipantsFromUpcomingTraining

