import React, { useState } from 'react'
import Navigation from "../../Navigation/Navigation";
import YLoader from "../../components/Loader";
import './referraldashboardpage.css';
import {
    Grid, Tab, Tabs, Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dashboard from './Dashboard';
import ReferredToMe from './ReferredToMe';
import ReferredByMe from './ReferredByMe';



const ReferralDashboardPage = () => {
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <div style={{ backgroundColor: "#fff", height: "auto" }}>

                <div style={{ display: "flex" }}>

                    <div className="leftDrawer">
                        <Navigation />
                    </div>

                    <div className="referralDashboard-Container">

                        <Grid container>
                            <Grid item xs={12}>
                                <Box >
                                    <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons={false}>
                                        <Tab label="Dashboard" />
                                        <Tab label="Referred to Me" />
                                        <Tab label="Referred by Me" />
                                    </Tabs>
                                </Box>
                            </Grid>
                        </Grid>
                        {selectedTab === 0 && <Dashboard />}
                        {selectedTab === 1 && <ReferredToMe />}
                        {selectedTab === 2 && <ReferredByMe />}

                    </div>
                </div>


                <YLoader loading={loading} />
            </div>
        </>
    )
}

export default ReferralDashboardPage