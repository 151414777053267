import React from "react";
import { Paper, Typography, IconButton, Button } from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import "../../Profile/Profile.css";

const ViewCareer = ({ userCareerData }) => {

    return (
        <>
            {/* ############################ career section ######################################### */}
            <div>
                
                <div>
                    <Typography align="left" className="view-user-title" color="text.primary">
                        Career
                    </Typography>
                </div>

                {userCareerData && userCareerData.map((element) => {
                    return (
                        <Paper elevation={4} className="paper-container" key={element.id}>

                            <div className="section_container">
                                <IconButton>
                                    <ApartmentIcon color="primary" fontSize="large" />
                                </IconButton>
                                <div className='section_details'>
                                    <Typography align="left" className="profile_title profile_text" color="text.primary" >
                                        {element.title}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {element.company}
                                    </Typography>
                                    <Typography align="left" className="profile_subtitle profile_text" color="text.primary">
                                        {element.from} - {element.currentEmployer === true ? "Present" : element.to}
                                    </Typography>
                                    <Typography align="left" className="profile_description profile_text" color="text.primary">
                                        {element.city}, {element.state}, {element.country}
                                    </Typography>
                                </div>

                            </div>

                        </Paper>
                    );
                })}

            </div >
        </>
    )
}

export default ViewCareer
